import React from "react";
import { Field, reduxForm } from "redux-form";
import history from "../../history";
import * as util from "../../shared/utility";

class ProjectFileForm extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  handleCancelClick = () => {
    history.goBack();
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <Field
          name="newName"
          component={util.RenderInput}
          label={"Rename: " + this.props.initialValues.oldName}
        />
        <button className="ui button primary" disabled={this.props.submitting}>
          Save
        </button>
        <button
          type="button"
          className="ui button"
          onClick={this.handleCancelClick}
        >
          Cancel
        </button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.newName) {
    errors.newName = "You must enter a name for the file.";
  } else if (formValues.newName.lastIndexOf(".") === -1) {
    errors.newName = "You cannot remove the extension.";
  } else if (
    formValues.newName.substr(formValues.newName.lastIndexOf(".") + 1) !==
    formValues.ext
  ) {
    errors.newName = "You cannot change the extension.";
  } else if (
    formValues.newName.substr(0, formValues.newName.lastIndexOf(".")).length ===
    0
  ) {
    errors.newName = "The file needs a name.";
  } else if (formValues.newName.includes("%")) {
    errors.newName = "The file can't have a %-sign.";
  }

  return errors;
};

export default reduxForm({
  form: "projectFileForm",
  validate,
})(ProjectFileForm);
