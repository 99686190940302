import solartoolapi from "../apis/solartoolapi";
import history from "../history";
import {
  GENERATE_OFFER,
  FETCH_OFFER,
  DELETE_OFFER,
  EDIT_OFFER,
  FETCH_OFFER_FOR_PROJECT,
  UNLOAD_OFFER,
  GENERATE_PDF_OFFER,
  FETCH_OFFER_PROJECTID,
  UNLOAD_OFFERS,
  DOWNLOAD_PROJECT_FILE,
  RESET_OFFERS_MESSAGE,
} from "./types";
import { authHeader } from "../shared/utility";
import * as actions from "../actions";
import fileDownload from "js-file-download";

export const generateOffer =
  (calculationID, projectID) => async (dispatch, getState) => {
    const response = await solartoolapi.post(
      `/offers/${calculationID}`,
      {},
      { headers: authHeader() }
    );

    dispatch({ type: GENERATE_OFFER, payload: response.data.result });

    history.push(`/sales/offers/edit/${response.data.result.offerID}`);
  };

export const editOffer = (id, formValues, projectID) => async (dispatch) => {
  const response = await solartoolapi.put(`/offers/${id}`, formValues, {
    headers: authHeader(),
  });

  dispatch({ type: EDIT_OFFER, payload: response.data.result });

  history.push(`/sales/projects/dashboard/${projectID}`);
};

export const deleteOffer = (id) => async (dispatch) => {
  await solartoolapi.delete(`/offers/${id}`, { headers: authHeader() });

  dispatch({ type: DELETE_OFFER, payload: id });
  history.go(-1);
};

export const fetchOffer = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/offers/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_OFFER, payload: response.data.result });
  dispatch(actions.fetchOfferProjectID(id));
};

export const fetchOffersForProject = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/offers/project/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_OFFER_FOR_PROJECT, payload: response.data.result });
};

export const generateOfferPdf = (offerid, projectid) => async (dispatch) => {
  const response = await solartoolapi.post(
    `/offers/generatepdf/${offerid}`,
    {},
    {
      headers: authHeader(),
    }
  );

  dispatch({ type: GENERATE_PDF_OFFER, payload: response.data.message });
  dispatch(actions.fetchProjectFiles(projectid));
};

export const previewOfferPdf = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/offers/previewpdf/${id}`, {
    headers: authHeader(),
    responseType: "blob",
  });

  var previewPdfName = `Offerte_DeLaatSolarSystems_Preview_${Date.now()}.pdf`;

  fileDownload(response.data, previewPdfName);
  dispatch({ type: DOWNLOAD_PROJECT_FILE, payload: previewPdfName });
};

export const fetchOfferProjectID = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/offers/${id}/getprojectid`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_OFFER_PROJECTID, payload: response.data.result });
};

export const unloadOffer = () => (dispatch) => {
  dispatch({ type: UNLOAD_OFFER });
};

export const unloadOffers = () => (dispatch) => {
  dispatch({ type: UNLOAD_OFFERS });
};

export const resetOffersMessage = () => (dispatch) => {
  dispatch({ type: RESET_OFFERS_MESSAGE });
};
