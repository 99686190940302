import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { Link } from "react-router-dom";
import * as util from "../../shared/utility";
import { Grid } from "semantic-ui-react";

import ProjectBreadcrumb from "../breadcrumb/ProjectBreadcrumb";
import StandardTableHeader from "../UI/StandardHeader";

class OfferList extends React.Component {
  componentDidMount() {
    const { projectid } = this.props.match.params;
    this.props.fetchOffersForProject(projectid);
  }

  componentWillUnmount() {
    this.props.unloadOffers();
  }

  renderTable() {
    let offerslist = null;

    if (this.props.offers.length !== 0) {
      offerslist = this.props.offers
        .sort((a, b) =>
          b.lastUpdatedDateTime.localeCompare(a.lastUpdatedDateTime)
        )
        .map((offer) => {
          return (
            <tr key={offer.offerID}>
              <td className="mobile hidden">{offer.offerID}</td>
              <td>
                <Link
                  className="item"
                  to={`/sales/offers/edit/${offer.offerID}`}
                >
                  <div className="item">{offer.name}</div>
                </Link>
              </td>
              <td className="center aligned">{offer.revenuePercentage}</td>
              <td className="center aligned mobile hidden">
                {util.utcStringToLocalDateTime(offer.lastUpdatedDateTime)}
              </td>
              <td>
                <i
                  style={{ cursor: "pointer" }}
                  className="file pdf icon orange"
                  title="Download file preview."
                  onClick={() => this.props.previewOfferPdf(offer.offerID)}
                />
              </td>
              <td>
                <i
                  style={{ cursor: "pointer" }}
                  className="file save icon blue"
                  title="Create file in project folder."
                  onClick={() => this.props.generateOfferPdf(offer.offerID)}
                />
              </td>
              <td>
                <Link
                  className="item"
                  to={`/sales/offers/delete/${offer.offerID}`}
                  key={offer.offerID}
                >
                  <i className="trash icon" title="Delete record." />
                </Link>
              </td>
            </tr>
          );
        });
    }

    return (
      <div>
        <table className="ui celled table unstackable">
          <thead>
            <tr>
              <th className="one wide mobile hidden">ID</th>
              <th className="nine wide">Name</th>
              <th className="two wide center aligned">%</th>
              <th className="three wide center aligned mobile hidden">
                Changed
              </th>
              <th className="one wide center aligned" colSpan="3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>{offerslist}</tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <StandardTableHeader headerLabel="Offers">
                <ProjectBreadcrumb
                  projectID={this.props.match.params.projectid}
                  currentPath="Offers"
                />
              </StandardTableHeader>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{this.renderTable()}</Grid.Column>
          </Grid.Row>
        </Grid>
        {(() => {
          if (this.props.showmessage) {
            return (
              <div className="ui positive message">
                <i
                  className="close icon"
                  onClick={this.props.resetOffersMessage}
                />
                <div className="header">Response:</div>
                <p>{this.props.responsemessage}</p>
              </div>
            );
          }
        })()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    offers: state.offers.items,
    isMobile: state.utility.isMobile,
    responsemessage: state.offers.responsemessage,
    showmessage: state.offers.showmessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOffersForProject: (id) => dispatch(actions.fetchOffersForProject(id)),
    generateOfferPdf: (id) => dispatch(actions.generateOfferPdf(id)),
    previewOfferPdf: (id) => dispatch(actions.previewOfferPdf(id)),
    unloadOffers: () => dispatch(actions.unloadOffers()),
    resetOffersMessage: () => dispatch(actions.resetOffersMessage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferList);
