import React from "react";

import { useSelector } from "react-redux";

import DesktopMenu from "./desktopMenu/DesktopMenu";
import MobileMenu from "./mobileMenu/MobileMenu";

import MenuItems from "./MenuItems";

import { app_stage } from "../../shared/utility";
import logo from "../../assets/images/delaatsolar_24dpi.png";
import stagingImage from "../../assets/images/delaatsolar_24dpi_staging.png";
import developmentImage from "../../assets/images/delaatsolar_24dpi_development.png";

const Header = () => {
  const isMobile = useSelector((state) => state.utility.isMobile);
  const isAuthenticated = useSelector((state) => state.auth.authenticated);

  const environmentLogo =
    app_stage === "Development"
      ? developmentImage
      : app_stage === "Staging"
      ? stagingImage
      : logo;

  if (!isMobile) {
    return (
      <DesktopMenu
        menuItems={MenuItems}
        isAuthenticated={isAuthenticated}
        logo={environmentLogo}
      />
    );
  } else {
    return (
      <MobileMenu
        menuItems={MenuItems}
        isAuthenticated={isAuthenticated}
        logo={environmentLogo}
      />
    );
  }
};

export default Header;
