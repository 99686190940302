import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import "./DesktopMenu.css";

import DesktopMenuItem from "./desktopMenuItem/DesktopMenuItem";
import DesktopMenuDropdown from "./desktopMenuDropdown/DesktopMenuDropdown";

const DeskTopHeader = ({ menuItems, isAuthenticated, logo }) => {
  const mainMenu =
    isAuthenticated &&
    menuItems.map((menuItem, index) => {
      if (!menuItem.subMenuItems) {
        return <DesktopMenuItem key={index} menuItem={menuItem} />;
      } else {
        return <DesktopMenuDropdown key={index} menuItem={menuItem} />;
      }
    });

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <div className="desktopMenu">
            <Link to="/" id="home" className="HeaderContainer desktopMenuLogo">
              <img className="HeaderImage" src={logo} alt="logo" />
            </Link>
            {mainMenu}
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default DeskTopHeader;
