const menuItems = [
  // { label: "Relations", path: "/relations" },
  {
    label: "Sales",
    path: "/sales",
    subMenuItems: [
      { label: "Projects", path: "/sales/projects" },
      { label: "Planning", path: "/sales/planning" },
      { label: "Materials", path: "/sales/materials" },
      { label: "Customers", path: "/sales/customers" },
      // { label: "New Customer", path: "/sales/customers/new" },
    ],
  },
  {
    label: "Inventory",
    path: "/inventory",
    subMenuItems: [
      { label: "Articles", path: "/inventory/articles" },
      { label: "Inventory", path: "/inventory/inventorylist" },
    ],
  },
  {
    label: "Purchasing",
    path: "/purchasing",
    subMenuItems: [
      { label: "Purchase Orders", path: "/purchasing/purchaseorders" },
      { label: "Suppliers", path: "/purchasing/suppliers" },
      // { label: "New Supplier", path: "/purchasing/suppliers/new" },
    ],
  },
  {
    label: "Application",
    path: "/application",
    subMenuItems: [
      { label: "Files", path: "/application/files" },
      { label: "Mail Templates", path: "/application/mailtemplates" },
      { label: "Users", path: "/application/users" },
      { label: "Change Log", path: "/application/changelog" },
    ],
  },
  // {
  //   label: "System",
  //   path: "/system",
  //   subMenuItems: [
  //     { label: "Test Page", path: "/system/testpage" },
  //     { label: "Style Guide", path: "/system/styleguide" },
  //   ],
  // },
  {
    label: "User",
    path: "/logout",
    subMenuItems: [
      //{ label: "Profile", path: "/user/profile" },
      { label: "Logout", path: "/logout" },
    ],
  },
];

export default menuItems;
