import _ from "lodash";

import {
  FETCH_PURCHASE_ORDER,
  UNLOAD_PURCHASE_ORDER,
  FETCH_PURCHASE_ORDERS_PAGED,
  SET_PURCHASE_ORDERS_PAGE_FILTER,
  RESET_PURCHASE_ORDERS_FILTER,
  SET_PURCHASE_ORDERS_PAGE_NUMBER,
  TOGGLE_PURHCASE_ORDERS_SHOW_ORDERED,
  TOGGLE_PURHCASE_ORDERS_SHOW_PLANNED,
  TOGGLE_PURHCASE_ORDERS_SHOW_DELIVERED,
  SET_PURCHASE_ORDER_RETURN_ON_SAVE,
} from "../actions/types";

var initialState = {
  item: null,
  pageItems: {},
  pageMetadata: {},
  pageNumber: 1,
  pageFilter: "",
  showOrdered: true,
  showPlanned: true,
  showDelivered: false,
  purchaseOrderReturnOnSave: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PURCHASE_ORDER:
      return {
        ...state,
        item: action.payload,
      };
    case UNLOAD_PURCHASE_ORDER:
      return {
        ...state,
        item: null,
      };
    case FETCH_PURCHASE_ORDERS_PAGED:
      return {
        ...state,
        pageItems: {
          ..._.mapKeys(action.payload.result, "purchaseOrderID"),
        },
        pageMetadata: { ...action.payload.metadata },
      };
    case SET_PURCHASE_ORDERS_PAGE_FILTER:
      return {
        ...state,
        pageFilter: action.payload,
        pageNumber: 1,
      };
    case RESET_PURCHASE_ORDERS_FILTER:
      return {
        ...state,
        pageFilter: "",
        pageNumber: 1,
        showOrdered: initialState.showOrdered,
        showPlanned: initialState.showPlanned,
        showDelivered: initialState.showDelivered,
      };
    case SET_PURCHASE_ORDERS_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case TOGGLE_PURHCASE_ORDERS_SHOW_ORDERED:
      return {
        ...state,
        showOrdered: !state.showOrdered,
        pageNumber: 1,
      };
    case TOGGLE_PURHCASE_ORDERS_SHOW_PLANNED:
      return {
        ...state,
        showPlanned: !state.showPlanned,
        pageNumber: 1,
      };
    case TOGGLE_PURHCASE_ORDERS_SHOW_DELIVERED:
      return {
        ...state,
        showDelivered: !state.showDelivered,
        pageNumber: 1,
      };
    case SET_PURCHASE_ORDER_RETURN_ON_SAVE:
      return {
        ...state,
        purchaseOrderReturnOnSave: action.payload,
      };

    default:
      return state;
  }
};
