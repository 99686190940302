import {
  AUTH_REQUEST_START,
  AUTH_REQUEST_SUCCESS,
  AUTH_REQUEST_FAILURE,
  LOGIN,
  LOGOUT,
  CLEAR_AUTH,
} from "../actions/types";

let user = JSON.parse(localStorage.getItem("user"));

const initialState = { authenticated: user ? true : false };

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_REQUEST_START:
      return {
        ...state,
        authError: false,
        authErrorMessage: "",
        authSuccess: false,
        authSuccessMessage: "",
        authRequest: true,
      };
    case AUTH_REQUEST_SUCCESS:
      return {
        ...state,
        authError: false,
        authErrorMessage: "",
        authSuccess: true,
        authSuccessMessage: `${new Date().toLocaleString()} --- ${
          action.payload
        }`,
        authRequest: false,
      };
    case AUTH_REQUEST_FAILURE:
      return {
        ...state,
        authError: true,
        authErrorMessage: `${new Date().toLocaleString()} --- ${
          action.payload
        }`,
        authSuccess: false,
        authSuccessMessage: "",
        authRequest: false,
      };
    case LOGOUT:
      return { ...state, authenticated: false };
    case LOGIN:
      return { ...state, authenticated: true };
    case CLEAR_AUTH:
      return {
        ...state,
        authError: false,
        authErrorMessage: "",
        authSuccess: false,
        authSuccessMessage: "",
        authRequest: false,
      };

    default:
      return state;
  }
};
