import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Popup } from "semantic-ui-react";

import * as actions from "../../../actions";

const ProjectFileUpload = ({ projectid, mobileTextHidden = true }) => {
  const dispatch = useDispatch();

  const fileInput = useRef(null);

  const fileChangedHandler = (event) => {
    let newErrorMessages = [];
    Array.from(event.target.files).forEach((file) => {
      if (file.name.includes("%")) {
        newErrorMessages.push(`Can't upload file with the name: ${file.name}`);
      } else {
        dispatch(actions.createProjectFile(projectid, file));
      }
    });

    if (newErrorMessages.length > 0) {
      dispatch(actions.setProjectFilesErrorMessages(newErrorMessages));
    }
  };

  const FileUploadControl = () => {
    return (
      <div className="dashboardFileUpload">
        <input
          style={{ display: "none" }}
          type="file"
          multiple
          onChange={fileChangedHandler}
          ref={fileInput}
        />
        <button
          className="ui button primary small"
          onClick={() => fileInput.current.click()}
        >
          <i className="upload icon" />
          <div
            className={`${mobileTextHidden ? "mobile hidden" : ""}`}
            style={{ display: "inline" }}
          >
            Upload Project File
          </div>
        </button>
        <Popup
          wide
          position="bottom right"
          trigger={
            <i
              className="info circle icon big"
              style={{
                color: "var(--company-bg-color-100)",
                margin: "6px",
              }}
            />
          }
        >
          <Popup.Content>
            <h3>Multiple files:</h3>
            <p>Use shift or control to upload multiple files.</p>
            <h3>Special files:</h3>
            <p>
              Project file with the name "maps.jpg" or "maps.png" will be used
              on the visit.
            </p>
            <p>
              Project Files with "woonhuis" in the name will be added
              automatically to the first card.
            </p>
            <p>
              Project Files with "meterkast" in the name will be added
              automatically to a separated card.
            </p>
            <p>
              Project file with the name "kabelrouting" in the name will be
              added automatically to a separated card.
            </p>
            <p>
              Project file with the name "lay-out" in the name will be added
              automatically to a separated card.
            </p>
            <p>
              Project file with the name "stringplan" in the name will be added
              automatically to a separated card.
            </p>
            <p>
              Project file with the name "vervangingMK" in the name will be
              added automatically to a separated card.
            </p>
          </Popup.Content>
        </Popup>
      </div>
    );
  };

  return (
    <div className="column">
      <FileUploadControl />
    </div>
  );
};

export default ProjectFileUpload;
