import React from "react";
import { Field, reduxForm } from "redux-form";
import history from "../../history";
import * as util from "../../shared/utility";

const UserForm = (props) => {
  const { handleSubmit, onSubmit, submitting } = props;

  const handleCancelClick = () => {
    history.goBack();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="ui form error">
      <Field name="name" component={util.RenderInput} label="Name:" />
      <Field name="email" component={util.RenderInput} label="Email:" />
      <button className="ui button primary" disabled={submitting}>
        Save
      </button>
      <button type="button" className="ui button" onClick={handleCancelClick}>
        Cancel
      </button>
    </form>
  );
};

const validate = (formValues) => {
  const errors = {};

  if (!formValues.email) {
    errors.email = "You must enter an email address.";
  }

  if (!formValues.name) {
    errors.name = "You must enter a valid name.";
  }

  if (formValues.email && formValues.email.length > 200) {
    errors.email = "The email cannot exceed 200 characters.";
  }

  if (formValues.name && formValues.name.length > 200) {
    errors.name = "The name cannot exceed 200 characters.";
  }

  return errors;
};

export default reduxForm({
  form: "userForm",
  validate,
})(UserForm);
