import _ from "lodash";
import {
  FETCH_ARTICLE,
  FETCH_ARTICLES,
  CREATE_ARTICLE,
  EDIT_ARTICLE,
  DELETE_ARTICLE,
  SET_ARTICLES_FILTER,
  SET_ARTICLES_OFFSET,
  FETCH_INVENTORY,
  SET_INVENTORY_FILTER,
  SET_INVENTORY_OFFSET,
  TOGGLE_SHOW_ONLY_ACTION_REQUIRED,
  SET_INVENTORY_LIST_ARTICLE_INVENTORY_CATEGORY_FILTER,
  RESET_INVENTORY_LIST_FILTER,
  FETCH_INVENTORY_ITEM,
  UNLOAD_INVENTORY_ITEM,
  SET_INVENTORY_LIST_ARTICLE_CATEGORY_FILTER,
  TOGGLE_INVENTORY_LIST_INVENTORY_ORDER_ECONOMIC,
} from "../actions/types";

var initialState = {
  items: [],
  filteredItems: [],
  articlesFilter: "",
  articlesOffset: 0,
  articlesLimit: 10,
  inventory: [],
  inventoryFilteredItems: [],
  inventoryFilter: "",
  inventoryOffset: 0,
  showOnlyActionRequired: true,
  inventoryListArticleInventoryCategoryFilter: "3",
  inventoryListArticleCategoryFilter: "",
  inventoryItem: null,
  inventoryOrderEconomic: false,
};

const filterList = (data, filter) => {
  return data.filter((article) => {
    return (
      article.name.toUpperCase().includes(filter.toUpperCase()) ||
      article.category.name.toUpperCase().includes(filter.toUpperCase())
    );
  });
};

const filterInventory = (
  data,
  filter,
  showOnlyActionRequired,
  inventoryListArticleInventoryCategoryFilter,
  inventoryListArticleCategoryFilter,
  inventoryOrderEconomic
) => {
  return data
    .map((x) => {
      return {
        ...x,
        diff: inventoryOrderEconomic
          ? x.economicStock - x.reorderPoint
          : x.technicalStock - x.reorderPoint,
      };
    })
    .filter((article) => {
      return article.name.toUpperCase().includes(filter.toUpperCase());
    })
    .filter((x) => {
      return (
        showOnlyActionRequired === false ||
        x.economicStock < x.reorderPoint ||
        x.technicalStock < x.reorderPoint
      );
    })
    .filter((x) => {
      return (
        inventoryListArticleInventoryCategoryFilter === "" ||
        (x.articleInventoryCategory > 0 &&
          x.articleInventoryCategory <=
            inventoryListArticleInventoryCategoryFilter)
      );
    })
    .filter((x) => {
      return (
        inventoryListArticleCategoryFilter === "" ||
        x.categoryID === +inventoryListArticleCategoryFilter
      );
    });
};

const processInventoryItem = (data) => {
  let currentEconomicStock = data.mutations
    .filter((x) => new Date(x.orderDate) <= new Date())
    .sort((a, b) => b.orderDate.localeCompare(a.orderDate))
    .slice(0, 1);

  if (currentEconomicStock.length > 0) {
    currentEconomicStock = currentEconomicStock[0].economicStock;
  } else {
    currentEconomicStock = 0;
  }

  let currentTechnicalStock = data.mutations
    .filter(
      (x) => x.deliverDate != null && new Date(x.deliverDate) <= new Date()
    )
    .sort((a, b) => b.deliverDate.localeCompare(a.deliverDate))
    .slice(0, 1);

  if (currentTechnicalStock.length > 0) {
    currentTechnicalStock = currentTechnicalStock[0].technicalStock;
  } else {
    currentTechnicalStock = 0;
  }

  return {
    ...data,
    mutations: data.mutations.map((x) => {
      return {
        ...x,
        deliverDateForOrder: x.deliverDate || x.plannedDeliverDate,
      };
    }),
    currentEconomicStock: currentEconomicStock,
    currentTechnicalStock: currentTechnicalStock,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTICLES:
      return {
        ...state,
        items: { ..._.mapKeys(action.payload, "articleID") },
        filteredItems: {
          ..._.mapKeys(
            filterList(action.payload, state.articlesFilter),
            "articleID"
          ),
        },
      };
    case FETCH_INVENTORY:
      return {
        ...state,
        inventory: { ..._.mapKeys(action.payload, "articleID") },
        inventoryFilteredItems: {
          ..._.mapKeys(
            filterInventory(
              action.payload,
              state.inventoryFilter,
              state.showOnlyActionRequired,
              state.inventoryListArticleInventoryCategoryFilter,
              state.inventoryListArticleCategoryFilter,
              state.inventoryOrderEconomic
            ),
            "articleID"
          ),
        },
      };
    case FETCH_ARTICLE:
      return {
        ...state,
        items: { ...state.items, [action.payload.articleID]: action.payload },
      };
    case FETCH_INVENTORY_ITEM:
      return {
        ...state,
        inventoryItem: processInventoryItem(action.payload),
      };

    case UNLOAD_INVENTORY_ITEM:
      return {
        ...state,
        inventoryItem: null,
      };
    case CREATE_ARTICLE:
      return {
        ...state,
        items: { ...state.items, [action.payload.articleID]: action.payload },
      };
    case EDIT_ARTICLE:
      return {
        ...state,
        items: { ...state.items, [action.payload.articleID]: action.payload },
      };
    case DELETE_ARTICLE:
      return {
        ...state,
        items: { ..._.omit(state.items, action.payload) },
      };
    case SET_ARTICLES_FILTER:
      return {
        ...state,
        articlesFilter: action.payload,
        filteredItems: {
          ..._.mapKeys(
            filterList(Object.values(state.items), action.payload),
            "articleID"
          ),
        },
        articlesOffset: 0,
      };
    case SET_ARTICLES_OFFSET:
      return {
        ...state,
        articlesOffset: action.payload,
      };
    case SET_INVENTORY_FILTER:
      return {
        ...state,
        inventoryFilter: action.payload,
        inventoryFilteredItems: {
          ..._.mapKeys(
            filterInventory(
              Object.values(state.inventory),
              action.payload,
              state.showOnlyActionRequired,
              state.inventoryListArticleInventoryCategoryFilter,
              state.inventoryListArticleCategoryFilter,
              state.inventoryOrderEconomic
            ),
            "articleID"
          ),
        },
        inventoryOffset: 0,
      };
    case SET_INVENTORY_OFFSET:
      return {
        ...state,
        inventoryOffset: action.payload,
      };
    case TOGGLE_SHOW_ONLY_ACTION_REQUIRED:
      return {
        ...state,
        showOnlyActionRequired: !state.showOnlyActionRequired,
        inventoryOffset: 0,
        inventoryFilteredItems: {
          ..._.mapKeys(
            filterInventory(
              Object.values(state.inventory),
              state.inventoryFilter,
              !state.showOnlyActionRequired,
              state.inventoryListArticleInventoryCategoryFilter,
              state.inventoryListArticleCategoryFilter,
              state.inventoryOrderEconomic
            ),
            "articleID"
          ),
        },
      };
    case SET_INVENTORY_LIST_ARTICLE_INVENTORY_CATEGORY_FILTER:
      return {
        ...state,
        inventoryListArticleInventoryCategoryFilter: action.payload,
        inventoryOffset: 0,
        inventoryFilteredItems: {
          ..._.mapKeys(
            filterInventory(
              Object.values(state.inventory),
              state.inventoryFilter,
              state.showOnlyActionRequired,
              action.payload,
              state.inventoryListArticleCategoryFilter,
              state.inventoryOrderEconomic
            ),
            "articleID"
          ),
        },
      };
    case SET_INVENTORY_LIST_ARTICLE_CATEGORY_FILTER:
      return {
        ...state,
        inventoryListArticleCategoryFilter: action.payload,
        inventoryOffset: 0,
        inventoryFilteredItems: {
          ..._.mapKeys(
            filterInventory(
              Object.values(state.inventory),
              state.inventoryFilter,
              state.showOnlyActionRequired,
              state.inventoryListArticleInventoryCategoryFilter,
              action.payload,
              state.inventoryOrderEconomic
            ),
            "articleID"
          ),
        },
      };
    case TOGGLE_INVENTORY_LIST_INVENTORY_ORDER_ECONOMIC:
      return {
        ...state,
        inventoryOrderEconomic: !state.inventoryOrderEconomic,
        inventoryOffset: 0,
        inventoryFilteredItems: {
          ..._.mapKeys(
            filterInventory(
              Object.values(state.inventory),
              state.inventoryFilter,
              state.showOnlyActionRequired,
              state.inventoryListArticleInventoryCategoryFilter,
              state.inventoryListArticleCategoryFilter,
              !state.inventoryOrderEconomic
            ),
            "articleID"
          ),
        },
      };
    case RESET_INVENTORY_LIST_FILTER:
      return {
        ...state,
        showOnlyActionRequired: initialState.showOnlyActionRequired,
        inventoryOffset: 0,
        inventoryFilter: initialState.inventoryFilter,
        inventoryListArticleInventoryCategoryFilter:
          initialState.inventoryListArticleInventoryCategoryFilter,
        inventoryListArticleCategoryFilter:
          initialState.inventoryListArticleCategoryFilter,
        inventoryFilteredItems: {
          ..._.mapKeys(
            filterInventory(
              Object.values(state.inventory),
              initialState.inventoryFilter,
              initialState.showOnlyActionRequired,
              initialState.inventoryListArticleInventoryCategoryFilter,
              initialState.inventoryListArticleCategoryFilter
            ),
            "articleID"
          ),
        },
      };
    default:
      return state;
  }
};
