import React from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";

import * as actions from "../../actions";
import * as util from "../../shared/utility";
import history from "../../history";

import VisitRoofForm from "./visitRoofForm/VisitRoofForm";

import ProjectHeader from "../projectHeader/ProjectHeader";

class VisitCreate extends React.Component {
  componentDidMount() {
    const { projectid } = this.props.match.params;

    this.props.fetchArticles();
    this.props.fetchVisitMap(projectid);
    this.props.setCalculationError("");
    this.props.fetchProjectDashboard(projectid);
  }

  componentWillUnmount() {
    this.props.unloadVisit();
  }

  onSubmit = (formValues) => {
    const { projectid } = this.props.match.params;
    this.props.createVisit(
      projectid,
      formValues,
      this.props.createCalculationOnSave,
      this.props.navigateToRoofSectionsOnSave
    );
  };

  render() {
    if (this.props.articles.length === 0) {
      return <div>Loading....</div>;
    }

    var MountingArticles = this.props.articles
      .filter((article) => {
        return article.categoryID === 3;
      })
      .sort((a, b) =>
        a.priority < b.priority
          ? 1
          : a.priority > b.priority
          ? -1
          : a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : 0
      );

    if (MountingArticles.length === 0) {
      return <div>Error: no articles in mounting category.</div>;
    }

    var WorkArticles = this.props.articles
      .filter((article) => {
        return article.categoryID === 4;
      })
      .sort((a, b) =>
        a.priority < b.priority
          ? 1
          : a.priority > b.priority
          ? -1
          : a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : 0
      );

    if (WorkArticles.length === 0) {
      return <div>Error: no articles in work category.</div>;
    }

    var FuseArticles = this.props.articles
      .filter((article) => {
        return article.categoryID === 6;
      })
      .sort((a, b) =>
        a.priority < b.priority
          ? 1
          : a.priority > b.priority
          ? -1
          : a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : 0
      );

    if (FuseArticles.length === 0) {
      return <div>Error: no articles in work category.</div>;
    }

    var TransportArticles = this.props.articles
      .filter((article) => {
        return article.categoryID === 9;
      })
      .sort((a, b) =>
        a.priority < b.priority
          ? 1
          : a.priority > b.priority
          ? -1
          : a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : 0
      );

    if (TransportArticles.length === 0) {
      return <div>Error: no articles in transport category.</div>;
    }

    var InternetArticles = this.props.articles
      .filter((article) => {
        return article.categoryID === 8;
      })
      .sort((a, b) =>
        a.priority < b.priority
          ? 1
          : a.priority > b.priority
          ? -1
          : a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : 0
      );

    if (InternetArticles.length === 0) {
      return <div>Error: no articles in internet category.</div>;
    }

    const initialVal = {
      workArticleID: WorkArticles[0].articleID,
      mountingArticleID: MountingArticles[0].articleID,
      fuseArticleID: FuseArticles[0].articleID,
      transportArticleID: TransportArticles[0].articleID,
      internetArticleID: InternetArticles[0].articleID,
      roofPitch: 0,
      roofType: util.roofType.PITCHED,
      visitDateTime: new Date().toISOString().split("T")[0],
    };

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <ProjectHeader project={this.props.project} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <VisitRoofForm
              initialValues={initialVal}
              articles={this.props.articles}
              onSubmit={this.onSubmit}
              projectID={this.props.match.params.projectid}
              base64map={this.props.base64map}
              imageExtension={this.props.imageExtension}
              project={this.props.project}
            />
          </Grid.Column>
        </Grid.Row>
        {(() => {
          if (this.props.calculationError) {
            return (
              <div className="row">
                <div className="column">
                  <div className="ui error message">
                    <i
                      className="close icon"
                      onClick={() => this.props.setCalculationError("")}
                    />
                    <div className="header">Error:</div>
                    <p>{this.props.calculationError}</p>
                  </div>
                </div>
              </div>
            );
          }
        })()}
        <Grid.Row columns={2} className="stackable">
          <Grid.Column>
            <button
              type="button"
              className="ui button primary"
              onClick={() => this.props.saveVisitAndReturn()}
            >
              Save
            </button>
            <button
              type="button"
              className="ui button "
              onClick={() => history.go(-1)}
            >
              Cancel
            </button>
          </Grid.Column>
          <Grid.Column>
            <button
              type="button"
              className="ui orange button "
              onClick={() => this.props.saveVisitAndCreateCalculation()}
            >
              Save & Create Calculation
            </button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    articles: Object.values(state.articles.items),
    base64map: state.visits.base64map,
    imageExtension: state.visits.imageExtension,
    createCalculationOnSave: state.visits.createCalculationOnSave,
    navigateToRoofSectionsOnSave: state.visits.navigateToRoofSectionsOnSave,
    calculationError: state.calculations.calculationError,
    project: state.projects.projectDashboard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createVisit: (
      projectID,
      formValues,
      createCalculationOnSave,
      navigateToRoofSectionsOnSave
    ) =>
      dispatch(
        actions.createVisit(
          projectID,
          formValues,
          createCalculationOnSave,
          navigateToRoofSectionsOnSave
        )
      ),
    fetchVisitMap: (id) => dispatch(actions.fetchVisitMap(id)),
    fetchArticles: () => dispatch(actions.fetchArticles()),
    unloadVisit: () => dispatch(actions.unloadVisit()),
    saveVisitAndReturn: () => dispatch(actions.saveVisitAndReturn()),
    saveVisitAndCreateCalculation: () =>
      dispatch(actions.saveVisitAndCreateCalculation()),
    saveVisitAndNavigateToRoofSections: () =>
      dispatch(actions.saveVisitAndNavigateToRoofSections()),
    setCalculationError: (error) =>
      dispatch(actions.setCalculationError(error)),
    fetchProjectDashboard: (projectid) =>
      dispatch(actions.fetchProjectDashboard(projectid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitCreate);
