import React from "react";
import RelationList from "./RelationList";

const Suppliers = () => {
  return (
    <RelationList
      relationType={"1"}
      newItemUrl={"/purchasing/suppliers/new"}
      headerText={"Relations - Suppliers"}
    />
  );
};

export default Suppliers;
