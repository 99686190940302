import {
  FETCH_OFFER_FOR_PROJECT,
  FETCH_OFFER,
  UNLOAD_OFFER,
  FETCH_OFFER_PROJECTID,
  UNLOAD_OFFERS,
  GENERATE_PDF_OFFER,
  RESET_OFFERS_MESSAGE
} from '../actions/types';

var initialState = {
  responsemessage: '',
  showmessage: false,
  items: [],
  item: null,
  projectID: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OFFER:
      return {
        ...state,
        item: action.payload
      };
    case UNLOAD_OFFER:
      return {
        ...state,
        item: null,
        projectID: null
      };
    case UNLOAD_OFFERS:
      return {
        ...state,
        responsemessage: '',
        showmessage: false,
        items: [],
        item: null,
        projectID: null
      };
    case FETCH_OFFER_FOR_PROJECT:
      return {
        ...state,
        items: action.payload
      };
    case FETCH_OFFER_PROJECTID:
      return {
        ...state,
        projectID: action.payload
      };
    case GENERATE_PDF_OFFER:
      return {
        ...state,
        responsemessage: action.payload,
        showmessage: true
      };
    case RESET_OFFERS_MESSAGE:
      return {
        ...state,
        responsemessage: '',
        showmessage: false
      };
    default:
      return state;
  }
};
