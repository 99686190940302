import React from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal';
import history from '../../history';
import * as actions from '../../actions';

class InvoiceDelete extends React.Component {
  componentDidMount() {
    this.props.fetchInvoice(this.props.match.params.invoiceid);
  }

  componentWillUnmount() {
    this.props.unloadInvoice();
  }

  goBack() {
    history.goBack();
  }

  renderActions() {
    const { invoiceid } = this.props.match.params;

    return (
      <React.Fragment>
        <button onClick={() => this.props.deleteInvoice(invoiceid)} className="ui button negative">
          Delete
        </button>
        <button className="ui button" onClick={this.goBack}>
          Cancel
        </button>
      </React.Fragment>
    );
  }

  renderContent() {
    if (!this.props.invoice) {
      return 'Are you sure you want to delete this invoice?';
    }

    return `Are you sure you want to delete the invoice with id: ${this.props.invoice.invoiceID}`;
  }

  render() {
    return (
      <Modal
        title="Delete Invoice"
        content={this.renderContent()}
        actions={this.renderActions()}
        onDismiss={this.goBack}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { invoice: state.invoices.item };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchInvoice: id => dispatch(actions.fetchInvoice(id)),
    unloadInvoice: () => dispatch(actions.unloadInvoice()),
    deleteInvoice: id => dispatch(actions.deleteInvoice(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceDelete);
