import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from "semantic-ui-react";
import { Link } from "react-router-dom";

import * as util from "../../shared/utility";
import * as actions from "../../actions";
import StandardTableHeader from "../UI/StandardHeader";

const PurchaseOrderList = () => {
  const dispatch = useDispatch();

  const pageMetadata = useSelector(
    (state) => state.purchaseOrders.pageMetadata
  );
  const pageItems = useSelector((state) => state.purchaseOrders.pageItems);
  const pageFilter = useSelector((state) => state.purchaseOrders.pageFilter);
  const pageNumber = useSelector((state) => state.purchaseOrders.pageNumber);

  const showOrdered = useSelector((state) => state.purchaseOrders.showOrdered);
  const showPlanned = useSelector((state) => state.purchaseOrders.showPlanned);
  const showDelivered = useSelector(
    (state) => state.purchaseOrders.showDelivered
  );

  const [searchValue, setSearchValue] = useState(pageFilter);

  useEffect(() => {
    dispatch(
      actions.fetchPurchaseOrdersPaged(
        pageFilter,
        pageNumber,
        showDelivered,
        showPlanned,
        showOrdered
      )
    );

    // On a reset, make sure the searchvalue is also resetted.
    if (searchValue !== pageFilter) {
      setSearchValue(pageFilter);
    }
  }, [pageFilter, pageNumber, showDelivered, showPlanned, showOrdered]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchValue !== pageFilter) {
        dispatch(actions.setPurchaseOrdersPageFilter(searchValue));
      }
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchValue]);

  const purchaseOrdersList = Object.values(pageItems)
    .sort((a, b) => (b.purchaseOrderID > a.purchaseOrderID ? 1 : -1))
    .sort((a, b) => {
      let dateA = a.orderDate ? a.orderDate : "2300-01-01";
      let dateB = b.orderDate ? b.orderDate : "2300-01-01";
      return dateB.localeCompare(dateA);
    })
    // .sort((a, b) => b.lastUpdatedDateTime.localeCompare(a.lastUpdatedDateTime))
    // .sort((a, b) =>
    //   b.orderDate && a.orderDate ? b.orderDate.localeCompare(a.orderDate) : 0
    // )
    // .sort((a, b) =>
    //   b.plannedDeliverDate && a.plannedDeliverDate
    //     ? b.plannedDeliverDate.localeCompare(a.plannedDeliverDate)
    //     : b.orderDate && a.orderDate ? b.orderDate.localeCompare(a.orderDate) : 0
    // )
    // .sort((a, b) =>
    //   b.plannedDeliverDate && a.plannedDeliverDate
    //     ? b.plannedDeliverDate.localeCompare(a.plannedDeliverDate)
    //     : 0
    // )
    // .sort((a, b) => b.deliverDate.localeCompare(a.deliverDate))
    .map((purchaseOrder) => {
      return (
        <tr key={purchaseOrder.purchaseOrderID}>
          <td className="mobile hidden">{purchaseOrder.purchaseOrderID}</td>
          <td>
            <Link
              className="item"
              to={`/purchasing/purchaseorders/edit/${purchaseOrder.purchaseOrderID}`}
            >
              <div className="item" title={purchaseOrder.description}>
                {purchaseOrder.name}
              </div>
            </Link>
          </td>
          <td className="mobile hidden">
            <div className="item" title={purchaseOrder.reference}>
              {purchaseOrder.reference}
            </div>
          </td>
          <td className="center aligned">
            {util.utcStringToLocalDate(purchaseOrder.orderDate)}
          </td>
          <td
            className="center aligned mobile hidden"
            style={
              purchaseOrder.deliverDate
                ? { backgroundColor: "var(--company-success-50)" }
                : { opacity: "0.5" }
            }
          >
            {purchaseOrder.deliverDate
              ? util.utcStringToLocalDate(purchaseOrder.deliverDate)
              : util.utcStringToLocalDate(purchaseOrder.plannedDeliverDate)}
          </td>
          {/* <td className="mobile hidden">
            {util.utcStringToLocalDateTime(purchaseOrder.lastUpdatedDateTime)}
          </td> */}
          {/* <td>
            <Link
            className="item"
            to={`/purchasing/purchaseorders/edit/${purchaseOrder.purchaseOrderID}`}
            key={purchaseOrder.purchaseOrderID}
            >
            <i className="edit icon" title="Edit record." />
            </Link>
          </td> */}
          {/* <td className="right aligned">{purchaseOrder.articleCount}</td> */}
          <td>
            <i
              style={{ cursor: "pointer" }}
              className="copy icon blue"
              onClick={() =>
                dispatch(
                  actions.copyPurchaseOrder(purchaseOrder.purchaseOrderID)
                )
              }
              title="Copy record."
            />
            {/* <Link
              className="item"
              to={`/purchasing/purchaseorders/copy/${purchaseOrder.purchaseOrderID}`}
              key={purchaseOrder.purchaseOrderID}
            >
              <i className="copy icon" title="Copy record." />
            </Link> */}
          </td>
          <td>
            {/* <i
              style={{ cursor: "pointer" }}
              className="trash icon blue"
              title="Delete record."
              onClick={() => setDeleteItemID(purchaseOrder.purchaseOrderID)}
            /> */}
            <Link
              className="item"
              to={`/purchasing/purchaseorders/delete/${purchaseOrder.purchaseOrderID}`}
              key={purchaseOrder.purchaseOrderID}
            >
              <i className="trash icon" title="Delete record." />
            </Link>
          </td>
        </tr>
      );
    });

  const renderTable = (
    <>
      <table className="ui celled table unstackable">
        <thead>
          <tr>
            <th className="one wide mobile hidden">ID</th>
            <th className="five wide">Supplier</th>
            <th className="five wide mobile hidden">Reference</th>
            <th className="two wide center aligned">
              Order Date
              <i className="caret down icon" />
            </th>
            <th className="two wide center aligned mobile hidden">
              Deliver Date
            </th>
            {/* <th
              className="one wide mobile hidden right aligned"
              title="article count"
            >
              #
            </th> */}
            {/* <th className="three wide mobile hidden">Changed</th> */}
            <th className="one wide center aligned " colSpan="2">
              Action
            </th>
          </tr>
        </thead>
        <tbody>{pageMetadata.totalCount > 0 && purchaseOrdersList}</tbody>
      </table>
      {pageMetadata.totalCount > 0 && (
        <Pagination
          activePage={pageMetadata.currentPage}
          totalPages={pageMetadata.totalPages}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          onPageChange={(e, props) =>
            dispatch(actions.setPurchaseOrdersPageNumber(props.activePage))
          }
        />
      )}
    </>
  );

  // const PurchaseOrderFilter = () => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "flex-end",
  //         alignItems: "center",
  //         whiteSpace: "nowrap",
  //         paddingTop: "10px",
  //       }}
  //     >
  //       <select
  //         className="ui selection dropdown"
  //         name="visitorName"
  //         label="Visitor:"
  //         onChange={(e) =>
  //           dispatch(actions.setPurchaseOrderFilter(e.target.value))
  //         }
  //         value={projectsVisitorFilter}
  //       >
  //         <option key={0} value={""}>
  //           Select visitor...
  //         </option>
  //         {util.VISITORS.map((x) => (
  //           <option key={x.Key} value={x.Name}>
  //             {x.Name}
  //           </option>
  //         ))}
  //         <option key={100} value={"NoVisitor"}>
  //           No visitor
  //         </option>
  //       </select>
  //       <button
  //         className="ui button labeled icon"
  //         onClick={() => dispatch(actions.resetProjectsFilter())}
  //         title="Reset filters."
  //         style={{
  //           backgroundColor: "var(--company-bg-color-50)",
  //           marginLeft: "10px",
  //         }}
  //       >
  //         <i className="undo icon" />
  //         Reset Filters
  //       </button>
  //     </div>
  //   );
  // };

  // const [deleteItemID, setDeleteItemID] = useState(null);

  // const handleDeleteCancelClick = () => {
  //   setDeleteItemID(null);
  // };

  // const handleDeleteSubmitClick = () => {
  //   dispatch(actions.deletePurchaseOrder(deleteItemID));
  //   setDeleteItemID(null);
  // };

  // const DeleteItem = () => {
  //   if (!deleteItemID) {
  //     return null;
  //   }

  //   const item = Object.values(purchaseOrdersPageItems).find(
  //     (e) => e.purchaseOrderID === deleteItemID
  //   );

  //   return (
  //     <Modal onClose={handleDeleteCancelClick} open={!!deleteItemID}>
  //       <Modal.Header>{`Delete purchase order ${item.purchaseOrderID}`}</Modal.Header>
  //       <Modal.Content>
  //         <p>{`Are you sure you want to delete the purchaseOrder ${item.purchaseOrderID} from ${item.name}.`}</p>
  //         <p>
  //           {item.orderDate &&
  //             `Ordered on ${util.utcStringToLocalDate(item.orderDate)}?`}
  //         </p>
  //       </Modal.Content>
  //       <Modal.Actions>
  //         <Button
  //           content="Delete"
  //           labelPosition="right"
  //           icon="trash"
  //           onClick={handleDeleteSubmitClick}
  //           negative
  //         ></Button>
  //         <Button onClick={handleDeleteCancelClick}>Cancel</Button>
  //       </Modal.Actions>
  //     </Modal>
  //   );
  // };

  /////// TBD: SEARCH BY SUPPLIER AND SEARCH BY ARTICLE?
  /////// TBD: Delete as on roofsections

  const PurchaseOrderFilter = () => {
    return (
      <div className="pageFilterRow">
        <div>
          <div
            className="ui toggle checkbox"
            onClick={() => dispatch(actions.togglePurchaseOrdersShowOrdered())}
            style={{ cursor: "pointer" }}
          >
            <input type="checkbox" name="public" defaultChecked={showOrdered} />
            <label>Show Ordered</label>
          </div>
          <div
            className="ui toggle checkbox"
            onClick={() => dispatch(actions.togglePurchaseOrdersShowPlanned())}
            style={{ cursor: "pointer" }}
          >
            <input type="checkbox" name="public" defaultChecked={showPlanned} />
            <label>Show Planned</label>
          </div>
          <div
            className="ui toggle checkbox"
            onClick={() =>
              dispatch(actions.togglePurchaseOrdersShowDelivered())
            }
            style={{ cursor: "pointer" }}
          >
            <input
              type="checkbox"
              name="public"
              defaultChecked={showDelivered}
            />
            <label>Show Delivered</label>
          </div>
        </div>

        <div>
          {/* <select
            className="ui selection dropdown"
            name="visitorName"
            label="Visitor:"
            onChange={(e) =>
              dispatch(actions.setOrdersVisitorFilterPlanning(e.target.value))
            }
            value={visitorFilter}
          >
            <option key={0} value={""}>
              Select visitor...
            </option>
            {util.VISITORS.map((x) => (
              <option key={x.Key} value={x.Name}>
                {x.Name}
              </option>
            ))}
            <option key={100} value={"NoVisitor"}>
              No visitor
            </option>
          </select> */}
          <button
            id="pageFilterButton"
            className="ui button labeled icon "
            onClick={() => dispatch(actions.resetPurchaseOrdersFilter())}
            title="Reset filters."
            style={{
              backgroundColor: "var(--company-bg-color-50)",
              marginLeft: "10px",
            }}
          >
            <i className="undo icon" />
            Reset Filters
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="ui one column grid">
      <div className="column">
        <div className="row">
          <StandardTableHeader
            headerLabel="Purchase Orders"
            linkForNewButton="/purchasing/purchaseorders/new"
            recordCount={pageMetadata.totalCount}
            searchValue={searchValue}
            searchTooltip={`You can search on the supplier name or reference.`}
            searchFunction={(e) => setSearchValue(e.target.value)}
          ></StandardTableHeader>
        </div>
        <div className="row">
          <PurchaseOrderFilter />
        </div>
      </div>
      <div className="column">{renderTable}</div>
      {/* <DeleteItem /> */}
    </div>
  );
};

export default PurchaseOrderList;
