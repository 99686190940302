import React from "react";
import { connect } from "react-redux";
import Modal from "../Modal";
import history from "../../history";
import * as actions from "../../actions";

class VisitDelete extends React.Component {
  componentDidMount() {
    const { projectid } = this.props.match.params;
    this.props.fetchVisit(projectid);
  }

  goBack() {
    history.goBack();
  }

  renderActions() {
    return (
      <React.Fragment>
        <button
          onClick={() =>
            this.props.deleteVisit(
              this.props.visit.visitID,
              this.props.visit.projectID
            )
          }
          className="ui button negative"
        >
          Delete
        </button>
        <button className="ui button" onClick={this.goBack}>
          Cancel
        </button>
      </React.Fragment>
    );
  }

  renderContent() {
    return `Are you sure you want to delete the visit with id: ${this.props.visit.visitID}`;
  }

  render() {
    if (!this.props.visit) {
      return <div>Loading...</div>;
    }

    return (
      <Modal
        title="Delete Visit"
        content={this.renderContent()}
        actions={this.renderActions()}
        onDismiss={this.goBack}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { visit: state.visits.item };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVisit: (id) => dispatch(actions.fetchVisit(id)),
    deleteVisit: (id, projectID) =>
      dispatch(actions.deleteVisit(id, projectID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitDelete);
