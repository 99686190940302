import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import history from "../../history";
import * as util from "../../shared/utility";

class ArticleForm extends React.Component {
  onSubmit = (formValues) => {
    const connectedArticleIDCorrect =
      parseInt(formValues.connectedArticleID) === 0
        ? null
        : formValues.connectedArticleID;
    const connectedPanelArticleIDCorrect =
      parseInt(formValues.connectedPanelArticleID) === 0
        ? null
        : formValues.connectedPanelArticleID;

    const fv = {
      ...formValues,
      connectedArticleID: connectedArticleIDCorrect,
      connectedPanelArticleID: connectedPanelArticleIDCorrect,
    };

    this.props.onSubmit(fv);
  };

  handleCancelClick = () => {
    history.goBack();
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <Field name="name" component={util.RenderInput} label="Name:" />
        <Field
          name="description"
          component={util.RenderInput}
          label="Description (for trello):"
        />
        <Field
          name="descriptionCustomer"
          component={util.RenderInput}
          label="Description customer (for offer):"
        />
        <Field
          name="productDescription"
          component={util.RenderInputMultiline}
          label="Product Description (for offer):"
        />
        <Field
          name="price"
          component={util.RenderInputDecimal}
          label="Price (€):"
        />
        <Field
          name="marge"
          component={util.RenderInputBoolean}
          label="Marge:"
        />
        <Field name="btwID" label="BTW:" component={util.RenderSelect}>
          <option key={1} value={1}>
            21 procent
          </option>
        </Field>
        {/* 
        <Field
          name="categoryID"
          label="Category:"
          component={util.RenderSelectWithSearch}
          handleChange={(e, { val }) =>
            change("articleForm", "categoryID", val)
          }
        >
          {this.props.categories
            .sort((a, b) =>
              a.ordinal > b.ordinal
                ? 1
                : a.ordinal < b.ordinal
                ? -1
                : a.name.toLowerCase() < b.name.toLowerCase()
                ? -1
                : a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : 0
            )
            .map((category) => ({
              key: category.categoryID,
              value: category.categoryID,
              text: category.name,
            }))}
        </Field> */}

        <Field
          name="categoryID"
          label="Category:"
          component={util.RenderSelect}
        >
          <option key={0} value={0}>
            Select a category...
          </option>
          {this.props.categories
            .sort((a, b) =>
              a.ordinal > b.ordinal
                ? 1
                : a.ordinal < b.ordinal
                ? -1
                : a.name.toLowerCase() < b.name.toLowerCase()
                ? -1
                : a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : 0
            )
            .map((category) => (
              <option key={category.categoryID} value={category.categoryID}>
                {category.name}
              </option>
            ))}
        </Field>
        <Field
          name="connectedArticleID"
          label="Connected article (single article on calculation):"
          component={util.RenderSelect}
        >
          <option key={0} value={0}>
            Select a connected article...
          </option>
          {this.props.validArticles
            .sort((a, b) =>
              a.priority < b.priority
                ? 1
                : a.priority > b.priority
                ? -1
                : a.name.toLowerCase() < b.name.toLowerCase()
                ? -1
                : a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : 0
            )
            .map((article) => (
              <option key={article.articleID} value={article.articleID}>
                {article.name}
              </option>
            ))}
        </Field>
        <Field
          name="connectedPanelArticleID"
          label="Connected panel article (panelcount x article on calculation):"
          component={util.RenderSelect}
        >
          <option key={0} value={0}>
            Select a connected panel article...
          </option>
          {this.props.validArticles
            .sort((a, b) =>
              a.priority < b.priority
                ? 1
                : a.priority > b.priority
                ? -1
                : a.name.toLowerCase() < b.name.toLowerCase()
                ? -1
                : a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : 0
            )
            .map((article) => (
              <option key={article.articleID} value={article.articleID}>
                {article.name}
              </option>
            ))}
        </Field>
        <Field
          name="isVisibleOnOffer"
          component={util.RenderInputBoolean}
          label="Visible on offer:"
        />
        {/* {this.props.isInverter && (
          <Field
            name="optimizerArticleID"
            label="Optimizer article:"
            component={util.RenderSelect}
          >
            <option key={0} value={0}>
              Select an optimizer...
            </option>
            {this.props.optimizers
              .sort((a, b) =>
                a.priority < b.priority
                  ? 1
                  : a.priority > b.priority
                  ? -1
                  : a.name.toLowerCase() < b.name.toLowerCase()
                  ? -1
                  : a.name.toLowerCase() > b.name.toLowerCase()
                  ? 1
                  : 0
              )
              .map(article => (
                <option key={article.articleID} value={article.articleID}>
                  {article.name}
                </option>
              ))}
          </Field>
        )} */}
        <Field
          name="priority"
          component={util.RenderInputNumber}
          label="Priority for order in select controls:"
        />
        <Field name="power" component={util.RenderInputNumber} label="WP:" />
        <Field
          name="reorderPoint"
          component={util.RenderInputNumber}
          label="Reorder point:"
        />
        <Field
          name="orderQuantity"
          component={util.RenderInputNumber}
          label="OrderQuantity:"
        />
        <Field
          name="deliverTimeInDays"
          component={util.RenderInputNumber}
          label="DeliverTimeInDays:"
        />
        <Field
          name="articleInventoryCategory"
          label="Inventory Category:"
          component={util.RenderSelect}
        >
          <option key={0} value={0}>
            Select a category...
          </option>
          <option key={1} value={1}>
            A
          </option>
          <option key={2} value={2}>
            B
          </option>
          <option key={3} value={3}>
            C
          </option>
        </Field>
        <Field
          name="remarks"
          component={util.RenderInputMultiline}
          label="Remarks (for purchasing):"
        />
        <button className="ui button primary" disabled={this.props.submitting}>
          Save
        </button>
        <button
          type="button"
          className="ui button"
          onClick={this.handleCancelClick}
        >
          Cancel
        </button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.name) {
    errors.name = "You must enter a name for the article.";
  }

  if (!formValues.description) {
    errors.description = "You must enter a description.";
  }

  if (!formValues.descriptionCustomer) {
    errors.descriptionCustomer =
      "You must enter a description for the customer.";
  }

  if (
    formValues.price === undefined ||
    formValues.price === null ||
    formValues.price === ""
  ) {
    errors.price = "You must enter a price.";
  } else if (formValues.price < 0 || formValues.price > 1000000000) {
    errors.price = "The price must be a number between 0 and 1000000000.";
  }

  if (!formValues.categoryID || formValues.categoryID === "0") {
    errors.categoryID = "You need to select a category";
  }

  if (
    !formValues.priority ||
    formValues.priority < 0 ||
    formValues.priority > 1000
  ) {
    errors.priority = "You need to select a value between 0 and 1000.";
  }

  if (formValues.power && (formValues.power < 0 || formValues.power > 100000)) {
    errors.power =
      "You need to select a value between 0 and 100000 or nothing.";
  }

  if (
    formValues.reorderPoint === undefined ||
    formValues.reorderPoint === null ||
    formValues.reorderPoint === "" ||
    formValues.reorderPoint < 0
  ) {
    errors.reorderPoint = "You need to select a value greater or equal to 0.";
  }

  if (formValues.name && formValues.name.length > 200) {
    errors.name = "The name cannot exceed 200 characters.";
  }

  if (formValues.description && formValues.description.length > 200) {
    errors.description = "The description cannot exceed 200 characters.";
  }

  if (
    formValues.descriptionCustomer &&
    formValues.descriptionCustomer.length > 200
  ) {
    errors.descriptionCustomer =
      "The description customer cannot exceed 200 characters.";
  }

  if (
    formValues.productDescription &&
    formValues.productDescription.length > 2000
  ) {
    errors.productDescription =
      "The product description cannot exceed 2000 characters.";
  }

  if (
    !formValues.orderQuantity ||
    formValues.orderQuantity < 0 ||
    formValues.orderQuantity > 1000000
  ) {
    errors.orderQuantity = "You need to select a value between 0 and 1000000.";
  }

  return errors;
};

let SelectingFormValuesForm = reduxForm({
  form: "articleForm",
  validate,
})(ArticleForm);

// Decorate with connect to read form values
const selector = formValueSelector("articleForm"); // <-- same as form name

SelectingFormValuesForm = connect((state) => {
  // can select values individually
  const isInverter = parseInt(selector(state, "categoryID")) === 2;
  return {
    isInverter,
  };
})(SelectingFormValuesForm);

export default SelectingFormValuesForm;
