import axios from "axios";
import * as util from "../shared/utility";
import * as actions from "../actions";

let store;

let api = axios.create({
  baseURL: util.baseUrlApi(),
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      console.log("Network error, service unavailable.");
      throw error;
    } else if (error.response.status === 401) {
      console.log("401 error");
      // auto logout if 401 response returned from api
      localStorage.removeItem("user");
      window.location.reload(true);
    } else if (error.response.status === 400) {
      console.log("400 error");
      // Handle gloval errors from server (model validation)
      if (
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.length > 0
      ) {
        store.dispatch(actions.setGlobalErrors(error.response.data.errors));
      } else if (error.response.data && error.response.data.message) {
        store.dispatch(actions.setGlobalErrors([error.response.data.message]));
      }
      throw error;
    } else {
      console.log("undefined error");
      throw error;
    }
  }
);

export const injectStore = (_store) => {
  store = _store;
};

export default api;
