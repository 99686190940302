import React from "react";
import { useDispatch } from "react-redux";
import { Segment } from "semantic-ui-react";

import * as actions from "../../actions";
import ProjectForm from "./ProjectForm";

const ProjectEdit = ({ project, onCancel }) => {
  if (!project) {
    return null;
  }

  const dispatch = useDispatch();

  const onSubmit = (formValues) => {
    dispatch(
      actions.editProjectAndUpdateProject(project.projectID, formValues)
    );
  };

  return (
    <Segment>
      <h3>Edit Project</h3>
      <ProjectForm
        initialValues={project}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Segment>
  );
};

export default ProjectEdit;
