import React from "react";

import DashboardMenu from "./DashboardMenu";
import DashboardProgress from "./DashboardProgress";

const ProjectHeader = ({ project }) => {
  if (!project) {
    return null;
  }

  return (
    <>
      <DashboardMenu project={project} />
      <DashboardProgress project={project} />
    </>
  );
};

export default ProjectHeader;
