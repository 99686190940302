import solartoolapi from "../apis/solartoolapi";
import history from "../history";
import {
  GENERATE_INVOICE,
  FETCH_INVOICE,
  DELETE_INVOICE,
  EDIT_INVOICE,
  FETCH_INVOICE_FOR_PROJECT,
  UNLOAD_INVOICE,
  UNLOAD_INVOICES,
  GENERATE_PDF_INVOICE,
  FETCH_INVOICE_PROJECTID,
  DOWNLOAD_PROJECT_FILE,
  RESET_INVOICES_MESSAGE,
} from "./types";
import { authHeader } from "../shared/utility";
import * as actions from "../actions";
import fileDownload from "js-file-download";

export const generateInvoice =
  (offerID, projectID) => async (dispatch, getState) => {
    const response = await solartoolapi.post(
      `/invoices/${offerID}`,
      {},
      { headers: authHeader() }
    );

    dispatch({ type: GENERATE_INVOICE, payload: response.data.result });

    history.push(`/sales/invoices/edit/${response.data.result.invoiceID}`);
  };

export const editInvoice = (id, formValues, projectID) => async (dispatch) => {
  const response = await solartoolapi.put(`/invoices/${id}`, formValues, {
    headers: authHeader(),
  });

  dispatch({ type: EDIT_INVOICE, payload: response.data.result });

  history.push(`/sales/projects/dashboard/${projectID}`);
};

export const deleteInvoice = (id) => async (dispatch) => {
  await solartoolapi.delete(`/invoices/${id}`, { headers: authHeader() });

  dispatch({ type: DELETE_INVOICE, payload: id });
  history.go(-1);
};

export const fetchInvoice = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/invoices/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_INVOICE, payload: response.data.result });
  dispatch(actions.fetchInvoiceProjectID(id));
};

export const fetchInvoicesForProject = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/invoices/project/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_INVOICE_FOR_PROJECT, payload: response.data.result });
};

export const generateInvoicePdf =
  (invoiceid, projectid) => async (dispatch) => {
    const response = await solartoolapi.post(
      `/invoices/generatepdf/${invoiceid}`,
      {},
      {
        headers: authHeader(),
      }
    );

    dispatch({ type: GENERATE_PDF_INVOICE, payload: response.data.message });
    dispatch(actions.fetchProjectFiles(projectid));
  };

export const previewInvoicePdf = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/invoices/previewpdf/${id}`, {
    headers: authHeader(),
    responseType: "blob",
  });

  var previewPdfName = `Factuur_DeLaatSolarSystems_Preview_${Date.now()}.pdf`;

  fileDownload(response.data, previewPdfName);
  dispatch({ type: DOWNLOAD_PROJECT_FILE, payload: previewPdfName });
};

export const fetchInvoiceProjectID = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/invoices/${id}/getprojectid`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_INVOICE_PROJECTID, payload: response.data.result });
};

export const unloadInvoice = () => (dispatch) => {
  dispatch({ type: UNLOAD_INVOICE });
};

export const unloadInvoices = () => (dispatch) => {
  dispatch({ type: UNLOAD_INVOICES });
};

export const resetInvoicesMessage = () => (dispatch) => {
  dispatch({ type: RESET_INVOICES_MESSAGE });
};
