import _ from "lodash";
import {
  FETCH_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
  REMOVE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
  CREATE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
  EDIT_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
  FETCH_PURCHASE_ORDER_ROW,
  REMOVE_PURCHASE_ORDER_ROW,
  COPY_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
} from "../actions/types";

var initialState = {
  items: {},
  item: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PURCHASE_ORDER_ROW:
      return {
        ...state,
        item: action.payload,
      };
    case REMOVE_PURCHASE_ORDER_ROW:
      return {
        ...state,
        item: null,
      };
    case FETCH_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER:
      return {
        ...state,
        items: { ..._.mapKeys(action.payload, "purchaseOrderRowID") },
      };
    case REMOVE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER:
      return {
        ...state,
        items: {},
      };
    case CREATE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.purchaseOrderRowID]: action.payload,
        },
      };
    case EDIT_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.purchaseOrderRowID]: action.payload,
        },
      };
    case DELETE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER:
      return {
        ...state,
        items: { ..._.omit(state.items, action.payload) },
      };
    case COPY_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.purchaseOrderRowID]: action.payload,
        },
      };
    default:
      return state;
  }
};
