import React from "react";
import { NavLink } from "react-router-dom";

const MobileMenuItem = ({ menuItem: { label, path }, setShowMobileMenu }) => (
  <div className="mobileMenuItem">
    <NavLink
      to={path}
      activeClassName="active"
      onClick={() => setShowMobileMenu(false)}
    >
      {label}
    </NavLink>
  </div>
);

export default MobileMenuItem;
