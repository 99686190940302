import _ from "lodash";
import {
  FETCH_ROOF_SECTIONS_FOR_PROJECT,
  REMOVE_ROOF_SECTIONS_FOR_PROJECT,
  CREATE_ROOF_SECTIONS_FOR_PROJECT,
  EDIT_ROOF_SECTIONS_FOR_PROJECT,
  DELETE_ROOF_SECTIONS_FOR_PROJECT,
  FETCH_ROOF_SECTION,
  REMOVE_ROOF_SECTION,
  COPY_ROOF_SECTIONS_FOR_PROJECT,
} from "../actions/types";

var initialState = {
  items: {},
  item: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROOF_SECTION:
      return {
        ...state,
        item: action.payload,
      };
    case REMOVE_ROOF_SECTION:
      return {
        ...state,
        item: null,
      };
    case FETCH_ROOF_SECTIONS_FOR_PROJECT:
      return {
        ...state,
        items: { ..._.mapKeys(action.payload, "roofSectionID") },
      };
    case REMOVE_ROOF_SECTIONS_FOR_PROJECT:
      return {
        ...state,
        items: {},
      };
    case CREATE_ROOF_SECTIONS_FOR_PROJECT:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.roofSectionID]: action.payload,
        },
      };
    case EDIT_ROOF_SECTIONS_FOR_PROJECT:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.roofSectionID]: action.payload,
        },
      };
    case DELETE_ROOF_SECTIONS_FOR_PROJECT:
      return {
        ...state,
        items: { ..._.omit(state.items, action.payload) },
      };
    case COPY_ROOF_SECTIONS_FOR_PROJECT:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.roofSectionID]: action.payload,
        },
      };
    default:
      return state;
  }
};
