import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";

import MailTemplateForm from "./MailTemplateForm";
import StandardTableHeader from "../UI/StandardHeader";

class MailTemplateEdit extends React.Component {
  componentDidMount() {
    if (this.props.categories.length === 0) {
      this.props.fetchCategories();
    }
    this.props.fetchMailTemplate(this.props.match.params.mailTemplateID);
  }

  onSubmit = (fv) => {
    this.props.editMailTemplate(this.props.match.params.mailTemplateID, fv);
  };

  render() {
    if (!this.props.mailTemplate) {
      return <div>Loading...</div>;
    }

    return (
      <div className="ui one column grid">
        <div className="column">
          <StandardTableHeader headerLabel="Edit Mail Template"></StandardTableHeader>
        </div>
        <div className="column">
          <MailTemplateForm
            initialValues={this.props.mailTemplate}
            categories={this.props.categories}
            onSubmit={this.onSubmit}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    mailTemplate:
      state.mailTemplates.items[ownProps.match.params.mailTemplateID],
    categories: state.categories.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMailTemplate: (id) => dispatch(actions.fetchMailTemplate(id)),
    editMailTemplate: (id, formValues) =>
      dispatch(actions.editMailTemplate(id, formValues)),
    fetchCategories: () => dispatch(actions.fetchCategories()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailTemplateEdit);
