import solartoolapi from "../apis/solartoolapi";
import history from "../history";
import {
  CREATE_VISIT,
  // FETCH_VISITS,
  FETCH_VISIT,
  DELETE_VISIT,
  EDIT_VISIT,
  UNLOAD_VISIT,
  FETCH_VISIT_MAP,
  SET_VISIT_CREATE_CALCULATION_ON_SAVE,
  FETCH_VISIT_START,
  FETCH_VISIT_END,
  SET_VISIT_NAVIGATE_TO_ROOF_SECTIONS_ON_SAVE,
} from "./types";
import { authHeader } from "../shared/utility";
import { submit } from "redux-form";
import {
  createCalculationForProject,
  createCalculationForProjectFromVisitCreate,
} from "./calculationsActions";

export const createVisit =
  (
    projectID,
    formValues,
    createCalculationOnSave,
    navigateToRoofSectionsOnSave
  ) =>
  async (dispatch) => {
    await solartoolapi
      .post(
        "/visits",
        {
          ...formValues,
          projectID,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        dispatch({ type: CREATE_VISIT, payload: response.data.result });

        if (createCalculationOnSave) {
          dispatch(
            createCalculationForProjectFromVisitCreate(
              projectID,
              response.data.result.visitID
            )
          );
        } else if (navigateToRoofSectionsOnSave) {
          history.push(`/sales/roofsections/project/${projectID}`);
        } else {
          history.go(-1);
        }
      });
  };

export const editVisit =
  (
    visitID,
    formValues,
    createCalculationOnSave,
    navigateToRoofSectionsOnSave
  ) =>
  async (dispatch) => {
    await solartoolapi
      .put(`/visits/${visitID}`, formValues, {
        headers: authHeader(),
      })
      .then((response) => {
        dispatch({ type: EDIT_VISIT, payload: response.data.result });
        if (createCalculationOnSave) {
          dispatch(createCalculationForProject(response.data.result.projectID));
        } else if (navigateToRoofSectionsOnSave) {
          history.push(`/sales/roofsections/project/${formValues.projectID}`);
        } else {
          history.go(-1);
        }
      });
  };

export const saveVisitAndReturn = () => async (dispatch) => {
  Promise.resolve(
    dispatch({ type: SET_VISIT_CREATE_CALCULATION_ON_SAVE, payload: false })
  ).then(() => {
    dispatch(submit("visitForm"));
  });
};

export const saveVisitAndCreateCalculation = () => async (dispatch) => {
  Promise.resolve(
    dispatch({ type: SET_VISIT_CREATE_CALCULATION_ON_SAVE, payload: true })
  ).then(() => {
    dispatch(submit("visitForm"));
  });
};

export const saveVisitAndNavigateToRoofSections = () => async (dispatch) => {
  Promise.resolve(
    dispatch({
      type: SET_VISIT_NAVIGATE_TO_ROOF_SECTIONS_ON_SAVE,
      payload: true,
    })
  ).then(() => {
    dispatch(submit("visitForm"));
  });
};

export const deleteVisit = (id, projectID) => async (dispatch) => {
  await solartoolapi.delete(`/visits/${id}`, { headers: authHeader() });

  dispatch({ type: DELETE_VISIT, payload: id });
  history.push(`/sales/projects/dashboard/${projectID}`);
};

export const fetchVisit = (id) => async (dispatch) => {
  dispatch({ type: FETCH_VISIT_START });

  const response = await solartoolapi.get(`/visits/project/${id}`, {
    headers: authHeader(),
  });

  await Promise.all([
    dispatch({ type: FETCH_VISIT, payload: response.data.result }),
    dispatch(fetchVisitMap(response.data.result.projectID)),
  ]);

  dispatch({ type: FETCH_VISIT_END });
};

export const fetchVisitMap = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/files/maps/${id}`, {
    headers: authHeader(),
  });

  if (response.data.result) {
    dispatch({ type: FETCH_VISIT_MAP, payload: response.data.result });
  }
};

export const unloadVisit = () => (dispatch) => {
  dispatch({ type: UNLOAD_VISIT });
};
