import React, { Component } from "react";
import { connect } from "react-redux";
import { loginUser, clearAuth } from "../../actions";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import * as util from "../../shared/utility";

class Auth extends Component {
  componentWillUnmount() {
    this.props.clearAuth();
  }

  onSubmit = (formValues) => {
    this.props.loginUser(formValues.email, formValues.password);
  };

  render() {
    var divStyleDesktop = {
      background: "#ddd",
      marginTop: "60px",
    };

    let errorMessage = null;
    if (this.props.authError) {
      errorMessage = (
        <p className="ui error message">{this.props.authErrorMessage}</p>
      );
    }
    return (
      <div>
        <div className="ui grid stackable">
          <div className="three wide column computer tablet only" />
          <div className="ten wide column">
            <div
              className="ui middle aligned center aligned column segment item"
              style={divStyleDesktop}
            >
              <form
                onSubmit={this.props.handleSubmit(this.onSubmit)}
                className="ui form error"
              >
                <Field
                  name="email"
                  component={util.RenderInput}
                  label="Email"
                  type="text"
                />
                <Field
                  name="password"
                  component={util.RenderInputPassword}
                  label="Password"
                  type="password"
                />
                <div className="ui three column grid">
                  <div className="column"></div>
                  <div className="column">
                    <button className="ui button primary">Login</button>
                  </div>
                  <div className="column bottom aligned ">
                    <Link
                      className="ui right floated item"
                      to={"/resetpassword"}
                    >
                      Reset
                    </Link>
                  </div>
                </div>
              </form>

              {errorMessage}
            </div>
          </div>
          <div className="three wide column computer tablet only" />
        </div>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.email) {
    errors.email = "You must enter an email address.";
  }

  if (!formValues.password) {
    errors.password = "You must enter a password.";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    authErrorMessage: state.auth.authErrorMessage,
  };
};

const authComponent = connect(mapStateToProps, { loginUser, clearAuth })(Auth);

export default reduxForm({
  form: "authForm",
  validate,
})(authComponent);
