import React from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";

import * as actions from "../../actions";
import TrelloForm from "./TrelloForm";
import ProjectBreadcrumb from "../breadcrumb/ProjectBreadcrumb";
import StandardTableHeader from "../UI/StandardHeader";

class TrelloCreate extends React.Component {
  constructor() {
    super();
    this.state = {
      searchValue: "",
    };
  }

  componentDidMount() {
    const { orderid } = this.props.match.params;
    this.props.fetchTrello(orderid);
    this.props.fetchFiles();
  }

  onSubmit = (fv) => {
    this.props.createTrello(fv);
  };

  updatesearchValue = (evt) => {
    this.setState({
      searchValue: evt.target.value,
    });
  };

  componentWillUnmount() {
    this.props.unloadTrello();
  }

  render() {
    if (!this.props.orderid || !this.props.projectid) {
      return <div>No valid orderid...</div>;
    }

    const init = { ...this.props.trello };

    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <StandardTableHeader headerLabel="Send To Trello">
                <ProjectBreadcrumb
                  projectID={this.props.projectid}
                  currentPath="Trello"
                />
              </StandardTableHeader>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <h4>Order: {this.props.orderid}</h4>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <TrelloForm
                enableReinitialize={true}
                initialValues={init}
                onSubmit={this.onSubmit}
                files={this.props.files}
                projectFiles={this.props.projectFiles}
                downloadFile={this.props.downloadFile}
                downloadProjectFile={this.props.downloadProjectFile}
                showmessage={this.props.showmessage}
                resetTrelloMessage={this.props.resetTrelloMessage}
                responsemessage={this.props.responsemessage}
                projectid={this.props.projectid}
                searchValue={this.state.searchValue}
                updatesearchValue={this.updatesearchValue}
                creating={this.props.creating}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    orderid: ownProps.match.params.orderid,
    trello: state.trellos.item,
    creating: state.trellos.creating,
    projectid: state.orders.projectID,
    // mailTemplates: Object.values(state.mailTemplates.items),
    // //mailTemplate: state.mail.mailTemplate,
    // projectMailDto: state.mail.projectMailDto,
    responsemessage: state.trellos.responsemessage,
    showmessage: state.trellos.showmessage,
    files: state.files.items,
    //projectFiles: []
    projectFiles: state.projectFiles.items,
    // responsemessage: state.mail.responsemessage,
    // showmessage: state.mail.showmessage,
    // project: state.projects.items[ownProps.match.params.projectid]
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFiles: () => dispatch(actions.fetchFiles()),
    createTrello: (formValues) => dispatch(actions.createTrello(formValues)),
    resetTrelloMessage: () => dispatch(actions.resetTrelloMessage()),
    fetchProjectFiles: (projectid) =>
      dispatch(actions.fetchProjectFiles(projectid)),
    unloadTrello: () => dispatch(actions.unloadTrello()),
    fetchTrello: (orderid) => dispatch(actions.fetchTrello(orderid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrelloCreate);
