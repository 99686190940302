import React from "react";
import { connect } from "react-redux";
import {
  fetchUsers,
  resetPassword,
  clearAuth,
  disableUser,
} from "../../actions";
import { Link } from "react-router-dom";
import * as util from "../../shared/utility";
// import { Grid, Segment } from "semantic-ui-react";
// import { upperCase } from "lodash";

import StandardTableHeader from "../UI/StandardHeader";
class UserList extends React.Component {
  constructor() {
    super();
    this.state = {
      searchValue: "",
    };
  }

  componentDidMount() {
    this.props.fetchUsers();
  }

  componentWillUnmount() {
    this.props.clearAuth();
  }

  renderTable() {
    let userList = this.props.users
      .filter((User) => {
        return User.name
          .toUpperCase()
          .includes(this.state.searchValue.toUpperCase());
      })
      .map((User) => {
        return (
          <tr key={User.userID}>
            <td className="mobile hidden">{User.userID}</td>
            <td>
              <Link
                className="item"
                to={`/application/users/edit/${User.userID}`}
              >
                <div className="item" title={User.description}>
                  {User.name}
                </div>
              </Link>
            </td>
            <td className="center aligned mobile hidden">
              {util.utcStringToLocalDateTime(User.lastUpdatedDateTime)}
            </td>
            <td className="center aligned mobile hidden">
              {util.utcStringToLocalDateTime(User.lastLogonDateTime)}
            </td>
            <td className="center aligned">
              <i
                style={{ cursor: "pointer" }}
                className="mail icon blue"
                title="Send password mail."
                onClick={() => this.props.resetPassword(User.email)}
              />
            </td>
            <td className="center aligned">
              <Link
                className="item"
                to={`/application/users/delete/${User.userID}`}
              >
                <i
                  style={{ cursor: "pointer" }}
                  className="trash icon blue"
                  title="Delete record."
                />
              </Link>
            </td>
          </tr>
        );
      });

    return (
      <table className="ui celled table unstackable">
        <thead>
          <tr>
            <th className="one wide mobile hidden">ID</th>
            <th className="eight wide">Name</th>
            <th className="three wide center aligned mobile hidden">Changed</th>
            <th className="three wide center aligned mobile hidden">
              LastLogon
            </th>
            <th colSpan={2} className="one wide center aligned">
              Action
            </th>
          </tr>
        </thead>
        <tbody>{userList}</tbody>
      </table>
    );
  }

  updateSearchValue(evt) {
    this.setState({
      searchValue: evt.target.value,
    });
  }

  responseMessage() {
    if (this.props.authSuccessMessage) {
      return (
        <div className="column">
          <div className="ui success message">
            <i className="close icon" onClick={this.props.clearAuth} />
            <div className="header">Success:</div>
            <p>{this.props.authSuccessMessage}</p>
          </div>
        </div>
      );
    } else if (this.props.authErrorMessage) {
      return (
        <div className="column">
          <div className="ui error message">
            <i className="close icon" onClick={this.props.clearAuth} />
            <div className="header">Error:</div>
            <p>{this.props.authErrorMessage}</p>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="ui one column grid">
        <div className="column">
          <StandardTableHeader
            headerLabel="Users"
            linkForNewButton="/application/users/new"
            recordCount={this.props.users.length}
            searchValue={this.state.searchValue}
            searchFunction={(evt) => this.updateSearchValue(evt)}
          />
        </div>
        <div className="column">{this.renderTable()}</div>
        {this.responseMessage()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: Object.values(state.users.items),
    authRequest: state.auth.authRequest,
    authError: state.auth.authError,
    authErrorMessage: state.auth.authErrorMessage,
    authSuccess: state.auth.authSuccess,
    authSuccessMessage: state.auth.authSuccessMessage,
  };
};

export default connect(mapStateToProps, {
  fetchUsers,
  resetPassword,
  clearAuth,
  disableUser,
})(UserList);
