import React from 'react';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions';

class Logout extends React.Component {
  componentDidMount() {
    this.props.logoutUser();
  }

  render() {
    return <div>Logout</div>;
  }
}

export default connect(
  null,
  { logoutUser }
)(Logout);
