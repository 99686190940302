import React from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import moment from "moment";

import * as actions from "../../actions";
import InvoiceForm from "./InvoiceForm";
import ProjectBreadcrumb from "../breadcrumb/ProjectBreadcrumb";
import StandardTableHeader from "../UI/StandardHeader";

class InvoiceEdit extends React.Component {
  componentDidMount() {
    const { invoiceid } = this.props.match.params;
    this.props.fetchInvoice(invoiceid);
  }

  onSubmit = (fv) => {
    const { invoiceid } = this.props.match.params;
    this.props.editInvoice(invoiceid, fv, this.props.projectID);
  };

  componentWillUnmount() {
    this.props.unloadInvoice();
  }

  render() {
    if (!this.props.invoice || !this.props.projectID) {
      return <div>Loading...</div>;
    }

    const initialVal = {
      ...this.props.invoice,
      sendDateTime: this.props.invoice.sendDateTime
        ? moment(this.props.invoice.sendDateTime).format("YYYY-MM-DD")
        : null,
    };

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <StandardTableHeader headerLabel="Edit Invoice">
              <ProjectBreadcrumb
                projectID={this.props.projectID}
                currentPath="Invoice"
              />
            </StandardTableHeader>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <InvoiceForm initialValues={initialVal} onSubmit={this.onSubmit} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    invoice: state.invoices.item,
    projectID: state.invoices.projectID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInvoice: (id) => dispatch(actions.fetchInvoice(id)),
    unloadInvoice: () => dispatch(actions.unloadInvoice()),
    editInvoice: (id, formValues, projectID) =>
      dispatch(actions.editInvoice(id, formValues, projectID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceEdit);
