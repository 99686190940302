import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import history from "../../history";
import * as util from "../../shared/utility";
//import FileSelectList from '../files/FileSelectList';
import { Grid } from "semantic-ui-react";

class TelloForm extends React.Component {
  onSubmit = (formValues) => {
    var arr = [];
    for (var p1 in formValues.commonFiles) {
      arr.push(util.atobUTF16(p1));
    }

    var arrProject = [];
    for (var p2 in formValues.projectFiles) {
      arrProject.push(util.atobUTF16(p2));
    }

    var fv = {
      ...formValues,
      commonFiles: arr,
      projectFiles: arrProject,
      projectID: this.props.projectid,
    };

    this.props.onSubmit(fv);
  };

  handleCancelClick = () => {
    history.goBack();
  };

  renderField = ({ input, label, file, type, meta: { touched, error } }) => (
    <React.Fragment>
      <td className="center aligned">
        <div className="ui checkbox">
          <input {...input} type="checkbox" name={label} value={file.name} />
          <label />
        </div>
      </td>
      <td>{label}</td>
      <td className="center aligned">{util.formatBytes(file.length)}</td>
      <td className="center aligned">
        {util.formatStringToDateTime(file.lastAccessTime)}
      </td>
      <td className="center aligned">
        <i
          style={{ cursor: "pointer" }}
          className="download icon blue"
          onClick={() => this.props.downloadFile(label)}
        />
      </td>
    </React.Fragment>
  );

  renderProjectField = ({
    input,
    label,
    file,
    type,
    meta: { touched, error },
  }) => (
    <React.Fragment>
      <td className="center aligned">
        <div className="ui checkbox">
          <input {...input} type="checkbox" name={label} value={file.name} />
          <label />
        </div>
      </td>
      <td>{label}</td>
      <td className="center aligned">{util.formatBytes(file.length)}</td>
      <td className="center aligned">
        {util.formatStringToDateTime(file.lastAccessTime)}
      </td>
      <td className="center aligned">
        <i
          style={{ cursor: "pointer" }}
          className="download icon blue"
          onClick={() =>
            this.props.downloadProjectFile(this.props.projectid, label)
          }
        />
      </td>
    </React.Fragment>
  );

  renderFiles = ({ fields, meta: { error, submitFailed } }) => (
    <table className="ui celled table unstackable">
      <thead>
        <tr>
          <th className="one wide center aligned">Select</th>
          <th className="eight wide">Name</th>
          <th className="three wide center aligned">Size</th>
          <th className="three wide center aligned">Changed</th>
          <th className="one wide center aligned">Action</th>
        </tr>
      </thead>
      <tbody>
        {this.props.files
          .filter((file) => {
            return file.name
              .toUpperCase()
              .includes(this.props.searchValue.toUpperCase());
          })
          .sort((a, b) => b.lastAccessTime.localeCompare(a.lastAccessTime))
          .map((f, index) => (
            <tr key={index}>
              <Field
                name={`${"commonFiles"}.${util.btoaUTF16(f.name)}`}
                type="checkbox"
                component={this.renderField}
                label={f.name}
                file={f}
              />
            </tr>
          ))}
      </tbody>
    </table>
  );

  renderProjectFiles = ({ fields, meta: { error, submitFailed } }) => {
    if (this.props.projectFiles.length === 0) {
      return (
        <React.Fragment>
          <h3>Project Files:</h3>
          <div>No project files...</div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h3>Project Files:</h3>
          <table className="ui celled table unstackable">
            <thead>
              <tr>
                <th className="one wide center aligned">Select</th>
                <th className="eight wide">Name</th>
                <th className="three wide center aligned">Size</th>
                <th className="three wide center aligned">Changed</th>
                <th className="one wide center aligned">Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.projectFiles
                .sort((a, b) =>
                  b.lastAccessTime.localeCompare(a.lastAccessTime)
                )
                .map((f, index) => (
                  <tr key={index}>
                    <Field
                      name={`${"projectFiles"}.${util.btoaUTF16(f.name)}`}
                      type="checkbox"
                      component={this.renderProjectField}
                      label={f.name}
                      file={f}
                    />
                  </tr>
                ))}
            </tbody>
          </table>
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <Field
          name="projectText"
          component={util.RenderInput}
          label="Project:"
        />
        <Field name="visitText" component={util.RenderInput} label="Visit:" />
        <Field
          name="addressText"
          component={util.RenderInput}
          label="Address:"
        />
        <Field
          name="contactText"
          component={util.RenderInput}
          label="Contact:"
        />
        <Field
          name="deliverOnText"
          component={util.RenderInput}
          label="Deliver on:"
        />
        <Field
          name="cablePlanText"
          component={util.RenderInputMultiline}
          label="Location inverter - Cable plan - Location router:"
        />
        <Field
          name="stringPlanText"
          component={util.RenderInputMultiline}
          label="String plan:"
        />
        <Field
          name="obstaclesText"
          component={util.RenderInputMultiline}
          label="Obstacles:"
        />
        <h3>
          Project Files with "woonhuis" in the name will be added automatically
          to the first card.
          <br />
          Project Files with "meterkast" in the name will be added automatically
          to a separated card.
          <br />
          Project file with the name "kabelrouting" in the name will be added
          automatically to a separated card.
          <br />
          Project file with the name "lay-out" in the name will be added
          automatically to a separated card.
          <br />
          Project file with the name "stringplan" in the name will be added
          automatically to a separated card.
        </h3>
        <FieldArray
          name="projectFilesFieldArray"
          component={this.renderProjectFiles}
        />
        <br />
        <Grid className="middle aligned">
          <Grid.Row>
            <Grid.Column width={4}>
              <h3>Common Files:</h3>
            </Grid.Column>
            <Grid.Column width={12} textAlign="right">
              <div className="ui right floated icon input">
                <div
                  className="ui label center"
                  style={{ marginRight: 10, paddingTop: 12, minWidth: 80 }}
                >
                  Total:
                  <div className="detail">
                    {
                      this.props.files.filter((file) => {
                        return file.name
                          .toUpperCase()
                          .includes(this.props.searchValue.toUpperCase());
                      }).length
                    }
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Search..."
                  value={this.props.searchValue}
                  onChange={this.props.updatesearchValue}
                  style={{ width: 200 }}
                />
                <i className="search link icon" />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <FieldArray
                name="commonFilesFieldArray"
                component={this.renderFiles}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {(() => {
          if (this.props.showmessage) {
            return (
              <div className="ui positive message">
                <i
                  className="close icon"
                  onClick={this.props.resetTrelloMessage}
                />
                <div className="header">Trello response:</div>
                <p>{this.props.responsemessage}</p>
              </div>
            );
          }
        })()}
        <br />
        <button
          className={`ui button green ${this.props.creating ? "loading" : ""}`}
          disabled={this.props.creating}
        >
          Create In Trello
        </button>
        <button
          type="button"
          className="ui button"
          onClick={this.handleCancelClick}
        >
          Cancel
        </button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.projectText) {
    errors.projectText = "You cannot send an empty project text.";
  }

  return errors;
};

export default reduxForm({
  form: "trelloForm",
  validate,
})(TelloForm);
