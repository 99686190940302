import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import MailSendForm from "./MailSendForm";
import { Grid, Dropdown } from "semantic-ui-react";

import ProjectHeader from "../projectHeader/ProjectHeader";
class MailSend extends React.Component {
  constructor() {
    super();
    this.state = {
      searchValue: "",
    };
  }

  componentDidMount() {
    this.props.resetMailMessage();
    this.props.fetchMailTemplates();
    this.props.fetchFiles();
    this.props.fetchProjectFiles(this.props.projectid);
    this.props.fetchProjectDashboard(this.props.projectid);
  }

  componentWillUnmount() {
    // this.props.unloadProjectDashboard();
  }

  onSubmit = (fv) => {
    this.props.sendMail(fv);
  };

  handleChange = (e, { value }) => {
    //this.props.setMailTemplate(value);
    this.props.setProjectMailDto(value, this.props.projectid);
  };

  updatesearchValue = (evt) => {
    this.setState({
      searchValue: evt.target.value,
    });
  };

  render() {
    if (this.props.mailTemplates.length === 0) {
      return <div>No mail templates loaded...</div>;
    }

    if (!this.props.projectid) {
      return <div>No valid projectid...</div>;
    }

    const init = { ...this.props.projectMailDto };

    const mailTemplateOptions = this.props.mailTemplates
      .sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : 0
      )
      .map((mailTemplate) => ({
        key: mailTemplate.mailTemplateID,
        value: mailTemplate.mailTemplateID,
        text: mailTemplate.name,
      }));

    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <ProjectHeader project={this.props.project} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <h5>Mail Template:</h5>
              <Dropdown
                placeholder="Select Mail Template"
                onChange={this.handleChange}
                fluid
                search
                selection
                options={mailTemplateOptions}
              />
            </Grid.Column>
          </Grid.Row>
          {(() => {
            if (this.props.projectMailDto) {
              return (
                <Grid.Row>
                  <Grid.Column>
                    <MailSendForm
                      enableReinitialize={true}
                      initialValues={init}
                      onSubmit={this.onSubmit}
                      files={this.props.files}
                      projectFiles={this.props.projectFiles}
                      downloadFile={this.props.downloadFile}
                      downloadProjectFile={this.props.downloadProjectFile}
                      showmessage={this.props.showmessage}
                      clearMailResponseMessage={
                        this.props.clearMailResponseMessage
                      }
                      responsemessage={this.props.responsemessage}
                      projectid={this.props.projectid}
                      searchValue={this.state.searchValue}
                      updatesearchValue={this.updatesearchValue}
                    />
                  </Grid.Column>
                </Grid.Row>
              );
            }
          })()}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    projectid: ownProps.match.params.projectid,
    mailTemplates: Object.values(state.mailTemplates.items),
    //mailTemplate: state.mail.mailTemplate,
    projectMailDto: state.mail.projectMailDto,
    files: state.files.items,
    projectFiles: state.projectFiles.items,
    responsemessage: state.mail.responsemessage,
    showmessage: state.mail.showmessage,
    // project: state.projects.items[ownProps.match.params.projectid],
    project: state.projects.projectDashboard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMailTemplates: () => dispatch(actions.fetchMailTemplates()),
    //setMailTemplate: id => dispatch(actions.setMailTemplate(id)),
    setProjectMailDto: (id, projectid) =>
      dispatch(actions.setProjectMailDto(id, projectid)),
    sendMail: (formValues) => dispatch(actions.sendMail(formValues)),
    fetchFiles: () => dispatch(actions.fetchFiles()),
    fetchProjectFiles: (projectid) =>
      dispatch(actions.fetchProjectFiles(projectid)),
    fetchProject: (projectid) => dispatch(actions.fetchProject(projectid)),
    downloadFile: (filename) => dispatch(actions.downloadFile(filename)),
    downloadProjectFile: (projectid, filename) =>
      dispatch(actions.downloadProjectFile(projectid, filename)),
    resetMailMessage: () => dispatch(actions.resetMailMessage()),
    clearMailResponseMessage: () =>
      dispatch(actions.clearMailResponseMessage()),
    fetchProjectDashboard: (projectid) =>
      dispatch(actions.fetchProjectDashboard(projectid)),
    unloadProjectDashboard: () => dispatch(actions.unloadProjectDashboard()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailSend);
