import React from "react";

import VisitBottomForm from "./VisitBottomForm";
import VisitRoofFormTop from "./VisitRoofFormTop";
import RoofSectionOverview from "../../roofSections/RoofSectionOverview";

const VisitRoofForm = ({
  projectID,
  articles,
  onSubmit,
  initialValues,
  base64map,
  imageExtension,
  project,
}) => {
  return (
    <>
      <div className="ui one column grid">
        <div className="column">
          <VisitRoofFormTop
            onSubmit={onSubmit}
            initialValues={initialValues}
            articles={articles}
            projectID={projectID}
            base64map={base64map}
            imageExtension={imageExtension}
            project={project}
          />
        </div>
      </div>
      {/* <div className="ui one column grid segment orange inverted secondary"> */}
      {/* <div className="ui one column grid segment grey inverted tertiary"> */}
      <div className="ui one column grid segment ">
        <div className="column">
          <RoofSectionOverview articles={articles} projectID={projectID} />
        </div>
      </div>
      <div className="ui one column grid">
        <div className="column">
          <VisitBottomForm
            onSubmit={onSubmit}
            articles={articles}
            initialValues={initialValues}
            projectID={projectID}
          />
        </div>
      </div>
    </>
  );
};

export default VisitRoofForm;
