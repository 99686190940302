import {
  SET_ISMOBILE,
  SET_UPDATE_AVAILABLE,
  SET_GLOBAL_ERROR_MESSAGE,
} from "./types";

export const handleWindowSizeChange = () => async (dispatch) => {
  const width = window.innerWidth;
  const isMobile = width < 768;
  dispatch({ type: SET_ISMOBILE, payload: isMobile });
};

export const setUpdateAvailable = (available) => (dispatch) => {
  dispatch({
    type: SET_UPDATE_AVAILABLE,
    payload: available,
  });
};

export const setGlobalErrors = (message) => async (dispatch) => {
  dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: message });
};
