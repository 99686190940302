import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from "semantic-ui-react";
import { Link } from "react-router-dom";

import * as util from "../../shared/utility";
import * as actions from "../../actions";
import StandardTableHeader from "../UI/StandardHeader";

import "./OrderPagedList.css";

const OrderPagedListMaterials = () => {
  const dispatch = useDispatch();

  const pageMetadata = useSelector((state) => state.orders.pageMetadata);
  const pageItems = useSelector((state) => state.orders.pageItems);
  const pageFilter = useSelector((state) => state.orders.pageFilter);
  const pageNumber = useSelector((state) => state.orders.pageNumber);
  const showInvoiced = useSelector((state) => state.orders.showInvoiced);
  const showPlanned = useSelector((state) => state.orders.showPlanned);

  const visitorFilter = useSelector((state) => state.orders.visitorFilter);

  const [searchValue, setSearchValue] = useState(pageFilter);

  useEffect(() => {
    dispatch(
      actions.fetchOrdersPaged(
        pageFilter,
        pageNumber,
        showInvoiced,
        visitorFilter,
        showPlanned
      )
    );

    // On a reset, make sure the searchvalue is also resetted.
    if (searchValue !== pageFilter) {
      setSearchValue(pageFilter);
    }
  }, [pageFilter, pageNumber, showInvoiced, visitorFilter, showPlanned]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchValue !== pageFilter) {
        dispatch(actions.setOrdersPageFilter(searchValue));
      }
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchValue]);

  const [checkedOrderIds, setCheckedOrderIds] = useState([]);

  const toggleCheckBox = (id) => {
    if (checkedOrderIds.indexOf(id) === -1) {
      setCheckedOrderIds((checkedOrderIds) => [...checkedOrderIds, id]);
    } else {
      setCheckedOrderIds((checkedOrderIds) => [
        ...checkedOrderIds.filter((item) => item !== id),
      ]);
    }
  };

  const orderlist = Object.values(pageItems)
    .sort(
      (a, b) => b.orderDate.localeCompare(a.orderDate) || b.orderID - a.orderID
    )
    .map((order) => {
      return (
        <tr key={order.orderID}>
          <td
            className="center aligned checkboxDiv"
            onClick={() => toggleCheckBox(order.orderID)}
          >
            <input
              type="checkbox"
              checked={checkedOrderIds.includes(order.orderID)}
              onChange={() => {}}
            />
          </td>
          <td className="noWrapCell">
            <Link className="item" to={`/sales/orders/edit/${order.orderID}`}>
              <div className="item" title={order.name}>
                {order.name}
              </div>
            </Link>
          </td>
          <td className=" mobile hidden">{order.visitorName}</td>
          <td className="center aligned mobile hidden">
            {util.utcStringToLocalDate(order.orderDate)}
          </td>
          <td className="center aligned mobile hidden">
            {util.utcStringToLocalDate(order.deliverDate)}
          </td>
          <td className="center aligned mobile hidden">
            {order.invoiced && <i className="credit card icon green" />}
          </td>
          <td className="center aligned">
            <Link
              className="item"
              to={`/sales/projects/dashboard/${order.projectID}`}
              key={order.projectID}
            >
              <i
                className="tachometer alternate icon"
                title="Open project dashboard."
              />
            </Link>
          </td>
          <td className="center aligned">
            <i
              style={{ cursor: "pointer" }}
              className="file excel icon blue"
              title="Download material list."
              onClick={() =>
                dispatch(actions.getOrdersMaterials([order.orderID]))
              }
            />
          </td>
        </tr>
      );
    });

  const renderTable = (
    <>
      <table className="ui celled table unstackable">
        <thead>
          <tr>
            <th className="one wide"></th>
            <th>
              <i className="handshake icon large" />
              <h4 className="dashboardHeaderTexts">Orders</h4>
            </th>
            <th className="one wide  mobile hidden">Visitor</th>
            <th className="two wide center aligned  mobile hidden">
              Ordered
              <i className="caret down icon" />
            </th>
            <th className="two wide center aligned mobile hidden">Deliver</th>
            <th className="one wide center aligned mobile hidden">Invoiced</th>
            <th className="two wide center aligned" colSpan="2">
              Action
            </th>
          </tr>
        </thead>
        <tbody>{pageMetadata.totalCount > 0 && orderlist}</tbody>
      </table>
      {pageMetadata.totalCount > 0 && (
        <Pagination
          activePage={pageMetadata.currentPage}
          totalPages={pageMetadata.totalPages}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          onPageChange={(e, props) =>
            dispatch(actions.setOrdersPageNumber(props.activePage))
          }
        />
      )}
    </>
  );

  const OrderFilter = () => {
    return (
      <div className="pageFilterRow">
        <div>
          <div
            className="ui toggle checkbox"
            onClick={() => dispatch(actions.toggleShowInvoiced())}
            style={{ cursor: "pointer" }}
          >
            <input
              type="checkbox"
              name="public"
              defaultChecked={showInvoiced}
            />
            <label>Show Invoiced</label>
          </div>
          <div
            className="ui toggle checkbox"
            onClick={() => dispatch(actions.toggleShowPlanned())}
            style={{ cursor: "pointer" }}
          >
            <input type="checkbox" name="public" defaultChecked={showPlanned} />
            <label>Show Planned</label>
          </div>
        </div>
        <div>
          <select
            className="ui selection dropdown"
            name="visitorName"
            label="Visitor:"
            onChange={(e) =>
              dispatch(actions.setOrdersVisitorFilter(e.target.value))
            }
            value={visitorFilter}
          >
            <option key={0} value={""}>
              Select visitor...
            </option>
            {util.VISITORS.map((x) => (
              <option key={x.Key} value={x.Name}>
                {x.Name}
              </option>
            ))}
            <option key={100} value={"NoVisitor"}>
              No visitor
            </option>
          </select>
          <button
            className="ui button labeled icon"
            onClick={() => dispatch(actions.resetOrdersFilter())}
            title="Reset filters."
            style={{
              backgroundColor: "var(--company-bg-color-50)",
              marginLeft: "10px",
            }}
          >
            <i className="undo icon" />
            Reset Filters
          </button>
        </div>
      </div>
    );
  };

  const OrderSelection = () => {
    return (
      <div className="pageFilterRow">
        <div>Items selected: {checkedOrderIds.length}</div>
        <div>
          <button
            className="ui button labeled icon primary"
            onClick={() =>
              dispatch(actions.getOrdersMaterials(checkedOrderIds))
            }
            disabled={checkedOrderIds.length === 0}
            title="Download material list."
          >
            <i className="file excel icon" />
            Materials
          </button>
          <button
            className="ui button labeled icon"
            onClick={() => setCheckedOrderIds([])}
            title="Reset selection."
            style={{
              backgroundColor: "var(--company-bg-color-50)",
              marginLeft: "10px",
            }}
          >
            <i className="undo icon" />
            Reset Selection
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="ui one column grid">
      <div className="column">
        <div className="row">
          <StandardTableHeader
            headerLabel="Order Materials"
            recordCount={pageMetadata.totalCount}
            searchValue={searchValue}
            searchTooltip={`
You can search on the name 
of the order.`}
            searchFunction={(e) => setSearchValue(e.target.value)}
          ></StandardTableHeader>
        </div>
        <div className="row">
          <OrderFilter />
        </div>
        <div className="row">
          <OrderSelection />
        </div>
      </div>
      <div className="column">{renderTable}</div>
    </div>
  );
};

export default OrderPagedListMaterials;
