import solartoolapi from "../apis/solartoolapi";
import {
  FETCH_RELATION,
  FETCH_RELATIONS_PAGED,
  FETCH_SUPPLIERS,
  FETCH_CUSTOMERS,
  SET_RELATIONS_FILTER,
  SET_RELATIONS_OFFSET,
  SET_RELATIONS_PAGE_FILTER,
  SET_RELATIONS_RELATION_TYPE_FILTER,
  RESET_RELATIONS_PAGE_FILTER,
  SET_RELATIONS_PAGE_NUMBER,
  UNLOAD_RELATION,
} from "./types";
import { authHeader } from "../shared/utility";
import history from "../history";

export const fetchRelation = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/relations/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_RELATION, payload: response.data.result });
};

export const fetchRelationsPaged =
  (pageFilter, pageNumber, relationTypeFilter) => async (dispatch) => {
    const response = await solartoolapi.get(
      `/relations/paged?pageNumber=${pageNumber}&pageFilter=${pageFilter}&relationTypeFilter=${relationTypeFilter}`,
      {
        headers: authHeader(),
      }
    );
    dispatch({
      type: FETCH_RELATIONS_PAGED,
      payload: response.data,
    });
  };

// export const fetchRelationsPagedFirstTime =
//   (pageFilter, pageNumber, relationTypeFilter) => async (dispatch) => {
//     const response = await solartoolapi.get(
//       `/relations/paged?pageNumber=${pageNumber}&pageFilter=${pageFilter}&relationTypeFilter=${relationTypeFilter}`,
//       {
//         headers: authHeader(),
//       }
//     );
//     dispatch({
//       type: FETCH_RELATIONS_PAGED_FIRST,
//       payload: response.data,
//     });
//   };

export const fetchSuppliers = () => async (dispatch) => {
  const response = await solartoolapi.get(`/relations/suppliers`, {
    headers: authHeader(),
  });
  dispatch({
    type: FETCH_SUPPLIERS,
    payload: response.data,
  });
};

export const fetchCustomers = () => async (dispatch) => {
  const response = await solartoolapi.get(`/relations/customers`, {
    headers: authHeader(),
  });
  dispatch({
    type: FETCH_CUSTOMERS,
    payload: response.data,
  });
};

export const createRelation = (formValues) => async () => {
  await solartoolapi.post(
    "/relations",
    {
      ...formValues,
    },
    { headers: authHeader() }
  );

  history.goBack();
};

export const editRelation = (id, formValues) => async () => {
  await solartoolapi.put(`/relations/${id}`, formValues, {
    headers: authHeader(),
  });

  history.goBack();
};

export const disableRelation = (id) => async () => {
  await solartoolapi.delete(`/relations/disable/${id}`, {
    headers: authHeader(),
  });

  history.goBack();
};

export const setRelationsFilter = (searchText) => (dispatch) => {
  dispatch({ type: SET_RELATIONS_FILTER, payload: searchText });
};

export const setRelationsOffset = (offset) => (dispatch) => {
  dispatch({ type: SET_RELATIONS_OFFSET, payload: offset });
};

export const setRelationsPageFilter = (pageFilter) => (dispatch) => {
  dispatch({ type: SET_RELATIONS_PAGE_FILTER, payload: pageFilter });
};

export const resetRelationsFilter = (relationType) => (dispatch) => {
  dispatch({ type: RESET_RELATIONS_PAGE_FILTER, payload: relationType });
};

export const setRelationsPageNumber = (pageNumber) => (dispatch) => {
  dispatch({ type: SET_RELATIONS_PAGE_NUMBER, payload: pageNumber });
};

export const unloadRelation = () => (dispatch) => {
  dispatch({ type: UNLOAD_RELATION });
};

export const setRelationsRelationTypeFilter = (relationType) => (dispatch) => {
  dispatch({ type: SET_RELATIONS_RELATION_TYPE_FILTER, payload: relationType });
};
