import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";

import MailTemplateForm from "./MailTemplateForm";
import StandardTableHeader from "../UI/StandardHeader";

class MailTemplateCreate extends React.Component {
  componentDidMount() {
    if (this.props.categories.length === 0) {
      this.props.fetchCategories();
    }
  }

  onSubmit = (formValues) => {
    this.props.createMailTemplate(formValues);
  };

  render() {
    const initialVal = {
      btwID: 1,
      priority: 100,
    };

    return (
      <div className="ui one column grid">
        <div className="column">
          <StandardTableHeader headerLabel="Create Mail Template"></StandardTableHeader>
        </div>
        <div className="column">
          <MailTemplateForm
            initialValues={initialVal}
            categories={this.props.categories}
            onSubmit={this.onSubmit}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    categories: state.categories.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createMailTemplate: (formValues) =>
      dispatch(actions.createMailTemplate(formValues)),
    fetchCategories: () => dispatch(actions.fetchCategories()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailTemplateCreate);
