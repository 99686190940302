import React from "react";
import { NavLink } from "react-router-dom";
import { Popup } from "semantic-ui-react";

import "./DashboardMenu.css";
import * as util from "../../shared/utility";

const DashboardMenu = ({
  project,
  project: { projectAddress: address },
  project: { contactPerson: person },
}) => {
  if (!project) {
    return null;
  }

  const dashBoardMenuItems = [
    {
      name: "Dashboard",
      to: `/sales/projects/dashboard/${project.projectID}`,
      icon: "tachometer alternate",
    },
    {
      name: "Project Info",
      to: `/sales/projects/info/${project.projectID}`,
      icon: "id card outline",
    },
    {
      name: "Visit",
      to: !!project.visit
        ? `/sales/projects/visits/edit/${project.projectID}`
        : `/sales/projects/visits/new/${project.projectID}`,
      icon: "home",
    },
    {
      name: "Mail",
      to: `/sales/projects/mail/${project.projectID}`,
      icon: "mail",
    },
  ];

  const DashBoardMenuList = () =>
    dashBoardMenuItems.map((menuItem, index) => {
      return (
        <div key={index} className="dashboardMenyItem">
          <NavLink activeClassName="activeDashboardMenuItem" to={menuItem.to}>
            <i
              className={`${menuItem.icon} icon large`}
              title={menuItem.name}
            />
            <div className="mobile hidden">{menuItem.name}</div>
          </NavLink>
        </div>
      );
    });

  const ProjectDiv = ({ name, remarks }) => {
    return (
      <div>
        {name}
        {remarks && (
          <Popup
            wide
            position="bottom right"
            trigger={
              <i
                className="comment alternate outline icon"
                style={{
                  color: "var(--company-bg-color-100)",
                  marginLeft: "6px",
                }}
              />
            }
          >
            <Popup.Content>
              <h3>Remarks:</h3>
              <p style={{ whiteSpace: "pre-wrap" }}>{remarks}</p>
            </Popup.Content>
          </Popup>
        )}
      </div>
    );
  };

  const AddressDiv = ({ address }) => {
    if (!address) {
      return <div className="warning"> NO ADDRESS</div>;
    }

    return (
      <div>
        {util.GoogleHelperLink(
          `${address.fullAddress}, ${address.city}`,
          `${address.fullAddress},${address.zipCode},${address.city},${address.countryName}`
        )}
      </div>
    );
  };

  const PersonDiv = ({ person }) => {
    if (!person) {
      return <div className="warning"> NO CONTACT</div>;
    }

    return (
      <div>
        {`${person.fullName}`}
        {person.phoneNumber && (
          <a href={`tel:${person.phoneNumber}`}>
            <i
              className="phone icon"
              style={{
                color: "var(--company-bg-color-100)",
                marginLeft: "6px",
              }}
            />
          </a>
        )}
      </div>
    );
  };

  return (
    <div className="column">
      <div className="dashboardMenu">
        <DashBoardMenuList />
      </div>
      <div className="dashboardTitle ">
        <AddressDiv address={address} />
        <ProjectDiv name={project.name} remarks={project.remarks} />
        <PersonDiv person={person} />
      </div>
    </div>
  );
};

export default DashboardMenu;
