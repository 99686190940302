import { FETCH_COUNTRIES } from '../actions/types';

var initialState = {
  items: [],
  defaultID: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES:
      const defaultCountryID = action.payload.find(
        country => country.default === true
      ).countryID;

      return {
        ...state,
        items: action.payload,
        defaultID: defaultCountryID
      };
    default:
      return state;
  }
};
