import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid } from "semantic-ui-react";

import * as actions from "../../actions";
import * as util from "../../shared/utility";
import ProjectBreadcrumb from "../breadcrumb/ProjectBreadcrumb";
import StandardTableHeader from "../UI/StandardHeader";

class OrderList extends React.Component {
  componentDidMount() {
    const { projectid } = this.props.match.params;
    this.props.fetchOrdersForProject(projectid);
  }

  componentWillUnmount() {
    this.props.unloadOrders();
  }

  renderTable() {
    let orderslist = null;

    if (this.props.orders.length !== 0) {
      orderslist = this.props.orders
        .sort((a, b) =>
          b.lastUpdatedDateTime.localeCompare(a.lastUpdatedDateTime)
        )
        .map((order) => {
          return (
            <tr key={order.orderID}>
              <td className="mobile hidden">{order.orderID}</td>
              <td>
                <Link
                  className="item"
                  to={`/sales/orders/edit/${order.orderID}`}
                >
                  <div className="item">{order.name}</div>
                </Link>
              </td>
              <td className="center aligned mobile hidden">
                {util.formatStringToDateTime(order.deliverDate)}
              </td>
              <td className="center aligned mobile hidden">
                {util.utcStringToLocalDateTime(order.lastUpdatedDateTime)}
              </td>
              <td>
                <i
                  style={{ cursor: "pointer" }}
                  className="file pdf icon orange"
                  title="Download file preview."
                  onClick={() => this.props.previewOrderPdf(order.orderID)}
                />
              </td>
              <td>
                <i
                  style={{ cursor: "pointer" }}
                  className="file save icon blue"
                  title="Create file in project folder."
                  onClick={() => this.props.generateOrderPdf(order.orderID)}
                />
              </td>
              <td>
                <Link
                  className="item"
                  to={`/sales/orders/delete/${order.orderID}`}
                  key={order.orderID}
                >
                  <i className="trash icon" title="Delete record." />
                </Link>
              </td>
            </tr>
          );
        });
    }

    return (
      <div>
        <table className="ui celled table unstackable">
          <thead>
            <tr>
              <th className="one wide mobile hidden">ID</th>
              <th className="eight wide">Name</th>
              <th className="three wide center aligned mobile hidden">
                Deliver
              </th>
              <th className="three wide center aligned mobile hidden">
                Changed
              </th>
              <th className="one wide center aligned" colSpan="3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>{orderslist}</tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <StandardTableHeader headerLabel="Orders">
                <ProjectBreadcrumb
                  projectID={this.props.match.params.projectid}
                  currentPath="Orders"
                />
              </StandardTableHeader>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{this.renderTable()}</Grid.Column>
          </Grid.Row>
        </Grid>
        {(() => {
          if (this.props.showmessage) {
            return (
              <div className="ui positive message">
                <i
                  className="close icon"
                  onClick={this.props.resetOrdersMessage}
                />
                <div className="header">Response:</div>
                <p>{this.props.responsemessage}</p>
              </div>
            );
          }
        })()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    orders: state.orders.items,
    isMobile: state.utility.isMobile,
    responsemessage: state.orders.responsemessage,
    showmessage: state.orders.showmessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrdersForProject: (id) => dispatch(actions.fetchOrdersForProject(id)),
    generateOrderPdf: (id) => dispatch(actions.generateOrderPdf(id)),
    previewOrderPdf: (id) => dispatch(actions.previewOrderPdf(id)),
    unloadOrders: () => dispatch(actions.unloadOrders()),
    resetOrdersMessage: () => dispatch(actions.resetOrdersMessage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
