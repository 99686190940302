import React, { useEffect } from "react";
import {
  Field,
  reduxForm,
  formValueSelector,
  change,
  getFormSyncErrors,
  hasSubmitFailed,
} from "redux-form";
import { Grid } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";

import * as util from "../../../shared/utility";
import validate from "./validate";

let VisitBottomForm = (props) => {
  const { handleSubmit, onSubmit, articles } = props;

  const dispatch = useDispatch();

  const onSelectWithSearchChange = (e, data) => {
    dispatch(change("visitForm", data.name, data.value));
  };

  const syncErrors = useSelector((state) =>
    getFormSyncErrors("visitForm")(state)
  );

  const submitFailed = useSelector((state) =>
    hasSubmitFailed("visitForm")(state)
  );

  const ForceChangeEnergyMeter = useSelector((state) =>
    util.parseBoolean(formValueSelector("visitForm")(state, "validEnergyMeter"))
  );

  const IsBusiness = useSelector(
    (state) =>
      Number(formValueSelector("visitForm")(state, "customerType")) === 1 ||
      Number(formValueSelector("visitForm")(state, "customerType")) === 2
  );

  const IsScope12RequiredValue = useSelector((state) =>
    util.parseBoolean(
      formValueSelector("visitForm")(state, "isScope12Required")
    )
  );

  const errorMessage = submitFailed && Object.keys(syncErrors).length > 0 && (
    <div className="row">
      <div className="column">
        <div className="ui error message">
          <div className="header">
            Please fix the {Object.keys(syncErrors).length} errors above.
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (ForceChangeEnergyMeter) {
      dispatch(change("visitForm", "changeEnergyMeter", true));
    }
    if (!IsScope12RequiredValue) {
      dispatch(change("visitForm", "scope12ArticleID", null));
    }
    if (!IsBusiness) {
      dispatch(change("visitForm", "isScope12Required", null));
      dispatch(change("visitForm", "scope12ArticleID", null));
      dispatch(change("visitForm", "isPowerReturningAllowed", null));
    }
  }, [ForceChangeEnergyMeter, IsScope12RequiredValue, IsBusiness]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="ui form error">
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Field
              name="workArticleID"
              label="Desired work article:"
              component={util.RenderSelectWithSearch}
              handleChange={onSelectWithSearchChange}
            >
              {[
                ...articles
                  .filter((article) => {
                    return article.categoryID === 4;
                  })
                  .sort((a, b) =>
                    a.priority < b.priority
                      ? 1
                      : a.priority > b.priority
                      ? -1
                      : a.name.toLowerCase() < b.name.toLowerCase()
                      ? -1
                      : a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : 0
                  )
                  .map((x) => {
                    return {
                      key: x.articleID,
                      value: x.articleID,
                      text: x.name,
                    };
                  }),
              ]}
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Field
              name="transportArticleID"
              label="Desired handling and travel article:"
              component={util.RenderSelectWithSearch}
              handleChange={onSelectWithSearchChange}
            >
              {[
                ...articles
                  .filter((article) => {
                    return article.categoryID === 9;
                  })
                  .sort((a, b) =>
                    a.priority < b.priority
                      ? 1
                      : a.priority > b.priority
                      ? -1
                      : a.name.toLowerCase() < b.name.toLowerCase()
                      ? -1
                      : a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : 0
                  )
                  .map((x) => {
                    return {
                      key: x.articleID,
                      value: x.articleID,
                      text: x.name,
                    };
                  }),
              ]}
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable className="ElectricGrid">
        <Grid.Row columns={2}>
          <Grid.Column width={4}>
            <Field
              name="cableDuct"
              title="Roof support cable duct."
              component={util.RenderInputNumber}
              label="Cable duct (m):"
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Field
              name="metersCableUnderground"
              component={util.RenderInputNumber}
              label="Meters cable underground:"
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Field
              name="pavementType"
              component={util.RenderInput}
              label="Pavement type:"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <Field
              name="validEnergyMeter"
              component={util.RenderSelect}
              label="Valid energymeter:"
            >
              <option key={0} value={""}>
                Select...
              </option>
              <option key={1} value={true}>
                Yes
              </option>
              <option key={2} value={false}>
                No
              </option>
            </Field>
          </Grid.Column>
          <Grid.Column>
            <Field
              name="changeEnergyMeter"
              component={util.RenderSelect}
              label="Change energymeter:"
            >
              <option key={0} value={""}>
                Select...
              </option>
              <option key={1} value={true}>
                Yes
              </option>
              <option key={2} value={false}>
                No
              </option>
            </Field>
          </Grid.Column>
          <Grid.Column>
            <Field
              name="electrician"
              component={util.RenderSelect}
              label="Electrician:"
            >
              <option key={0} value={""}>
                Select...
              </option>
              <option key={1} value={"Keetels"}>
                Keetels
              </option>
              <option key={2} value={"A3"}>
                A3
              </option>
              <option key={3} value={"Haverhals"}>
                Haverhals
              </option>
              <option key={4} value={"VTI Voets"}>
                VTI Voets
              </option>
              <option key={5} value={"v.d. Heuvel"}>
                v.d. Heuvel
              </option>
              <option key={6} value={"v.d. Wiel"}>
                v.d. Wiel
              </option>
              <option key={7} value={"klant zelf"}>
                klant zelf
              </option>
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Field
              name="fuseArticleID"
              label="Desired fuse box article:"
              component={util.RenderSelectWithSearch}
              handleChange={onSelectWithSearchChange}
            >
              {[
                ...articles
                  .filter((article) => {
                    return article.categoryID === 6;
                  })
                  .sort((a, b) =>
                    a.priority < b.priority
                      ? 1
                      : a.priority > b.priority
                      ? -1
                      : a.name.toLowerCase() < b.name.toLowerCase()
                      ? -1
                      : a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : 0
                  )
                  .map((x) => {
                    return {
                      key: x.articleID,
                      value: x.articleID,
                      text: x.name,
                    };
                  }),
              ]}
            </Field>
          </Grid.Column>
          <Grid.Column>
            <Field
              name="fuseBoxHandlingArticleID"
              label="Fuse Box Replacement:"
              component={util.RenderSelectWithSearch}
              handleChange={onSelectWithSearchChange}
            >
              {[
                ...[{ key: 0, value: null, text: "Select..." }],
                ...articles
                  .filter((article) => {
                    return article.categoryID === 18;
                  })
                  .sort((a, b) =>
                    a.priority < b.priority
                      ? 1
                      : a.priority > b.priority
                      ? -1
                      : a.name.toLowerCase() < b.name.toLowerCase()
                      ? -1
                      : a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : 0
                  )
                  .map((x) => {
                    return {
                      key: x.articleID,
                      value: x.articleID,
                      text: x.name,
                    };
                  }),
              ]}
            </Field>
          </Grid.Column>
        </Grid.Row>
        {IsBusiness && (
          <Grid.Row columns={3}>
            <Grid.Column>
              <Field
                name="isPowerReturningAllowed"
                component={util.RenderSelect}
                label="Power returning allowed:"
              >
                <option key={0} value={""}>
                  Select...
                </option>
                <option key={1} value={true}>
                  Yes
                </option>
                <option key={2} value={false}>
                  No
                </option>
              </Field>
            </Grid.Column>
            <Grid.Column>
              <Field
                name="isScope12Required"
                component={util.RenderSelect}
                label="Scope 12 inspection required:"
              >
                <option key={0} value={""}>
                  Select...
                </option>
                <option key={1} value={true}>
                  Yes
                </option>
                <option key={2} value={false}>
                  No
                </option>
              </Field>
            </Grid.Column>
            {IsScope12RequiredValue && (
              <Grid.Column>
                <Field
                  name="scope12ArticleID"
                  label="Scope 12 article:"
                  component={util.RenderSelectWithSearch}
                  handleChange={onSelectWithSearchChange}
                >
                  {[
                    ...[{ key: 0, value: null, text: "Select..." }],
                    ...articles
                      .filter((article) => {
                        return article.categoryID === 19;
                      })
                      .sort((a, b) =>
                        a.priority < b.priority
                          ? 1
                          : a.priority > b.priority
                          ? -1
                          : a.name.toLowerCase() < b.name.toLowerCase()
                          ? -1
                          : a.name.toLowerCase() > b.name.toLowerCase()
                          ? 1
                          : 0
                      )
                      .map((x) => {
                        return {
                          key: x.articleID,
                          value: x.articleID,
                          text: x.name,
                        };
                      }),
                  ]}
                </Field>
              </Grid.Column>
            )}
          </Grid.Row>
        )}
        <Grid.Row columns={1}>
          <Grid.Column>
            <Field
              name="locationInverter"
              component={util.RenderInput}
              label="Location inverter:"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Field
              name="stringPlan"
              component={util.RenderInput}
              label="String plan:"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Field
              name="internetArticleID"
              label="Desired internet article:"
              component={util.RenderSelectWithSearch}
              handleChange={onSelectWithSearchChange}
            >
              {[
                ...articles
                  .filter((article) => {
                    return article.categoryID === 8;
                  })
                  .sort((a, b) =>
                    a.priority < b.priority
                      ? 1
                      : a.priority > b.priority
                      ? -1
                      : a.name.toLowerCase() < b.name.toLowerCase()
                      ? -1
                      : a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : 0
                  )
                  .map((x) => {
                    return {
                      key: x.articleID,
                      value: x.articleID,
                      text: x.name,
                    };
                  }),
              ]}
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Field
              name="cablePlan"
              component={util.RenderInputMultiline}
              label="Cable plan - Location router:"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Field
              name="remarks"
              component={util.RenderInputMultiline}
              label="Visit remarks."
              fieldType="multiline"
            />
          </Grid.Column>
        </Grid.Row>
        {errorMessage}
      </Grid>
    </form>
  );
};

VisitBottomForm = reduxForm({
  form: "visitForm",
  validate,
})(VisitBottomForm);

export default VisitBottomForm;
