import React from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../../actions";
import AddressDetail from "./address/AddressDetail";
import AddressCreate from "./address/AddressCreate";
import AddressEdit from "./address/AddressEdit";
import AddressDelete from "./address/AddressDelete";
import AddressNew from "./address/AddressNew";
import * as util from "../../shared/utility";

import { Segment } from "semantic-ui-react";

const DashboardAddress = ({ address, projectID }) => {
  const formMode = useSelector((state) => state.addresses.mode);
  const dispatch = useDispatch();

  if (!address) {
    if (formMode === util.formModes.creating) {
      return (
        <AddressCreate
          projectID={projectID}
          onCancel={() =>
            dispatch(actions.setAddressMode(util.formModes.reading))
          }
        />
      );
    } else {
      return (
        <AddressNew
          onClick={() =>
            dispatch(actions.setAddressMode(util.formModes.creating))
          }
        />
      );
    }
  } else {
    if (address && formMode === util.formModes.reading) {
      return (
        <AddressDetail
          address={address}
          onEdit={() =>
            dispatch(actions.setAddressMode(util.formModes.updating))
          }
          onDelete={() =>
            dispatch(actions.setAddressMode(util.formModes.deleting))
          }
        />
      );
    } else if (address && formMode === util.formModes.updating) {
      return (
        <AddressEdit
          projectID={projectID}
          address={address}
          onCancel={() =>
            dispatch(actions.setAddressMode(util.formModes.reading))
          }
        />
      );
    } else if (address && formMode === util.formModes.deleting) {
      return (
        <AddressDelete
          projectID={projectID}
          onCancel={() =>
            dispatch(actions.setAddressMode(util.formModes.reading))
          }
        />
      );
    } else {
      return <Segment loading />;
    }
  }
};

export default DashboardAddress;
