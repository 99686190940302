import { combineReducers } from "redux";

import addressesReducer from "./addressesReducer";
import articlesReducer from "./articlesReducer";
import categoriesReducer from "./categoriesReducer";
import countriesReducer from "./countriesReducer";
import projectsReducer from "./projectsReducer";
import usersReducer from "./usersReducer";
import visitsReducer from "./visitsReducer";
import personsReducer from "./personsReducer";
import calculationsReducer from "./calculationsReducer";
import calculationRowsReducer from "./calculationRowsReducer";
import mailTemplatesReducer from "./mailTemplatesReducer";
import mailReducer from "./mailReducer";
import filesReducer from "./filesReducer";
import projectFilesReducer from "./projectFilesReducer";
import offersReducer from "./offersReducer";
import ordersReducer from "./ordersReducer";
import invoicesReducer from "./invoicesReducer";
import trellosReducer from "./trellosReducer";
import roofSectionsReducer from "./roofSectionsReducer";
import relationsReducer from "./relationsReducer";
import purchaseOrdersReducer from "./purchaseOrdersReducer";
import purchaseOrderRowsReducer from "./purchaseOrderRowsReducer";

import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import utilityReducer from "./utilityReducer";

export default combineReducers({
  addresses: addressesReducer,
  articles: articlesReducer,
  categories: categoriesReducer,
  calculations: calculationsReducer,
  calculationRows: calculationRowsReducer,
  countries: countriesReducer,
  persons: personsReducer,
  projects: projectsReducer,
  users: usersReducer,
  visits: visitsReducer,
  form: formReducer,
  auth: authReducer,
  utility: utilityReducer,
  mailTemplates: mailTemplatesReducer,
  mail: mailReducer,
  files: filesReducer,
  projectFiles: projectFilesReducer,
  offers: offersReducer,
  orders: ordersReducer,
  invoices: invoicesReducer,
  trellos: trellosReducer,
  roofSections: roofSectionsReducer,
  relations: relationsReducer,
  purchaseOrders: purchaseOrdersReducer,
  purchaseOrderRows: purchaseOrderRowsReducer,
});
