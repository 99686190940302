import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import * as util from "../../../shared/utility";
import * as actions from "../../../actions";

const DashboardInvoices = ({ invoices, projectid }) => {
  if (!invoices || invoices.length === 0) {
    return null;
  }

  const dispatch = useDispatch();

  const invoiceList = invoices
    .sort((a, b) => b.lastUpdatedDateTime.localeCompare(a.lastUpdatedDateTime))
    .map((invoice) => {
      return (
        <tr key={invoice.invoiceID}>
          <td>
            <Link
              className="item"
              to={`/sales/invoices/edit/${invoice.invoiceID}`}
            >
              <div className="item">{invoice.name}</div>
            </Link>
          </td>
          <td className="center aligned">{invoice.invoiceNumber}</td>
          <td className="center aligned mobile hidden">
            {util.utcStringToLocalDate(invoice.sendDateTime)}
          </td>
          <td className="center aligned mobile hidden">
            {util.utcStringToLocalDateTime(invoice.lastUpdatedDateTime)}
          </td>
          <td className="center aligned">
            <i
              style={{ cursor: "pointer" }}
              className="file pdf icon orange"
              title="Download file preview."
              onClick={() =>
                dispatch(actions.previewInvoicePdf(invoice.invoiceID))
              }
            />
          </td>
          <td className="center aligned">
            <i
              style={{ cursor: "pointer" }}
              className="file save icon blue"
              title="Create file in project folder."
              onClick={() =>
                dispatch(
                  actions.generateInvoicePdf(invoice.invoiceID, projectid)
                )
              }
            />
          </td>
          <td className="center aligned">
            <Link
              className="item"
              to={`/sales/invoices/delete/${invoice.invoiceID}`}
              key={invoice.invoiceID}
            >
              <i className="trash icon" title="Delete record." />
            </Link>
          </td>
        </tr>
      );
    });

  return (
    <div className="ui one column grid">
      <div className="row">
        <div className="column">
          <table className="ui celled very compact table unstackable">
            <thead>
              <tr>
                <th className="seven wide">
                  <i className="credit card icon large" />
                  <h4 className="dashboardHeaderTexts">Invoices</h4>
                </th>
                <th className="two wide center aligned">Number</th>
                <th className="two wide center aligned mobile hidden">Send</th>
                <th className="three wide center aligned mobile hidden">
                  Changed
                </th>
                <th className="two wide center aligned" colSpan="3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{invoiceList}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DashboardInvoices;
