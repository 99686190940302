import React from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal';
import history from '../../history';
import * as actions from '../../actions';

class ProjectFileDelete extends React.Component {
  goBack() {
    history.goBack();
  }

  renderActions() {
    return (
      <React.Fragment>
        <button
          onClick={() =>
            this.props.deleteProjectFile(this.props.match.params.projectid, this.props.match.params.filename)
          }
          className="ui button negative"
        >
          Delete
        </button>
        <button className="ui button" onClick={this.goBack}>
          Cancel
        </button>
      </React.Fragment>
    );
  }

  renderContent() {
    return `Are you sure you want to delete the project file with id: ${this.props.match.params.filename}`;
  }

  render() {
    return (
      <Modal
        title="Delete ProjectFile"
        content={this.renderContent()}
        actions={this.renderActions()}
        onDismiss={this.goBack}
      />
    );
  }
}

// const mapStateToProps = (state, ownProps) => {
//   return null;
// };

const mapDispatchToProps = dispatch => {
  return {
    deleteProjectFile: (projectid, filename) => dispatch(actions.deleteProjectFile(projectid, filename))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ProjectFileDelete);
