import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import FileForm from "./FileForm";

import StandardTableHeader from "../UI/StandardHeader";

class FileEdit extends React.Component {
  onSubmit = (fv) => {
    this.props.renameFile(fv);
  };

  render() {
    const fileName = this.props.match.params.filename;
    if (fileName == null || fileName.lastIndexOf(".") === -1) {
      return <div>No valid filename found...</div>;
    }

    var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
    var name = fileName.substr(0, fileName.lastIndexOf("."));

    const initialVal = {
      oldName: fileName,
      newName: fileName,
      ext: ext,
      name: name,
    };

    return (
      <div className="ui one column grid">
        <div className="column">
          <StandardTableHeader headerLabel="Edit File"></StandardTableHeader>
        </div>
        <div className="column">
          <FileForm initialValues={initialVal} onSubmit={this.onSubmit} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    renameFile: (fv) => dispatch(actions.renameFile(fv)),
  };
};

export default connect(null, mapDispatchToProps)(FileEdit);
