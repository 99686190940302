import React from "react";
import { useDispatch } from "react-redux";
import { Grid } from "semantic-ui-react";

import * as actions from "../../actions";
import StandardTableHeader from "../UI/StandardHeader";
import history from "../../history";
import PurchaseOrderForm from "./PurchaseOrderForm";

const PurchaseOrderCreate = () => {
  const dispatch = useDispatch();

  const onSubmit = (fv) => {
    dispatch(actions.createPurchaseOrder(fv));
  };

  const initialVal = {
    orderDate: new Date().toISOString().split("T")[0],
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <StandardTableHeader headerLabel="Create Purchase Order"></StandardTableHeader>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <PurchaseOrderForm initialValues={initialVal} onSubmit={onSubmit} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2} className="stackable">
        <Grid.Column>
          <button
            type="button"
            className="ui button primary"
            onClick={() => dispatch(actions.savePurchaseOrderAndReturn())}
          >
            Save
          </button>
          <button
            type="button"
            className="ui button "
            onClick={() => history.goBack()}
          >
            Cancel
          </button>
        </Grid.Column>
        <Grid.Column>
          <button
            type="button"
            className="ui orange button "
            onClick={() => dispatch(actions.savePurchaseOrderAndStay())}
          >
            Save & Create Rows
          </button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PurchaseOrderCreate;
