import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Segment } from "semantic-ui-react";

import AddressForm from "./AddressForm";
import * as actions from "../../../actions";

const AddressEdit = ({ address, projectID, onCancel }) => {
  if (!address) {
    return null;
  }

  const dispatch = useDispatch();
  const countries = useSelector((state) => state.countries.items);

  useEffect(() => {
    if (countries.length === 0) dispatch(actions.fetchCountries());
  }, []);

  const onSubmit = (formValues) => {
    dispatch(
      actions.editAddressAndUpdateProject(
        address.addressID,
        projectID,
        formValues
      )
    );
  };

  if (countries.length === 0) {
    return <Segment loading />;
  }

  return (
    <Segment>
      <h3>Edit Address</h3>
      <AddressForm
        initialValues={address}
        countries={countries}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Segment>
  );
};

export default AddressEdit;
