import React from "react";
import { Field, reduxForm, change } from "redux-form";
import { Modal, Grid } from "semantic-ui-react";
import { useDispatch } from "react-redux";

import * as util from "../../../shared/utility";
import VisitInfo from "../VisitInfo";
import validate from "./validate";

let VisitTopForm = (props) => {
  const {
    handleSubmit,
    onSubmit,
    articles,
    projectID,
    base64map,
    imageExtension,
    project,
  } = props;

  const dispatch = useDispatch();

  const onSelectWithSearchChange = (e, data) => {
    dispatch(change("visitForm", data.name, data.value));
  };

  const ModalExampleCloseIcon = (url) => (
    <Modal
      trigger={
        <img
          className="ui small image"
          src={url}
          alt="example"
          style={{ cursor: "pointer" }}
        />
      }
      closeIcon
    >
      <Modal.Content>
        <img className="ui image centered" src={url} alt="example" />
      </Modal.Content>
    </Modal>
  );

  const ModalMapsCloseIcon = () => {
    let source = "/images/nomapsfound.jpg";
    if (base64map != null) {
      source = `data:image/${imageExtension};base64,` + base64map;
    }

    var divStyle = {
      backgroundPosition: "center",
      backgroundImage: "url(" + source + ")",
      height: 350,
      width: "100%",
      margin: "auto",
      //backgroundSize: 'cover'
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    };

    return (
      <Modal
        trigger={<div className="ui image rounded" style={divStyle} />}
        closeIcon
      >
        <Modal.Content>
          <img className="ui image centered" src={source} alt="maps" />
        </Modal.Content>
      </Modal>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="ui form error">
      <Grid stackable>
        <Grid.Row columns={2} className="middle aligned">
          <Grid.Column>
            <ModalMapsCloseIcon />
          </Grid.Column>
          <Grid.Column>
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <VisitInfo projectID={projectID} project={project} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Field
                    name="visitorName"
                    component={util.RenderSelect}
                    label="Visitor:"
                  >
                    <option key={0} value={""}>
                      Select...
                    </option>
                    {util.VISITORS.map((x) => (
                      <option key={x.Key} value={x.Name} disabled={x.Disabled}>
                        {x.Name}
                      </option>
                    ))}
                  </Field>
                </Grid.Column>
                <Grid.Column>
                  <Field
                    name="customerType"
                    component={util.RenderSelect}
                    label="Customer type:"
                  >
                    <option key={0} value={0}>
                      Particulier
                    </option>
                    <option key={3} value={3}>
                      Particulier incl. BTW
                    </option>
                    <option key={1} value={1}>
                      Zakelijk t/m 3x80A
                    </option>
                    <option key={2} value={2}>
                      Zakelijk SDE++
                    </option>
                  </Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Field
                    name="visitDateTime"
                    component={util.RenderInputDate}
                    label="Visit date:"
                  ></Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Field
              name="yearlyConsumption"
              component={util.RenderInputNumber}
              label="Yearly consumption (kWh):"
            />
          </Grid.Column>
          <Grid.Column>
            <Field
              name="desiredYield"
              component={util.RenderInputNumber}
              label="Desired yield (kWh):"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Field
              name="isInfoProvidedLaw"
              component={util.RenderInputBoolean}
              label="Salderingswet"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Field
              name="inverterArticleID"
              label="Desired inverter article:"
              component={util.RenderSelectWithSearch}
              handleChange={onSelectWithSearchChange}
            >
              {[
                ...[{ key: 0, value: 0, text: "Select a inverter..." }],
                ...articles
                  .filter((article) => {
                    return article.categoryID === 2;
                  })
                  .sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase()
                      ? -1
                      : a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : 0
                  )
                  .map((x) => {
                    return {
                      key: x.articleID,
                      value: x.articleID,
                      text: x.name,
                    };
                  }),
              ]}
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Grid columns={4} className="middle aligned">
              <Grid.Row>
                <Grid.Column>
                  {ModalExampleCloseIcon("/images/examples/1111.jpg")}
                </Grid.Column>
                <Grid.Column>
                  {ModalExampleCloseIcon("/images/examples/2222.jpg")}
                </Grid.Column>
                <Grid.Column>
                  {ModalExampleCloseIcon("/images/examples/3333.jpg")}
                </Grid.Column>
                <Grid.Column>
                  {ModalExampleCloseIcon("/images/examples/4444.jpg")}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column>
            <Grid columns={4} className="middle aligned">
              <Grid.Row>
                <Grid.Column>
                  {ModalExampleCloseIcon("/images/examples/5555.jpg")}
                </Grid.Column>
                <Grid.Column>
                  {ModalExampleCloseIcon("/images/examples/6666.jpg")}
                </Grid.Column>
                <Grid.Column>
                  {ModalExampleCloseIcon("/images/examples/7777.jpg")}
                </Grid.Column>
                <Grid.Column>
                  {ModalExampleCloseIcon("/images/examples/8888.jpg")}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </form>
  );
};

VisitTopForm = reduxForm({
  form: "visitForm",
  validate,
})(VisitTopForm);

export default VisitTopForm;
