import React from "react";
import { Field, reduxForm } from "redux-form";
import history from "../../history";
import * as util from "../../shared/utility";

class OrderFrom extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  handleCancelClick = () => {
    if (new URLSearchParams(window.location.search).get("new")) {
      history.push(`/sales/projects/dashboard/${this.props.projectID}`);
    } else {
      history.goBack();
    }
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <Field name="name" component={util.RenderInput} label="Name:" />
        <Field
          name="bodyText"
          component={util.RenderInputMultilineHtml}
          label="Body:"
          height={1000}
        />
        <Field
          name="orderDate"
          component={util.RenderInputDate}
          label="Ordered on:"
        />
        <Field
          name="plannedDeliverDate"
          component={util.RenderInputDate}
          label="Planned Deliver on:"
        />
        <Field
          name="deliverDate"
          component={util.RenderInputDate}
          label="Deliver on:"
        />
        <Field
          name="confirmationSource"
          label="Confirmation:"
          component={util.RenderSelect}
        >
          <option key={1} value={1}>
            Mail
          </option>
          <option key={2} value={2}>
            Phone
          </option>
          <option key={3} value={3}>
            Letter
          </option>
          <option key={4} value={4}>
            Other
          </option>
        </Field>
        <button className="ui button primary" disabled={this.props.submitting}>
          Save
        </button>
        <button
          type="button"
          className="ui button"
          onClick={this.handleCancelClick}
        >
          Back
        </button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.name) {
    errors.name = "You must enter a name for the order.";
  }

  if (!formValues.body) {
    errors.body = "You must enter a body for the order.";
  }

  if (!formValues.orderDate) {
    errors.orderDate = "The order date is mandatory.";
  }

  if (formValues.name && formValues.name.length > 200) {
    errors.name = "The name cannot exceed 200 characters.";
  }

  return errors;
};

export default reduxForm({
  form: "orderFrom",
  validate,
})(OrderFrom);
