import React from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal';
import history from '../../history';
import * as actions from '../../actions';

class OfferDelete extends React.Component {
  componentDidMount() {
    this.props.fetchOffer(this.props.match.params.offerid);
  }

  goBack() {
    history.goBack();
  }

  renderActions() {
    const { offerid } = this.props.match.params;

    return (
      <React.Fragment>
        <button onClick={() => this.props.deleteOffer(offerid)} className="ui button negative">
          Delete
        </button>
        <button className="ui button" onClick={this.goBack}>
          Cancel
        </button>
      </React.Fragment>
    );
  }

  renderContent() {
    if (!this.props.offer) {
      return 'Are you sure you want to delete this offer?';
    }

    return `Are you sure you want to delete the offer with id: ${this.props.offer.offerID}`;
  }

  render() {
    return (
      <Modal
        title="Delete Offer"
        content={this.renderContent()}
        actions={this.renderActions()}
        onDismiss={this.goBack}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { offer: state.offers.item };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOffer: id => dispatch(actions.fetchOffer(id)),
    deleteOffer: id => dispatch(actions.deleteOffer(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferDelete);
