import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from "semantic-ui-react";
import { Link } from "react-router-dom";

import * as util from "../../shared/utility";
import * as actions from "../../actions";
import StandardTableHeader from "../UI/StandardHeader";

import "./OrderPagedList.css";
import OrderDeliverDateModal from "./OrderDeliverDateModal";

const OrderPagedListPlanning = () => {
  const dispatch = useDispatch();

  const pageMetadata = useSelector(
    (state) => state.orders.pageMetadataPlanning
  );
  const pageItems = useSelector((state) => state.orders.pageItemsPlanning);
  const pageFilter = useSelector((state) => state.orders.pageFilterPlanning);
  const pageNumber = useSelector((state) => state.orders.pageNumberPlanning);
  const lastOrderDeliverDateUpdate = useSelector(
    (state) => state.orders.lastOrderDeliverDateUpdate
  );
  const showInvoiced = useSelector(
    (state) => state.orders.showInvoicedPlanning
  );
  const showPlanned = useSelector((state) => state.orders.showPlannedPlanning);

  const visitorFilter = useSelector(
    (state) => state.orders.visitorFilterPlanning
  );

  const [searchValue, setSearchValue] = useState(pageFilter);
  const [orderModalValues, setOrderModalValues] = useState(null);

  const resetOrderModalValues = () => {
    setOrderModalValues(null);
  };

  useEffect(() => {
    dispatch(
      actions.fetchOrdersPagedPlanning(
        pageFilter,
        pageNumber,
        showInvoiced,
        visitorFilter,
        showPlanned
      )
    );

    // On a reset, make sure the searchvalue is also resetted.
    if (searchValue !== pageFilter) {
      setSearchValue(pageFilter);
    }
  }, [
    pageFilter,
    pageNumber,
    showInvoiced,
    visitorFilter,
    showPlanned,
    lastOrderDeliverDateUpdate,
  ]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchValue !== pageFilter) {
        dispatch(actions.setOrdersPageFilterPlanning(searchValue));
      }
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchValue]);

  const orderlist = Object.values(pageItems)
    .sort(
      (a, b) => b.orderDate.localeCompare(a.orderDate) || b.orderID - a.orderID
    )
    .map((order) => {
      return (
        <tr key={order.orderID}>
          <td className="noWrapCell">
            <Link className="item" to={`/sales/orders/edit/${order.orderID}`}>
              <div className="item" title={order.name}>
                {order.name}
              </div>
            </Link>
          </td>
          <td className=" mobile hidden">{order.visitorName}</td>
          <td className="center aligned mobile hidden">
            {util.utcStringToLocalDate(order.orderDate)}
          </td>
          <td className="center aligned mobile hidden">
            {util.utcStringToLocalDate(order.deliverDate)}
          </td>
          <td className="center aligned mobile hidden">
            {order.invoiced && <i className="credit card icon green" />}
          </td>
          <td className="center aligned">
            <Link
              className="item"
              to={`/sales/projects/dashboard/${order.projectID}`}
              key={order.projectID}
            >
              <i
                className="tachometer alternate icon"
                title="Open project dashboard."
              />
            </Link>
          </td>
          <td className="center aligned">
            <i
              style={{ cursor: "pointer" }}
              className="calendar alternate icon blue"
              title="Set deliver date."
              onClick={() =>
                setOrderModalValues({
                  ...order,
                })
              }
            />
          </td>
        </tr>
      );
    });

  const renderTable = (
    <>
      <table className="ui celled table unstackable">
        <thead>
          <tr>
            <th>
              <i className="handshake icon large" />
              <h4 className="dashboardHeaderTexts">Orders</h4>
            </th>
            <th className="one wide  mobile hidden">Visitor</th>
            <th className="two wide center aligned  mobile hidden">
              Ordered
              <i className="caret down icon" />
            </th>
            <th className="two wide center aligned mobile hidden">Deliver</th>
            <th className="one wide center aligned mobile hidden">Invoiced</th>
            <th className="two wide center aligned" colSpan="2">
              Action
            </th>
          </tr>
        </thead>
        <tbody>{pageMetadata.totalCount > 0 && orderlist}</tbody>
      </table>
      {pageMetadata.totalCount > 0 && (
        <Pagination
          activePage={pageMetadata.currentPage}
          totalPages={pageMetadata.totalPages}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          onPageChange={(e, props) =>
            dispatch(actions.setOrdersPageNumberPlanning(props.activePage))
          }
        />
      )}
    </>
  );

  const OrderFilter = () => {
    return (
      <div className="pageFilterRow">
        <div>
          <div
            className="ui toggle checkbox"
            onClick={() => dispatch(actions.toggleShowInvoicedPlanning())}
            style={{ cursor: "pointer" }}
          >
            <input
              type="checkbox"
              name="public"
              defaultChecked={showInvoiced}
            />
            <label>Show Invoiced</label>
          </div>
          <div
            className="ui toggle checkbox"
            onClick={() => dispatch(actions.toggleShowPlannedPlanning())}
            style={{ cursor: "pointer" }}
          >
            <input type="checkbox" name="public" defaultChecked={showPlanned} />
            <label>Show Planned</label>
          </div>
        </div>
        <div>
          <select
            className="ui selection dropdown"
            name="visitorName"
            label="Visitor:"
            onChange={(e) =>
              dispatch(actions.setOrdersVisitorFilterPlanning(e.target.value))
            }
            value={visitorFilter}
          >
            <option key={0} value={""}>
              Select visitor...
            </option>
            {util.VISITORS.map((x) => (
              <option key={x.Key} value={x.Name}>
                {x.Name}
              </option>
            ))}
            <option key={100} value={"NoVisitor"}>
              No visitor
            </option>
          </select>
          <button
            id="pageFilterButton"
            className="ui button labeled icon "
            onClick={() => dispatch(actions.resetOrdersFilterPlanning())}
            title="Reset filters."
            style={{
              backgroundColor: "var(--company-bg-color-50)",
              marginLeft: "10px",
            }}
          >
            <i className="undo icon" />
            Reset Filters
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="ui one column grid">
      <div className="column">
        <div className="row">
          <StandardTableHeader
            headerLabel="Order Planning"
            recordCount={pageMetadata.totalCount}
            searchValue={searchValue}
            searchTooltip={`
You can search on the name 
of the order.`}
            searchFunction={(e) => setSearchValue(e.target.value)}
          ></StandardTableHeader>
        </div>
        <div className="row">
          <OrderFilter />
        </div>
      </div>
      <div className="column">{renderTable}</div>
      {orderModalValues && (
        <OrderDeliverDateModal
          order={orderModalValues}
          resetOrderModalValues={resetOrderModalValues}
        />
      )}
    </div>
  );
};

export default OrderPagedListPlanning;
