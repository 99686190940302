import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as actions from "../../actions";
import DashboardCalculations from "./ProjectDashboard/DashboardCalculations";
import DashboardFiles from "./ProjectDashboard/DashboardFiles";
import DashboardOffers from "./ProjectDashboard/DashboardOffers";
import DashboardOrders from "./ProjectDashboard/DashboardOrders";
import DashboardInvoices from "./ProjectDashboard/DashboardInvoices";
import ProjectHeader from "../projectHeader/ProjectHeader";

import "./Dashboard.css";

const Dashboard = ({
  match: {
    params: { projectid },
  },
}) => {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.projects.projectDashboard);
  const files = useSelector((state) => state.projectFiles.items);

  useEffect(() => {
    dispatch(actions.fetchProjectDashboard(projectid));
    dispatch(actions.fetchProjectFiles(projectid));

    return () => {
      dispatch(actions.resetTrelloMessage());
      dispatch(actions.setProjectFilesErrorMessages([]));
    };
  }, []);

  if (!project) {
    return null;
  }

  return (
    <div className="ui one column grid">
      <div className="row">
        <div className="column">
          <ProjectHeader project={project} />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <DashboardFiles files={files} projectid={projectid} />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <DashboardCalculations
            calculations={project.calculations}
            projectid={projectid}
          />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <DashboardOffers offers={project.offers} projectid={projectid} />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <DashboardOrders orders={project.orders} projectid={projectid} />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <DashboardInvoices
            invoices={project.invoices}
            projectid={projectid}
          />
        </div>
      </div>
      {/* <pre>{JSON.stringify(project, undefined, 4)}</pre> */}
    </div>
  );
};

export default Dashboard;
