import React from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal';
import history from '../../history';
import * as actions from '../../actions';

class OrderDelete extends React.Component {
  componentDidMount() {
    this.props.fetchOrder(this.props.match.params.orderid);
  }

  componentWillUnmount() {
    this.props.unloadOrder();
  }

  goBack() {
    history.goBack();
  }

  renderActions() {
    const { orderid } = this.props.match.params;

    return (
      <React.Fragment>
        <button onClick={() => this.props.deleteOrder(orderid)} className="ui button negative">
          Delete
        </button>
        <button className="ui button" onClick={this.goBack}>
          Cancel
        </button>
      </React.Fragment>
    );
  }

  renderContent() {
    if (!this.props.order) {
      return 'Are you sure you want to delete this order?';
    }

    return `Are you sure you want to delete the order with id: ${this.props.order.orderID}`;
  }

  render() {
    return (
      <Modal
        title="Delete Order"
        content={this.renderContent()}
        actions={this.renderActions()}
        onDismiss={this.goBack}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { order: state.orders.item };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrder: id => dispatch(actions.fetchOrder(id)),
    unloadOrder: () => dispatch(actions.unloadOrder()),
    deleteOrder: id => dispatch(actions.deleteOrder(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDelete);
