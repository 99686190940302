import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as actions from "../../actions";
import * as util from "../../shared/utility";
import StandardTableHeader from "../UI/StandardHeader";
import PurchaseOrderRowCreateOnOverview from "./PurchaseOrderRowCreateOnOverview";
import PurchaseOrderRowEditOnOverview from "./PurchaseOrderRowEditOnOverview";

import { Button, Modal } from "semantic-ui-react";

const PurchaseOrderRowOverview = ({ purchaseOrderID, articles }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (purchaseOrderID) {
      dispatch(actions.fetchPurchaseOrderRowsForPurchaseOrder(purchaseOrderID));
    }

    return () => {
      dispatch(actions.removePurchaseOrderRowsForPurchaseOrder());
      // dispatch(actions.setCalculationError(""));
    };
  }, []);

  const items = useSelector((state) =>
    Object.values(state.purchaseOrderRows.items)
  );

  const [deletePurchaseOrderRowActive, setDeletePurchaseOrderRowActive] =
    useState(null);
  const [editPurchaseOrderRowActive, setEditPurchaseOrderRowActive] =
    useState(null);

  const handleDeleteCancelClick = () => {
    setDeletePurchaseOrderRowActive(null);
  };

  const handleDeleteSubmitClick = () => {
    dispatch(
      actions.deletePurchaseOrderRowForPurchaseOrder(
        deletePurchaseOrderRowActive
      )
    );
    setDeletePurchaseOrderRowActive(null);
  };

  const handleEditPurchaseOrderRowActiveClick = (id) => {
    if (editPurchaseOrderRowActive === id) {
      setEditPurchaseOrderRowActive(null);
    } else {
      setEditPurchaseOrderRowActive(id);
    }
  };

  const purchaseOrderRowList = items
    .sort((a, b) => a.purchaseOrderRowID - b.purchaseOrderRowID)
    .map((purchaseOrderRow) => {
      if (editPurchaseOrderRowActive === purchaseOrderRow.purchaseOrderRowID) {
        return (
          <tr key={purchaseOrderRow.purchaseOrderRowID}>
            <td colSpan={9} className="forcusAreaOnForms">
              <PurchaseOrderRowEditOnOverview
                articles={articles}
                item={purchaseOrderRow}
                handleCancelClick={() => setEditPurchaseOrderRowActive(null)}
              />
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={purchaseOrderRow.purchaseOrderRowID}>
            {/* <td className="mobile hidden">
              {purchaseOrderRow.purchaseOrderRowID}
            </td> */}
            <td>
              <div
                className="item"
                style={{ cursor: "pointer", color: "#4183c4" }}
                onClick={() =>
                  handleEditPurchaseOrderRowActiveClick(
                    purchaseOrderRow.purchaseOrderRowID
                  )
                }
              >
                {purchaseOrderRow.articleReference.name}
              </div>
            </td>
            <td className="right aligned">{purchaseOrderRow.quantity}</td>
            <td className="mobile hidden right aligned">
              {util.formatDecimal(purchaseOrderRow.price)}
            </td>
            <td className="mobile hidden right aligned">
              {util.formatDecimal(purchaseOrderRow.articleReference.price)}
            </td>
            <td>
              <i
                style={{ cursor: "pointer" }}
                className="copy icon blue"
                onClick={() =>
                  dispatch(
                    actions.copyPurchaseOrderRowForPurchaseOrder(
                      purchaseOrderRow.purchaseOrderRowID
                    )
                  )
                }
                title="Copy record."
              />
            </td>
            <td className="center aligned">
              <i
                style={{ cursor: "pointer" }}
                className="trash icon blue"
                title="Delete record."
                onClick={() =>
                  setDeletePurchaseOrderRowActive(
                    purchaseOrderRow.purchaseOrderRowID
                  )
                }
              />
            </td>
          </tr>
        );
      }
    });

  const renderTable = (
    <table className="ui celled table unstackable">
      <thead>
        <tr>
          {/* <th className="one wide mobile hidden">ID</th> */}
          <th className="eight wide ">Article</th>
          <th className="two wide right aligned">Quantity</th>
          <th className="two wide mobile hidden right aligned">Price (€)</th>
          <th className="two wide mobile hidden right aligned">
            Current Price (€)
          </th>
          <th className="one wide center aligned" colSpan={2}>
            Action
          </th>
        </tr>
      </thead>
      <tbody>{purchaseOrderRowList}</tbody>
      <tfoot>
        <tr>
          <th className="right aligned" colSpan={2}>
            <b>Total</b>
          </th>
          <th className="right aligned">
            {util.formatDecimal(
              items.reduce(
                (partialSum, a) => a.quantity * a.price + partialSum,
                0
              )
            )}
          </th>
          <th colSpan={3}></th>
        </tr>
      </tfoot>
    </table>
  );

  const DeletePurchaseOrderRow = () => {
    if (!deletePurchaseOrderRowActive) {
      return null;
    }

    const item = items.find(
      (e) => e.purchaseOrderRowID === deletePurchaseOrderRowActive
    );

    return (
      <Modal
        onClose={handleDeleteCancelClick}
        open={!!deletePurchaseOrderRowActive}
      >
        <Modal.Header>{`Delete purchase order row ${item.purchaseOrderRowID}`}</Modal.Header>
        <Modal.Content>
          <p>
            {`Are you sure you want to delete the purchase order row with ${item.quantity} articles.`}
          </p>
          <p>{`Type: ${item.articleReference.name}`}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Delete"
            labelPosition="right"
            icon="trash"
            onClick={handleDeleteSubmitClick}
            negative
          ></Button>
          <Button onClick={handleDeleteCancelClick}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  };

  return (
    <div className="ui one column grid">
      <div className="column">
        <StandardTableHeader
          headerLabel="purchase order row sections"
          recordCount={items.length}
        ></StandardTableHeader>
      </div>
      <div className="column">{renderTable}</div>
      <PurchaseOrderRowCreateOnOverview
        purchaseOrderID={purchaseOrderID}
        articles={articles}
      />
      <DeletePurchaseOrderRow />
    </div>
  );
};

export default PurchaseOrderRowOverview;
