import React from "react";

export const AxisBottom = ({
  xScale,
  innerHeight,
  tickFormat,
  tickOffset = 3,
}) =>
  xScale.ticks().map((tickValue) => (
    <g
      className="tick"
      key={tickValue}
      transform={`translate(${xScale(tickValue)},0)`}
    >
      <line stroke="lightgray" y2={innerHeight} />
      <text
        style={{ textAnchor: "middle" }}
        y={innerHeight + tickOffset}
        // dy=".71em"
        transform={`rotate(-90), translate(${
          -innerHeight - 45
        }, ${-innerHeight})`}
      >
        {tickFormat(tickValue)}
      </text>
    </g>
  ));
