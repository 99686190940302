import solartoolapi from "../apis/solartoolapi";
import {
  FETCH_PERSON,
  CREATE_PERSON,
  EDIT_PERSON,
  DELETE_PERSON,
  UNLOAD_PERSON_COMPONENT,
  SET_PERSON_MODE,
} from "./types";

import * as actions from "../actions";
import * as util from "../shared/utility";

export const fetchPerson = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/persons/${id}`, {
    headers: util.authHeader(),
  });

  dispatch({ type: FETCH_PERSON, payload: response.data.result });
};

const createPerson = (projectID, formValues) => async (dispatch, getState) => {
  const response = await solartoolapi.post(
    `/persons/${projectID}`,
    {
      ...formValues,
    },
    { headers: util.authHeader() }
  );

  dispatch({ type: CREATE_PERSON, payload: response.data.result });
};

export const createPersonAndUpdateProject =
  (projectID, formValues) => async (dispatch) => {
    await dispatch(createPerson(projectID, formValues));
    await dispatch(actions.fetchProjectDashboard(projectID));
    await dispatch(setPersonMode(util.formModes.reading));
  };

export const editPerson = (id, formValues) => async (dispatch) => {
  const response = await solartoolapi.put(`/persons/${id}`, formValues, {
    headers: util.authHeader(),
  });

  dispatch({ type: EDIT_PERSON, payload: response.data.result });
};

export const editPersonAndUpdateProject =
  (personID, projectID, formValues) => async (dispatch) => {
    await dispatch(editPerson(personID, formValues));
    await dispatch(actions.fetchProjectDashboard(projectID));
    await dispatch(setPersonMode(util.formModes.reading));
  };

const deletePerson = (id) => async (dispatch) => {
  await solartoolapi.delete(`/persons/${id}`, { headers: util.authHeader() });

  dispatch({ type: DELETE_PERSON, payload: id });
};

export const deletePersonAndUpdateProject =
  (personID, projectID) => async (dispatch) => {
    await dispatch(deletePerson(personID));
    await dispatch(actions.fetchProjectDashboard(projectID));
    await dispatch(setPersonMode(util.formModes.reading));
  };

export const unloadPersonComponent = () => (dispatch) => {
  dispatch({ type: UNLOAD_PERSON_COMPONENT });
};

export const setPersonMode = (mode) => (dispatch) => {
  dispatch({ type: SET_PERSON_MODE, payload: mode });
};
