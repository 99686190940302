import React from 'react';
import { Segment } from 'semantic-ui-react';

const AddressNew = props => {
  return (
    <Segment textAlign="center">
      <button type="button" className="ui button primary" onClick={() => props.onClick()}>
        Create Address
      </button>
    </Segment>
  );
};

export default AddressNew;
