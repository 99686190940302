import solartoolapi from "../apis/solartoolapi";
import {
  FETCH_USER,
  FETCH_USERS,
  CREATE_USER,
  EDIT_USER,
  DELETE_USER,
} from "./types";
import { authHeader } from "../shared/utility";
import history from "../history";

export const fetchUser = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/users/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_USER, payload: response.data.result });
};

export const fetchUsers = () => async (dispatch) => {
  const response = await solartoolapi.get("/users", { headers: authHeader() });

  dispatch({ type: FETCH_USERS, payload: response.data.result });
};

export const createUser = (formValues) => async (dispatch, getState) => {
  const response = await solartoolapi.post(
    "/users",
    {
      ...formValues,
    },
    { headers: authHeader() }
  );

  dispatch({ type: CREATE_USER, payload: response.data.result });
  history.push("/application/users");
};

export const editUser = (id, formValues) => async (dispatch) => {
  const response = await solartoolapi.put(`/users/${id}`, formValues, {
    headers: authHeader(),
  });
  dispatch({ type: EDIT_USER, payload: response.data.result });
  history.goBack();
};

export const disableUser = (id) => async (dispatch) => {
  await solartoolapi.delete(`/users/disable/${id}`, { headers: authHeader() });

  dispatch({ type: DELETE_USER, payload: id });
  history.push("/application/users");
};
