//import _ from 'lodash';
import React from "react";
import { connect } from "react-redux";

import * as actions from "../../actions";

import CalculationForm from "./CalculationForm";
import StandardTableHeader from "../UI/StandardHeader";

class CalculationEdit extends React.Component {
  componentDidMount() {
    this.props.fetchCalculation(this.props.match.params.calculationid);
  }

  onSubmit = (formValues) => {
    this.props.editCalculation(
      this.props.match.params.calculationid,
      formValues
    );
  };

  componentWillUnmount() {
    this.props.unloadCalculation();
  }

  render() {
    if (!this.props.calculation) {
      return <div>Loading...</div>;
    }

    return (
      <div className="ui one column grid">
        <div className="column">
          <StandardTableHeader headerLabel="Edit Calculation"></StandardTableHeader>
        </div>
        <div className="column">
          <CalculationForm
            initialValues={this.props.calculation}
            articles={this.props.articles}
            onSubmit={this.onSubmit}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    calculation: state.calculations.item,
    articles: Object.values(state.articles.items),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCalculation: (id) => dispatch(actions.fetchCalculation(id)),
    editCalculation: (calculationID, projectID, formValues) =>
      dispatch(actions.editCalculation(calculationID, projectID, formValues)),
    fetchArticles: () => dispatch(actions.fetchArticles()),
    unloadCalculation: () => dispatch(actions.unloadCalculation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculationEdit);
