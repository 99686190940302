import solartoolapi from "../apis/solartoolapi";
import history from "../history";
import {
  CREATE_PROJECT_FILE,
  FETCH_PROJECT_FILES,
  DELETE_PROJECT_FILE,
  DOWNLOAD_PROJECT_FILE,
  RENAME_PROJECT_FILE,
  SET_PROJECT_FILES_ERROR_MESSAGES,
} from "./types";
import { authHeader } from "../shared/utility";
import fileDownload from "js-file-download";

export const createProjectFile =
  (projectid, file) => async (dispatch, getState) => {
    const fd = new FormData();
    fd.append("file", file, file.name);
    const response = await solartoolapi.post(
      `/files/project/${projectid}`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
          ...authHeader(),
        },
      }
    );

    dispatch({ type: CREATE_PROJECT_FILE, payload: response.data.result });
    dispatch(fetchProjectFiles(projectid));
  };

export const fetchProjectFiles = (projectid) => async (dispatch) => {
  const response = await solartoolapi.get(`/files/project/${projectid}`, {
    headers: authHeader(),
  });
  dispatch({ type: FETCH_PROJECT_FILES, payload: response.data.result });
};

export const downloadProjectFile = (projectid, id) => async (dispatch) => {
  const response = await solartoolapi.get(`/files/project/${projectid}/${id}`, {
    headers: authHeader(),
    responseType: "blob",
  });

  fileDownload(response.data, id);
  dispatch({ type: DOWNLOAD_PROJECT_FILE, payload: id });
};

export const renameProjectFile =
  (projectid, formValues) => async (dispatch) => {
    const { data } = await solartoolapi.put(
      `/files/project/${projectid}`,
      formValues,
      {
        headers: authHeader(),
      }
    );

    dispatch({ type: RENAME_PROJECT_FILE, payload: data.result });
    history.goBack();
  };

export const deleteProjectFile = (projectid, filename) => async (dispatch) => {
  await solartoolapi.delete(`/files/project/${projectid}/${filename}`, {
    headers: authHeader(),
  });

  dispatch({ type: DELETE_PROJECT_FILE, payload: filename });
  history.go(-1);
};

export const setProjectFilesErrorMessages =
  (errorMessages) => async (dispatch) => {
    dispatch({
      type: SET_PROJECT_FILES_ERROR_MESSAGES,
      payload: errorMessages,
    });
  };
