import React from "react";
import StandardTableHeader from "../UI/StandardHeader";

const ChangeLog = () => {
  const changeLogItems = [
    {
      date: "12-07-2024",
      changes: [
        {
          message:
            'Added property "Cable Duct" to Visit, this will create a calculation row for cable duct.',
        },
        {
          message:
            'Added property "Is Power Returning Allowed" to Visit. This property will only be visible for business customers and will create a card in trello.',
        },
        {
          message:
            'Added property "Scope 12 Required" to Visit. This property will only be visible for business customers and will create a card in trello.',
        },
        {
          message:
            "If Scope 12 is required you can select a Scope 12 article (also created a category for this) which will be added to the calculation if.",
        },
        {
          message:
            "Changed offer text to show CO2 reduction and kWh price next 25 years.",
        },
      ],
    },
    {
      date: "28-02-2024",
      changes: [
        {
          message: "Added css width for logo.",
        },
        {
          message: "Changed text on offer.",
        },
      ],
    },
    {
      date: "23-02-2024",
      changes: [
        {
          message:
            "Changed time processing of files, lastwritetime will now always be shown correctly.",
        },
        {
          message: "Added email to trello output f available.",
        },
        {
          message: "Changed text on offer.",
        },
        {
          message: "Removed old unused code from frontend.",
        },
      ],
    },
    {
      date: "27-02-2023",
      changes: [
        {
          message:
            "Added properties to arcticles (InventoryCategory,OrderQuantity,DeliverTimeInDays,Remarks).",
        },
        {
          message:
            "Added InventoryList (new pages, filters, lists, new api endpoints, new db queries).",
        },
        {
          message:
            "Added InventoryItem (new pages, filters, lists, new api endpoints, new db queries).",
        },
        {
          message:
            "Added planned deliver date to orders and added input on create.",
        },
        {
          message: "Changed dates on order to be dates and not datetimes.",
        },
        {
          message:
            "Added purchase orders (new pages, filters, CRUD and api endpoints).",
        },
        {
          message:
            "Added relations (new pages, filters, CRUD and api endpoints), 2 variants; customers for in the future and suppliers for the purchase orders (new pages and api endpoints).",
        },
        {
          message:
            "Renamed all to De Laat Solar (Offer, Order, Invoice, Signature, Email, etc.).",
        },
        {
          message: "Article reference is now mandotry for calculation rows.",
        },
        {
          message:
            "Changed the menu (and routes), so all can fit in the menubar.",
        },
        {
          message: "Added inventory graph (added 3d library).",
        },
        {
          message:
            "Removed unused code and changed some class components to functional component.",
        },
        {
          message: "Removed preflight requests (performance improvement).",
        },
        {
          message: "Fixed bug when clicking edit on project list.",
          bug: true,
        },
        {
          message: "Fixed bug when clicking cancel on project create.",
          bug: true,
        },
      ],
    },
    {
      date: "12-01-2023",
      changes: [
        {
          message: "Removed btw check on Visit.",
        },
        {
          message: "Removed btw for private customertype (particulieren).",
        },
        {
          message: "Removed forfait.",
        },
        {
          message: "Added extra customertype (particulieren incl. BTW).",
        },
      ],
    },
    {
      date: "08-11-2022",
      changes: [
        {
          message: "Removed mobile number from email footer buttons.",
        },
      ],
    },
    {
      date: "07-11-2022",
      changes: [
        {
          message:
            "Changed all string fields in database to have a max number of characters.",
        },
        {
          message:
            "Added validation to frontend for all string fields and numbers.",
        },
        {
          message: "Added global error handling for modal validation.",
        },
        {
          message: "Fixed wrong name on person; surname changed to firstname.",
        },
        {
          message: "Removed efficiency as editable field on calculationrow.",
        },
        {
          message: "Changed a lot of fields to be required.",
        },
        {
          message:
            "Changed customertype to be an enum and added a service layer for it.",
        },
        {
          message:
            "Changed address of Solar Systems on pdf's and mail footers.",
        },
      ],
    },
    {
      date: "28-10-2022",
      changes: [
        {
          message: "Changed marge information table in frontend.",
        },
        {
          message: "Changed standard offer template.",
        },
        {
          message: "Added offer templates fro SDE and 80A",
        },
        {
          message: "Savings on offer is now devided in a min and max value.",
        },
        {
          message:
            "Changed KWh prices and also added different prices for business.",
        },
      ],
    },
    {
      date: "31-03-2022",
      changes: [
        {
          message: "Added order materials page.",
        },
        {
          message: "Added order planning page.",
        },
        {
          message: "Added Marge information on calculation page.",
        },
        {
          message:
            "Added line to trello Card(opdracht): Alles in orde -> factureren.",
        },
        {
          message: "Default action on files is download instead of edit name.",
        },
        {
          message:
            "Added visitor on project list and made a seperate filter for it.",
        },
        {
          message:
            "Added dropdown for Electrician on Visit, this will show up in Trello cards Meterkast and Vervanging Meterkast.",
        },
        {
          message:
            'Files with "vervangingMK" in the name will be added to the trello card Vervanging Meterkast.',
        },
        {
          message: "Added 3 replacetags in mailtemplates.",
        },
        {
          message:
            "Productdescriptions on the end of the offer are only printed once.",
        },
        {
          message:
            "For the BCC email address the email of the logged on user is used.",
        },
        {
          message:
            "Added specific logging to GenerateOffer function for debugging.",
          bug: true,
        },
        {
          message:
            "Rewrote GenerateOffer data getting linq statements and function does not use async functionality anymore.",
          bug: true,
        },
        {
          message:
            "Fixed bug when adding address and person information and not showing on initial opening Visit.",
          bug: true,
        },
        {
          message: "Fixed bug when a mailtemplate had no subject.",
          bug: true,
        },
      ],
    },
    {
      date: "24-12-2021",
      changes: [
        { message: "Created new endpoint for project dashboard." },
        {
          message:
            "Created a dashboard for an overview of the project files, caculations, offers, orders and invoices.",
        },
        {
          message:
            "Created a project header for better navigation and more insights.",
        },
        {
          message: "Changed a lot of screens so they are more mobile friendly.",
        },
        {
          message:
            "Added button for trello on dashboard to create trello cards from there.",
        },
        { message: "Improved navigation." },
        {
          message:
            "The projectlist is now returned from the API paged, with better information and requires far less bandwith.",
        },
        {
          message: "The projectlist now shows project progress more visual.",
        },
        {
          message:
            "Added customer type selection on Visit (default: Particulier).",
        },
        {
          message:
            "Added calculation row for groundword if metersunderground > 0, changed type of variable to integer and improved trello card.",
        },
        {
          message:
            "Improved overflow with overlay-css instead of min-height 102 %.",
        },
        { message: "Changed kWh price in template and calculation." },
        { message: "Changed offerTemplate text (prijs 1 maand)." },
        {
          message:
            "Removed extra card for metersunderground. It is a special article and will be show because of that.",
        },
        { message: "Fixed search on filelist page.", bug: true },
      ],
    },
    {
      date: "16-7-2021",
      changes: [
        { message: "Implemented roofsection CRUD on visit." },
        {
          message:
            "A lot of changes regarding not leaving VisitForm while creating, editing and deleting roofsections.",
        },
        {
          message: "Removed roofsection from navigation and removed old crud.",
        },
        { message: "Added copy function for roofsections" },
        { message: "Added CompanyName to Offer, Order and Invoice." },
        { message: "RoofType BOTH only usable from certain projectID." },
        {
          message:
            "Made pages mobile friendly: (CalculationList, FileList, ProjectFileLst, InvoiceList, FileList on mailsendform, Offerlist, OrderList, Userlist)",
        },
        {
          message:
            "ErrorMessages on files with % sign on upload and edit; on projectfiles and common files.",
          bug: true,
        },
      ],
    },
    {
      date: "9-7-2021",
      changes: [
        {
          message:
            "Articles grouped on description on order, offer, trello (2x).",
        },
        { message: "Combined obstacle text everywhere. (trello)" },
        {
          message:
            "Combined roofdirection text everywhere. (offer, trello, mail)",
        },
        { message: "Added Efficiency on calculationrowform." },
        { message: "Added Stringplan to visit." },
        {
          message:
            "Removed stringplan from order (backend, frontend, database).",
        },
        {
          message:
            "Removed panellayout everywhere (backend, frontend, database).",
        },
      ],
    },
    {
      date: "2-7-2021",
      changes: [
        { message: "Removed unused fields from visit in database." },
        { message: "Added efficiency on calculationrow." },
        { message: "Fixed efficiency calculations." },
        { message: "Changed efficiency calculations marge to 3%." },
        {
          message:
            "Added errormessage from backend to show in frontend on generating calculation.",
        },
        {
          message:
            "Removed enter on offertemplate and changed roofdirection text.",
        },
        {
          message:
            "Fixed calculationRow count for inverterArticle etc when generating calculation.",
          bug: true,
        },
      ],
    },
    {
      date: "1-7-2021",
      changes: [
        { message: "Added roofsections to project." },
        { message: "Removed fields and logic from visit." },
        { message: "Added CRUD functionality for roofsections." },
        { message: "Generating calculations of roofsections." },
        { message: "Added navigation on project to roofsections." },
        { message: "Copied data of visits to roofsections." },
        {
          message: "Fixed tooltip delete on userlist.",
          bug: true,
        },
        {
          message: "Added cursor pointer on mouseover visit panel images.",
          bug: true,
        },
      ],
    },
    {
      date: "4-6-2021",
      changes: [
        {
          message:
            "Fixed logout menu path, was the same and highlighted when on system menu navigaton.",
          bug: true,
        },
        {
          message: "Removed double loading project on visit and breadcrumb.",
        },
      ],
    },
    {
      date: "3-6-2021",
      changes: [
        {
          message:
            "Fixed filter on mailtemplate. It did not work without subjects.",
          bug: true,
        },
        { message: "Added user create, edit and delete." },
        { message: "Added user reset email button in userlist." },
        { message: "Added last logon datetime on user." },
        { message: "Only show non system users." },
      ],
    },
    {
      date: "2-6-2021",
      changes: [
        { message: "Improved mobile menu." },
        { message: "Added navigation header on all pages." },
        { message: "Removed table headers where navigation header was added." },
      ],
    },
    {
      date: "17-5-2021",
      changes: [
        { message: "Added multi file upload support." },
        { message: "Started on style guide." },
        { message: "Added Change Log page." },
        {
          message:
            "Fixed retrieving projectfiles on Trello page before ProjectID was known.",
          bug: true,
        },
      ],
    },
    {
      date: "13-5-2021",
      changes: [
        { message: "Added dropdown menu to navigation." },
        { message: "Added multiple pages." },
        { message: "Reorganized menu." },
      ],
    },
    {
      date: "1-5-2021",
      changes: [
        {
          message:
            "Update notification for frontend when used with outdated version.",
        },
      ],
    },
  ];

  return (
    <div className="ui one column grid">
      <div className="column">
        <StandardTableHeader headerLabel="Change Log" />
      </div>
      <div className="column">
        {changeLogItems.map((x, i) => {
          const supitem = x.changes.map((y, j) => {
            return (
              <div key={j} class="ui row">
                <div class="two wide column">{y.bug ? "BUG:" : "FEATURE:"}</div>
                <div class="fourteen wide column">{y.message}</div>
              </div>
            );
          });

          return (
            <div
              key={i}
              className="ui centered card"
              style={{ width: "800px" }}
            >
              <div
                className="content"
                style={{ backgroundColor: "var(--company-bg-color-50)" }}
              >
                <div className="header">{x.date}</div>
              </div>
              <div className="content">
                <div className="description">
                  <div class="ui internally celled grid">{supitem}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChangeLog;
