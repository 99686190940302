import React from "react";

export const Legend = ({ setPeriod, period, margin, strokeWidth }) => {
  const offset = 150;
  return (
    <g
      className="chart-legend"
      transform={`translate(${margin.left + 150},20)`}
    >
      <g>
        <line
          y1={-5}
          x1={-10}
          y2={-5}
          x2={-50}
          stroke="orange"
          strokeWidth={strokeWidth}
        ></line>
        <text>Technical</text>
      </g>
      <g transform={`translate(${offset * 1},0)`}>
        <line
          y1={-5}
          x1={-10}
          y2={-5}
          x2={-50}
          stroke="steelblue"
          strokeWidth={strokeWidth}
        ></line>
        <text>Economic</text>
      </g>
      <g transform={`translate(${offset * 2},0)`}>
        <line
          y1={-5}
          x1={-10}
          y2={-5}
          x2={-50}
          stroke="red"
          strokeWidth={strokeWidth}
          strokeDasharray={10}
        ></line>
        <text className="legend-label">ROP</text>
      </g>
      <g transform={`translate(${offset * 3 - 40},0)`}>
        <line
          y1={-5}
          x1={-10}
          y2={-5}
          x2={-50}
          stroke="black"
          strokeWidth={strokeWidth}
          strokeDasharray={10}
        ></line>
        <text>Today</text>
      </g>
      <g
        transform={`translate(${offset * 4 - 80},-20)`}
        className="period-buttons"
      >
        <g onMouseDown={() => setPeriod(2)} transform={`translate(0,0)`}>
          <rect
            width={60}
            height={30}
            className={`${period === 2 ? "active" : ""}`}
          ></rect>
          <text x={15} y={20}>
            2M
          </text>
        </g>
        <g onMouseDown={() => setPeriod(6)} transform={`translate(80,0)`}>
          <rect
            width={60}
            height={30}
            className={`${period === 6 ? "active" : ""}`}
          ></rect>
          <text x={15} y={20}>
            6M
          </text>
        </g>
        <g onMouseDown={() => setPeriod(null)} transform={`translate(160,0)`}>
          <rect
            width={60}
            height={30}
            className={`${period === null ? "active" : ""}`}
          ></rect>
          <text x={15} y={20}>
            ALL
          </text>
        </g>
      </g>
    </g>
  );
};
