import React from "react";
import { Field, reduxForm } from "redux-form";
import history from "../../history";
import * as util from "../../shared/utility";

class MailTemplateForm extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  handleCancelClick = () => {
    history.goBack();
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <Field name="name" component={util.RenderInput} label="Name:" />
        {util.usableTags()}
        <Field name="subject" component={util.RenderInput} label="Subject:" />
        <Field
          name="body"
          component={util.RenderInputMultilineHtml}
          label="Body:"
        />
        <button className="ui button primary" disabled={this.props.submitting}>
          Save
        </button>
        <button
          type="button"
          className="ui button"
          onClick={this.handleCancelClick}
        >
          Cancel
        </button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.name) {
    errors.name = "You must enter a name for the mail template.";
  }

  if (!formValues.body) {
    errors.body = "You must enter a body for the mail template.";
  }

  if (formValues.name && formValues.name.length > 200) {
    errors.name = "The name cannot exceed 200 characters.";
  }

  if (formValues.subject && formValues.subject.length > 200) {
    errors.subject = "The subject cannot exceed 200 characters.";
  }

  return errors;
};

export default reduxForm({
  form: "mailTemplateForm",
  validate,
})(MailTemplateForm);
