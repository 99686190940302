import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { Link } from "react-router-dom";
import * as util from "../../shared/utility";
import { Pagination, Icon } from "semantic-ui-react";

import StandardTableHeader from "../UI/StandardHeader";
class ArticleList extends React.Component {
  componentDidMount() {
    this.props.fetchArticles();
  }

  handlePageChange(e) {
    const newOffset = (e.activePage - 1) * this.props.limit;
    this.props.setArticlesOffset(newOffset);
  }

  renderTable() {
    const pages = Math.ceil(this.props.articles.length / this.props.limit);
    const activePage = 1 + this.props.offset / this.props.limit;

    let articlelist = null;

    if (this.props.isMobile) {
      articlelist = this.props.articles
        .sort((a, b) => a.articleStatusID - b.articleStatusID)
        .sort((a, b) =>
          b.lastUpdatedDateTime.localeCompare(a.lastUpdatedDateTime)
        )
        .slice(this.props.offset, this.props.offset + this.props.limit)
        .map((article) => {
          return (
            <tr key={article.articleID}>
              <td>
                <Link
                  className="item"
                  to={`/inventory/articles/edit/${article.articleID}`}
                >
                  <div className="item" title={article.description}>
                    {article.name}
                  </div>
                </Link>
              </td>
              <td className="center aligned">
                <Link
                  className="item"
                  to={`/inventory/articles/delete/${article.articleID}`}
                  key={article.articleID}
                >
                  <i className="trash icon" title="Delete record." />
                </Link>
              </td>
            </tr>
          );
        });
    } else {
      articlelist = this.props.articles
        .sort((a, b) => a.articleStatusID - b.articleStatusID)
        .sort((a, b) =>
          b.lastUpdatedDateTime.localeCompare(a.lastUpdatedDateTime)
        )
        .slice(this.props.offset, this.props.offset + this.props.limit)
        .map((article) => {
          return (
            <tr key={article.articleID}>
              <td>{article.articleID}</td>
              <td>
                <Link
                  className="item"
                  to={`/inventory/articles/edit/${article.articleID}`}
                >
                  <div className="item" title={article.description}>
                    {article.name}
                  </div>
                </Link>
              </td>
              <td>{article.category.name}</td>
              <td className="right aligned">
                {util.formatDecimal(article.price)}
              </td>
              <td className="center aligned">
                {article.marge === true ? (
                  <Icon className="green check" />
                ) : (
                  <Icon className="x red" />
                )}
              </td>
              <td className="center aligned">
                {util.utcStringToLocalDateTime(article.lastUpdatedDateTime)}
              </td>
              <td className="center aligned">
                <Link
                  className="item"
                  to={`/inventory/articles/delete/${article.articleID}`}
                  key={article.articleID}
                >
                  <i className="trash icon" title="Delete record." />
                </Link>
              </td>
            </tr>
          );
        });
    }

    if (this.props.isMobile) {
      return (
        <div>
          <table className="ui celled table unstackable">
            <thead>
              <tr>
                <th className="fifteen wide">Name</th>
                <th className="one wide center aligned">Action</th>
              </tr>
            </thead>
            <tbody>{articlelist}</tbody>
          </table>
          <Pagination
            activePage={activePage}
            totalPages={pages}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            onPageChange={(e, props, offset) => this.handlePageChange(props)}
          />
        </div>
      );
    } else {
      return (
        <div>
          <table className="ui celled table">
            <thead>
              <tr>
                <th className="one wide">ID</th>
                <th className="five wide">Name</th>
                <th className="three wide">Category</th>
                <th className="two wide right aligned">Price (€)</th>
                <th className="one wide center aligned">Marge</th>
                <th className="three wide center aligned">Changed</th>
                <th className="one wide center aligned">Action</th>
              </tr>
            </thead>
            <tbody>{articlelist}</tbody>
          </table>
          <Pagination
            activePage={activePage}
            totalPages={pages}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            onPageChange={(e, props, offset) => this.handlePageChange(props)}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="ui one column grid">
        <div className="column">
          <StandardTableHeader
            headerLabel="Articles"
            linkForNewButton="/inventory/articles/new"
            recordCount={this.props.articles.length}
            searchValue={this.props.articlesFilter}
            searchFunction={(e) => this.props.setArticlesFilter(e.target.value)}
          />
        </div>
        <div className="column">{this.renderTable()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    articles: Object.values(state.articles.filteredItems),
    articlesFilter: state.articles.articlesFilter,
    offset: state.articles.articlesOffset,
    limit: state.articles.articlesLimit,
    isMobile: state.utility.isMobile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchArticles: () => dispatch(actions.fetchArticles()),
    setArticlesFilter: (text) => dispatch(actions.setArticlesFilter(text)),
    setArticlesOffset: (offset) => dispatch(actions.setArticlesOffset(offset)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleList);
