import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const MobileMenuDropdown = ({
  menuItem: { label, path, subMenuItems },
  setShowMobileMenu,
}) => {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  const subMenu = subMenuItems.map((subMenuItem, index) => {
    return (
      <NavLink
        key={index}
        to={subMenuItem.path}
        activeClassName="active"
        onClick={() => setShowMobileMenu(false)}
      >
        {subMenuItem.label}
      </NavLink>
    );
  });

  return (
    <div
      className={`mobileMenuItem mobileMenuItemWithDropdown ${
        showDropdownMenu ? "active" : ""
      }`}
      onClick={() => setShowDropdownMenu(!showDropdownMenu)}
    >
      <NavLink
        to={path}
        activeClassName="active"
        onClick={(e) => e.preventDefault()}
      >
        {label}
        {showDropdownMenu ? (
          <i className="caret up icon small" />
        ) : (
          <i className="caret down icon small" />
        )}
      </NavLink>
      {showDropdownMenu && <div className="mobileMenuDropdown">{subMenu}</div>}
    </div>
  );
};

export default MobileMenuDropdown;
