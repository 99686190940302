import solartoolapi from "../apis/solartoolapi";
import history from "../history";
import {
  FETCH_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
  REMOVE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
  CREATE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
  EDIT_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
  COPY_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
  FETCH_PURCHASE_ORDER_ROW,
  REMOVE_PURCHASE_ORDER_ROW,
} from "./types";
import { authHeader } from "../shared/utility";

export const fetchPurchaseOrderRowsForPurchaseOrder =
  (id) => async (dispatch) => {
    const response = await solartoolapi.get(
      `/purchaseorderrows/purchaseorder/${id}`,
      {
        headers: authHeader(),
      }
    );

    dispatch({
      type: FETCH_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
      payload: response.data.result,
    });
  };

export const removePurchaseOrderRowsForPurchaseOrder =
  () => async (dispatch) => {
    dispatch({
      type: REMOVE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
    });
  };

export const createPurchaseOrderRowForPurchaseOrder =
  (formValues, projectID) => async (dispatch) => {
    const response = await solartoolapi.post(
      "/purchaseorderrows",
      {
        ...formValues,
        projectID,
      },
      { headers: authHeader() }
    );

    dispatch({
      type: CREATE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
      payload: response.data.result,
    });
  };

export const editPurchaseOrderRowForPurchaseOrder =
  (id, formValues) => async (dispatch) => {
    const response = await solartoolapi.put(
      `/purchaseorderrows/${id}`,
      formValues,
      {
        headers: authHeader(),
      }
    );

    dispatch({
      type: EDIT_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
      payload: response.data.result,
    });
  };

export const deletePurchaseOrderRowForPurchaseOrder =
  (id) => async (dispatch) => {
    await solartoolapi.delete(`/purchaseorderrows/${id}`, {
      headers: authHeader(),
    });

    dispatch({
      type: DELETE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
      payload: id,
    });
  };

export const copyPurchaseOrderRowForPurchaseOrder =
  (id) => async (dispatch) => {
    const response = await solartoolapi.post(
      `/purchaseorderrows/copy/${id}`,
      {},
      {
        headers: authHeader(),
      }
    );

    dispatch({
      type: COPY_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER,
      payload: response.data.result,
    });
  };

export const fetchPurchaseOrderRow = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/purchaseorderrows/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_PURCHASE_ORDER_ROW, payload: response.data.result });
};

export const removePurchaseOrderRow = () => async (dispatch) => {
  dispatch({ type: REMOVE_PURCHASE_ORDER_ROW });
};

export const deletePurchaseOrderRow = (id) => async (dispatch) => {
  await solartoolapi.delete(`/purchaseorderrows/${id}`, {
    headers: authHeader(),
  });

  history.go(-1);
};

export const editPurchaseOrderRow = (id, formValues) => async (dispatch) => {
  await solartoolapi.put(`/purchaseorderrows/${id}`, formValues, {
    headers: authHeader(),
  });

  history.goBack();
};

export const createPurchaseOrderRow = (formValues, projectID) => async () => {
  await solartoolapi.post(
    "/purchaseorderrows",
    {
      ...formValues,
      projectID,
    },
    { headers: authHeader() }
  );

  history.goBack();
};
