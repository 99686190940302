import solartoolapi from "../apis/solartoolapi";
import history from "../history";
import {
  FETCH_ROOF_SECTIONS_FOR_PROJECT,
  REMOVE_ROOF_SECTIONS_FOR_PROJECT,
  CREATE_ROOF_SECTIONS_FOR_PROJECT,
  EDIT_ROOF_SECTIONS_FOR_PROJECT,
  DELETE_ROOF_SECTIONS_FOR_PROJECT,
  COPY_ROOF_SECTIONS_FOR_PROJECT,
  FETCH_ROOF_SECTION,
  REMOVE_ROOF_SECTION,
} from "./types";
import { authHeader } from "../shared/utility";

export const fetchRoofSectionsForProject = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/roofsections/project/${id}`, {
    headers: authHeader(),
  });

  dispatch({
    type: FETCH_ROOF_SECTIONS_FOR_PROJECT,
    payload: response.data.result,
  });
};

export const removeRoofSectionsForProject = () => async (dispatch) => {
  dispatch({
    type: REMOVE_ROOF_SECTIONS_FOR_PROJECT,
  });
};

export const createRoofSectionForProject =
  (formValues, projectID) => async (dispatch) => {
    const response = await solartoolapi.post(
      "/roofsections",
      {
        ...formValues,
        projectID,
      },
      { headers: authHeader() }
    );

    dispatch({
      type: CREATE_ROOF_SECTIONS_FOR_PROJECT,
      payload: response.data.result,
    });
  };

export const editRoofSectionForProject =
  (id, formValues) => async (dispatch) => {
    const response = await solartoolapi.put(`/roofsections/${id}`, formValues, {
      headers: authHeader(),
    });

    dispatch({
      type: EDIT_ROOF_SECTIONS_FOR_PROJECT,
      payload: response.data.result,
    });
  };

export const deleteRoofSectionForProject = (id) => async (dispatch) => {
  await solartoolapi.delete(`/roofsections/${id}`, {
    headers: authHeader(),
  });

  dispatch({
    type: DELETE_ROOF_SECTIONS_FOR_PROJECT,
    payload: id,
  });
};

export const copyRoofSectionForProject = (id) => async (dispatch) => {
  const response = await solartoolapi.post(
    `/roofsections/copy/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );

  dispatch({
    type: COPY_ROOF_SECTIONS_FOR_PROJECT,
    payload: response.data.result,
  });
};

export const fetchRoofSection = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/roofsections/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_ROOF_SECTION, payload: response.data.result });
};

export const removeRoofSection = () => async (dispatch) => {
  dispatch({ type: REMOVE_ROOF_SECTION });
};

export const deleteRoofSection = (id) => async (dispatch) => {
  await solartoolapi.delete(`/roofsections/${id}`, { headers: authHeader() });

  history.go(-1);
};

export const editRoofSection = (id, formValues) => async (dispatch) => {
  await solartoolapi.put(`/roofsections/${id}`, formValues, {
    headers: authHeader(),
  });

  history.goBack();
};

export const createRoofSection = (formValues, projectID) => async () => {
  await solartoolapi.post(
    "/roofsections",
    {
      ...formValues,
      projectID,
    },
    { headers: authHeader() }
  );

  history.goBack();
};
