export const CREATE_PROJECT = "CREATE_PROJECT";
export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const FETCH_PROJECT = "FETCH_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const SET_PROJECTS_FILTER = "SET_PROJECTS_FILTER";
export const SET_PROJECTS_OFFSET = "SET_PROJECTS_OFFSET";
export const FETCH_PROJECTS_PAGED = "FETCH_PROJECTS_PAGED";
export const SET_PROJECTS_PAGE_FILTER = "SET_PROJECTS_PAGE_FILTER";
export const SET_PROJECTS_PAGE_NUMBER = "SET_PROJECTS_PAGE_NUMBER";
export const FETCH_PROJECT_DASHBOARD = "FETCH_PROJECT_DASHBOARD";
export const UNLOAD_PROJECT_DASHBOARD = "UNLOAD_PROJECT_DASHBOARD";
export const SET_PROJECT_MODE = "SET_PROJECT_MODE";
export const SET_PROJECTS_VISITOR_FILTER = "SET_PROJECTS_VISITOR_FILTER";
export const RESET_PROJECTS_FILTER = "RESET_PROJECTS_FILTER";

export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const FETCH_ARTICLES = "FETCH_ARTICLES";
export const FETCH_ARTICLE = "FETCH_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const EDIT_ARTICLE = "EDIT_ARTICLE";
export const SET_ARTICLES_FILTER = "SET_ARTICLES_FILTER";
export const SET_ARTICLES_OFFSET = "SET_ARTICLES_OFFSET";
export const FETCH_INVENTORY = "FETCH_INVENTORY";
export const SET_INVENTORY_FILTER = "SET_INVENTORY_FILTER";
export const SET_INVENTORY_OFFSET = "SET_INVENTORY_OFFSET";
export const TOGGLE_SHOW_ONLY_ACTION_REQUIRED =
  "TOGGLE_SHOW_ONLY_ACTION_REQUIRED";
export const SET_INVENTORY_LIST_ARTICLE_INVENTORY_CATEGORY_FILTER =
  "SET_INVENTORY_LIST_ARTICLE_INVENTORY_CATEGORY_FILTER";
export const RESET_INVENTORY_LIST_FILTER = "RESET_INVENTORY_LIST_FILTER";
export const FETCH_INVENTORY_ITEM = "FETCH_INVENTORY_ITEM";
export const UNLOAD_INVENTORY_ITEM = "UNLOAD_INVENTORY_ITEM";
export const SET_INVENTORY_LIST_ARTICLE_CATEGORY_FILTER =
  "SET_INVENTORY_LIST_ARTICLE_CATEGORY_FILTER";
export const TOGGLE_INVENTORY_LIST_INVENTORY_ORDER_ECONOMIC =
  "TOGGLE_INVENTORY_LIST_INVENTORY_ORDER_ECONOMIC";

export const FETCH_USER = "FETCH_USER";
export const FETCH_USERS = "FETCH_USERS";
export const CREATE_USER = "CREATE_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const SET_USER_ERROR = "SET_USER_ERROR";

export const AUTH_REQUEST_START = "AUTH_REQUEST_START";
export const AUTH_REQUEST_SUCCESS = "AUTH_REQUEST_SUCCESS";
export const AUTH_REQUEST_FAILURE = "AUTH_REQUEST_FAILURE";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const CLEAR_AUTH = "CLEAR_AUTH";

export const CREATE_VISIT = "CREATE_VISIT";
export const EDIT_VISIT = "EDIT_VISIT";
export const FETCH_VISIT_START = "FETCH_VISIT_START";
export const FETCH_VISIT = "FETCH_VISIT";
export const FETCH_VISIT_END = "FETCH_VISIT_END";
export const DELETE_VISIT = "DELETE_VISIT";
export const UNLOAD_VISIT = "UNLOAD_VISIT";
export const FETCH_VISIT_MAP = "FETCH_VISIT_MAP";
export const SET_VISIT_CREATE_CALCULATION_ON_SAVE =
  "SET_VISIT_CREATE_CALCULATION_ON_SAVE";
export const SET_VISIT_NAVIGATE_TO_ROOF_SECTIONS_ON_SAVE =
  "SET_VISIT_NAVIGATE_TO_ROOF_SECTIONS_ON_SAVE";

export const GENERATE_CALCULATION = "GENERATE_CALCULATION";
export const CREATE_CALCULATION = "CREATE_CALCULATION";
export const EDIT_CALCULATION = "EDIT_CALCULATION";
export const FETCH_CALCULATION = "FETCH_CALCULATION";
export const DELETE_CALCULATION = "DELETE_CALCULATION";
export const FETCH_CALCULATION_FOR_PROJECT = "FETCH_CALCULATION_FOR_PROJECT";
export const COPY_CALCULATION = "COPY_CALCULATION";
export const UNLOAD_CALCULATION = "UNLOAD_CALCULATION";
export const UNLOAD_CALCULATIONS = "UNLOAD_CALCULATIONS";
export const CREATE_CALCULATION_FOR_PROJECT = "CREATE_CALCULATION_FOR_PROJECT";
export const SET_CALCULATION_ERROR = "SET_CALCULATION_ERROR";

export const SET_ISMOBILE = "SET_ISMOBILE";
export const SET_UPDATE_AVAILABLE = "SET_UPDATE_AVAILABLE";
export const SET_GLOBAL_ERROR_MESSAGE = "SET_GLOBAL_ERROR_MESSAGE";

export const FETCH_COUNTRIES = "FETCH_COUNTRIES";

export const FETCH_CATEGORIES = "FETCH_CATEGORIES";

export const FETCH_ADDRESS = "FETCH_ADDRESS";
export const CREATE_ADDRESS = "CREATE_ADDRESS";
export const EDIT_ADDRESS = "EDIT_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const UNLOAD_ADDRESS_COMPONENT = "UNLOAD_ADDRESS_COMPONENT";
export const SET_ADDRESS_MODE = "SET_ADDRESS_MODE";

export const FETCH_PERSON = "FETCH_PERSON";
export const CREATE_PERSON = "CREATE_PERSON";
export const EDIT_PERSON = "EDIT_PERSON";
export const DELETE_PERSON = "DELETE_PERSON";
export const UNLOAD_PERSON_COMPONENT = "UNLOAD_PERSON_COMPONENT";
export const SET_PERSON_MODE = "SET_PERSON_MODE";

export const CREATE_MAILTEMPLATE = "CREATE_MAILTEMPLATE";
export const FETCH_MAILTEMPLATES = "FETCH_MAILTEMPLATES";
export const FETCH_MAILTEMPLATE = "FETCH_MAILTEMPLATE";
export const DELETE_MAILTEMPLATE = "DELETE_MAILTEMPLATE";
export const EDIT_MAILTEMPLATE = "EDIT_MAILTEMPLATE";
export const SET_MAILTEMPLATES_FILTER = "SET_MAILTEMPLATES_FILTER";
export const SET_MAILTEMPLATES_OFFSET = "SET_MAILTEMPLATES_OFFSET";

export const SEND_MAIL_RESET = "SEND_MAIL_RESET";
export const SEND_MAIL_START = "SEND_MAIL_START";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
export const SEND_MAIL_FAIL = "SEND_MAIL_FAIL";
export const SET_MAILTEMPLATE = "SET_MAILTEMPLATE";
export const SET_PROJECT_MAIL_DTO = "SET_PROJECT_MAIL_DTO";
export const CLEAR_MAIL_RESPONSE_MESSAGE = "CLEAR_MAIL_RESPONSE_MESSAGE";

export const CREATE_FILE = "CREATE_FILE";
export const FETCH_FILES = "FETCH_FILES";
export const DELETE_FILE = "DELETE_FILE";
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const RENAME_FILE = "RENAME_FILE";

export const CREATE_PROJECT_FILE = "CREATE_PROJECT_FILE";
export const FETCH_PROJECT_FILES = "FETCH_PROJECT_FILES";
export const DELETE_PROJECT_FILE = "DELETE_PROJECT_FILE";
export const DOWNLOAD_PROJECT_FILE = "DOWNLOAD_PROJECT_FILE";
export const RENAME_PROJECT_FILE = "RENAME_PROJECT_FILE";
export const SET_PROJECT_FILES_ERROR_MESSAGES =
  "SET_PROJECT_FILES_ERROR_MESSAGES";

export const FETCH_CALCULATION_ROW = "FETCH_CALCULATION_ROW";
export const UNLOAD_CALCULATION_ROW = "UNLOAD_CALCULATION_ROW";
export const UP_CALCULATION_ROW = "UP_CALCULATION_ROW";
export const DOWN_CALCULATION_ROW = "DOWN_CALCULATION_ROW";
export const FETCH_CALCULATION_PROJECTID = "FETCH_CALCULATION_PROJECTID";

export const GENERATE_OFFER = "GENERATE_OFFER";
export const FETCH_OFFER_FOR_PROJECT = "FETCH_OFFER_FOR_PROJECT";
export const FETCH_OFFER = "FETCH_OFFER";
export const FETCH_OFFER_PROJECTID = "FETCH_OFFER_PROJECTID";
export const EDIT_OFFER = "EDIT_OFFER";
export const DELETE_OFFER = "DELETE_OFFER";
export const GENERATE_PDF_OFFER = "GENERATE_PDF_OFFER";
export const UNLOAD_OFFER = "UNLOAD_OFFER";
export const UNLOAD_OFFERS = "UNLOAD_OFFERS";
export const RESET_OFFERS_MESSAGE = "RESET_OFFERS_MESSAGE";

export const GENERATE_ORDER = "GENERATE_ORDER";
export const FETCH_ORDER_FOR_PROJECT = "FETCH_ORDER_FOR_PROJECT";
export const FETCH_ORDER = "FETCH_ORDER";
export const FETCH_ORDER_PROJECTID = "FETCH_ORDER_PROJECTID";
export const EDIT_ORDER = "EDIT_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";
export const GENERATE_PDF_ORDER = "GENERATE_PDF_ORDER";
export const UNLOAD_ORDER = "UNLOAD_ORDER";
export const UNLOAD_ORDERS = "UNLOAD_ORDERS";
export const RESET_ORDERS_MESSAGE = "RESET_ORDERS_MESSAGE";
export const FETCH_ORDERS_PAGED = "FETCH_ORDERS_PAGED";
export const SET_ORDERS_PAGE_FILTER = "SET_ORDERS_PAGE_FILTER";
export const SET_ORDERS_PAGE_NUMBER = "SET_ORDERS_PAGE_NUMBER";
export const RESET_ORDERS_FILTER = "RESET_ORDERS_FILTER";
export const TOGGLE_SHOW_INVOICED = "TOGGLE_SHOW_INVOICED";
export const SET_ORDERS_VISITOR_FILTER = "SET_ORDERS_VISITOR_FILTER";
export const TOGGLE_SHOW_PLANNED = "TOGGLE_SHOW_PLANNED";

export const FETCH_ORDERS_PAGED_PLANNING = "FETCH_ORDERS_PAGED_PLANNING";
export const SET_ORDERS_PAGE_FILTER_PLANNING =
  "SET_ORDERS_PAGE_FILTER_PLANNING";
export const SET_ORDERS_PAGE_NUMBER_PLANNING =
  "SET_ORDERS_PAGE_NUMBER_PLANNING";
export const RESET_ORDERS_FILTER_PLANNING = "RESET_ORDERS_FILTER_PLANNING";
export const TOGGLE_SHOW_INVOICED_PLANNING = "TOGGLE_SHOW_INVOICED_PLANNING";
export const SET_ORDERS_VISITOR_FILTER_PLANNING =
  "SET_ORDERS_VISITOR_FILTER_PLANNING";
export const TOGGLE_SHOW_PLANNED_PLANNING = "TOGGLE_SHOW_PLANNED_PLANNING";
export const UPDATE_ORDER_DELIVER_DATE = "UPDATE_ORDER_DELIVER_DATE";

export const GENERATE_INVOICE = "GENERATE_INVOICE";
export const FETCH_INVOICE_FOR_PROJECT = "FETCH_INVOICE_FOR_PROJECT";
export const FETCH_INVOICE = "FETCH_INVOICE";
export const FETCH_INVOICE_PROJECTID = "FETCH_INVOICE_PROJECTID";
export const EDIT_INVOICE = "EDIT_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const GENERATE_PDF_INVOICE = "GENERATE_PDF_INVOICE";
export const UNLOAD_INVOICE = "UNLOAD_INVOICE";
export const UNLOAD_INVOICES = "UNLOAD_INVOICES";
export const RESET_INVOICES_MESSAGE = "RESET_INVOICES_MESSAGE";

export const CREATE_TRELLO = "CREATE_TRELLO";
export const FETCH_TRELLO = "FETCH_TRELLO";
export const UNLOAD_TRELLO = "UNLOAD_TRELLO";
export const CREATE_TRELLO_RESET = "CREATE_TRELLO_RESET";
export const CREATE_TRELLO_START = "CREATE_TRELLO_START";

export const FETCH_ROOF_SECTIONS_FOR_PROJECT =
  "FETCH_ROOF_SECTIONS_FOR_PROJECT";
export const REMOVE_ROOF_SECTIONS_FOR_PROJECT =
  "REMOVE_ROOF_SECTIONS_FOR_PROJECT";
export const CREATE_ROOF_SECTIONS_FOR_PROJECT =
  "CREATE_ROOF_SECTIONS_FOR_PROJECT";
export const EDIT_ROOF_SECTIONS_FOR_PROJECT = "EDIT_ROOF_SECTIONS_FOR_PROJECT";
export const DELETE_ROOF_SECTIONS_FOR_PROJECT =
  "DELETE_ROOF_SECTIONS_FOR_PROJECT";
export const FETCH_ROOF_SECTION = "FETCH_ROOF_SECTION";
export const DELETE_ROOF_SECTION = "DELETE_ROOF_SECTION";
export const REMOVE_ROOF_SECTION = "REMOVE_ROOF_SECTION";
export const COPY_ROOF_SECTIONS_FOR_PROJECT = "COPY_ROOF_SECTIONS_FOR_PROJECT";

export const FETCH_RELATION = "FETCH_RELATION";
export const FETCH_SUPPLIERS = "FETCH_SUPPLIERS";
export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const FETCH_RELATIONS_PAGED = "FETCH_RELATIONS_PAGED";
export const SET_RELATIONS_FILTER = "SET_RELATIONS_FILTER";
export const SET_RELATIONS_OFFSET = "SET_RELATIONS_OFFSET";
export const SET_RELATIONS_PAGE_FILTER = "SET_RELATIONS_PAGE_FILTER";
export const SET_RELATIONS_RELATION_TYPE_FILTER =
  "SET_RELATIONS_RELATION_TYPE_FILTER";
export const RESET_RELATIONS_PAGE_FILTER = "RESET_RELATIONS_PAGE_FILTER";
export const SET_RELATIONS_PAGE_NUMBER = "SET_RELATIONS_PAGE_NUMBER";
export const UNLOAD_RELATION = "UNLOAD_RELATION";

export const FETCH_PURCHASE_ORDER = "FETCH_PURCHASE_ORDER";
export const UNLOAD_PURCHASE_ORDER = "UNLOAD_PURCHASE_ORDER";
export const CREATE_PURCHASE_ORDER = "CREATE_PURCHASE_ORDER";
export const EDIT_PURCHASE_ORDER = "EDIT_PURCHASE_ORDER";
export const DELETE_PURCHASE_ORDER = "DELETE_PURCHASE_ORDER";
export const FETCH_PURCHASE_ORDERS_PAGED = "FETCH_PURCHASE_ORDERS_PAGED";
export const SET_PURCHASE_ORDERS_PAGE_FILTER =
  "SET_PURCHASE_ORDERS_PAGE_FILTER";
export const RESET_PURCHASE_ORDERS_FILTER = "RESET_PURCHASE_ORDERS_FILTER";
export const SET_PURCHASE_ORDERS_PAGE_NUMBER =
  "SET_PURCHASE_ORDERS_PAGE_NUMBER";
export const TOGGLE_PURHCASE_ORDERS_SHOW_ORDERED =
  "TOGGLE_PURHCASE_ORDERS_SHOW_ORDERED";
export const TOGGLE_PURHCASE_ORDERS_SHOW_PLANNED =
  "TOGGLE_PURHCASE_ORDERS_SHOW_PLANNED";
export const TOGGLE_PURHCASE_ORDERS_SHOW_DELIVERED =
  "TOGGLE_PURHCASE_ORDERS_SHOW_DELIVERED";
export const SET_PURCHASE_ORDER_RETURN_ON_SAVE =
  "SET_PURCHASE_ORDER_RETURN_ON_SAVE";

export const FETCH_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER =
  "FETCH_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER";
export const REMOVE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER =
  "REMOVE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER";
export const CREATE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER =
  "CREATE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER";
export const EDIT_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER =
  "EDIT_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER";
export const DELETE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER =
  "DELETE_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER";
export const COPY_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER =
  "COPY_PURCHASE_ORDER_ROWS_FOR_PURCHASE_ORDER";
export const FETCH_PURCHASE_ORDER_ROW = "FETCH_PURCHASE_ORDER_ROW";
export const REMOVE_PURCHASE_ORDER_ROW = "REMOVE_PURCHASE_ORDER_ROW";
