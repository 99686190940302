import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import AddressForm from './AddressForm';
import { Segment } from 'semantic-ui-react';

class AddressCreate extends React.Component {
  componentDidMount() {
    if (this.props.countries.length === 0) {
      this.props.fetchCountries();
    }
  }

  onSubmit = formValues => {
    this.props.createAddressAndUpdateProject(this.props.projectID, formValues);
  };

  render() {
    const initialVal = {
      countryID: this.props.defaultCountryID
    };

    if (!this.props.defaultCountryID) {
      return <Segment loading />;
    }

    return (
      <Segment>
        <h3>Create Address</h3>
        <AddressForm
          initialValues={initialVal}
          countries={this.props.countries}
          onSubmit={this.onSubmit}
          onCancel={this.props.onCancel}
        />
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    countries: state.countries.items,
    defaultCountryID: state.countries.defaultID
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createAddressAndUpdateProject: (projectID, formValues) =>
      dispatch(actions.createAddressAndUpdateProject(projectID, formValues)),
    fetchCountries: () => dispatch(actions.fetchCountries())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressCreate);
