import solartoolapi from "../apis/solartoolapi";
import {
  SEND_MAIL_SUCCESS,
  SEND_MAIL_RESET,
  SET_MAILTEMPLATE,
  SEND_MAIL_START,
  SET_PROJECT_MAIL_DTO,
  CLEAR_MAIL_RESPONSE_MESSAGE,
} from "./types";
import { authHeader } from "../shared/utility";

export const sendMail = (formValues) => async (dispatch) => {
  dispatch({ type: SEND_MAIL_START });
  const response = await solartoolapi.post(
    "/mailtemplates/send",
    {
      ...formValues,
    },
    { headers: authHeader() }
  );

  dispatch({ type: SEND_MAIL_SUCCESS, payload: response.data.message });
};

export const resetMailMessage = () => (dispatch) => {
  dispatch({ type: SEND_MAIL_RESET });
};

export const clearMailResponseMessage = () => (dispatch) => {
  dispatch({ type: CLEAR_MAIL_RESPONSE_MESSAGE });
};

export const setMailTemplate = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/mailtemplates/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: SET_MAILTEMPLATE, payload: response.data.result });
};

export const setProjectMailDto = (id, projectid) => async (dispatch) => {
  const response = await solartoolapi.get(
    `/mailtemplates/${id}/project/${projectid}`,
    {
      headers: authHeader(),
    }
  );

  dispatch({ type: SET_PROJECT_MAIL_DTO, payload: response.data.result });
};
