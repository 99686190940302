import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as actions from "../../actions";
import StandardTableHeader from "../UI/StandardHeader";
import RoofSectionCreateOnOverview from "./RoofSectionCreateOnOverview";
import RoofSectionEditOnOverview from "./RoofSectionEditOnOverview";

import { Button, Modal } from "semantic-ui-react";

const RoofSectionOverview = ({ projectID, articles }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchRoofSectionsForProject(projectID));

    return () => {
      dispatch(actions.removeRoofSectionsForProject());
      dispatch(actions.setCalculationError(""));
    };
  }, []);

  const items = useSelector((state) => Object.values(state.roofSections.items));

  const [deleteRoofSectionActive, setDeleteRoofSectionActive] = useState(null);
  const [editRoofSectionActive, setEditRoofSectionActive] = useState(null);

  const handleDeleteCancelClick = () => {
    setDeleteRoofSectionActive(null);
  };

  const handleDeleteSubmitClick = () => {
    dispatch(actions.deleteRoofSectionForProject(deleteRoofSectionActive));
    setDeleteRoofSectionActive(null);
  };

  const handleEditRoofSectionActiveClick = (id) => {
    if (editRoofSectionActive === id) {
      setEditRoofSectionActive(null);
    } else {
      setEditRoofSectionActive(id);
    }
  };

  const roofSectionList = items
    .sort((a, b) => a.roofSectionID - b.roofSectionID)
    .map((roofSection) => {
      if (editRoofSectionActive === roofSection.roofSectionID) {
        return (
          <tr key={roofSection.roofSectionID}>
            <td colSpan={9} className="forcusAreaOnForms">
              <RoofSectionEditOnOverview
                articles={articles}
                item={roofSection}
                handleCancelClick={() => setEditRoofSectionActive(null)}
              />
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={roofSection.roofSectionID}>
            <td className="mobile hidden">{roofSection.roofSectionID}</td>
            <td className="mobile hidden">{roofSection.roofType}</td>
            <td className="center aligned">{roofSection.areaSize}</td>
            <td>
              <div
                className="item"
                style={{ cursor: "pointer", color: "#4183c4" }}
                onClick={() =>
                  handleEditRoofSectionActiveClick(roofSection.roofSectionID)
                }
              >
                {roofSection.panelArticle.name}
              </div>
            </td>
            <td className="mobile hidden">{roofSection.roofDirection}</td>
            <td className="center aligned mobile hidden">
              {roofSection.roofPitch}
            </td>
            <td className="center aligned mobile hidden">
              {roofSection.efficiency}
            </td>
            <td>
              <i
                style={{ cursor: "pointer" }}
                className="copy icon blue"
                onClick={() =>
                  dispatch(
                    actions.copyRoofSectionForProject(roofSection.roofSectionID)
                  )
                }
                title="Copy record."
              />
            </td>
            <td className="center aligned">
              <i
                style={{ cursor: "pointer" }}
                className="trash icon blue"
                title="Delete record."
                onClick={() =>
                  setDeleteRoofSectionActive(roofSection.roofSectionID)
                }
              />
            </td>
          </tr>
        );
      }
    });

  const renderTable = (
    <table className="ui celled table unstackable">
      <thead>
        <tr>
          <th className="one wide mobile hidden">ID</th>
          <th className="one wide mobile hidden">Type</th>
          <th className="one wide center aligned">Size</th>
          <th className="eight wide ">Panel</th>
          <th className="two wide mobile hidden">Direction</th>
          <th className="one wide center aligned mobile hidden">Pitch</th>
          <th className="one wide center aligned mobile hidden">Efficiency</th>
          <th className="one wide center aligned" colSpan={2}>
            Action
          </th>
        </tr>
      </thead>
      <tbody>{roofSectionList}</tbody>
    </table>
  );

  const DeleteRoofSection = () => {
    if (!deleteRoofSectionActive) {
      return null;
    }

    const item = items.find((e) => e.roofSectionID === deleteRoofSectionActive);

    return (
      <Modal onClose={handleDeleteCancelClick} open={!!deleteRoofSectionActive}>
        <Modal.Header>{`Delete Roof Section ${item.roofSectionID}`}</Modal.Header>
        <Modal.Content>
          <p>
            {`Are you sure you want to delete the roof section with ${item.areaSize} panels on a ${item.roofType} roof.`}
          </p>
          <p>{`Type: ${item.panelArticle.name}`}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Delete"
            labelPosition="right"
            icon="trash"
            onClick={handleDeleteSubmitClick}
            negative
          ></Button>
          <Button onClick={handleDeleteCancelClick}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  };

  return (
    <div className="ui one column grid">
      <div className="column">
        <StandardTableHeader
          headerLabel="Roof Sections"
          recordCount={items.length}
        ></StandardTableHeader>
      </div>
      <div className="column">{renderTable}</div>
      <RoofSectionCreateOnOverview projectID={projectID} articles={articles} />
      <DeleteRoofSection />
    </div>
  );
};

export default RoofSectionOverview;
