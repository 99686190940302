import {
  SET_ISMOBILE,
  SET_UPDATE_AVAILABLE,
  SET_GLOBAL_ERROR_MESSAGE,
} from "../actions/types";

const width = window.innerWidth;
const isMobile = width <= 768;
const initialState = {
  isMobile,
  updateAvailable: false,
  globalErrors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ISMOBILE:
      return { ...state, isMobile: action.payload };
    case SET_UPDATE_AVAILABLE:
      return { ...state, updateAvailable: action.payload };
    case SET_GLOBAL_ERROR_MESSAGE:
      return {
        ...state,
        globalErrors: action.payload,
      };
    default:
      return state;
  }
};
