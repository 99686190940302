import React from "react";
import { Field, reduxForm } from "redux-form";
import * as util from "../../shared/utility";
import { Grid } from "semantic-ui-react";

class CreateOrderForm extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <Grid className="middle aligned">
          <Grid.Row>
            <Grid.Column className="right aligned" width={6}>
              Ordered on (mandatory):
            </Grid.Column>
            <Grid.Column width={6}>
              <Field name="orderDate" component={util.RenderInputDate} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className="right aligned" width={6}>
              Planned deliver on (mandatory):
            </Grid.Column>
            <Grid.Column width={6}>
              <Field
                name="plannedDeliverDate"
                component={util.RenderInputDate}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className="right aligned" width={6}>
              Deliver on (optional):
            </Grid.Column>
            <Grid.Column width={6}>
              <Field name="deliverDate" component={util.RenderInputDate} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="middle aligned">
            <Grid.Column width={16} className="center aligned">
              <button
                type="submit"
                className="ui orange button "
                disabled={this.props.submitting}
              >
                Create Order
              </button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.orderDate) {
    errors.orderDate = "The order date is mandatory.";
  }

  if (
    !formValues.plannedDeliverDate ||
    formValues.plannedDeliverDate < formValues.orderDate
  ) {
    errors.plannedDeliverDate =
      "The planned deliver date is mandatory and needs to be later then the order date.";
  }

  if (
    formValues.orderDate &&
    formValues.deliverDate &&
    formValues.deliverDate < formValues.orderDate
  ) {
    errors.deliverDate =
      "The deliver date needs to be later then the order date.";
  }

  return errors;
};

export default reduxForm({
  form: "createOrderForm",
  validate,
})(CreateOrderForm);
