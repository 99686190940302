import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import CalculationRowForm from "./CalculationRowForm";
import { Grid } from "semantic-ui-react";
import StandardTableHeader from "../UI/StandardHeader";

const CalculationRowEdit = ({
  match: {
    params: { calculationrowid },
  },
}) => {
  const dispatch = useDispatch();

  const articles = useSelector((state) => Object.values(state.articles.items));
  const calculationRow = useSelector((state) => state.calculationRows.item);

  useEffect(() => {
    dispatch(actions.fetchArticles());
    dispatch(actions.fetchCalculationRow(calculationrowid));

    return () => {
      dispatch(actions.unloadCalculationRow());
    };
  }, []);

  const onSubmit = (fv) => {
    dispatch(actions.editCalculationRow(fv));
  };

  if (!calculationRow) {
    return <div>Loading...</div>;
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <StandardTableHeader headerLabel="Edit CalculationRow"></StandardTableHeader>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <CalculationRowForm
            initialValues={calculationRow}
            onSubmit={onSubmit}
            articles={articles}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CalculationRowEdit;
