import React from "react";
import { useDispatch } from "react-redux";

import * as actions from "../../actions";
import StandardTableHeader from "../UI/StandardHeader";
import RoofSectionForm from "./RoofSectionForm";

const RoofSectionEditOnOverview = ({ articles, item, handleCancelClick }) => {
  const dispatch = useDispatch();

  const onSubmit = (fv) => {
    dispatch(actions.editRoofSectionForProject(item.roofSectionID, fv));
    handleCancelClick(null);
  };

  return (
    <div className="column">
      <StandardTableHeader
        headerLabel={`Edit Roof Section ${item.roofSectionID}`}
      />
      <RoofSectionForm
        onSubmit={onSubmit}
        articles={articles}
        initialValues={{ ...item }}
        handleCancelClick={handleCancelClick}
        projectID={item.projectID}
      />
    </div>
  );
};

export default RoofSectionEditOnOverview;
