import React from "react";
import { Table } from "semantic-ui-react";
import * as util from "../../shared/utility";

class VisitInfo extends React.Component {
  render() {
    if (!this.props.project) {
      return <div>Loading...</div>;
    }

    let contactPart = null;
    if (this.props.project.contactPerson) {
      const { fullName, email, phoneNumber } = this.props.project.contactPerson;
      contactPart = (
        <React.Fragment>
          <Table.Row>
            <Table.Cell width={5}>Fullname:</Table.Cell>
            <Table.Cell>{fullName}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={5}>Email:</Table.Cell>
            <Table.Cell>{util.EmailHelperLink(email, email)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={5}>Phonenumber:</Table.Cell>
            <Table.Cell>
              {util.PhoneNumberHelperLink(phoneNumber, phoneNumber)}
            </Table.Cell>
          </Table.Row>
        </React.Fragment>
      );
    } else {
      contactPart = (
        <React.Fragment>
          <Table.Row>
            <Table.Cell colSpan={2} width={16} className="center aligned">
              Contact Unkown
            </Table.Cell>
          </Table.Row>
        </React.Fragment>
      );
    }

    let addressPart = null;
    if (this.props.project.projectAddress) {
      const {
        fullAddress,
        city,
        zipCode,
        countryName = this.props.project.projectAddress.country.name,
      } = this.props.project.projectAddress;
      addressPart = (
        <React.Fragment>
          <Table.Row>
            <Table.Cell width={5}>Address:</Table.Cell>
            <Table.Cell>
              {util.GoogleHelperLink(
                fullAddress,
                `${fullAddress},${zipCode},${city},${countryName}`
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={5}>City:</Table.Cell>
            <Table.Cell>{city}</Table.Cell>
          </Table.Row>
        </React.Fragment>
      );
    } else {
      addressPart = (
        <React.Fragment>
          <Table.Row>
            <Table.Cell colSpan={2} width={16} className="center aligned">
              Address Unkown
            </Table.Cell>
          </Table.Row>
        </React.Fragment>
      );
    }

    return (
      <Table celled definition unstackable>
        <Table.Body>
          {contactPart}
          {addressPart}
        </Table.Body>
      </Table>
    );
  }
}

export default VisitInfo;
