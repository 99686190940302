import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const DesktopMenuDropdown = ({ menuItem: { label, path, subMenuItems } }) => {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  const subMenu = subMenuItems.map((subMenuItem, index) => {
    return (
      <NavLink
        key={index}
        to={subMenuItem.path}
        activeClassName="active"
        onClick={() => setShowDropdownMenu(false)}
      >
        {subMenuItem.label}
      </NavLink>
    );
  });

  return (
    <div
      className="desktopMenuItem desktopMenuItemWithDropdown"
      onMouseEnter={() => setShowDropdownMenu(true)}
      onMouseLeave={() => setShowDropdownMenu(false)}
    >
      <NavLink
        to={path}
        activeClassName="active"
        onClick={(e) => {
          e.preventDefault();
          setShowDropdownMenu(true);
        }}
      >
        {label}
        {showDropdownMenu ? (
          <i className="caret down icon small" />
        ) : (
          <i className="caret up icon small" />
        )}
      </NavLink>
      {showDropdownMenu && <div className="desktopMenuDropdown">{subMenu}</div>}
    </div>
  );
};

export default DesktopMenuDropdown;
