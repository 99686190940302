import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import * as util from "../../../shared/utility";
import * as actions from "../../../actions";

import ProjectFileUpload from "./ProjectFileUpload";

const DashboardFiles = ({ files, projectid }) => {
  const dispatch = useDispatch();

  const errorMessages = useSelector(
    (state) => state.projectFiles.errorMessages
  );

  const fileList = files
    .sort((a, b) => b.lastAccessTime.localeCompare(a.lastAccessTime))
    .map((file) => {
      return (
        <tr key={file.name}>
          <td
            className="noWrapCell actionlink"
            onClick={() =>
              dispatch(actions.downloadProjectFile(projectid, file.name))
            }
          >
            {file.name}
          </td>
          <td className="center aligned mobile hidden">
            {util.formatBytes(file.length)}
          </td>
          <td className="center aligned mobile hidden">
            {util.utcStringToLocalDateTime(file.lastAccessTime)}
          </td>
          <td className="center aligned">
            <Link
              className="item"
              to={`/sales/files/project/${projectid}/edit/${file.name}`}
              title={file.name}
            >
              <i className="edit icon" title="Edit record." />
            </Link>
          </td>
          <td className="center aligned">
            <Link
              className="item"
              to={`/sales/files/project/${projectid}/delete/${file.name}`}
              key={file.name}
            >
              <i className="trash icon" title="Delete record." />
            </Link>
          </td>
        </tr>
      );
    });

  const ErrorMessage = ({ errorMessages, resetMessages }) => {
    if (!errorMessages || errorMessages.length === 0) {
      return null;
    }

    return (
      <div className="row">
        <div className="column">
          <div className="ui error message">
            <i className="close icon" onClick={resetMessages} />
            <div className="header">File upload error:</div>
            {errorMessages.map((x, i) => {
              return <p key={i}>- {x}</p>;
            })}
          </div>
        </div>
      </div>
    );
  };

  if (!files || files.length === 0) {
    return (
      <div className="ui one column grid">
        <div className="row">
          <div className="column">
            <table className="ui celled very compact table unstackable">
              <thead>
                <tr>
                  <th style={{ padding: "8px" }}>
                    <div style={{ display: "inline", float: "left" }}>
                      <i className="file icon large" />
                      <h4 className="dashboardHeaderTexts">Files</h4>
                    </div>
                    <div style={{ display: "inline", float: "right" }}>
                      <ProjectFileUpload projectid={projectid} />
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <ErrorMessage
          errorMessages={errorMessages}
          resetMessages={() =>
            dispatch(actions.setProjectFilesErrorMessages([]))
          }
        />
      </div>
    );
  }

  return (
    <div className="ui one column grid">
      <div className="row">
        <div className="column">
          <table className="ui celled very compact table unstackable">
            <thead>
              <tr>
                <th className="nine wide" style={{ padding: "8px" }}>
                  <div style={{ display: "inline", float: "left" }}>
                    <i className="file icon large" />
                    <h4 className="dashboardHeaderTexts">Files</h4>
                  </div>
                  <div style={{ display: "inline", float: "right" }}>
                    <ProjectFileUpload projectid={projectid} />
                  </div>
                </th>
                <th className="two wide center aligned mobile hidden">Size</th>
                <th className="three wide center aligned mobile hidden">
                  Changed
                </th>
                <th className="two wide center aligned" colSpan="2">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{fileList}</tbody>
          </table>
        </div>
      </div>
      <ErrorMessage
        errorMessages={errorMessages}
        resetMessages={() => dispatch(actions.setProjectFilesErrorMessages([]))}
      />
    </div>
  );
};

export default DashboardFiles;
