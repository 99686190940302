import React from "react";
import { useDispatch } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import history from "../../history";
import * as util from "../../shared/utility";

let CalculationRowForm = (props) => {
  const { handleSubmit, onSubmit, submitting, articles } = props;

  const handleCancelClick = () => {
    history.goBack();
  };

  const dispatch = useDispatch();
  const onSelectWithSearchChange = (e, data) => {
    dispatch(change("calculationRowForm", data.name, data.value));

    if (data.name === "articleReferenceID" && data.value !== 0) {
      var a = articles.filter((article) => {
        return article.articleID === data.value;
      });

      dispatch(change("calculationRowForm", "name", a[0].name));
      dispatch(change("calculationRowForm", "description", a[0].description));
      dispatch(
        change(
          "calculationRowForm",
          "descriptionCustomer",
          a[0].descriptionCustomer
        )
      );
      dispatch(change("calculationRowForm", "price", a[0].price));
      dispatch(change("calculationRowForm", "btwID", a[0].btwID));
      dispatch(change("calculationRowForm", "marge", a[0].marge));
      dispatch(
        change("calculationRowForm", "isVisibleOnOffer", a[0].isVisibleOnOffer)
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="ui form error">
      <Field
        name="articleReferenceID"
        label="Article:"
        component={util.RenderSelectWithSearch}
        handleChange={onSelectWithSearchChange}
      >
        {[
          ...[{ key: 0, value: 0, text: "Select an article..." }],
          ...articles
            .sort((a, b) =>
              a.name.toLowerCase() < b.name.toLowerCase()
                ? -1
                : a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : 0
            )
            .map((x) => {
              return {
                key: x.articleID,
                value: x.articleID,
                text: x.name,
              };
            }),
        ]}
      </Field>
      <Field name="name" component={util.RenderInput} label="Name:" />
      <Field
        name="description"
        component={util.RenderInput}
        label="Description:"
      />
      <Field
        name="descriptionCustomer"
        component={util.RenderInput}
        label="Description Customer:"
      />
      <Field
        name="price"
        component={util.RenderInputDecimal}
        label="Price (€):"
      />
      <Field name="marge" component={util.RenderInputBoolean} label="Marge:" />
      <Field
        name="quantity"
        component={util.RenderInputNumber}
        label="Quantity:"
      />
      <Field name="btwID" label="BTW:" component={util.RenderSelect}>
        <option key={1} value={1}>
          21 procent
        </option>
      </Field>
      <Field
        name="isVisibleOnOffer"
        component={util.RenderInputBoolean}
        label="Visible on offer:"
      />
      <button className="ui button primary" disabled={submitting}>
        Save
      </button>
      <button type="button" className="ui button" onClick={handleCancelClick}>
        Cancel
      </button>
    </form>
  );
};

const validate = (formValues) => {
  const errors = {};

  if (!formValues.articleReferenceID || formValues.articleReferenceID === "0") {
    errors.articleReferenceID = "You need to select an article.";
  }

  if (!formValues.name) {
    errors.name = "You must enter a name.";
  }

  if (!formValues.description) {
    errors.description = "You must enter a description.";
  }

  if (!formValues.descriptionCustomer) {
    errors.descriptionCustomer =
      "You must enter a description for the customer.";
  }

  if (formValues.name && formValues.name.length > 200) {
    errors.name = "The name cannot exceed 200 characters.";
  }

  if (formValues.description && formValues.description.length > 200) {
    errors.description = "The description cannot exceed 200 characters.";
  }

  if (
    formValues.descriptionCustomer &&
    formValues.descriptionCustomer.length > 200
  ) {
    errors.descriptionCustomer =
      "The description customer cannot exceed 200 characters.";
  }

  if (
    formValues.productDescription &&
    formValues.productDescription.length > 2000
  ) {
    errors.productDescription =
      "The product description cannot exceed 2000 characters.";
  }

  if (
    formValues.price === undefined ||
    formValues.price === null ||
    formValues.price === ""
  ) {
    errors.price = "You must enter a price.";
  }

  if (
    formValues.quantity === undefined ||
    formValues.quantity === null ||
    formValues.quantity === ""
  ) {
    errors.quantity = "You must enter a quantity.";
  } else if (formValues.quantity < 0) {
    errors.quantity = "The quantity must be a positive number.";
  }

  return errors;
};

export default reduxForm({
  form: "calculationRowForm",
  validate,
})(CalculationRowForm);
