import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Grid } from "semantic-ui-react";

import * as actions from "../../actions";
import history from "../../history";
import StandardTableHeader from "../UI/StandardHeader";
import PurchaseOrderForm from "./PurchaseOrderForm";
import PurchaseOrderRowOverview from "../purchaseOrderRows/PurchaseOrderRowOverview";

const PurchaseOrderEdit = ({
  match: {
    params: { purchaseorderid },
  },
}) => {
  const dispatch = useDispatch();
  const purchaseOrder = useSelector((state) => state.purchaseOrders.item);
  const articles = useSelector((state) => Object.values(state.articles.items));

  useEffect(() => {
    dispatch(actions.fetchPurchaseOrder(purchaseorderid));
    dispatch(actions.fetchArticles());

    return () => {
      dispatch(actions.unloadPurchaseOrder());
    };
  }, []);

  const onSubmit = (fv) => {
    dispatch(actions.editPurchaseOrder(purchaseorderid, fv));
  };

  if (!purchaseOrder) {
    return null;
  } else {
    const initialVal = {
      ...purchaseOrder,
      orderDate: purchaseOrder.orderDate
        ? moment(purchaseOrder.orderDate).format("YYYY-MM-DD")
        : null,
      plannedDeliverDate: purchaseOrder.plannedDeliverDate
        ? moment(purchaseOrder.plannedDeliverDate).format("YYYY-MM-DD")
        : null,
      deliverDate: purchaseOrder.deliverDate
        ? moment(purchaseOrder.deliverDate).format("YYYY-MM-DD")
        : null,
    };

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <StandardTableHeader headerLabel="Edit Purchase Order"></StandardTableHeader>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <PurchaseOrderForm initialValues={initialVal} onSubmit={onSubmit} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className="stackable">
          <Grid.Column>
            <button
              type="button"
              className="ui button primary"
              onClick={() => dispatch(actions.savePurchaseOrderAndReturn())}
            >
              Save
            </button>
            <button
              type="button"
              className="ui button "
              onClick={() => history.goBack()}
            >
              Cancel
            </button>
          </Grid.Column>
          <Grid.Column>
            <button
              type="button"
              className="ui orange button "
              onClick={() => dispatch(actions.savePurchaseOrderAndStay())}
            >
              Save & Stay
            </button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <PurchaseOrderRowOverview
              articles={articles}
              purchaseOrderID={initialVal.purchaseOrderID}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

export default PurchaseOrderEdit;
