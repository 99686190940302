import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as actions from "../../actions";
import * as util from "../../shared/utility";
import ProjectHeader from "../projectHeader/ProjectHeader";
import DashboardAddress from "../addresses/DashboardAddress";
import DashboardPerson from "../persons/DashboardPerson";
import DashboardProject from "./DashboardProject";

const ProjectInfo = ({
  match: {
    params: { projectid },
  },
}) => {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.projects.projectDashboard);

  useEffect(() => {
    dispatch(actions.fetchProjectDashboard(projectid));

    dispatch(actions.setProjectMode(util.formModes.reading));
    dispatch(actions.setAddressMode(util.formModes.reading));
    dispatch(actions.setPersonMode(util.formModes.reading));
  }, []);

  const ProjectAddressPersonRow = ({ project }) => {
    return (
      <>
        <div className="row stackable two column">
          <div className="column">
            <DashboardAddress
              address={project.projectAddress}
              projectID={project.projectID}
            />
          </div>
          <div className="column">
            <DashboardPerson
              person={project.contactPerson}
              projectID={project.projectID}
            />
          </div>
        </div>
      </>
    );
  };

  if (!project) {
    return null;
  }

  return (
    <div className="ui one column grid">
      <div className="row">
        <div className="column">
          <ProjectHeader project={project} />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <DashboardProject project={project} />
        </div>
      </div>
      <ProjectAddressPersonRow project={project} />
    </div>
  );
};

export default ProjectInfo;
