import React from "react";

import RelationList from "./RelationList";

const Customers = () => {
  return (
    <RelationList
      relationType={"0"}
      newItemUrl={"/sales/customers/new"}
      headerText={"Relations - Customers"}
    />
  );
};

export default Customers;
