import solartoolapi from "../apis/solartoolapi";
import {
  AUTH_REQUEST_START,
  AUTH_REQUEST_SUCCESS,
  AUTH_REQUEST_FAILURE,
  LOGIN,
  LOGOUT,
  CLEAR_AUTH,
  SET_GLOBAL_ERROR_MESSAGE,
} from "./types";

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("user");
  dispatch({ type: LOGOUT });
};

export const authCheckState = () => {
  return (dispatch) => {
    const user = localStorage.getItem("user");
    if (!user) {
      dispatch(logoutUser());
    } else {
      // const expirationDate = new Date(localStorage.getItem('expirationDate'));
      // if (expirationDate <= new Date()) {
      //     dispatch(logout());
      // } else {
      //     const userId = localStorage.getItem('userId');
      //     dispatch(authSuccess(token, userId));
      //     dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
      // }
    }
  };
};

export const loginUser = (email, password) => async (dispatch) => {
  dispatch({ type: AUTH_REQUEST_START });

  await solartoolapi
    .post("/users/authenticate", {
      email: email,
      password: password,
    })
    .then((response) => {
      if (response.data.result != null) {
        const user = response.data.result;
        localStorage.setItem("user", JSON.stringify(user));
        dispatch({ type: AUTH_REQUEST_SUCCESS });
        dispatch({ type: LOGIN });
      }
    })
    .catch((error) => {
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: [] });
      dispatch({
        type: AUTH_REQUEST_FAILURE,
        payload: error.response.data.message,
      });
    });
};

export const resetPassword = (email) => async (dispatch) => {
  dispatch({ type: AUTH_REQUEST_START, payload: email });

  await solartoolapi
    .post("/users/resetpassword", { email: email, password: null })
    .then((response) => {
      dispatch({
        type: AUTH_REQUEST_SUCCESS,
        payload: response.data.message,
      });
    })
    .catch((error) => {
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: [] });
      dispatch({
        type: AUTH_REQUEST_FAILURE,
        payload: error.response.data.message,
      });
    });
};

export const clearAuth = () => async (dispatch) => {
  dispatch({ type: CLEAR_AUTH });
};

export const setPassword = (resettoken, password) => async (dispatch) => {
  dispatch({ type: AUTH_REQUEST_START });

  await solartoolapi
    .post(`/users/setpassword/${resettoken}`, {
      email: null,
      password: password,
    })
    .then((response) => {
      if (response.data.result != null) {
        const user = response.data.result;
        localStorage.setItem("user", JSON.stringify(user));
        dispatch({ type: AUTH_REQUEST_SUCCESS });
        dispatch({ type: LOGIN });
      }
    })
    .catch((error) => {
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: [] });
      dispatch({
        type: AUTH_REQUEST_FAILURE,
        payload: error.response.data.message,
      });
    });
};
