import solartoolapi from "../apis/solartoolapi";
import history from "../history";
import {
  FETCH_CALCULATION_ROW,
  UNLOAD_CALCULATION_ROW,
  UP_CALCULATION_ROW,
  DOWN_CALCULATION_ROW,
} from "./types";
import { authHeader } from "../shared/utility";
import * as actions from "../actions";

export const createCalculationRow =
  (calculationID, formValues) => async (dispatch, getState) => {
    await solartoolapi.post(
      "/calculationrows",
      {
        ...formValues,
        calculationID,
      },
      { headers: authHeader() }
    );

    history.push(`/sales/calculations/detail/${calculationID}`);
  };

export const editCalculationRow = (formValues) => async (dispatch) => {
  const response = await solartoolapi.put(
    `/calculationrows/${formValues.calculationRowID}`,
    formValues,
    {
      headers: authHeader(),
    }
  );

  history.push(
    `/sales/calculations/detail/${response.data.result.calculationID}`
  );
};

export const upCalculationRow =
  (calculationID, calculationRowID) => async (dispatch) => {
    const response = await solartoolapi.put(
      `/calculationrows/up/${calculationRowID}`,
      {},
      {
        headers: authHeader(),
      }
    );

    dispatch({ type: UP_CALCULATION_ROW, payload: response.data.result });
    dispatch(actions.fetchCalculation(calculationID));
  };

export const downCalculationRow =
  (calculationID, calculationRowID) => async (dispatch) => {
    const response = await solartoolapi.put(
      `/calculationrows/down/${calculationRowID}`,
      {},
      {
        headers: authHeader(),
      }
    );

    dispatch({ type: DOWN_CALCULATION_ROW, payload: response.data.result });
    dispatch(actions.fetchCalculation(calculationID));
  };

export const deleteCalculationRow =
  (calculationID, calculationRowID) => async (dispatch) => {
    await solartoolapi.delete(`/calculationrows/${calculationRowID}`, {
      headers: authHeader(),
    });

    dispatch(actions.fetchCalculation(calculationID));
  };

export const fetchCalculationRow = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/calculationrows/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_CALCULATION_ROW, payload: response.data.result });
};

export const unloadCalculationRow = () => (dispatch) => {
  dispatch({ type: UNLOAD_CALCULATION_ROW });
};
