import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import * as actions from "../../actions";

const ProjectBreadcrumb = ({ projectID, currentPath }) => {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.projects.items[projectID]);

  useEffect(() => {
    if (!project) {
      dispatch(actions.fetchProject(projectID));
    }
  }, []);

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <div className="ui one column grid">
      <div className="column">
        <div className="ui breadcrumb">
          <Link to={"/sales/projects"}>Projects</Link>
          <i className="right angle icon divider" />
          <Link to={"/sales/projects/dashboard/" + projectID}>
            <i className="tachometer alternate icon" title="Dashboard" />
            {project.name}
          </Link>
          <i className="right angle icon divider" />
          <div className="active section">{currentPath}</div>
        </div>
      </div>
    </div>
  );
};

export default ProjectBreadcrumb;
