import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Grid } from "semantic-ui-react";

import "./MobileMenu.css";

import MobileMenuItem from "./mobileMenuItem/MobileMenuItem";
import MobileMenuDropdown from "./mobileMenuDropdown/MobileMenuDropdown";

const MobileMenu = ({ menuItems, isAuthenticated, logo }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const headerLogo = (
    <Grid.Column width={8}>
      <Link to="/" id="home" className="HeaderContainer desktopMenuLogo">
        <img className="MobileHeaderImage" src={logo} alt="logo" />
      </Link>
    </Grid.Column>
  );

  const menuButton = (
    <Grid.Column width={8} textAlign="right">
      {isAuthenticated && (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          MENU <i className="bars icon large" />
        </div>
      )}
    </Grid.Column>
  );

  const hiddenMenu = isAuthenticated && showMobileMenu && (
    <Grid.Row>
      <Grid.Column>
        <div className="mobileMenu">
          {menuItems.map((menuItem, index) => {
            if (!menuItem.subMenuItems) {
              return (
                <MobileMenuItem
                  key={index}
                  menuItem={menuItem}
                  setShowMobileMenu={setShowMobileMenu}
                />
              );
            } else {
              return (
                <MobileMenuDropdown
                  key={index}
                  menuItem={menuItem}
                  setShowMobileMenu={setShowMobileMenu}
                />
              );
            }
          })}
        </div>
      </Grid.Column>
    </Grid.Row>
  );

  return (
    <Grid>
      <Grid.Row style={{ marginTop: 10 }}>
        {headerLogo}
        {menuButton}
      </Grid.Row>
      {hiddenMenu}
    </Grid>
  );
};

export default MobileMenu;
