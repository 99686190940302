import { FETCH_TRELLO, UNLOAD_TRELLO, CREATE_TRELLO, CREATE_TRELLO_RESET, CREATE_TRELLO_START } from '../actions/types';

var initialState = {
  item: null,
  responsemessage: '',
  showmessage: false,
  creating: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRELLO:
      return {
        ...state,
        item: action.payload,
        responsemessage: '',
        showmessage: false,
        creating: false
      };
    case UNLOAD_TRELLO:
      return {
        ...state,
        item: null,
        responsemessage: '',
        showmessage: false,
        creating: false
      };
    case CREATE_TRELLO_START:
      return {
        ...state,
        responsemessage: '',
        showmessage: false,
        creating: true
      };
    case CREATE_TRELLO:
      return {
        ...state,
        responsemessage: action.payload,
        showmessage: true,
        creating: false
      };
    case CREATE_TRELLO_RESET:
      return {
        ...state,
        responsemessage: '',
        showmessage: false,
        creating: false
      };
    default:
      return state;
  }
};
