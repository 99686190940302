import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import * as util from "../../../shared/utility";
import * as actions from "../../../actions";

const DashboardOffers = ({ offers, projectid }) => {
  if (!offers || offers.length === 0) {
    return null;
  }

  const dispatch = useDispatch();

  const offerList = offers
    .sort((a, b) => b.lastUpdatedDateTime.localeCompare(a.lastUpdatedDateTime))
    .map((offer) => {
      return (
        <tr key={offer.offerID}>
          <td>
            <Link className="item" to={`/sales/offers/edit/${offer.offerID}`}>
              {offer.name}
            </Link>
          </td>
          <td className="center aligned mobile hidden">
            {util.utcStringToLocalDateTime(offer.lastUpdatedDateTime)}
          </td>
          <td className="center aligned">
            <i
              style={{ cursor: "pointer" }}
              className="file pdf icon orange"
              title="Download file preview."
              onClick={() => dispatch(actions.previewOfferPdf(offer.offerID))}
            />
          </td>
          <td className="center aligned">
            <i
              style={{ cursor: "pointer" }}
              className="file save icon blue"
              title="Create file in project folder."
              onClick={() =>
                dispatch(actions.generateOfferPdf(offer.offerID, projectid))
              }
            />
          </td>
          <td className="center aligned">
            <Link
              className="item"
              to={`/sales/offers/delete/${offer.offerID}`}
              key={offer.offerID}
            >
              <i className="trash icon" title="Delete record." />
            </Link>
          </td>
        </tr>
      );
    });

  return (
    <div className="ui one column grid">
      <div className="row">
        <div className="column">
          <table className="ui celled very compact table unstackable">
            <thead>
              <tr>
                <th className="eleven wide">
                  <i className="shopping basket icon large" />
                  <h4 className="dashboardHeaderTexts">Offers</h4>
                </th>
                <th className="three wide center aligned mobile hidden">
                  Changed
                </th>
                <th className="two wide center aligned" colSpan="3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{offerList}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DashboardOffers;
