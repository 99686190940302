import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import history from "../../history";
import Modal from "../Modal";
import * as actions from "../../actions";

const UserDelete = ({
  match: {
    params: { userid },
  },
}) => {
  const user = useSelector((state) => state.users.items[userid]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchUser(userid));
  }, []);

  let renderContent = "Are you sure you want to delete this user?";
  if (user) {
    renderContent = `Are you sure you want to delete the user, ${user.name}, with email: ${user.email}?`;
  }

  const renderActions = (
    <React.Fragment>
      <button
        onClick={() => dispatch(actions.disableUser(userid))}
        className="ui button negative"
      >
        Delete
      </button>
      <button className="ui button" onClick={() => history.goBack()}>
        Cancel
      </button>
    </React.Fragment>
  );

  return (
    <Modal
      title="Delete User"
      content={renderContent}
      actions={renderActions}
      onDismiss={() => history.goBack()}
    />
  );
};

export default UserDelete;
