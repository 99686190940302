import _ from "lodash";

import {
  FETCH_PROJECT,
  FETCH_PROJECTS,
  CREATE_PROJECT,
  EDIT_PROJECT,
  DELETE_PROJECT,
  SET_PROJECTS_FILTER,
  SET_PROJECTS_OFFSET,
  FETCH_PROJECTS_PAGED,
  SET_PROJECTS_PAGE_FILTER,
  SET_PROJECTS_PAGE_NUMBER,
  FETCH_PROJECT_DASHBOARD,
  UNLOAD_PROJECT_DASHBOARD,
  SET_PROJECT_MODE,
  SET_PROJECTS_VISITOR_FILTER,
  RESET_PROJECTS_FILTER,
} from "../actions/types";
import * as util from "../shared/utility";

var initialState = {
  items: [],
  filteredItems: [],
  projectsFilter: "",
  projectsOffset: 0,
  projectsLimit: 10,
  projectsPageItems: {},
  projectsPageMetadata: {},
  projectsPageNumber: 1,
  projectsPageSize: 10,
  projectsPageFilter: "",
  projectsVisitorFilter: "",
  projectDashboard: null,
  mode: util.formModes.reading,
};

const filterAndSortList = (data, filter) => {
  return data.filter((project) => {
    return (
      project.name.toUpperCase().includes(filter.toUpperCase()) ||
      project.projectStatus.code.toUpperCase().includes(filter.toUpperCase())
    );
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROJECTS:
      return {
        ...state,
        items: { ..._.mapKeys(action.payload, "projectID") },
        filteredItems: {
          ..._.mapKeys(
            filterAndSortList(action.payload, state.projectsFilter),
            "projectID"
          ),
        },
      };
    case FETCH_PROJECT:
      return {
        ...state,
        items: { ...state.items, [action.payload.projectID]: action.payload },
      };
    case CREATE_PROJECT:
      return {
        ...state,
        items: { ...state.items, [action.payload.projectID]: action.payload },
      };
    case EDIT_PROJECT:
      return {
        ...state,
        items: { ...state.items, [action.payload.projectID]: action.payload },
      };
    case DELETE_PROJECT:
      return {
        ...state,
        items: { ..._.omit(state.items, action.payload) },
      };
    case SET_PROJECTS_FILTER:
      return {
        ...state,
        projectsFilter: action.payload,
        filteredItems: {
          ..._.mapKeys(
            filterAndSortList(Object.values(state.items), action.payload),
            "projectID"
          ),
        },
        projectsOffset: 0,
      };
    case SET_PROJECTS_OFFSET:
      return {
        ...state,
        projectsOffset: action.payload,
      };
    case FETCH_PROJECTS_PAGED:
      return {
        ...state,
        projectsPageItems: {
          ..._.mapKeys(action.payload.result, "projectID"),
        },
        projectsPageMetadata: { ...action.payload.metadata },
      };
    case SET_PROJECTS_PAGE_FILTER:
      return {
        ...state,
        projectsPageFilter: action.payload,
        projectsPageNumber: 1,
      };
    case SET_PROJECTS_VISITOR_FILTER:
      return {
        ...state,
        projectsVisitorFilter: action.payload,
        projectsPageNumber: 1,
      };
    case RESET_PROJECTS_FILTER:
      return {
        ...state,
        projectsPageFilter: "",
        projectsVisitorFilter: "",
        projectsPageNumber: 1,
      };
    case SET_PROJECTS_PAGE_NUMBER:
      return {
        ...state,
        projectsPageNumber: action.payload,
      };
    case FETCH_PROJECT_DASHBOARD:
      return {
        ...state,
        projectDashboard: action.payload,
      };
    case UNLOAD_PROJECT_DASHBOARD:
      return { ...state, projectDashboard: null };
    case SET_PROJECT_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    default:
      return state;
  }
};
