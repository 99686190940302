import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from "semantic-ui-react";
import { Link } from "react-router-dom";

import * as util from "../../shared/utility";
import * as actions from "../../actions";
import StandardTableHeader from "../UI/StandardHeader";

import DashboardProgress from "../projectHeader/DashboardProgress";

const ProjectPagedList = () => {
  const dispatch = useDispatch();

  const projectsPageMetadata = useSelector(
    (state) => state.projects.projectsPageMetadata
  );
  const projectsPageItems = useSelector(
    (state) => state.projects.projectsPageItems
  );
  const projectsPageFilter = useSelector(
    (state) => state.projects.projectsPageFilter
  );
  const projectsVisitorFilter = useSelector(
    (state) => state.projects.projectsVisitorFilter
  );
  const projectsPageNumber = useSelector(
    (state) => state.projects.projectsPageNumber
  );

  const [searchValue, setSearchValue] = useState(projectsPageFilter);

  useEffect(() => {
    dispatch(actions.unloadProjectDashboard());
  }, []);

  useEffect(() => {
    dispatch(
      actions.fetchProjectsPaged(
        projectsPageFilter,
        projectsPageNumber,
        projectsVisitorFilter
      )
    );

    // On a reset, make sure the searchvalue is also resetted.
    if (searchValue !== projectsPageFilter) {
      setSearchValue(projectsPageFilter);
    }
  }, [projectsPageFilter, projectsPageNumber, projectsVisitorFilter]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchValue !== projectsPageFilter) {
        dispatch(actions.setProjectsPageFilter(searchValue));
      }
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchValue]);

  const projectlist = Object.values(projectsPageItems)
    .sort((a, b) => a.projectStatusID - b.projectStatusID)
    .sort((a, b) => b.lastUpdatedDateTime.localeCompare(a.lastUpdatedDateTime))
    .map((project) => {
      return (
        <tr key={project.projectID}>
          <td className="mobile hidden">{project.projectID}</td>
          <td>
            <Link
              className="item"
              to={`/sales/projects/dashboard/${project.projectID}`}
            >
              <div className="item" title={project.description}>
                {project.name}
              </div>
            </Link>
          </td>
          <td className="mobile hidden">{project.visitor}</td>
          <td className="mobile hidden">
            <DashboardProgress project={project} showText={false} />
            {/* {util.RenderQuickLink(project)} */}
          </td>
          <td className="mobile hidden">
            {util.utcStringToLocalDateTime(project.lastUpdatedDateTime)}
          </td>
          <td className="center aligned">
            <Link
              className="item"
              to={`/sales/projects/delete/${project.projectID}`}
              key={project.projectID}
            >
              <i className="trash icon" title="Delete record." />
            </Link>
          </td>
        </tr>
      );
    });

  const renderTable = (
    <>
      <table className="ui celled table unstackable">
        <thead>
          <tr>
            <th className="one wide mobile hidden">ID</th>
            <th className="seven wide">Name</th>
            <th className="two wide mobile hidden">Visitor</th>
            <th className="two wide mobile hidden">Status</th>
            <th className="three wide mobile hidden">Changed</th>
            <th className="one wide center aligned ">Action</th>
          </tr>
        </thead>
        <tbody>{projectsPageMetadata.totalCount > 0 && projectlist}</tbody>
      </table>
      {projectsPageMetadata.totalCount > 0 && (
        <Pagination
          activePage={projectsPageMetadata.currentPage}
          totalPages={projectsPageMetadata.totalPages}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          onPageChange={(e, props) =>
            dispatch(actions.setProjectsPageNumber(props.activePage))
          }
        />
      )}
    </>
  );

  const VisitorFilter = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          whiteSpace: "nowrap",
          paddingTop: "10px",
        }}
      >
        <select
          className="ui selection dropdown"
          name="visitorName"
          label="Visitor:"
          onChange={(e) =>
            dispatch(actions.setProjectsVisitorFilter(e.target.value))
          }
          value={projectsVisitorFilter}
        >
          <option key={0} value={""}>
            Select visitor...
          </option>
          {util.VISITORS.map((x) => (
            <option key={x.Key} value={x.Name}>
              {x.Name}
            </option>
          ))}
          <option key={100} value={"NoVisitor"}>
            No visitor
          </option>
        </select>
        <button
          className="ui button labeled icon"
          onClick={() => dispatch(actions.resetProjectsFilter())}
          title="Reset filters."
          style={{
            backgroundColor: "var(--company-bg-color-50)",
            marginLeft: "10px",
          }}
        >
          <i className="undo icon" />
          Reset Filters
        </button>
      </div>
    );
  };

  return (
    <div className="ui one column grid">
      <div className="column">
        <div className="row">
          <StandardTableHeader
            headerLabel="Projects"
            linkForNewButton="/sales/projects/new"
            recordCount={projectsPageMetadata.totalCount}
            searchValue={searchValue}
            searchTooltip={`
You can search on the name 
and on the status.
For example: 'ordered'`}
            searchFunction={(e) => setSearchValue(e.target.value)}
          ></StandardTableHeader>
        </div>
        <div className="row">
          <VisitorFilter />
        </div>
      </div>
      <div className="column">{renderTable}</div>
    </div>
  );
};

export default ProjectPagedList;
