import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { Link } from "react-router-dom";
import * as util from "../../shared/utility";
import { Pagination } from "semantic-ui-react";

import StandardTableHeader from "../UI/StandardHeader";

class MailTemplateList extends React.Component {
  componentDidMount() {
    this.props.fetchMailTemplates();
  }

  handlePageChange(e) {
    const newOffset = (e.activePage - 1) * this.props.limit;
    this.props.setMailTemplatesOffset(newOffset);
  }

  renderTable() {
    const pages = Math.ceil(this.props.mailTemplates.length / this.props.limit);
    const activePage = 1 + this.props.offset / this.props.limit;

    let mailTemplatelist = null;

    if (this.props.isMobile) {
      mailTemplatelist = this.props.mailTemplates
        .sort((a, b) => a.mailTemplateStatusID - b.mailTemplateStatusID)
        .sort((a, b) =>
          b.lastUpdatedDateTime.localeCompare(a.lastUpdatedDateTime)
        )
        .slice(this.props.offset, this.props.offset + this.props.limit)
        .map((mailTemplate) => {
          return (
            <tr key={mailTemplate.mailTemplateID}>
              <td>
                <Link
                  className="item"
                  to={`/application/mailtemplates/edit/${mailTemplate.mailTemplateID}`}
                >
                  <div className="item" title={mailTemplate.description}>
                    {mailTemplate.name}
                  </div>
                </Link>
              </td>
              <td className="center aligned">
                <Link
                  className="item"
                  to={`/application/mailtemplates/delete/${mailTemplate.mailTemplateID}`}
                  key={mailTemplate.mailTemplateID}
                >
                  <i className="trash icon" title="Delete record." />
                </Link>
              </td>
            </tr>
          );
        });
    } else {
      mailTemplatelist = this.props.mailTemplates
        .sort((a, b) => a.mailTemplateStatusID - b.mailTemplateStatusID)
        .sort((a, b) =>
          b.lastUpdatedDateTime.localeCompare(a.lastUpdatedDateTime)
        )
        .slice(this.props.offset, this.props.offset + this.props.limit)
        .map((mailTemplate) => {
          return (
            <tr key={mailTemplate.mailTemplateID}>
              <td>{mailTemplate.mailTemplateID}</td>
              <td>
                <Link
                  className="item"
                  to={`/application/mailtemplates/edit/${mailTemplate.mailTemplateID}`}
                >
                  <div className="item" title={mailTemplate.description}>
                    {mailTemplate.name}
                  </div>
                </Link>
              </td>
              <td>{mailTemplate.subject}</td>
              <td className="center aligned">
                {util.utcStringToLocalDateTime(
                  mailTemplate.lastUpdatedDateTime
                )}
              </td>
              <td className="center aligned">
                <Link
                  className="item"
                  to={`/application/mailtemplates/delete/${mailTemplate.mailTemplateID}`}
                  key={mailTemplate.mailTemplateID}
                >
                  <i className="trash icon" title="Delete record." />
                </Link>
              </td>
            </tr>
          );
        });
    }

    if (this.props.isMobile) {
      return (
        <div>
          <table className="ui celled table unstackable">
            <thead>
              <tr>
                <th className="fifteen wide">Name</th>
                <th className="one wide center aligned" colSpan="1">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{mailTemplatelist}</tbody>
          </table>
          <Pagination
            activePage={activePage}
            totalPages={pages}
            onPageChange={(e, props, offset) => this.handlePageChange(props)}
          />
        </div>
      );
    } else {
      return (
        <div>
          <table className="ui celled table">
            <thead>
              <tr>
                <th className="one wide">ID</th>
                <th className="five wide">Name</th>
                <th className="six wide">Subject</th>
                <th className="three wide center aligned">Changed</th>
                <th className="one wide center aligned" colSpan="1">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{mailTemplatelist}</tbody>
          </table>
          <Pagination
            activePage={activePage}
            totalPages={pages}
            onPageChange={(e, props, offset) => this.handlePageChange(props)}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="ui one column grid">
        <div className="column">
          <StandardTableHeader
            headerLabel="Mail templates"
            linkForNewButton="/application/mailtemplates/new"
            recordCount={this.props.mailTemplates.length}
            searchValue={this.props.mailTemplatesFilter}
            searchFunction={(e) =>
              this.props.setMailTemplatesFilter(e.target.value)
            }
          />
        </div>
        <div className="column">{this.renderTable()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    mailTemplates: Object.values(state.mailTemplates.filteredItems),
    mailTemplatesFilter: state.mailTemplates.mailTemplatesFilter,
    offset: state.mailTemplates.mailTemplatesOffset,
    limit: state.mailTemplates.mailTemplatesLimit,
    isMobile: state.utility.isMobile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMailTemplates: () => dispatch(actions.fetchMailTemplates()),
    setMailTemplatesFilter: (text) =>
      dispatch(actions.setMailTemplatesFilter(text)),
    setMailTemplatesOffset: (offset) =>
      dispatch(actions.setMailTemplatesOffset(offset)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailTemplateList);
