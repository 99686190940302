import { submit } from "redux-form";

import solartoolapi from "../apis/solartoolapi";
import {
  FETCH_PURCHASE_ORDER,
  FETCH_PURCHASE_ORDERS_PAGED,
  SET_PURCHASE_ORDERS_PAGE_FILTER,
  RESET_PURCHASE_ORDERS_FILTER,
  SET_PURCHASE_ORDERS_PAGE_NUMBER,
  UNLOAD_PURCHASE_ORDER,
  TOGGLE_PURHCASE_ORDERS_SHOW_ORDERED,
  TOGGLE_PURHCASE_ORDERS_SHOW_PLANNED,
  TOGGLE_PURHCASE_ORDERS_SHOW_DELIVERED,
  SET_PURCHASE_ORDER_RETURN_ON_SAVE,
} from "./types";
import { authHeader } from "../shared/utility";
import history from "../history";

export const fetchPurchaseOrder = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/purchaseorders/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_PURCHASE_ORDER, payload: response.data.result });
};

export const fetchPurchaseOrdersPaged =
  (pageFilter, pageNumber, showDelivered, showPlanned, showOrdered) =>
  async (dispatch) => {
    const response = await solartoolapi.get(
      `/purchaseorders/paged?pageNumber=${pageNumber}&pageFilter=${pageFilter}&showOrdered=${showOrdered}&showPlanned=${showPlanned}&showDelivered=${showDelivered}`,
      {
        headers: authHeader(),
      }
    );
    dispatch({
      type: FETCH_PURCHASE_ORDERS_PAGED,
      payload: response.data,
    });
  };

export const createPurchaseOrder =
  (formValues) => async (dispatch, getState) => {
    const response = await solartoolapi.post(
      "/purchaseorders",
      {
        ...formValues,
      },
      { headers: authHeader() }
    );

    const { purchaseOrderReturnOnSave } = getState().purchaseOrders;

    if (purchaseOrderReturnOnSave) {
      // history.push(`/purchasing/purchaseorders`);
      history.goBack();
    } else {
      history.push(
        `/purchasing/purchaseorders/edit/${response.data.result.purchaseOrderID}`
      );
    }
  };

export const editPurchaseOrder =
  (id, formValues) => async (dispatch, getState) => {
    const response = await solartoolapi.put(
      `/purchaseorders/${id}`,
      formValues,
      {
        headers: authHeader(),
      }
    );
    const { purchaseOrderReturnOnSave } = getState().purchaseOrders;

    if (purchaseOrderReturnOnSave) {
      history.push(`/purchasing/purchaseorders`);
      // history.goBack();
    } else {
      history.push(
        `/purchasing/purchaseorders/edit/${response.data.result.purchaseOrderID}`
      );
    }
  };

export const deletePurchaseOrder = (id) => async (dispatch) => {
  await solartoolapi.delete(`/purchaseorders/${id}`, {
    headers: authHeader(),
  });

  history.push(`/purchasing/purchaseorders`);
};

export const copyPurchaseOrder = (id) => async (dispatch, getState) => {
  const response = await solartoolapi.post(
    `/purchaseorders/copy/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );

  history.push(
    `/purchasing/purchaseorders/edit/${response.data.result.purchaseOrderID}`
  );

  // const { pageFilter, showDelivered, showPlanned, showOrdered } =
  //   getState().purchaseOrders;

  // dispatch(
  //   fetchPurchaseOrdersPaged(
  //     pageFilter,
  //     1,
  //     showDelivered,
  //     showPlanned,
  //     showOrdered
  //   )
  // );
};

export const setPurchaseOrdersPageFilter = (pageFilter) => (dispatch) => {
  dispatch({ type: SET_PURCHASE_ORDERS_PAGE_FILTER, payload: pageFilter });
};

export const resetPurchaseOrdersFilter = () => (dispatch) => {
  dispatch({ type: RESET_PURCHASE_ORDERS_FILTER });
};

export const setPurchaseOrdersPageNumber = (pageNumber) => (dispatch) => {
  dispatch({ type: SET_PURCHASE_ORDERS_PAGE_NUMBER, payload: pageNumber });
};

export const unloadPurchaseOrder = () => (dispatch) => {
  dispatch({ type: UNLOAD_PURCHASE_ORDER });
};

export const togglePurchaseOrdersShowOrdered = () => (dispatch) => {
  dispatch({ type: TOGGLE_PURHCASE_ORDERS_SHOW_ORDERED });
};

export const togglePurchaseOrdersShowPlanned = () => (dispatch) => {
  dispatch({ type: TOGGLE_PURHCASE_ORDERS_SHOW_PLANNED });
};

export const togglePurchaseOrdersShowDelivered = () => (dispatch) => {
  dispatch({ type: TOGGLE_PURHCASE_ORDERS_SHOW_DELIVERED });
};

export const savePurchaseOrderAndReturn = () => async (dispatch) => {
  Promise.resolve(
    dispatch({ type: SET_PURCHASE_ORDER_RETURN_ON_SAVE, payload: true })
  ).then(() => {
    dispatch(submit("purchaseOrderForm"));
  });
};

export const savePurchaseOrderAndStay = () => async (dispatch) => {
  Promise.resolve(
    dispatch({ type: SET_PURCHASE_ORDER_RETURN_ON_SAVE, payload: false })
  ).then(() => {
    dispatch(submit("purchaseOrderForm"));
  });
};

export const createPurchaseOrderFromIds =
  (articleIDs, diff, orderDate, plannedDeliverDate, deliverDate, relationID) =>
  async (dispatch) => {
    const body = {
      articleIDs,
      diff,
      orderDate,
      plannedDeliverDate,
      deliverDate,
      relationID,
    };

    const response = await solartoolapi.post(
      `/purchaseorders/createfromids`,
      body,
      {
        headers: authHeader(),
      }
    );

    history.push(
      `/purchasing/purchaseorders/edit/${response.data.result.purchaseOrderID}`
    );
  };
