import {
  FETCH_CALCULATION_ROW,
  UNLOAD_CALCULATION_ROW,
} from "../actions/types";

var initialState = {
  item: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CALCULATION_ROW:
      return {
        ...state,
        item: action.payload,
      };
    case UNLOAD_CALCULATION_ROW:
      return {
        item: null,
      };
    default:
      return state;
  }
};
