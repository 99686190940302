import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import ArticleForm from "./ArticleForm";
import StandardTableHeader from "../UI/StandardHeader";

class ArticleCreate extends React.Component {
  componentDidMount() {
    this.props.fetchArticles();
    if (this.props.categories.length === 0) {
      this.props.fetchCategories();
    }
  }

  onSubmit = (formValues) => {
    this.props.createArticle(formValues);
  };

  render() {
    const initialVal = {
      btwID: 1,
      priority: 100,
      reorderPoint: 0,
      marge: true,
      isVisibleOnOffer: true,
      orderQuantity: 1,
    };

    return (
      <div className="ui one column grid">
        <div className="column">
          <StandardTableHeader headerLabel="Create Article"></StandardTableHeader>
        </div>
        <div className="column">
          <ArticleForm
            initialValues={initialVal}
            categories={this.props.categories}
            validArticles={this.props.articles}
            onSubmit={this.onSubmit}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    categories: state.categories.items,
    articles: Object.values(state.articles.items),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createArticle: (formValues) => dispatch(actions.createArticle(formValues)),
    fetchCategories: () => dispatch(actions.fetchCategories()),
    fetchArticles: () => dispatch(actions.fetchArticles()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleCreate);
