const validate = (formValues) => {
  const errors = {};

  if (!formValues.yearlyConsumption) {
    errors.yearlyConsumption = "You must enter a yearly consumption.";
  }

  if (!formValues.desiredYield) {
    errors.desiredYield = "You must enter the desired yield.";
  }

  if (!formValues.inverterArticleID || formValues.inverterArticleID === "0") {
    errors.inverterArticleID = "You need to select an inverter.";
  }

  if (!formValues.isInfoProvidedLaw) {
    errors.isInfoProvidedLaw = "Informeer over de salderingswet.";
  }

  if (
    formValues.metersCableUnderground &&
    (formValues.metersCableUnderground < 0 ||
      formValues.metersCableUnderground > 10000)
  ) {
    errors.metersCableUnderground =
      "Meters cable underground must be a value between 0 and 10000.";
  }

  if (formValues.remarks && formValues.remarks.length > 500) {
    errors.remarks = "The remarks cannot exceed 500 characters.";
  }

  if (formValues.cablePlan && formValues.cablePlan.length > 2000) {
    errors.cablePlan = "The cablePlan cannot exceed 2000 characters.";
  }

  if (formValues.pavementType && formValues.pavementType.length > 100) {
    errors.pavementType = "The pavementType cannot exceed 100 characters.";
  }

  if (formValues.locationInverter && formValues.locationInverter.length > 200) {
    errors.locationInverter =
      "The locationInverter cannot exceed 200 characters.";
  }

  if (formValues.stringPlan && formValues.stringPlan.length > 200) {
    errors.stringPlan = "The stringPlan cannot exceed 200 characters.";
  }

  if (
    formValues.customerType === 1 ||
    formValues.customerType === "1" ||
    formValues.customerType === 2 ||
    formValues.customerType === "2"
  ) {
    if (
      !(
        formValues.isPowerReturningAllowed === "true" ||
        formValues.isPowerReturningAllowed === true ||
        formValues.isPowerReturningAllowed === "false" ||
        formValues.isPowerReturningAllowed === false
      )
    ) {
      errors.isPowerReturningAllowed = "You need to select true or false.";
    }

    if (
      !(
        formValues.isScope12Required === "true" ||
        formValues.isScope12Required === true ||
        formValues.isScope12Required === "false" ||
        formValues.isScope12Required === false
      )
    ) {
      errors.isScope12Required = "You need to select true or false.";
    }
  }

  if (
    formValues.cableDuct &&
    (formValues.cableDuct < 0 || formValues.cableDuct > 10000)
  ) {
    errors.cableDuct = "Cable duct must be a value between 0 and 10000.";
  }

  return errors;
};
export default validate;
