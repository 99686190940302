import {
  FETCH_CALCULATION_FOR_PROJECT,
  FETCH_CALCULATION,
  CREATE_CALCULATION,
  DELETE_CALCULATION,
  COPY_CALCULATION,
  FETCH_CALCULATION_PROJECTID,
  UNLOAD_CALCULATION,
  UNLOAD_CALCULATIONS,
  SET_CALCULATION_ERROR,
} from "../actions/types";

var initialState = {
  items: [],
  item: null,
  projectID: null,
  calculationError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CALCULATION:
      return {
        ...state,
        item: action.payload,
      };
    case CREATE_CALCULATION:
      return {
        ...state,
        item: action.payload,
      };
    case DELETE_CALCULATION:
      return {
        ...state,
        item: null,
      };
    case FETCH_CALCULATION_FOR_PROJECT:
      return {
        ...state,
        items: action.payload,
      };
    case COPY_CALCULATION:
      return {
        ...state,
        item: action.payload,
      };
    case FETCH_CALCULATION_PROJECTID:
      return {
        ...state,
        projectID: action.payload,
      };
    case UNLOAD_CALCULATION:
      return {
        ...state,
        item: null,
        projectID: null,
      };
    case UNLOAD_CALCULATIONS:
      return {
        items: [],
        item: null,
        projectID: null,
      };
    case SET_CALCULATION_ERROR:
      return {
        ...state,
        calculationError: action.payload,
      };
    default:
      return state;
  }
};
