import {
  FETCH_PERSON,
  CREATE_PERSON,
  EDIT_PERSON,
  DELETE_PERSON,
  UNLOAD_PERSON_COMPONENT,
  SET_PERSON_MODE
} from '../actions/types';
import * as util from '../shared/utility';

const initialState = {
  person: null,
  mode: util.formModes.reading
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PERSON:
      return { ...state, person: action.payload };
    case CREATE_PERSON:
      return {
        ...state,
        person: action.payload,
        mode: util.formModes.reading
      };
    case EDIT_PERSON:
      return {
        ...state,
        person: action.payload
      };
    case DELETE_PERSON:
      return {
        ...state,
        person: null
      };
    case UNLOAD_PERSON_COMPONENT:
      return {
        ...state,
        person: null,
        mode: util.formModes.reading
      };
    case SET_PERSON_MODE:
      return {
        ...state,
        mode: action.payload
      };
    default:
      return state;
  }
};
