import {
  FETCH_ADDRESS,
  CREATE_ADDRESS,
  EDIT_ADDRESS,
  DELETE_ADDRESS,
  UNLOAD_ADDRESS_COMPONENT,
  SET_ADDRESS_MODE
} from '../actions/types';
import * as util from '../shared/utility';

const initialState = {
  address: null,
  mode: util.formModes.reading
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADDRESS:
      return { ...state, address: action.payload };
    case CREATE_ADDRESS:
      return {
        ...state,
        address: action.payload,
        mode: util.formModes.reading
      };
    case EDIT_ADDRESS:
      return {
        ...state,
        address: action.payload
      };
    case DELETE_ADDRESS:
      return {
        ...state,
        address: null
      };
    case UNLOAD_ADDRESS_COMPONENT:
      return {
        ...state,
        address: null,
        mode: util.formModes.reading
      };
    case SET_ADDRESS_MODE:
      return {
        ...state,
        mode: action.payload
      };
    default:
      return state;
  }
};
