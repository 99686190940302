import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as actions from "../../actions";
import StandardTableHeader from "../UI/StandardHeader";
import UserForm from "./UserForm";

const UserEdit = ({
  match: {
    params: { userid },
  },
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.items[userid]);

  useEffect(() => {
    dispatch(actions.fetchUser(userid));
  }, []);

  const onSubmit = (fv) => {
    dispatch(actions.editUser(userid, fv));
  };

  if (!user) {
    return <div>Loading...</div>;
  } else {
    const initialVal = {
      name: user.name,
      email: user.email,
    };

    return (
      <div className="ui one column grid">
        <div className="column">
          <StandardTableHeader headerLabel="Edit User"></StandardTableHeader>
        </div>
        <div className="column">
          <UserForm initialValues={initialVal} onSubmit={onSubmit} />
        </div>
      </div>
    );
  }
};

export default UserEdit;
