import React from "react";
//import { connect } from 'react-redux';

import Aux from "../_Aux/_Aux";
import classes from "./Layout.css";
import Header from "../../components/header/Header";
import Updater from "../updater/Updater";
import StickyMessage from "../../components/stickyMessage/StickyMessage";

class Layout extends React.Component {
  render() {
    return (
      <Aux>
        <Updater />
        <Header />
        <StickyMessage />
        <main className={classes.Content}>{this.props.children}</main>
      </Aux>
    );
  }
}

export default Layout;
