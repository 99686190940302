//import _ from 'lodash';
import React from "react";
import { connect } from "react-redux";

import * as actions from "../../actions";
import ArticleForm from "./ArticleForm";
import StandardTableHeader from "../UI/StandardHeader";

class ArticleEdit extends React.Component {
  componentDidMount() {
    this.props.fetchArticles();
    if (this.props.categories.length === 0) {
      this.props.fetchCategories();
    }
  }

  onSubmit = (fv) => {
    this.props.editArticle(this.props.match.params.articleid, fv);
  };

  render() {
    if (!this.props.article || !this.props.categories) {
      return <div>Loading...</div>;
    }

    var ValidArticles = this.props.articles.filter((article) => {
      return article.articleID !== parseInt(this.props.match.params.articleid);
    });

    return (
      <div className="ui one column grid">
        <div className="column">
          <StandardTableHeader headerLabel="Edit Article"></StandardTableHeader>
        </div>
        <div className="column">
          <ArticleForm
            initialValues={this.props.article}
            categories={this.props.categories}
            validArticles={ValidArticles}
            onSubmit={this.onSubmit}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    article: state.articles.items[ownProps.match.params.articleid],
    categories: state.categories.items,
    articles: Object.values(state.articles.items),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editArticle: (id, formValues) =>
      dispatch(actions.editArticle(id, formValues)),
    fetchCategories: () => dispatch(actions.fetchCategories()),
    fetchArticles: () => dispatch(actions.fetchArticles()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleEdit);
