import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Segment } from "semantic-ui-react";

import * as util from "../../shared/utility";
import * as actions from "../../actions";

import PersonDetail from "./person/PersonDetail";
import PersonCreate from "./person/PersonCreate";
import PersonEdit from "./person/PersonEdit";
import PersonDelete from "./person/PersonDelete";
import PersonNew from "./person/PersonNew";

const DashboardPerson = ({ person, projectID }) => {
  const formMode = useSelector((state) => state.persons.mode);
  const dispatch = useDispatch();

  if (!person) {
    if (formMode === util.formModes.creating) {
      return (
        <PersonCreate
          projectID={projectID}
          onCancel={() =>
            dispatch(actions.setPersonMode(util.formModes.reading))
          }
        />
      );
    } else {
      return (
        <PersonNew
          onClick={() =>
            dispatch(actions.setPersonMode(util.formModes.creating))
          }
        />
      );
    }
  } else {
    if (person && formMode === util.formModes.reading) {
      return (
        <PersonDetail
          person={person}
          onEdit={() =>
            dispatch(actions.setPersonMode(util.formModes.updating))
          }
          onDelete={() =>
            dispatch(actions.setPersonMode(util.formModes.deleting))
          }
        />
      );
    } else if (person && formMode === util.formModes.updating) {
      return (
        <PersonEdit
          projectID={projectID}
          person={person}
          onCancel={() =>
            dispatch(actions.setPersonMode(util.formModes.reading))
          }
        />
      );
    } else if (person && formMode === util.formModes.deleting) {
      return (
        <PersonDelete
          projectID={projectID}
          onCancel={() =>
            dispatch(actions.setPersonMode(util.formModes.reading))
          }
          refreshProject={() => this.props.refreshProject()}
        />
      );
    } else {
      return <Segment loading />;
    }
  }
};

export default DashboardPerson;
