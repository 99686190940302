import solartoolapi from "../apis/solartoolapi";
//import history from '../history';
import {
  CREATE_TRELLO,
  FETCH_TRELLO,
  UNLOAD_TRELLO,
  CREATE_TRELLO_RESET,
  CREATE_TRELLO_START,
} from "./types";
import { authHeader } from "../shared/utility";
import * as actions from ".";

export const createTrello = (formValues) => async (dispatch, getState) => {
  dispatch({ type: CREATE_TRELLO_START });

  const response = await solartoolapi.post(
    "/trellos",
    {
      ...formValues,
    },
    { headers: authHeader() }
  );

  dispatch({ type: CREATE_TRELLO, payload: response.data.message });
};

export const fetchTrello = (orderid) => async (dispatch) => {
  const response = await solartoolapi.get(`/trellos/${orderid}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_TRELLO, payload: response.data.result });
  dispatch(actions.fetchOrderProjectID(orderid));
  dispatch(actions.fetchProjectFiles(response.data.result.projectID));
};

export const createTrelloDirect = (orderid) => async (dispatch) => {
  dispatch({ type: CREATE_TRELLO_START });
  const response = await solartoolapi.get(`/trellos/direct/${orderid}`, {
    headers: authHeader(),
  });

  dispatch({ type: CREATE_TRELLO, payload: response.data.message });
};

export const unloadTrello = () => (dispatch) => {
  dispatch({ type: UNLOAD_TRELLO });
};

export const resetTrelloMessage = () => (dispatch) => {
  dispatch({ type: CREATE_TRELLO_RESET });
};
