import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "../../components/Modal";

import * as util from "../../shared/utility";
import * as actions from "../../actions";

import "./OrderPagedList.css";

const OrderDeliverDateModal = ({
  order: { orderID, deliverDate, name, visitorName, orderDate },
  resetOrderModalValues,
}) => {
  const dispatch = useDispatch();

  const [newDeliverDate, setNewDeliverDate] = useState(
    util.sqlDateToInputDate(deliverDate)
  );

  const [errorMessage, setErrorMessage] = useState("");

  const renderActions = (
    <React.Fragment>
      <button onClick={() => saveDate()} className="ui button positive">
        Save Date
      </button>
      <button className="ui button" onClick={() => resetOrderModalValues()}>
        Cancel
      </button>
    </React.Fragment>
  );

  const saveDate = () => {
    if (
      newDeliverDate !== null &&
      newDeliverDate !== "" &&
      !util.isValidDate(newDeliverDate)
    ) {
      setErrorMessage(`${util.utcStringToLocalDate(newDeliverDate)}`);
    } else {
      const oldDeliverDate = util.sqlDateToInputDate(deliverDate);
      if (
        (newDeliverDate !== null && newDeliverDate !== oldDeliverDate) ||
        (newDeliverDate === null &&
          oldDeliverDate !== null &&
          oldDeliverDate !== "")
      ) {
        dispatch(actions.updateOrderDeliverDate(orderID, newDeliverDate));
      }
      resetOrderModalValues();
    }
  };

  const renderContent = orderID ? (
    <>
      <div className="ui form error">
        <div className="field">
          <label>Name</label>
          {name}
        </div>
        <div className="field">
          <label>Order Date</label>
          {util.utcStringToLocalDate(orderDate)}
        </div>
        <div className="field">
          <label>Visitor</label>
          {visitorName}
        </div>
        {deliverDate && (
          <div className="field">
            <label>Old Deliver Date</label>
            {util.utcStringToLocalDate(deliverDate)}
          </div>
        )}
        <br />
        <div className="field">
          <label>Set Deliver Date</label>
          <input
            type="date"
            value={newDeliverDate}
            autoComplete="off"
            min="2000-01-01"
            max="2100-01-01"
            onChange={(e) => setNewDeliverDate(e.target.value)}
          />
        </div>
        {(() => {
          if (errorMessage !== "") {
            return (
              <div className="ui negative message">
                <i className="close icon" onClick={() => setErrorMessage("")} />
                <div className="header">This is not a valid date:</div>
                <p>{errorMessage}</p>
              </div>
            );
          }
        })()}
      </div>
    </>
  ) : null;

  return (
    <Modal
      className="OrderDeliverDateModal"
      title={`Select deliver date for order ${orderID}.`}
      content={renderContent}
      actions={renderActions}
      onDismiss={() => resetOrderModalValues()}
    />
  );
};

export default OrderDeliverDateModal;
