import React from "react";

import "./DashboardProgress.css";

const DashboardProgress = ({ project, showText = true }) => {
  if (!project) {
    return null;
  }

  const dashBoardProgressItems = [
    {
      name: "Visited",
      activated: !!project.visit || project.projectStatusID > 1,
      icon: "home",
    },
    {
      name: "Calculated",
      activated:
        (project.calculations && project.calculations.length > 0) ||
        project.projectStatusID > 2,
      icon: "calculator",
    },
    {
      name: "Offered",
      activated:
        (project.offers && project.offers.length > 0) ||
        project.projectStatusID > 3,
      icon: "shopping basket",
    },
    {
      name: "Ordered",
      activated:
        (project.orders && project.orders.length > 0) ||
        project.projectStatusID > 4,
      icon: "handshake",
    },
    {
      name: "Invoiced",
      activated:
        (project.invoices && project.invoices.length > 0) ||
        project.projectStatusID > 5,
      icon: "credit card",
    },
  ];

  const dashBoardProgressList = dashBoardProgressItems.map(
    (progressItem, index) => {
      return (
        <div
          key={index}
          className={`dashboardProgressItem ${
            progressItem.activated ? "dashboardProgressItemActivated" : ""
          }`}
          title={progressItem.name}
          style={
            progressItem.activated
              ? null
              : {
                  color: "rgba(0, 0, 0, 0.2)",
                }
          }
        >
          <i className={`${progressItem.icon} icon`}></i>
          {showText && <div className="mobile hidden">{progressItem.name}</div>}
        </div>
      );
    }
  );

  return (
    <div className="column" style={{ paddingTop: 0, paddingBottom: 0 }}>
      <div
        className={`dashboardProgress ${
          project.projectStatusID > 5 ? "dashboardProgressSuccess" : null
        }`}
      >
        {dashBoardProgressList}
      </div>
    </div>
  );
};

export default DashboardProgress;
