import _ from "lodash";
import {
  FETCH_USER,
  FETCH_USERS,
  CREATE_USER,
  EDIT_USER,
  DELETE_USER,
  SET_USER_ERROR,
} from "../actions/types";

var initialState = {
  items: {},
  userError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        items: { ...state.items, [action.payload.userID]: action.payload },
      };
    case FETCH_USERS:
      return {
        ...state,
        items: { ..._.mapKeys(action.payload, "userID") },
      };
    case CREATE_USER:
      return {
        ...state,
        items: { ...state.items, [action.payload.userID]: action.payload },
      };
    case EDIT_USER:
      return {
        ...state,
        items: { ...state.items, [action.payload.userID]: action.payload },
      };
    case DELETE_USER:
      return {
        ...state,
        items: { ..._.omit(state.items, action.payload) },
      };
    case SET_USER_ERROR:
      return {
        ...state,
        userError: action.payload,
      };
    default:
      return state;
  }
};
