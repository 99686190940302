import React from "react";
import { Field, reduxForm } from "redux-form";
import history from "../../history";
import * as util from "../../shared/utility";

const RelationForm = (props) => {
  const { handleSubmit, onSubmit, submitting } = props;

  const handleCancelClick = () => {
    history.goBack();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="ui form error">
      <Field name="name" component={util.RenderInput} label="Name:" />
      <Field
        name="relationType"
        component={util.RenderSelect}
        label="Relation type:"
      >
        <option key={0} value={0}>
          Customer
        </option>
        <option key={1} value={1}>
          Supplier
        </option>
        <option key={2} value={2}>
          Both
        </option>
      </Field>
      <button className="ui button primary" disabled={submitting}>
        Save
      </button>
      <button type="button" className="ui button" onClick={handleCancelClick}>
        Cancel
      </button>
    </form>
  );
};

const validate = (formValues) => {
  const errors = {};

  if (!formValues.name) {
    errors.name = "You must enter a valid name.";
  }

  return errors;
};

export default reduxForm({
  form: "relationForm",
  validate,
})(RelationForm);
