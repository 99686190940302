import React from "react";
import { Field, reduxForm, change } from "redux-form";
import * as util from "../../../shared/utility";

class AddressForm extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  onSelectWithSearchChange = (e, data) => {
    this.props.dispatch(change("addressForm", data.name, data.value));
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <Field name="street" component={util.RenderInput} label="Street:" />
        <Field
          name="number"
          component={util.RenderInputNumber}
          label="Number:"
        />
        <Field
          name="numberAddition"
          component={util.RenderInput}
          label="Number Addition:"
        />
        <Field name="city" component={util.RenderInput} label="City:" />
        <Field name="zipCode" component={util.RenderInput} label="Zipcode:" />
        <Field
          name="countryID"
          label="Countries"
          component={util.RenderSelectWithSearch}
          handleChange={this.onSelectWithSearchChange}
          disabled={false}
        >
          {this.props.countries
            .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
            .map((country) => {
              return {
                key: country.countryID,
                value: country.countryID,
                text: country.name,
              };
            })}
        </Field>

        <button className="ui button primary" disabled={this.props.submitting}>
          Save
        </button>
        <button
          type="button"
          className="ui button"
          onClick={this.props.onCancel}
        >
          Cancel
        </button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.street) {
    errors.street = "You must enter a street";
  }

  if (!formValues.city) {
    errors.city = "You must enter a city";
  }

  if (formValues.street && formValues.street.length > 100) {
    errors.street = "The street cannot exceed 100 characters.";
  }

  if (formValues.number && formValues.number > 1000000) {
    errors.number = "The number must be between 0 and 1000000.";
  }

  if (formValues.numberAddition && formValues.numberAddition.length > 10) {
    errors.numberAddition = "The number addition cannot exceed 10 characters.";
  }

  if (formValues.city && formValues.city.length > 50) {
    errors.city = "The city cannot exceed 50 characters.";
  }

  if (formValues.zipCode && formValues.zipCode.length > 20) {
    errors.zipCode = "The zipcode cannot exceed 20 characters.";
  }

  return errors;
};

export default reduxForm({
  form: "addressForm",
  validate,
})(AddressForm);
