import React from "react";
import { useDispatch } from "react-redux";
import { Segment } from "semantic-ui-react";

import PersonForm from "./PersonForm";
import * as actions from "../../../actions";

const PersonEdit = ({ person, projectID, onCancel }) => {
  if (!person) {
    return null;
  }

  const dispatch = useDispatch();

  const onSubmit = (formValues) => {
    dispatch(
      actions.editPersonAndUpdateProject(person.personID, projectID, formValues)
    );
  };

  return (
    <Segment>
      <h3>Edit Contact</h3>
      <PersonForm
        initialValues={person}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Segment>
  );
};

export default PersonEdit;
