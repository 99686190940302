import solartoolapi from '../apis/solartoolapi';
import { FETCH_CATEGORIES } from './types';
import { authHeader } from '../shared/utility';

export const fetchCategories = () => async dispatch => {
  const response = await solartoolapi.get('/categories', {
    headers: authHeader()
  });

  dispatch({ type: FETCH_CATEGORIES, payload: response.data.result });
};
