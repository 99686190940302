import React from "react";
import { Field, reduxForm } from "redux-form";
// import history from "../../history";
import * as util from "../../shared/utility";

class ProjectForm extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <Field name="name" component={util.RenderInput} label="Name:" />
        <Field
          name="description"
          component={util.RenderInput}
          label="Description:"
        />
        <Field
          name="remarks"
          component={util.RenderInputMultiline}
          label="Remarks:"
        />

        <button className="ui button primary" disabled={this.props.submitting}>
          Save
        </button>
        <button
          type="button"
          className="ui button"
          onClick={this.props.onCancel}
        >
          Cancel
        </button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.name) {
    errors.name = "You must enter a name for the project.";
  }

  if (!formValues.description) {
    errors.description = "You must enter a description";
  }

  if (formValues.name && formValues.name.length > 200) {
    errors.name = "The name cannot exceed 200 characters.";
  }

  if (formValues.description && formValues.description.length > 200) {
    errors.description = "The description cannot exceed 200 characters.";
  }

  return errors;
};

export default reduxForm({
  form: "projectForm",
  validate,
})(ProjectForm);
