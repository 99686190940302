import React from "react";
import { useDispatch, connect } from "react-redux";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { Grid } from "semantic-ui-react";

import * as util from "../../shared/utility";

let PurchaseOrderRowForm = ({
  onSubmit,
  handleSubmit,
  articles,
  selectedArticleReferenceID,
  submitting,
  handleCancelClick,
}) => {
  const dispatch = useDispatch();

  const onSelectWithSearchChange = (e, data) => {
    dispatch(change("purchaseOrderRowForm", data.name, data.value));

    // var price =
    //   data.value &&
    //   articles.find((x) => x.articleID === data.value) &&
    //   articles.find((x) => x.articleID === data.value).price;

    // if (price) {
    //   dispatch(change("purchaseOrderRowForm", "price", price));
    //   dispatch(change("purchaseOrderRowForm", "quantity", 1));
    // }

    // console.log(price);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="ui form error">
      <Grid stackable>
        <Grid.Row columns={3}>
          {/* <Grid.Column className="one wide"></Grid.Column> */}
          <Grid.Column className="nine wide">
            <Field
              name="articleReferenceID"
              // label="Article:"
              focus={true}
              component={util.RenderSelectWithSearch}
              handleChange={onSelectWithSearchChange}
            >
              {[
                ...[{ key: 0, value: 0, text: "Select an article..." }],
                ...articles
                  // .filter((article) => {
                  //   return article.categoryID === 1;
                  // })
                  .sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase()
                      ? -1
                      : a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : 0
                  )
                  .map((x) => {
                    return {
                      key: x.articleID,
                      value: x.articleID,
                      text: x.name + "-" + x.articleID,
                    };
                  }),
              ]}
            </Field>
          </Grid.Column>
          <Grid.Column className="two wide right aligned">
            <Field
              name="quantity"
              component={util.RenderInputNumber}
              // label="Quantity:"
            />
          </Grid.Column>
          <Grid.Column className="two wide right aligned">
            <Field
              name="price"
              component={util.RenderInputDecimal}
              // label="Price (€):"
            />
          </Grid.Column>
          <Grid.Column className="two wide middle aligned">
            {/* <span>
              <b>Current Price (€)</b>
            </span> */}
            <span>
              {selectedArticleReferenceID != null &&
                articles.find(
                  (x) => x.articleID === selectedArticleReferenceID
                ) &&
                util.formatDecimal(
                  articles.find(
                    (x) => x.articleID === selectedArticleReferenceID
                  ).price
                )}
            </span>
          </Grid.Column>
          <Grid.Column className="one wide"></Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column className="center aligned">
            <button className="ui button primary" disabled={submitting}>
              Save
            </button>
            <button
              type="button"
              className="ui button"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </form>
  );
};

const validate = (formValues) => {
  const errors = {};

  if (!formValues.articleReferenceID || formValues.articleReferenceID === "0") {
    errors.articleReferenceID = "You need to select an article.";
  }

  if (
    formValues.price === undefined ||
    formValues.price === null ||
    formValues.price === ""
  ) {
    errors.price = "You must enter a price.";
  }

  if (
    formValues.quantity === undefined ||
    formValues.quantity === null ||
    formValues.quantity === ""
  ) {
    errors.quantity = "You must enter a quantity.";
  } else if (formValues.quantity < 0) {
    errors.quantity = "The quantity must be a positive number.";
  }

  return errors;
};

PurchaseOrderRowForm = reduxForm({
  form: "purchaseOrderRowForm",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(PurchaseOrderRowForm);

// Decorate with connect to read form values
const selector = formValueSelector("purchaseOrderRowForm"); // <-- same as form name

PurchaseOrderRowForm = connect((state) => {
  // can select values individually
  const selectedArticleReferenceID = selector(state, "articleReferenceID");

  return {
    selectedArticleReferenceID,
  };
})(PurchaseOrderRowForm);

export default PurchaseOrderRowForm;
