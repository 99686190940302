import solartoolapi from "../apis/solartoolapi";
import history from "../history";
import {
  GENERATE_CALCULATION,
  //CREATE_CALCULATION,
  // FETCH_CALCULATIONS,
  FETCH_CALCULATION,
  DELETE_CALCULATION,
  EDIT_CALCULATION,
  FETCH_CALCULATION_FOR_PROJECT,
  COPY_CALCULATION,
  FETCH_CALCULATION_PROJECTID,
  UNLOAD_CALCULATION,
  UNLOAD_CALCULATIONS,
  CREATE_CALCULATION_FOR_PROJECT,
  SET_CALCULATION_ERROR,
} from "./types";
import { authHeader } from "../shared/utility";
import * as actions from "../actions";

export const generateCalculation =
  (visitID, projectID) => async (dispatch, getState) => {
    const response = await solartoolapi.post(
      `/calculations/${visitID}`,
      {},
      { headers: authHeader() }
    );

    dispatch({ type: GENERATE_CALCULATION, payload: response.data.result });
    history.push(`/sales/calculations/project/${projectID}`);
  };

export const createCalculationForProject = (projectID) => async (dispatch) => {
  try {
    const response = await solartoolapi.post(
      `/calculations/project/${projectID}`,
      null,
      { headers: authHeader() }
    );

    dispatch({
      type: CREATE_CALCULATION_FOR_PROJECT,
      payload: response.data.result,
    });
    history.push(
      `/sales/calculations/detail/${response.data.result.calculationID}`
    );
  } catch (error) {
    dispatch({
      type: SET_CALCULATION_ERROR,
      payload: error.response.data.message,
    });
  }
};

export const createCalculationForProjectFromVisitCreate =
  (projectID, visitID) => async (dispatch) => {
    try {
      const response = await solartoolapi.post(
        `/calculations/project/${projectID}`,
        null,
        { headers: authHeader() }
      );

      dispatch({
        type: CREATE_CALCULATION_FOR_PROJECT,
        payload: response.data.result,
      });
      history.push(
        `/sales/calculations/detail/${response.data.result.calculationID}`
      );
    } catch (error) {
      history.push(`/sales/visits/edit/${visitID}`);
      dispatch({
        type: SET_CALCULATION_ERROR,
        payload: error.response.data.message,
      });
    }
  };

export const editCalculation = (id, formValues) => async (dispatch) => {
  const response = await solartoolapi.put(`/calculations/${id}`, formValues, {
    headers: authHeader(),
  });

  dispatch({ type: EDIT_CALCULATION, payload: response.data.result });
  history.push(
    `/sales/calculations/detail/${response.data.result.calculationID}`
  );
};

export const deleteCalculation = (id, projectID) => async (dispatch) => {
  await solartoolapi.delete(`/calculations/${id}`, { headers: authHeader() });

  dispatch({ type: DELETE_CALCULATION, payload: id });
  history.push(`/sales/projects/dashboard/${projectID}`);
};

export const fetchCalculation = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/calculations/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_CALCULATION, payload: response.data.result });
  dispatch(actions.fetchCalculationProjectID(id));
};

export const fetchCalculationsForProject = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/calculations/project/${id}`, {
    headers: authHeader(),
  });

  dispatch({
    type: FETCH_CALCULATION_FOR_PROJECT,
    payload: response.data.result,
  });
};

export const copyCalculation = (id) => async (dispatch) => {
  const response = await solartoolapi.post(
    `/calculations/copy/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );

  dispatch({ type: COPY_CALCULATION, payload: response.data.result });
  history.push(
    `/sales/calculations/detail/${response.data.result.calculationID}`
  );
};

export const fetchCalculationProjectID = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/calculations/${id}/getprojectid`, {
    headers: authHeader(),
  });

  dispatch({
    type: FETCH_CALCULATION_PROJECTID,
    payload: response.data.result,
  });
};

export const unloadCalculation = () => (dispatch) => {
  dispatch({ type: UNLOAD_CALCULATION });
};

export const unloadCalculations = () => (dispatch) => {
  dispatch({ type: UNLOAD_CALCULATIONS });
};

export const setCalculationError = (v) => (dispatch) => {
  dispatch({ type: SET_CALCULATION_ERROR, payload: v });
};
