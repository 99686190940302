import React from "react";
import { useDispatch } from "react-redux";

import * as actions from "../../actions";
import StandardTableHeader from "../UI/StandardHeader";
import RelationForm from "./RelationForm";

const RelationCreate = () => {
  const dispatch = useDispatch();

  const onSubmit = (fv) => {
    dispatch(actions.createRelation(fv));
  };

  const initRelationType = () => {
    const path = window.location.pathname.toLowerCase();
    if (path.includes("supplier")) {
      return 1;
    } else {
      return 0;
    }
  };

  const initialVal = {
    relationType: initRelationType(),
  };

  return (
    <div className="ui one column grid">
      <div className="column">
        <StandardTableHeader headerLabel="Create Relation"></StandardTableHeader>
      </div>
      <div className="column">
        <RelationForm initialValues={initialVal} onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export default RelationCreate;
