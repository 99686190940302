import {
  FETCH_INVOICE_FOR_PROJECT,
  FETCH_INVOICE,
  UNLOAD_INVOICE,
  UNLOAD_INVOICES,
  FETCH_INVOICE_PROJECTID,
  GENERATE_PDF_INVOICE,
  RESET_INVOICES_MESSAGE
} from '../actions/types';

var initialState = {
  responsemessage: '',
  showmessage: false,
  items: [],
  item: null,
  projectID: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVOICE:
      return {
        ...state,
        item: action.payload
      };
    case UNLOAD_INVOICE:
      return {
        ...state,
        item: null,
        projectID: null
      };
    case UNLOAD_INVOICES:
      return {
        ...state,
        responsemessage: '',
        showmessage: false,
        items: [],
        item: null,
        projectID: null
      };
    case FETCH_INVOICE_FOR_PROJECT:
      return {
        ...state,
        items: action.payload
      };
    case FETCH_INVOICE_PROJECTID:
      return {
        ...state,
        projectID: action.payload
      };
    case GENERATE_PDF_INVOICE:
      return {
        ...state,
        responsemessage: action.payload,
        showmessage: true
      };
    case RESET_INVOICES_MESSAGE:
      return {
        ...state,
        responsemessage: '',
        showmessage: false
      };
    default:
      return state;
  }
};
