import _ from "lodash";
import {
  FETCH_MAILTEMPLATE,
  FETCH_MAILTEMPLATES,
  CREATE_MAILTEMPLATE,
  EDIT_MAILTEMPLATE,
  DELETE_MAILTEMPLATE,
  SET_MAILTEMPLATES_FILTER,
  SET_MAILTEMPLATES_OFFSET,
} from "../actions/types";

var initialState = {
  items: [],
  filteredItems: [],
  mailTemplatesFilter: "",
  mailTemplatesOffset: 0,
  mailTemplatesLimit: 10,
};

const filterAndSortList = (data, filter) => {
  return data.filter((mailTemplate) => {
    return (
      mailTemplate.name.toUpperCase().includes(filter.toUpperCase()) ||
      (mailTemplate.subject &&
        mailTemplate.subject.toUpperCase().includes(filter.toUpperCase()))
    );
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MAILTEMPLATES:
      return {
        ...state,
        items: { ..._.mapKeys(action.payload, "mailTemplateID") },
        filteredItems: {
          ..._.mapKeys(
            filterAndSortList(action.payload, state.mailTemplatesFilter),
            "mailTemplateID"
          ),
        },
      };
    case FETCH_MAILTEMPLATE:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.mailTemplateID]: action.payload,
        },
      };
    case CREATE_MAILTEMPLATE:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.mailTemplateID]: action.payload,
        },
      };
    case EDIT_MAILTEMPLATE:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.mailTemplateID]: action.payload,
        },
      };
    case DELETE_MAILTEMPLATE:
      return {
        ...state,
        items: { ..._.omit(state.items, action.payload) },
      };
    case SET_MAILTEMPLATES_FILTER:
      return {
        ...state,
        mailTemplatesFilter: action.payload,
        filteredItems: {
          ..._.mapKeys(
            filterAndSortList(Object.values(state.items), action.payload),
            "mailTemplateID"
          ),
        },
        mailTemplatesOffset: 0,
      };
    case SET_MAILTEMPLATES_OFFSET:
      return {
        ...state,
        mailTemplatesOffset: action.payload,
      };
    default:
      return state;
  }
};
