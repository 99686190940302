import solartoolapi from "../apis/solartoolapi";
import {
  FETCH_ADDRESS,
  CREATE_ADDRESS,
  EDIT_ADDRESS,
  DELETE_ADDRESS,
  UNLOAD_ADDRESS_COMPONENT,
  SET_ADDRESS_MODE,
} from "./types";
import * as actions from "../actions";
import * as util from "../shared/utility";

export const fetchAddress = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/addresses/${id}`, {
    headers: util.authHeader(),
  });

  dispatch({ type: FETCH_ADDRESS, payload: response.data.result });
};

const createAddress = (projectID, formValues) => async (dispatch) => {
  const response = await solartoolapi.post(
    `/addresses/${projectID}`,
    {
      ...formValues,
    },
    { headers: util.authHeader() }
  );

  dispatch({ type: CREATE_ADDRESS, payload: response.data.result });
};

export const createAddressAndUpdateProject =
  (projectID, formValues) => async (dispatch) => {
    await dispatch(createAddress(projectID, formValues));
    await dispatch(actions.fetchProjectDashboard(projectID));
    await dispatch(setAddressMode(util.formModes.reading));
  };

export const editAddress = (id, formValues) => async (dispatch) => {
  const response = await solartoolapi.put(`/addresses/${id}`, formValues, {
    headers: util.authHeader(),
  });

  dispatch({ type: EDIT_ADDRESS, payload: response.data.result });
};

export const editAddressAndUpdateProject =
  (addressID, projectID, formValues) => async (dispatch) => {
    await dispatch(editAddress(addressID, formValues));
    await dispatch(actions.fetchProjectDashboard(projectID));
    await dispatch(setAddressMode(util.formModes.reading));
  };

const deleteAddress = (id) => async (dispatch) => {
  await solartoolapi.delete(`/addresses/${id}`, { headers: util.authHeader() });

  dispatch({ type: DELETE_ADDRESS, payload: id });
};

export const deleteAddressAndUpdateProject =
  (addressID, projectID) => async (dispatch) => {
    await dispatch(deleteAddress(addressID));
    await dispatch(actions.fetchProjectDashboard(projectID));
    await dispatch(setAddressMode(util.formModes.reading));
  };

export const unloadAddressComponent = () => (dispatch) => {
  dispatch({ type: UNLOAD_ADDRESS_COMPONENT });
};

export const setAddressMode = (mode) => (dispatch) => {
  dispatch({ type: SET_ADDRESS_MODE, payload: mode });
};
