import solartoolapi from "../apis/solartoolapi";
import history from "../history";
import {
  CREATE_FILE,
  FETCH_FILES,
  DELETE_FILE,
  DOWNLOAD_FILE,
  RENAME_FILE,
} from "./types";
import { authHeader } from "../shared/utility";
import fileDownload from "js-file-download";

export const createFile = (file) => async (dispatch, getState) => {
  const fd = new FormData();
  fd.append("file", file, file.name);
  const response = await solartoolapi.post("/files", fd, {
    headers: {
      "content-type": "multipart/form-data",
      ...authHeader(),
    },
  });

  dispatch({ type: CREATE_FILE, payload: response.data.result });
  dispatch(fetchFiles());
};

export const fetchFiles = () => async (dispatch) => {
  const response = await solartoolapi.get("/files", {
    headers: authHeader(),
  });
  dispatch({ type: FETCH_FILES, payload: response.data.result });
};

export const downloadFile = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/files/${id}`, {
    headers: authHeader(),
    responseType: "blob",
  });

  fileDownload(response.data, id);
  dispatch({ type: DOWNLOAD_FILE, payload: id });
};

export const renameFile = (formValues) => async (dispatch) => {
  const response = await solartoolapi.put(`/files`, formValues, {
    headers: authHeader(),
  });

  dispatch({ type: RENAME_FILE, payload: response.data.result });
  history.goBack();
};

export const deleteFile = (filename) => async (dispatch) => {
  await solartoolapi.delete(`/files/${filename}`, { headers: authHeader() });

  dispatch({ type: DELETE_FILE, payload: filename });
  history.go(-1);
};
