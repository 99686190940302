import React from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";

import * as actions from "../../actions";
import OfferForm from "./OfferForm";
import CreateOrderForm from "./CreateOrderForm";
import ProjectBreadcrumb from "../breadcrumb/ProjectBreadcrumb";
import StandardTableHeader from "../UI/StandardHeader";

class OfferEdit extends React.Component {
  componentDidMount() {
    const { offerid } = this.props.match.params;
    this.props.fetchOffer(offerid);
  }

  onSubmit = (fv) => {
    const { offerid } = this.props.match.params;
    this.props.editOffer(offerid, fv, this.props.projectID);
  };

  componentWillUnmount() {
    this.props.unloadOffer();
  }

  generateOrderWithDate = (fv) => {
    const { offerid } = this.props.match.params;
    this.props.generateOrder(offerid, this.props.projectID, fv);
  };

  render() {
    if (!this.props.offer || !this.props.projectID) {
      return <div>Loading...</div>;
    }

    const initialVal = {
      orderDate: new Date().toISOString().split("T")[0],
    };

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <StandardTableHeader headerLabel="Edit Offer">
              <ProjectBreadcrumb
                projectID={this.props.projectID}
                currentPath="Offer"
              />
            </StandardTableHeader>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <OfferForm
              initialValues={this.props.offer}
              onSubmit={this.onSubmit}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <CreateOrderForm
              initialValues={initialVal}
              onSubmit={this.generateOrderWithDate}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    offer: state.offers.item,
    projectID: state.offers.projectID,
    isMobile: state.utility.isMobile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOffer: (id) => dispatch(actions.fetchOffer(id)),
    unloadOffer: () => dispatch(actions.unloadOffer()),
    editOffer: (id, formValues, projectID) =>
      dispatch(actions.editOffer(id, formValues, projectID)),
    generateOrder: (offerID, projectID, formValues) =>
      dispatch(actions.generateOrder(offerID, projectID, formValues)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferEdit);
