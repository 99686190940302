import React from "react";
import { connect } from "react-redux";
import Modal from "../Modal";
import history from "../../history";
import * as actions from "../../actions";

class ArticleDelete extends React.Component {
  componentDidMount() {
    this.props.fetchArticle(this.props.match.params.articleid);
  }

  goBack() {
    history.goBack();
  }

  renderActions() {
    const { articleid } = this.props.match.params;

    return (
      <React.Fragment>
        <button
          onClick={() => this.props.deleteArticle(articleid)}
          className="ui button negative"
        >
          Delete
        </button>
        <button className="ui button" onClick={this.goBack}>
          Cancel
        </button>
      </React.Fragment>
    );
  }

  renderContent() {
    if (!this.props.article) {
      return "Are you sure you want to delete this article?";
    }

    return `Are you sure you want to delete the article with name: ${this.props.article.name}`;
  }

  render() {
    return (
      <Modal
        title="Delete Article"
        content={this.renderContent()}
        actions={this.renderActions()}
        onDismiss={this.goBack}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { article: state.articles.items[ownProps.match.params.articleid] };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchArticle: (id) => dispatch(actions.fetchArticle(id)),
    deleteArticle: (id) => dispatch(actions.deleteArticle(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleDelete);
