import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../actions";

import ArticleCreate from "./articles/ArticleCreate";
import ArticleDelete from "./articles/ArticleDelete";
import ArticleEdit from "./articles/ArticleEdit";
import ArticleList from "./articles/ArticleList";
import Auth from "./auth/Auth";
import CalculationDelete from "./calculations/CalculationDelete";
import CalculationDetail from "./calculations/CalculationDetail";
import CalculationEdit from "./calculations/CalculationEdit";
import CalculationList from "./calculations/CalculationList";
import CalculationRowCreate from "./calculationrows/CalculationRowCreate";
import CalculationRowEdit from "./calculationrows/CalculationRowEdit";
import ChangeLog from "./changeLog/ChangeLog";
import FileDelete from "./files/FileDelete";
import FileEdit from "./files/FileEdit";
import FileList from "./files/FileList";
import InvoiceDelete from "./invoices/InvoiceDelete";
import InvoiceEdit from "./invoices/InvoiceEdit";
import InvoiceList from "./invoices/InvoiceList";
import Layout from "../hoc/Layout/Layout";
import Logout from "./auth/Logout";
import MailSend from "./mails/MailSend";
import MailTemplateCreate from "./mailTemplates/MailTemplateCreate";
import MailTemplateDelete from "./mailTemplates/MailTemplateDelete";
import MailTemplateEdit from "./mailTemplates/MailTemplateEdit";
import MailTemplateList from "./mailTemplates/MailTemplateList";
import OfferDelete from "./offers/OfferDelete";
import OfferEdit from "./offers/OfferEdit";
import OfferList from "./offers/OfferList";
import OrderDelete from "./orders/OrderDelete";
import OrderEdit from "./orders/OrderEdit";
import OrderList from "./orders/OrderList";
import ProjectCreate from "./projects/ProjectCreate";
import ProjectDelete from "./projects/ProjectDelete";
import ProjectEdit from "./projects/ProjectEdit";
import ProjectFileDelete from "./projectFiles/ProjectFileDelete";
import ProjectFileEdit from "./projectFiles/ProjectFileEdit";
import ProjectFileList from "./projectFiles/ProjectFileList";
import ResetPassword from "./auth/ResetPassword";
import SetPassword from "./auth/SetPassword";
// import StyleGuide from "./styleGuide/StyleGuide";
// import TestPage from "./testPage/TestPage";
import TrelloCreate from "./trellos/TrelloCreate";
import UserCreate from "./users/UserCreate";
import UserDelete from "./users/UserDelete";
import UserEdit from "./users/UserEdit";
import UserList from "./users/UserList";
import VisitCreate from "./visits/VisitCreate";
import VisitDelete from "./visits/VisitDelete";
import VisitEdit from "./visits/VisitEdit";
import OrderPagedListMaterials from "./orders/OrderPagedListMaterials";
import OrderPagedListPlanning from "./orders/OrderPagedListPlanning";
import ProjectPagedList from "./projects/ProjectPagedList";
import Dashboard from "./dashboard/Dashboard";
import ProjectInfo from "./projects/ProjectInfo";

import PurchaseOrderList from "./purchaseOrders/PurchaseOrderList";
import PurchaseOrderCreate from "./purchaseOrders/PurchaseOrderCreate";
import PurchaseOrderDelete from "./purchaseOrders/PurchaseOrderDelete";
import PurchaseOrderEdit from "./purchaseOrders/PurchaseOrderEdit";

import Suppliers from "./relations/Suppliers";
import Customers from "./relations/Customers";
import RelationCreate from "./relations/RelationCreate";
import RelationDelete from "./relations/RelationDelete";
import RelationEdit from "./relations/RelationEdit";

import InventoryList from "./articles/InventoryList";
import InventoryItem from "./articles/InventoryItem";

class App extends React.Component {
  UNSAFE_componentWillMount() {
    window.addEventListener("resize", this.props.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.props.handleWindowSizeChange);
  }

  // componentWillUpdate() {
  //   this.props.authCheckState();
  // }

  render() {
    let routes = (
      <Switch>
        {/* <Route path="/" exact component={null} /> */}
        <Route path="/login" exact component={Auth} />
        <Route path="/resetpassword" exact component={ResetPassword} />
        <Route path="/setpassword/:resettoken" exact component={SetPassword} />
        <Redirect to="/login" />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route path="/logout" exact component={Logout} />

          {/* SALES */}
          <Route
            path="/sales/materials"
            exact
            component={OrderPagedListMaterials}
          />
          <Route
            path="/sales/planning"
            exact
            component={OrderPagedListPlanning}
          />
          <Route path="/sales/projects" exact component={ProjectPagedList} />
          <Route path="/sales/projects/new" exact component={ProjectCreate} />
          <Route
            path="/sales/projects/delete/:projectid"
            exact
            component={ProjectDelete}
          />
          <Route
            path="/sales/projects/edit/:projectid"
            exact
            component={ProjectEdit}
          />
          <Route
            path="/sales/projects/dashboard/:projectid"
            exact
            component={Dashboard}
          />
          <Route
            path="/sales/projects/info/:projectid"
            exact
            component={ProjectInfo}
          />
          <Route
            path="/sales/projects/mail/:projectid"
            exact
            component={MailSend}
          />
          <Route
            path="/sales/projects/visits/new/:projectid"
            exact
            component={VisitCreate}
          />
          <Route
            path="/sales/visits/delete/:projectid"
            exact
            component={VisitDelete}
          />
          <Route
            path="/sales/projects/visits/edit/:projectid"
            exact
            component={VisitEdit}
          />
          <Route
            path="/sales/calculations/project/:projectid"
            exact
            component={CalculationList}
          />
          <Route
            path="/sales/calculations/detail/:calculationid"
            exact
            component={CalculationDetail}
          />
          <Route
            path="/sales/calculations/delete/:calculationid"
            exact
            component={CalculationDelete}
          />
          <Route
            path="/sales/calculations/edit/:calculationid"
            exact
            component={CalculationEdit}
          />
          <Route
            path="/sales/calculationrows/edit/:calculationrowid"
            exact
            component={CalculationRowEdit}
          />
          <Route
            path="/sales/calculationrows/create/:calculationid"
            exact
            component={CalculationRowCreate}
          />
          <Route
            path="/sales/offers/project/:projectid"
            exact
            component={OfferList}
          />
          <Route
            path="/sales/offers/edit/:offerid"
            exact
            component={OfferEdit}
          />
          <Route
            path="/sales/offers/delete/:offerid"
            exact
            component={OfferDelete}
          />
          <Route
            path="/sales/orders/project/:projectid"
            exact
            component={OrderList}
          />
          <Route
            path="/sales/orders/edit/:orderid"
            exact
            component={OrderEdit}
          />
          <Route
            path="/sales/orders/delete/:orderid"
            exact
            component={OrderDelete}
          />
          <Route
            path="/sales/invoices/project/:projectid"
            exact
            component={InvoiceList}
          />
          <Route
            path="/sales/invoices/edit/:invoiceid"
            exact
            component={InvoiceEdit}
          />
          <Route
            path="/sales/invoices/delete/:invoiceid"
            exact
            component={InvoiceDelete}
          />
          <Route
            path="/sales/trellos/create/:orderid"
            exact
            component={TrelloCreate}
          />
          <Route
            path="/sales/files/project/:projectid"
            exact
            component={ProjectFileList}
          />
          <Route
            path="/sales/files/project/:projectid/delete/:filename"
            exact
            component={ProjectFileDelete}
          />
          <Route
            path="/sales/files/project/:projectid/edit/:filename"
            exact
            component={ProjectFileEdit}
          />
          <Route path="/sales/customers" exact component={Customers} />
          <Route path="/sales/customers/new" exact component={RelationCreate} />

          {/* INVENTORY */}
          <Route path="/inventory/articles" exact component={ArticleList} />
          <Route
            path="/inventory/articles/new"
            exact
            component={ArticleCreate}
          />
          <Route
            path="/inventory/articles/delete/:articleid"
            exact
            component={ArticleDelete}
          />
          <Route
            path="/inventory/articles/edit/:articleid"
            exact
            component={ArticleEdit}
          />
          <Route
            path="/inventory/inventorylist"
            exact
            component={InventoryList}
          />
          <Route
            path="/inventory/inventoryitem/:articleid"
            exact
            component={InventoryItem}
          />

          {/* PURCHASING */}
          <Route
            path="/purchasing/purchaseorders"
            exact
            component={PurchaseOrderList}
          />
          <Route
            path="/purchasing/purchaseorders/new"
            exact
            component={PurchaseOrderCreate}
          />
          <Route
            path="/purchasing/purchaseorders/edit/:purchaseorderid"
            exact
            component={PurchaseOrderEdit}
          />
          <Route
            path="/purchasing/purchaseorders/delete/:purchaseorderid"
            exact
            component={PurchaseOrderDelete}
          />

          <Route path="/purchasing/suppliers" exact component={Suppliers} />
          <Route
            path="/purchasing/suppliers/new"
            exact
            component={RelationCreate}
          />
          <Route
            path="/purchasing/suppliers/edit/:relationid"
            exact
            component={RelationEdit}
          />
          <Route
            path="/purchasing/suppliers/delete/:relationid"
            exact
            component={RelationDelete}
          />

          {/* APPLICATION */}
          <Route path="/application/files" exact component={FileList} />
          <Route
            path="/application/files/delete/:filename"
            exact
            component={FileDelete}
          />
          <Route
            path="/application/files/edit/:filename"
            exact
            component={FileEdit}
          />
          <Route
            path="/application/mailtemplates"
            exact
            component={MailTemplateList}
          />
          <Route
            path="/application/mailtemplates/new"
            exact
            component={MailTemplateCreate}
          />
          <Route
            path="/application/mailtemplates/delete/:mailTemplateID"
            exact
            component={MailTemplateDelete}
          />
          <Route
            path="/application/mailtemplates/edit/:mailTemplateID"
            exact
            component={MailTemplateEdit}
          />
          <Route path="/application/users" exact component={UserList} />
          <Route path="/application/users/new" exact component={UserCreate} />
          <Route
            path="/application/users/edit/:userid"
            exact
            component={UserEdit}
          />
          <Route
            path="/application/users/delete/:userid"
            exact
            component={UserDelete}
          />
          <Route path="/application/changelog" exact component={ChangeLog} />

          {/* SYSTEM */}
          {/* <Route path="/system/testpage" exact component={TestPage} />
          <Route path="/system/styleguide" exact component={StyleGuide} /> */}

          <Redirect to="/sales/projects" />
          {/* <Redirect to="/" /> */}
        </Switch>
      );
    }

    return (
      <div className="ui container rootcontainer">
        <Layout>{routes}</Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleWindowSizeChange: () => dispatch(actions.handleWindowSizeChange()),
    authCheckState: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
