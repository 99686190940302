import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as actions from "../../actions";
import StandardTableHeader from "../UI/StandardHeader";
import RelationForm from "./RelationForm";

const RelationEdit = ({
  match: {
    params: { relationid },
  },
}) => {
  const dispatch = useDispatch();
  const relation = useSelector((state) => state.relations.item);

  useEffect(() => {
    dispatch(actions.fetchRelation(relationid));

    return () => {
      dispatch(actions.unloadRelation());
    };
  }, []);

  const onSubmit = (fv) => {
    dispatch(actions.editRelation(relationid, fv));
  };

  if (!relation) {
    return <div>Loading...</div>;
  } else {
    const initialVal = {
      name: relation.name,
      relationType: relation.relationType,
    };

    return (
      <div className="ui one column grid">
        <div className="column">
          <StandardTableHeader headerLabel="Edit Relation"></StandardTableHeader>
        </div>
        <div className="column">
          <RelationForm initialValues={initialVal} onSubmit={onSubmit} />
        </div>
      </div>
    );
  }
};

export default RelationEdit;
