import React from "react";
import { Field, reduxForm } from "redux-form";
import history from "../../history";
import * as util from "../../shared/utility";

class InvoiceFrom extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  handleCancelClick = () => {
    history.goBack();
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        {util.RenderDisabledField(
          this.props.initialValues.invoiceNumber,
          "Inoice number"
        )}
        <Field name="name" component={util.RenderInput} label="Name:" />
        <Field
          name="bodyText"
          component={util.RenderInputMultilineHtml}
          label="Body:"
          height={1000}
        />
        <Field
          name="sendDateTime"
          component={util.RenderInputDate}
          label="Send on:"
        />
        <button className="ui button primary" disabled={this.props.submitting}>
          Save
        </button>
        <button
          type="button"
          className="ui button"
          onClick={this.handleCancelClick}
        >
          Back
        </button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.name) {
    errors.name = "You must enter a name for the order.";
  }

  if (formValues.name && formValues.name.length > 200) {
    errors.name = "The name cannot exceed 200 characters.";
  }

  if (!formValues.body) {
    errors.body = "You must enter a body for the invoice.";
  }

  if (formValues.sendDateTime && !util.isValidDate(formValues.sendDateTime)) {
    errors.sendDateTime = "You must enter a valid send date.";
  }

  return errors;
};

export default reduxForm({
  form: "invoiceFrom",
  validate,
})(InvoiceFrom);
