import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Segment } from 'semantic-ui-react';

class PersonDelete extends React.Component {
  onDelete = () => {
    this.props.deletePersonAndUpdateProject(this.props.person.personID, this.props.projectID);
  };

  render() {
    return (
      <Segment textAlign="center" style={{ background: '#BBB' }}>
        <h3>Are you sure you want to delete this contact?</h3>
        <button type="button" className="ui button negative" onClick={this.onDelete}>
          Delete
        </button>
        <button type="button" className="ui button" onClick={this.props.onCancel}>
          Cancel
        </button>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    person: state.persons.person
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deletePersonAndUpdateProject: (personID, projectID) =>
      dispatch(actions.deletePersonAndUpdateProject(personID, projectID))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonDelete);
