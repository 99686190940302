import React from "react";

export const AxisLeft = ({ yScale, innerWidth, tickOffset = 3 }) =>
  yScale.ticks().map((tickValue) => (
    <g
      className="tick"
      key={tickValue}
      transform={`translate(0,${yScale(tickValue)})`}
    >
      <line stroke="lightgray" x2={innerWidth} />
      <text
        style={{ textAnchor: "end" }}
        dy="0.32em"
        dx="-0.2em"
        x={-tickOffset}
      >
        {tickValue}
      </text>
    </g>
  ));
