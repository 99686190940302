import React from "react";
import { connect } from "react-redux";
import Modal from "../Modal";
import history from "../../history";
import * as actions from "../../actions";

class CalculationDelete extends React.Component {
  componentDidMount() {
    this.props.fetchCalculation(this.props.match.params.calculationid);
  }

  componentWillUnmount() {
    this.props.unloadCalculation();
  }

  goBack = () => {
    history.go(-1);
  };

  renderActions() {
    const { calculationid } = this.props.match.params;
    const { projectID } = this.props;

    return (
      <React.Fragment>
        <button
          onClick={() => this.props.deleteCalculation(calculationid, projectID)}
          className="ui button negative"
        >
          Delete
        </button>
        <button className="ui button" onClick={() => this.goBack()}>
          Cancel
        </button>
      </React.Fragment>
    );
  }

  renderContent() {
    if (!this.props.calculation) {
      return "Are you sure you want to delete this calculation?";
    }

    return `Are you sure you want to delete the calculation with id: ${this.props.calculation.calculationID}`;
  }

  render() {
    if (!this.props.calculation) {
      return <div>Calculation could not be found.</div>;
    }

    return (
      <Modal
        title="Delete Calculation"
        content={this.renderContent()}
        actions={this.renderActions()}
        onDismiss={this.goBack}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    calculation: state.calculations.item,
    projectID: state.calculations.projectID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCalculation: (id) => dispatch(actions.fetchCalculation(id)),
    deleteCalculation: (id, projectID) =>
      dispatch(actions.deleteCalculation(id, projectID)),
    unloadCalculation: () => dispatch(actions.unloadCalculation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculationDelete);
