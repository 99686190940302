import React from "react";
import { useDispatch } from "react-redux";

import * as actions from "../../actions";
import PurchaseOrderRowForm from "./PurchaseOrderRowForm";

const PurchaseOrderRowEditOnOverview = ({
  articles,
  item,
  handleCancelClick,
}) => {
  const dispatch = useDispatch();

  const onSubmit = (fv) => {
    dispatch(
      actions.editPurchaseOrderRowForPurchaseOrder(item.purchaseOrderRowID, fv)
    );
    handleCancelClick(null);
  };

  return (
    <div className="column">
      <PurchaseOrderRowForm
        onSubmit={onSubmit}
        articles={articles}
        initialValues={{ ...item }}
        handleCancelClick={handleCancelClick}
        projectID={item.projectID}
      />
    </div>
  );
};

export default PurchaseOrderRowEditOnOverview;
