import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import { Grid } from "semantic-ui-react";

import * as actions from "../../actions";
import * as util from "../../shared/utility";

let PurchaseOrderForm = (props) => {
  const { handleSubmit, onSubmit } = props;

  const suppliers = useSelector((state) => state.relations.suppliers);

  useEffect(() => {
    dispatch(actions.fetchSuppliers());
  }, []);

  const dispatch = useDispatch();

  const onSelectWithSearchChange = (e, data) => {
    dispatch(change("purchaseOrderForm", data.name, data.value));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="ui form error">
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column className="twelve wide">
            <Field
              name="relationID"
              label="Supplier:"
              component={util.RenderSelectWithSearch}
              handleChange={onSelectWithSearchChange}
            >
              {[
                ...[{ key: 0, value: 0, text: "Select a supplier..." }],
                ...Object.values(suppliers)
                  .sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase()
                      ? -1
                      : a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : 0
                  )
                  .map((x) => {
                    return {
                      key: x.relationID,
                      value: x.relationID,
                      text: x.name,
                    };
                  }),
              ]}
            </Field>
          </Grid.Column>
          <Grid.Column className="four wide">
            <Field
              name="orderDate"
              component={util.RenderInputDate}
              label="Order date:"
            ></Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column className="twelve wide">
            <Field
              name="reference"
              component={util.RenderInput}
              label="Reference:"
            />
          </Grid.Column>
          <Grid.Column className="four wide">
            <Field
              name="plannedDeliverDate"
              component={util.RenderInputDate}
              label="Planned Deliver date:"
            ></Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column className="twelve wide">
            <Field
              name="suplierReference"
              component={util.RenderInput}
              label="Suplier Reference:"
            />
          </Grid.Column>
          <Grid.Column className="four wide">
            <Field
              name="deliverDate"
              component={util.RenderInputDate}
              label="Deliver date:"
            ></Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              name="notes"
              component={util.RenderInputMultiline}
              label="Notes:"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </form>
  );
};

const validate = (formValues) => {
  const errors = {};

  if (!formValues.relationID || formValues.relationID === "0") {
    errors.relationID = "You need to select a supplier.";
  }

  if (!formValues.orderDate) {
    errors.orderDate = "The order date is mandatory.";
  }

  if (
    !formValues.plannedDeliverDate ||
    formValues.plannedDeliverDate < formValues.orderDate
  ) {
    errors.plannedDeliverDate =
      "The planned deliver date is mandatory and needs to be later then the order date.";
  }

  if (
    formValues.orderDate &&
    formValues.deliverDate &&
    formValues.deliverDate < formValues.orderDate
  ) {
    errors.deliverDate =
      "The deliver date needs to be later then the order date.";
  }

  if (formValues.reference && formValues.reference.length > 200) {
    errors.reference = "The reference cannot exceed 200 characters.";
  }

  if (formValues.suplierReference && formValues.suplierReference.length > 200) {
    errors.suplierReference =
      "The supplier reference cannot exceed 200 characters.";
  }

  if (formValues.notes && formValues.notes.length > 2000) {
    errors.notes = "The notes cannot exceed 2000 characters.";
  }

  return errors;
};

export default reduxForm({
  form: "purchaseOrderForm",
  validate,
})(PurchaseOrderForm);
