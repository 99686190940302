import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as util from "../../shared/utility";
import history from "../../history";
import Modal from "../Modal";
import * as actions from "../../actions";

const PurchaseOrderDelete = ({
  match: {
    params: { purchaseorderid },
  },
}) => {
  const purchaseOrder = useSelector((state) => state.purchaseOrders.item);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchPurchaseOrder(purchaseorderid));

    return () => {
      dispatch(actions.unloadPurchaseOrder());
    };
  }, []);

  let renderContent = "Are you sure you want to delete this purchaseOrder?";
  if (purchaseOrder && purchaseOrder.relation) {
    renderContent = `Are you sure you want to delete the purchaseOrder ${purchaseorderid} from ${
      purchaseOrder.relation.name
    } ordered on ${util.utcStringToLocalDate(purchaseOrder.orderDate)}?`;
  }

  const renderActions = (
    <React.Fragment>
      <button
        onClick={() => dispatch(actions.deletePurchaseOrder(purchaseorderid))}
        className="ui button negative"
      >
        Delete
      </button>
      <button className="ui button" onClick={() => history.goBack()}>
        Cancel
      </button>
    </React.Fragment>
  );

  return (
    <Modal
      title="Delete PurchaseOrder"
      content={renderContent}
      actions={renderActions}
      onDismiss={() => history.goBack()}
    />
  );
};

export default PurchaseOrderDelete;
