import React from "react";
import { useDispatch, connect } from "react-redux";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { Grid } from "semantic-ui-react";

import * as util from "../../shared/utility";

let RoofSectionForm = ({
  onSubmit,
  handleSubmit,
  articles,
  obstaclesValue,
  roofTypeValue,
  submitting,
  handleCancelClick,
  projectID,
}) => {
  const dispatch = useDispatch();

  const onSelectWithSearchChange = (e, data) => {
    dispatch(change("roofSectionForm", data.name, data.value));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="ui form error">
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Field
              name="panelArticleID"
              label="Desired panel article:"
              component={util.RenderSelectWithSearch}
              handleChange={onSelectWithSearchChange}
            >
              {[
                ...[{ key: 0, value: 0, text: "Select a panel..." }],
                ...articles
                  .filter((article) => {
                    return article.categoryID === 1;
                  })
                  .sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase()
                      ? -1
                      : a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : 0
                  )
                  .map((x) => {
                    return {
                      key: x.articleID,
                      value: x.articleID,
                      text: x.name,
                    };
                  }),
              ]}
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Field
              name="mountingArticleID"
              label="Desired mounting article:"
              component={util.RenderSelectWithSearch}
              handleChange={onSelectWithSearchChange}
            >
              {[
                ...articles
                  .filter((article) => {
                    return article.categoryID === 3;
                  })
                  .sort((a, b) =>
                    a.priority < b.priority
                      ? 1
                      : a.priority > b.priority
                      ? -1
                      : a.name.toLowerCase() < b.name.toLowerCase()
                      ? -1
                      : a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : 0
                  )
                  .map((x) => {
                    return {
                      key: x.articleID,
                      value: x.articleID,
                      text: x.name,
                    };
                  }),
              ]}
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Field
              name="areaSize"
              component={util.RenderInputNumber}
              label="Number of panels possible at location:"
            />
          </Grid.Column>
          <Grid.Column>
            <Field
              name="efficiency"
              component={util.RenderInputNumber}
              label="Expected efficiency (%):"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Field
              name="obstacles"
              component={util.RenderInputBoolean}
              label="Obstacles at location:"
            />
          </Grid.Column>
        </Grid.Row>
        {obstaclesValue && (
          <Grid.Row columns={1}>
            <Grid.Column>
              <Field
                name="obstaclesRemark"
                component={util.RenderInputMultiline}
                label="Remarks regarding the obstacles."
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row columns={2}>
          <Grid.Column>
            <Field
              name="roofType"
              component={util.RenderSelect}
              label="Roof type:"
            >
              <option key={0} value={util.roofType.PITCHED}>
                Pitched
              </option>
              <option key={1} value={util.roofType.FLAT}>
                Flat
              </option>
              {projectID < 655 && (
                <option key={2} value={util.roofType.BOTH}>
                  Flat & Pitched
                </option>
              )}
            </Field>
          </Grid.Column>
          <Grid.Column>
            <Field
              name="roofDirection"
              component={util.RenderSelect}
              label="Roof direction:"
            >
              <option key={0} value={""}>
                Select...
              </option>
              <option key={1} value={"noord"}>
                noord
              </option>
              <option key={2} value={"noordoost"}>
                noordoost
              </option>
              <option key={3} value={"oost"}>
                oost
              </option>
              <option key={4} value={"zuidoost"}>
                zuidoost
              </option>
              <option key={5} value={"zuid"}>
                zuid
              </option>
              <option key={6} value={"zuidwest"}>
                zuidwest
              </option>
              <option key={7} value={"west"}>
                west
              </option>
              <option key={8} value={"noordwest"}>
                noordwest
              </option>
              <option key={9} value={"oost en west"}>
                oost en west
              </option>
            </Field>
          </Grid.Column>
        </Grid.Row>
        {roofTypeValue === util.roofType.BOTH && (
          <Grid.Row columns={3}>
            <Grid.Column className="computer tablet only">
              <div className="ui divider"></div>
            </Grid.Column>
            <Grid.Column style={{ textAlign: "center" }}>Pitched</Grid.Column>
            <Grid.Column className="computer tablet only">
              <div className="ui divider"></div>
            </Grid.Column>
          </Grid.Row>
        )}
        {roofTypeValue !== util.roofType.FLAT && (
          <Grid.Row columns={2}>
            <Grid.Column>
              <Field
                name="roofPitch"
                component={util.RenderInputNumber}
                label="Roof pitch (°):"
              />
            </Grid.Column>
            <Grid.Column>
              <Field
                name="roofTileMounting"
                component={util.RenderInput}
                label="Roof tile mounting (Hooks, Screws):"
              />
            </Grid.Column>
          </Grid.Row>
        )}
        {roofTypeValue !== util.roofType.FLAT && (
          <>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Field
                  name="roofTileHeight"
                  component={util.RenderInput}
                  label="Roof tile height (cm):"
                />
              </Grid.Column>
              <Grid.Column>
                <Field
                  name="roofTileWidth"
                  component={util.RenderInput}
                  label="Roof tile width (cm):"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Field
                  name="birdScrapArticleID"
                  label="Bird scrap:"
                  component={util.RenderSelectWithSearch}
                  handleChange={onSelectWithSearchChange}
                >
                  {[
                    ...[{ key: 0, value: null, text: "Select..." }],
                    ...articles
                      .filter((article) => {
                        return article.categoryID === 16;
                      })
                      .sort((a, b) =>
                        a.priority < b.priority
                          ? 1
                          : a.priority > b.priority
                          ? -1
                          : a.name.toLowerCase() < b.name.toLowerCase()
                          ? -1
                          : a.name.toLowerCase() > b.name.toLowerCase()
                          ? 1
                          : 0
                      )
                      .map((x) => {
                        return {
                          key: x.articleID,
                          value: x.articleID,
                          text: x.name,
                        };
                      }),
                  ]}
                </Field>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        {roofTypeValue === util.roofType.BOTH && (
          <Grid.Row columns={3}>
            <Grid.Column className="computer tablet only">
              <div className="ui divider"></div>
            </Grid.Column>
            <Grid.Column style={{ textAlign: "center" }}>Flat</Grid.Column>
            <Grid.Column className="computer tablet only">
              <div className="ui divider"></div>
            </Grid.Column>
          </Grid.Row>
        )}
        {roofTypeValue !== util.roofType.PITCHED && (
          <Grid.Row columns={2}>
            <Grid.Column>
              <Field
                name="roofHeight"
                component={util.RenderInput}
                label="Roof height (cm):"
              />
            </Grid.Column>
            <Grid.Column>
              <Field
                name="verticalTransportArticleID"
                label="Vertical transport:"
                component={util.RenderSelectWithSearch}
                handleChange={onSelectWithSearchChange}
              >
                {[
                  ...[{ key: 0, value: null, text: "Select..." }],
                  ...articles
                    .filter((article) => {
                      return article.categoryID === 17;
                    })
                    .sort((a, b) =>
                      a.priority < b.priority
                        ? 1
                        : a.priority > b.priority
                        ? -1
                        : a.name.toLowerCase() < b.name.toLowerCase()
                        ? -1
                        : a.name.toLowerCase() > b.name.toLowerCase()
                        ? 1
                        : 0
                    )
                    .map((x) => {
                      return {
                        key: x.articleID,
                        value: x.articleID,
                        text: x.name,
                      };
                    }),
                ]}
              </Field>
            </Grid.Column>
          </Grid.Row>
        )}
        {roofTypeValue !== util.roofType.PITCHED && (
          <Grid.Row columns={2}>
            <Grid.Column>
              <Field
                name="roofCovering"
                component={util.RenderSelect}
                label="Roof covering:"
              >
                <option key={0} value={""}>
                  Select...
                </option>
                <option key={1} value={"bituum"}>
                  bituum
                </option>
                <option key={2} value={"kunstof"}>
                  kunstof
                </option>
                <option key={3} value={"epdm"}>
                  epdm
                </option>
              </Field>
            </Grid.Column>
            <Grid.Column>
              <Field
                name="roofThroughputArticleID"
                label="Roof throughput:"
                component={util.RenderSelectWithSearch}
                handleChange={onSelectWithSearchChange}
              >
                {[
                  ...[{ key: 0, value: null, text: "Select..." }],
                  ...articles
                    .filter((article) => {
                      return article.categoryID === 14;
                    })
                    .sort((a, b) =>
                      a.priority < b.priority
                        ? 1
                        : a.priority > b.priority
                        ? -1
                        : a.name.toLowerCase() < b.name.toLowerCase()
                        ? -1
                        : a.name.toLowerCase() > b.name.toLowerCase()
                        ? 1
                        : 0
                    )
                    .map((x) => {
                      return {
                        key: x.articleID,
                        value: x.articleID,
                        text: x.name,
                      };
                    }),
                ]}
              </Field>
            </Grid.Column>
          </Grid.Row>
        )}
        {roofTypeValue !== util.roofType.PITCHED && (
          <Grid.Row columns={2}>
            <Grid.Column>
              <Field
                name="gravelArticleID"
                label="Gravel:"
                component={util.RenderSelectWithSearch}
                handleChange={onSelectWithSearchChange}
              >
                {[
                  ...[{ key: 0, value: null, text: "Select..." }],
                  ...articles
                    .filter((article) => {
                      return article.categoryID === 15;
                    })
                    .sort((a, b) =>
                      a.priority < b.priority
                        ? 1
                        : a.priority > b.priority
                        ? -1
                        : a.name.toLowerCase() < b.name.toLowerCase()
                        ? -1
                        : a.name.toLowerCase() > b.name.toLowerCase()
                        ? 1
                        : 0
                    )
                    .map((x) => {
                      return {
                        key: x.articleID,
                        value: x.articleID,
                        text: x.name,
                      };
                    }),
                ]}
              </Field>
            </Grid.Column>
          </Grid.Row>
        )}
        {roofTypeValue !== util.roofType.PITCHED && (
          <Grid.Row columns={1}>
            <Grid.Column>
              <Field
                name="roofSize"
                component={util.RenderInput}
                label="Size of the flat roof:"
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row columns={1}>
          <Grid.Column>
            <button className="ui button primary" disabled={submitting}>
              Save
            </button>
            <button
              type="button"
              className="ui button"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </form>
  );
};

const validate = (formValues) => {
  const errors = {};

  if (!formValues.panelArticleID || formValues.panelArticleID === "0") {
    errors.panelArticleID = "You need to select a panel.";
  }

  if (
    formValues.efficiency &&
    (formValues.efficiency < 0 || formValues.efficiency > 100)
  ) {
    errors.efficiency = "Efficiency must be a value between 0 and 100.";
  }

  if (
    formValues.areaSize &&
    (formValues.areaSize < 0 || formValues.areaSize > 1000000)
  ) {
    errors.areaSize = "Number of panels must be a value between 0 and 1000000.";
  }

  if (!(formValues.roofPitch >= 0 && formValues.roofPitch <= 90)) {
    errors.roofPitch = "Roof pitch must be a value between 0 and 90.";
  }

  if (formValues.obstaclesRemark && formValues.obstaclesRemark.length > 500) {
    errors.obstaclesRemark =
      "The obstaclesRemark cannot exceed 500 characters.";
  }

  if (formValues.roofDirection && formValues.roofDirection.length > 20) {
    errors.roofDirection = "The roofDirection cannot exceed 20 characters.";
  }

  if (formValues.roofCovering && formValues.roofCovering.length > 20) {
    errors.roofCovering = "The roofCovering cannot exceed 20 characters.";
  }

  if (formValues.roofType && formValues.roofType.length > 20) {
    errors.roofType = "The roofType cannot exceed 20 characters.";
  }

  if (formValues.roofSize && formValues.roofSize.length > 200) {
    errors.roofSize = "The roofSize cannot exceed 200 characters.";
  }

  if (formValues.roofHeight && formValues.roofHeight.length > 50) {
    errors.roofHeight = "The roofHeight cannot exceed 50 characters.";
  }

  if (formValues.roofTileHeight && formValues.roofTileHeight.length > 50) {
    errors.roofTileHeight = "The roofTileHeight cannot exceed 50 characters.";
  }

  if (formValues.roofTileWidth && formValues.roofTileWidth.length > 50) {
    errors.roofTileWidth = "The roofTileWidth cannot exceed 50 characters.";
  }

  if (formValues.roofTileMounting && formValues.roofTileMounting.length > 50) {
    errors.roofTileMounting =
      "The roofTileMounting cannot exceed 50 characters.";
  }

  return errors;
};

RoofSectionForm = reduxForm({
  form: "roofSectionForm",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(RoofSectionForm);

// Decorate with connect to read form values
const selector = formValueSelector("roofSectionForm"); // <-- same as form name

RoofSectionForm = connect((state) => {
  // can select values individually
  const obstaclesValue = selector(state, "obstacles");
  const roofTypeValue = selector(state, "roofType");

  const isRoofHorizontal = true && !(selector(state, "roofPitch") > 0);

  return {
    obstaclesValue,
    isRoofHorizontal,
    roofTypeValue,
  };
})(RoofSectionForm);

export default RoofSectionForm;
