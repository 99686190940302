import solartoolapi from "../apis/solartoolapi";
import history from "../history";
import {
  GENERATE_ORDER,
  FETCH_ORDER,
  DELETE_ORDER,
  EDIT_ORDER,
  FETCH_ORDER_FOR_PROJECT,
  UNLOAD_ORDER,
  UNLOAD_ORDERS,
  GENERATE_PDF_ORDER,
  FETCH_ORDER_PROJECTID,
  DOWNLOAD_PROJECT_FILE,
  RESET_ORDERS_MESSAGE,
  FETCH_ORDERS_PAGED,
  SET_ORDERS_PAGE_FILTER,
  SET_ORDERS_PAGE_NUMBER,
  RESET_ORDERS_FILTER,
  TOGGLE_SHOW_INVOICED,
  SET_ORDERS_VISITOR_FILTER,
  TOGGLE_SHOW_PLANNED,
  FETCH_ORDERS_PAGED_PLANNING,
  SET_ORDERS_PAGE_FILTER_PLANNING,
  SET_ORDERS_PAGE_NUMBER_PLANNING,
  RESET_ORDERS_FILTER_PLANNING,
  TOGGLE_SHOW_INVOICED_PLANNING,
  SET_ORDERS_VISITOR_FILTER_PLANNING,
  TOGGLE_SHOW_PLANNED_PLANNING,
  UPDATE_ORDER_DELIVER_DATE,
} from "./types";
import { authHeader } from "../shared/utility";
import * as actions from "../actions";
import fileDownload from "js-file-download";

export const generateOrder =
  (offerID, projectID, formValues) => async (dispatch, getState) => {
    const response = await solartoolapi.post(`/orders/${offerID}`, formValues, {
      headers: authHeader(),
    });

    dispatch({ type: GENERATE_ORDER, payload: response.data.result });

    history.push(`/sales/orders/edit/${response.data.result.orderID}?new=true`);
  };

export const editOrder = (id, formValues, projectID) => async (dispatch) => {
  const response = await solartoolapi.put(`/orders/${id}`, formValues, {
    headers: authHeader(),
  });

  dispatch({ type: EDIT_ORDER, payload: response.data.result });

  if (new URLSearchParams(window.location.search).get("new")) {
    history.push(`/sales/projects/dashboard/${projectID}`);
  } else {
    history.goBack();
  }
};

export const deleteOrder = (id) => async (dispatch) => {
  await solartoolapi.delete(`/orders/${id}`, { headers: authHeader() });

  dispatch({ type: DELETE_ORDER, payload: id });
  history.go(-1);
};

export const fetchOrder = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/orders/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_ORDER, payload: response.data.result });
  dispatch(actions.fetchOrderProjectID(id));
};

// ORDER MATERIALS
export const fetchOrdersPaged =
  (pageFilter, pageNumber, showInvoiced, ordersVisitorFilter, showPlanned) =>
  async (dispatch) => {
    const response = await solartoolapi.get(
      `/orders/paged?pageNumber=${pageNumber}&pageFilter=${pageFilter}&showInvoiced=${showInvoiced}&visitorFilter=${ordersVisitorFilter}&showPlanned=${showPlanned}`,
      {
        headers: authHeader(),
      }
    );
    dispatch({
      type: FETCH_ORDERS_PAGED,
      payload: response.data,
    });
  };

export const setOrdersPageFilter = (pageFilter) => (dispatch) => {
  dispatch({ type: SET_ORDERS_PAGE_FILTER, payload: pageFilter });
};

export const setOrdersVisitorFilter = (visitorFilter) => (dispatch) => {
  dispatch({ type: SET_ORDERS_VISITOR_FILTER, payload: visitorFilter });
};

export const setOrdersPageNumber = (pageNumber) => (dispatch) => {
  dispatch({ type: SET_ORDERS_PAGE_NUMBER, payload: pageNumber });
};

export const resetOrdersFilter = () => (dispatch) => {
  dispatch({ type: RESET_ORDERS_FILTER });
};

export const toggleShowInvoiced = () => (dispatch) => {
  dispatch({ type: TOGGLE_SHOW_INVOICED });
};

export const toggleShowPlanned = () => (dispatch) => {
  dispatch({ type: TOGGLE_SHOW_PLANNED });
};
// END ORDER MATERIALS

// ORDER PLANNING
export const fetchOrdersPagedPlanning =
  (pageFilter, pageNumber, showInvoiced, ordersVisitorFilter, showPlanned) =>
  async (dispatch) => {
    const response = await solartoolapi.get(
      `/orders/paged?pageNumber=${pageNumber}&pageFilter=${pageFilter}&showInvoiced=${showInvoiced}&visitorFilter=${ordersVisitorFilter}&showPlanned=${showPlanned}`,
      {
        headers: authHeader(),
      }
    );
    dispatch({
      type: FETCH_ORDERS_PAGED_PLANNING,
      payload: response.data,
    });
  };

export const setOrdersPageFilterPlanning = (pageFilter) => (dispatch) => {
  dispatch({ type: SET_ORDERS_PAGE_FILTER_PLANNING, payload: pageFilter });
};

export const setOrdersVisitorFilterPlanning = (visitorFilter) => (dispatch) => {
  dispatch({
    type: SET_ORDERS_VISITOR_FILTER_PLANNING,
    payload: visitorFilter,
  });
};

export const setOrdersPageNumberPlanning = (pageNumber) => (dispatch) => {
  dispatch({ type: SET_ORDERS_PAGE_NUMBER_PLANNING, payload: pageNumber });
};

export const resetOrdersFilterPlanning = () => (dispatch) => {
  dispatch({ type: RESET_ORDERS_FILTER_PLANNING });
};

export const toggleShowInvoicedPlanning = () => (dispatch) => {
  dispatch({ type: TOGGLE_SHOW_INVOICED_PLANNING });
};

export const toggleShowPlannedPlanning = () => (dispatch) => {
  dispatch({ type: TOGGLE_SHOW_PLANNED_PLANNING });
};
// END ORDER PLANNING

export const fetchOrdersForProject = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/orders/project/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_ORDER_FOR_PROJECT, payload: response.data.result });
};

export const generateOrderPdf = (orderid, projectid) => async (dispatch) => {
  const response = await solartoolapi.post(
    `/orders/generatepdf/${orderid}`,
    {},
    {
      headers: authHeader(),
    }
  );

  dispatch({ type: GENERATE_PDF_ORDER, payload: response.data.message });
  dispatch(actions.fetchProjectFiles(projectid));
};

export const previewOrderPdf = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/orders/previewpdf/${id}`, {
    headers: authHeader(),
    responseType: "blob",
  });

  var previewPdfName = `Bevestiging_DeLaatSolarSystems_Preview_${Date.now()}.pdf`;

  fileDownload(response.data, previewPdfName);
  dispatch({ type: DOWNLOAD_PROJECT_FILE, payload: previewPdfName });
};

export const fetchOrderProjectID = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/orders/${id}/getprojectid`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_ORDER_PROJECTID, payload: response.data.result });
};

export const unloadOrder = () => (dispatch) => {
  dispatch({ type: UNLOAD_ORDER });
};

export const unloadOrders = () => (dispatch) => {
  dispatch({ type: UNLOAD_ORDERS });
};

export const resetOrdersMessage = () => (dispatch) => {
  dispatch({ type: RESET_ORDERS_MESSAGE });
};

export const getOrdersMaterials = (orderids) => async (dispatch) => {
  const response = await solartoolapi.post(`/orders/materials`, orderids, {
    headers: authHeader(),
    responseType: "blob",
  });

  const fileName = response.headers["content-disposition"]
    ? response.headers["content-disposition"]
        .split("filename=")[1]
        .split(";")[0]
    : `SolarTool_Materials_${Date.now()}.xlsx`;

  fileDownload(response.data, fileName);
};

export const updateOrderDeliverDate = (id, deliverDate) => async (dispatch) => {
  const response = await solartoolapi.put(
    `/orders/setdeliverdate/${id}`,
    { deliverDate },
    {
      headers: authHeader(),
    }
  );

  dispatch({
    type: UPDATE_ORDER_DELIVER_DATE,
    payload: response.data.result,
  });
};
