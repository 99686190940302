import React from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal';
import history from '../../history';
import * as actions from '../../actions';

class ProjectDelete extends React.Component {
  componentDidMount() {
    this.props.fetchProject(this.props.match.params.projectid);
  }

  goBack() {
    history.goBack();
  }

  renderActions() {
    const { projectid } = this.props.match.params;

    return (
      <React.Fragment>
        <button onClick={() => this.props.deleteProject(projectid)} className="ui button negative">
          Delete
        </button>
        <button className="ui button" onClick={this.goBack}>
          Cancel
        </button>
      </React.Fragment>
    );
  }

  renderContent() {
    if (!this.props.project) {
      return 'Are you sure you want to delete this project?';
    }

    return `Are you sure you want to delete the project with name: ${this.props.project.name}`;
  }

  render() {
    return (
      <Modal
        title="Delete Project"
        content={this.renderContent()}
        actions={this.renderActions()}
        onDismiss={this.goBack}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { project: state.projects.items[ownProps.match.params.projectid] };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProject: id => dispatch(actions.fetchProject(id)),
    deleteProject: id => dispatch(actions.deleteProject(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDelete);

// import React from 'react';

// const ProjectDelete = () => {
//   return <div>Are you sure....</div>;
// };

// export default ProjectDelete;
