import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination, Table } from "semantic-ui-react";

import * as actions from "../../actions";
import StandardTableHeader from "../UI/StandardHeader";
import * as util from "../../shared/utility";
import { InventoryChart } from "./InventoryChart/InventoryChart";
import history from "../../history";

import "./InventoryItem.css";

const InventoryItem = ({
  match: {
    params: { articleid },
  },
}) => {
  const dispatch = useDispatch();
  const inventoryItem = useSelector((state) => state.articles.inventoryItem);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchFilter, setSearchFilter] = useState("");
  const [orderTypeFilter, setOrderTypeFilter] = useState("");
  const [tableOrder, setTableOrder] = useState("deliverdate");
  const [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    dispatch(actions.fetchInventoryItem(articleid));

    return () => {
      dispatch(actions.unloadInventoryItem());
    };
  }, []);

  if (!inventoryItem) {
    return null;
  }

  const filteredData = inventoryItem.mutations
    .filter((mutation) => {
      if (!showHistory) {
        return (
          mutation.deliverDateForOrder.slice(0, 10) >=
          new Date().toISOString().slice(0, 10)
        );
      } else {
        return 1;
      }
    })
    .filter((mutation) => {
      return mutation.name.toUpperCase().includes(searchFilter.toUpperCase());
    })
    .filter((mutation) => {
      if (
        orderTypeFilter === "purchaseorders" &&
        mutation.purchaseOrderID != null
      ) {
        return mutation;
      } else if (
        orderTypeFilter === "salesorders" &&
        mutation.purchaseOrderID == null
      ) {
        return mutation;
      } else if (orderTypeFilter === "") {
        return mutation;
      } else {
        return null;
      }
    });

  const limit = 10;
  const totalPages = Math.ceil(filteredData.length / limit);
  const offset = (currentPage - 1) * limit;

  const mutationList = filteredData
    .sort((a, b) => {
      if (tableOrder === "orderdate") {
        return a.orderDate.localeCompare(b.orderDate);
      } else {
        if (a.deliverDateForOrder == null && b.deliverDateForOrder == null) {
          return a.orderDate.localeCompare(b.orderDate);
        }
        if (a.deliverDateForOrder === null) return 1;
        if (b.deliverDateForOrder === null) return -1;
        return a.deliverDateForOrder.localeCompare(b.deliverDateForOrder);
      }
    })
    .slice(offset, offset + limit)
    .map((mutation, i) => {
      return (
        <tr key={i} className={mutation.purchaseOrderID ? "purchaseOrder" : ""}>
          <td className="noWrapCell">
            <Link
              className="item"
              to={
                mutation.orderID
                  ? `/sales/orders/edit/${mutation.orderID}`
                  : `/purchasing/purchaseorders/edit/${mutation.purchaseOrderID}`
              }
            >
              <div className="item">{mutation.name}</div>
            </Link>
          </td>
          <td className="center aligned mobile hidden">
            {util.utcStringToLocalDate(mutation.orderDate)}
          </td>
          <td
            className="center aligned "
            style={mutation.deliverDate ? {} : { opacity: "0.5" }}
          >
            {util.utcStringToLocalDate(mutation.deliverDateForOrder)}
          </td>
          <td className="right aligned">{mutation.quantity}</td>
          <td
            className="right aligned"
            style={
              mutation.technicalStock < 0
                ? { backgroundColor: "var(--company-color-red-50)" }
                : {}
            }
          >
            {mutation.technicalStock}
          </td>
          <td className="right aligned mobile hidden">
            {mutation.economicStock}
          </td>
        </tr>
      );
    });

  const renderTable = inventoryItem.mutations.length > 0 && (
    <>
      <table className="ui celled table unstackable">
        <thead>
          <tr>
            <th className="nine wide">Name</th>
            <th
              className="two wide mobile hidden table-header-action"
              onClick={() => {
                setTableOrder("orderdate");
                setCurrentPage(1);
              }}
            >
              Order Date
              {tableOrder === "orderdate" && <i className="caret down icon" />}
            </th>
            <th
              className="two wide table-header-action"
              onClick={() => {
                setTableOrder("deliverdate");
                setCurrentPage(1);
              }}
            >
              Deliver Date
              {tableOrder === "deliverdate" && (
                <i className="caret down icon" />
              )}
            </th>
            <th className="one wide right aligned">&#916;</th>
            <th className="one wide right aligned">Technical</th>
            <th className="one wide mobile hidden right aligned">Econcomic</th>
          </tr>
        </thead>
        <tbody>{mutationList}</tbody>
      </table>
      <Pagination
        activePage={currentPage}
        totalPages={totalPages}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        siblingRange={1}
        onPageChange={(e, props) => setCurrentPage(props.activePage)}
      />
    </>
  );

  const renderDetails = (
    <div className="inventoryItemDetail">
      <div>
        <Link
          className="item"
          to={`/inventory/articles/edit/${inventoryItem.article.articleID}`}
        >
          <h4 className="ui header" style={{ color: "#4183c4" }}>
            <i className="edit icon"></i>
            <div className="content">
              <div className="item">{inventoryItem.article.name}</div>
            </div>
          </h4>
        </Link>
      </div>
      <div>
        <Table celled definition unstackable>
          <Table.Body>
            <Table.Row>
              <Table.Cell className="noWrapCell" width={10}>
                Price (€):
              </Table.Cell>
              <Table.Cell className="right aligned">
                {util.formatDecimal(inventoryItem.article.price)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="noWrapCell" width={10}>
                Reorderpoint:
              </Table.Cell>
              <Table.Cell className="right aligned">
                {inventoryItem.article.reorderPoint}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="noWrapCell" width={10}>
                Order Quantity:
              </Table.Cell>
              <Table.Cell className="right aligned">
                {inventoryItem.article.orderQuantity}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="noWrapCell" width={10}>
                Economic stock today:
              </Table.Cell>
              <Table.Cell className="right aligned">
                {inventoryItem.currentEconomicStock}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="noWrapCell" width={10}>
                Technical stock today:
              </Table.Cell>
              <Table.Cell className="right aligned">
                {inventoryItem.currentTechnicalStock}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="noWrapCell" width={10}>
                Total mutations:
              </Table.Cell>
              <Table.Cell className="right aligned">
                {inventoryItem.mutations.length}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </div>
  );

  return (
    <div className="ui one column grid">
      <div className="column">
        <StandardTableHeader headerLabel="Inventory Item" />
      </div>
      <div className="column">
        <div className="ui grid stackable">
          <div className="column five wide">{renderDetails}</div>
          <div className="column eleven wide">
            <InventoryChart
              data={inventoryItem.mutations}
              reorderPoint={inventoryItem.article.reorderPoint}
              currentEconomicStock={inventoryItem.currentEconomicStock}
              currentTechnicalStock={inventoryItem.currentTechnicalStock}
            />
          </div>
        </div>
      </div>
      <div className="column">
        <StandardTableHeader
          searchValue={searchFilter}
          recordCount={filteredData.length}
          searchFunction={(e) => {
            setSearchFilter(e.target.value);
            setCurrentPage(1);
          }}
        >
          <div className="inventory-item-header-filter">
            <button
              type="button"
              className="ui button"
              onClick={() => history.goBack()}
            >
              Back
            </button>
            <select
              className="ui selection dropdown"
              name="orderTypeFilter"
              onChange={(e) => {
                setOrderTypeFilter(e.target.value);
                setCurrentPage(1);
              }}
              value={orderTypeFilter}
            >
              <option key={0} value={""}>
                Select order type filter...
              </option>
              <option key={1} value={"purchaseorders"}>
                Purchase orders
              </option>
              <option key={2} value={"salesorders"}>
                Sales orders
              </option>
            </select>

            <div
              className="ui toggle checkbox"
              onClick={() => {
                setShowHistory(!showHistory);
                setCurrentPage(1);
              }}
              style={{ cursor: "pointer" }}
            >
              <input type="checkbox" name="public" checked={showHistory} />
              <label>Show History</label>
            </div>
          </div>
        </StandardTableHeader>
      </div>
      <div className="column">{renderTable}</div>
    </div>
  );
};

export default InventoryItem;
