import React from "react";
import { NavLink } from "react-router-dom";

const DesktopMenuItem = ({ menuItem: { label, path } }) => (
  <div className="desktopMenuItem">
    <NavLink to={path} activeClassName="active">
      {label}
    </NavLink>
  </div>
);

export default DesktopMenuItem;
