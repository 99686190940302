import React from "react";
import { useDispatch } from "react-redux";

import * as actions from "../../actions";
import StandardTableHeader from "../UI/StandardHeader";
import UserForm from "./UserForm";

const UserCreate = () => {
  const dispatch = useDispatch();

  const onSubmit = (fv) => {
    dispatch(actions.createUser(fv));
  };

  return (
    <div className="ui one column grid">
      <div className="column">
        <StandardTableHeader headerLabel="Create User"></StandardTableHeader>
      </div>
      <div className="column">
        <UserForm onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export default UserCreate;
