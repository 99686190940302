//import _ from 'lodash';
import React from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";

import * as actions from "../../actions";
import history from "../../history";

import VisitRoofForm from "./visitRoofForm/VisitRoofForm";
import ProjectHeader from "../projectHeader/ProjectHeader";

class VisitEdit extends React.Component {
  componentDidMount() {
    const { projectid } = this.props.match.params;

    this.props.fetchArticles();
    this.props.fetchVisit(projectid);
    this.props.fetchProjectDashboard(projectid);
  }

  onSubmit = (formValues) => {
    this.props.editVisit(
      this.props.visit.visitID,
      formValues,
      this.props.createCalculationOnSave,
      this.props.navigateToRoofSectionsOnSave
    );
  };

  createCalculation() {
    this.props.generateCalculation(
      this.props.visit.visitID,
      this.props.visit.projectID
    );
  }

  componentWillUnmount() {
    this.props.unloadVisit();
    this.props.setCalculationError("");
  }

  visitEditPart() {
    if (!this.props.visit || this.props.loading) {
      return <div>Loading...</div>;
    } else {
      return (
        <>
          <Grid.Row>
            <Grid.Column>
              <VisitRoofForm
                initialValues={this.props.visit}
                projectID={this.props.visit.projectID}
                articles={this.props.articles}
                onSubmit={this.onSubmit}
                base64map={this.props.base64map}
                imageExtension={this.props.imageExtension}
                project={this.props.project}
              />
            </Grid.Column>
          </Grid.Row>
          {(() => {
            if (this.props.calculationError) {
              return (
                <div className="row">
                  <div className="column">
                    <div className="ui error message">
                      <i
                        className="close icon"
                        onClick={() => this.props.setCalculationError("")}
                      />
                      <div className="header">Error:</div>
                      <p>{this.props.calculationError}</p>
                    </div>
                  </div>
                </div>
              );
            }
          })()}
          <Grid.Row columns={3} className="stackable">
            <Grid.Column>
              <button
                type="button"
                className="ui button primary"
                onClick={() => this.props.saveVisitAndReturn()}
              >
                Save
              </button>
              <button
                type="button"
                className="ui button "
                onClick={() => history.go(-1)}
              >
                Cancel
              </button>
            </Grid.Column>
            <Grid.Column>
              <button
                type="button"
                className="ui orange button "
                onClick={() => this.props.saveVisitAndCreateCalculation()}
              >
                Save & Create Calculation
              </button>
            </Grid.Column>
            <Grid.Column>
              <Link
                to={"/visits/delete/" + this.props.visit.projectID}
                className="button"
              >
                <div className="ui right floated negative button">
                  Delete Visit
                </div>
              </Link>
            </Grid.Column>
          </Grid.Row>
        </>
      );
    }
  }

  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <ProjectHeader project={this.props.project} />
          </Grid.Column>
        </Grid.Row>
        {this.visitEditPart()}
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    visit: state.visits.item,
    loading: state.visits.loading,
    base64map: state.visits.base64map,
    imageExtension: state.visits.imageExtension,
    articles: Object.values(state.articles.items),
    createCalculationOnSave: state.visits.createCalculationOnSave,
    navigateToRoofSectionsOnSave: state.visits.navigateToRoofSectionsOnSave,
    calculationError: state.calculations.calculationError,
    project: state.projects.projectDashboard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVisit: (id) => dispatch(actions.fetchVisit(id)),
    editVisit: (
      visitID,
      formValues,
      createCalculationOnSave,
      navigateToRoofSectionsOnSave
    ) =>
      dispatch(
        actions.editVisit(
          visitID,
          formValues,
          createCalculationOnSave,
          navigateToRoofSectionsOnSave
        )
      ),
    generateCalculation: (visitID, projectID) =>
      dispatch(actions.generateCalculation(visitID, projectID)),
    fetchArticles: () => dispatch(actions.fetchArticles()),
    unloadVisit: () => dispatch(actions.unloadVisit()),
    saveVisitAndReturn: () => dispatch(actions.saveVisitAndReturn()),
    saveVisitAndCreateCalculation: () =>
      dispatch(actions.saveVisitAndCreateCalculation()),
    saveVisitAndNavigateToRoofSections: () =>
      dispatch(actions.saveVisitAndNavigateToRoofSections()),
    setCalculationError: (error) =>
      dispatch(actions.setCalculationError(error)),
    fetchProjectDashboard: (projectid) =>
      dispatch(actions.fetchProjectDashboard(projectid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitEdit);
