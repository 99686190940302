import React from "react";
import { Field, reduxForm } from "redux-form";
import * as util from "../../../shared/utility";
import isEmail from "sane-email-validation";

class PersonForm extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <Field name="title" component={util.RenderSelect} label="Title:">
          <option key={0} value={""}>
            Select...
          </option>
          <option key={1} value={"heer"}>
            heer
          </option>
          <option key={2} value={"mevrouw"}>
            mevrouw
          </option>
          <option key={3} value={"heer en mevrouw"}>
            heer en mevrouw
          </option>
        </Field>
        <Field
          name="firstName"
          component={util.RenderInput}
          label="Initials:"
        />
        <Field
          name="middleName"
          component={util.RenderInput}
          label="Middlename:"
        />
        <Field name="lastName" component={util.RenderInput} label="Lastname:" />
        <Field name="email" component={util.RenderInput} label="Email:" />
        <Field
          name="phoneNumber"
          component={util.RenderInput}
          label="Phonenumber:"
        />
        <Field
          name="companyName"
          component={util.RenderInput}
          label="Companyname:"
        />
        <button className="ui button primary" disabled={this.props.submitting}>
          Save
        </button>
        <button
          type="button"
          className="ui button"
          onClick={this.props.onCancel}
        >
          Cancel
        </button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.lastName) {
    errors.lastName = "You must enter a lastname";
  }

  if (formValues.email) {
    if (!isEmail(formValues.email)) {
      errors.email = "Not a valid email.";
    }
  }

  if (formValues.firstName && formValues.firstName.length > 100) {
    errors.firstName = "The firstname cannot exceed 100 characters.";
  }

  if (formValues.middleName && formValues.middleName.length > 50) {
    errors.middleName = "The middlename cannot exceed 50 characters.";
  }

  if (formValues.lastName && formValues.lastName.length > 100) {
    errors.lastName = "The lastname cannot exceed 100 characters.";
  }

  if (formValues.title && formValues.title.length > 50) {
    errors.title = "The title cannot exceed 50 characters.";
  }

  if (formValues.email && formValues.email.length > 200) {
    errors.email = "The email cannot exceed 200 characters.";
  }

  if (formValues.phoneNumber && formValues.phoneNumber.length > 50) {
    errors.phoneNumber = "The phonenumber cannot exceed 50 characters.";
  }

  if (formValues.companyName && formValues.companyName.length > 100) {
    errors.companyName = "The companyname cannot exceed 100 characters.";
  }

  return errors;
};

export default reduxForm({
  form: "personForm",
  validate,
})(PersonForm);
