import React from "react";
import { Link } from "react-router-dom";

const ProjectDetail = ({ project, onEdit }) => {
  if (!project) {
    return null;
  }

  const { projectID, name, description, remarks } = project;

  return (
    <table className="ui definition table unstackable">
      <thead className="full-width">
        <tr>
          <th colSpan="2">
            <div className="ui two column grid">
              <div className="row">
                <div className="four wide column">
                  <h3>Project</h3>
                </div>
                <div className="twelve wide column right aligned">
                  <button
                    type="button"
                    className="ui button primary"
                    onClick={onEdit}
                  >
                    Edit
                  </button>
                  <Link
                    to={"/sales/projects/delete/" + projectID}
                    className="button"
                  >
                    <div className="ui negative button">Delete</div>
                  </Link>
                </div>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="three wide column">ProjectID:</td>
          <td>{projectID}</td>
        </tr>
        <tr>
          <td>Name:</td>
          <td>{name}</td>
        </tr>
        <tr>
          <td>Description:</td>
          <td>{description}</td>
        </tr>
        <tr>
          <td>Remarks:</td>
          <td style={{ whiteSpace: "pre-wrap" }}>{remarks}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default ProjectDetail;
