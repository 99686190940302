import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import history from "../../history";
import * as util from "../../shared/utility";
import isEmail from "sane-email-validation";
//import FileSelectList from '../files/FileSelectList';
import { Grid } from "semantic-ui-react";

class MailForm extends React.Component {
  onSubmit = (formValues) => {
    var arr = [];
    for (var p1 in formValues.commonFiles) {
      if (formValues.commonFiles[p1]) {
        arr.push(util.atobUTF16(p1));
      }
    }

    var arrProject = [];
    for (var p2 in formValues.projectFiles) {
      if (formValues.projectFiles[p2]) {
        arrProject.push(util.atobUTF16(p2));
      }
    }

    var fv = {
      ...formValues,
      commonFiles: arr,
      projectFiles: arrProject,
      projectID: this.props.projectid,
    };

    //Set ispreview so default preview email will be selected server side;
    if (document.activeElement.id === "previewButton") {
      fv = { ...fv, isPreview: true };
    }
    this.props.onSubmit(fv);
  };

  handleCancelClick = () => {
    history.goBack();
  };

  renderField = ({ input, label, file, type, meta: { touched, error } }) => (
    <React.Fragment>
      <td className="center aligned">
        <div className="ui checkbox">
          <input {...input} type="checkbox" name={label} value={file.name} />
          <label />
        </div>
      </td>
      <td>{label}</td>
      <td className="center aligned mobile hidden">
        {util.formatBytes(file.length)}
      </td>
      <td className="center aligned mobile hidden">
        {util.formatStringToDateTime(file.lastAccessTime)}
      </td>
      <td className="center aligned">
        <i
          style={{ cursor: "pointer" }}
          className="download icon blue"
          onClick={() => this.props.downloadFile(label)}
        />
      </td>
    </React.Fragment>
  );

  renderProjectField = ({
    input,
    label,
    file,
    type,
    meta: { touched, error },
  }) => (
    <React.Fragment>
      <td className="center aligned">
        <div className="ui checkbox">
          <input {...input} type="checkbox" name={label} value={file.name} />
          <label />
        </div>
      </td>
      <td>{label}</td>
      <td className="center aligned mobile hidden">
        {util.formatBytes(file.length)}
      </td>
      <td className="center aligned mobile hidden">
        {util.formatStringToDateTime(file.lastAccessTime)}
      </td>
      <td className="center aligned">
        <i
          style={{ cursor: "pointer" }}
          className="download icon blue"
          onClick={() =>
            this.props.downloadProjectFile(this.props.projectid, label)
          }
        />
      </td>
    </React.Fragment>
  );

  renderFiles = ({ fields, meta: { error, submitFailed } }) => (
    <table className="ui celled table unstackable">
      <thead>
        <tr>
          <th className="one wide center aligned">Select</th>
          <th className="eight wide">Name</th>
          <th className="three wide center aligned mobile hidden">Size</th>
          <th className="three wide center aligned mobile hidden">Changed</th>
          <th className="one wide center aligned">Action</th>
        </tr>
      </thead>
      <tbody>
        {this.props.files
          .filter((file) => {
            return file.name
              .toUpperCase()
              .includes(this.props.searchValue.toUpperCase());
          })
          // .sort((a, b) => b.lastAccessTime.localeCompare(a.lastAccessTime))
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((f, index) => (
            <tr key={index}>
              <Field
                name={`${"commonFiles"}.${util.btoaUTF16(f.name)}`}
                type="checkbox"
                component={this.renderField}
                label={f.name}
                file={f}
              />
            </tr>
          ))}
      </tbody>
    </table>
  );

  renderProjectFiles = ({ fields, meta: { error, submitFailed } }) => {
    if (this.props.projectFiles.length === 0) {
      return (
        <React.Fragment>
          <div>No project files...</div>
          <br />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h3>Project Files:</h3>
          <table className="ui celled table unstackable">
            <thead>
              <tr>
                <th className="one wide center aligned">Select</th>
                <th className="eight wide">Name</th>
                <th className="three wide center aligned mobile hidden">
                  Size
                </th>
                <th className="three wide center aligned mobile hidden">
                  Changed
                </th>
                <th className="one wide center aligned">Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.projectFiles
                .sort((a, b) =>
                  b.lastAccessTime.localeCompare(a.lastAccessTime)
                )
                .map((f, index) => (
                  <tr key={index}>
                    <Field
                      name={`${"projectFiles"}.${util.btoaUTF16(f.name)}`}
                      type="checkbox"
                      component={this.renderProjectField}
                      label={f.name}
                      file={f}
                    />
                  </tr>
                ))}
            </tbody>
          </table>
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <Field name="email" component={util.RenderInput} label="To Email:" />
        <Field name="subject" component={util.RenderInput} label="Subject:" />
        <Field
          name="body"
          component={util.RenderInputMultilineHtml}
          label="Body:"
        />
        <FieldArray
          name="projectFilesFieldArray"
          component={this.renderProjectFiles}
        />
        <br />
        <Grid className="middle aligned">
          <Grid.Row>
            <Grid.Column width={4}>
              <h3>Common Files:</h3>
            </Grid.Column>
            <Grid.Column width={12} textAlign="right">
              <div className="ui right floated icon input">
                <div
                  className="ui label center"
                  style={{ marginRight: 10, paddingTop: 12, minWidth: 80 }}
                >
                  Total:
                  <div className="detail">
                    {
                      this.props.files.filter((file) => {
                        return file.name
                          .toUpperCase()
                          .includes(this.props.searchValue.toUpperCase());
                      }).length
                    }
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Search..."
                  value={this.props.searchValue}
                  onChange={this.props.updatesearchValue}
                  style={{ width: 200 }}
                />
                <i className="search link icon" />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <FieldArray
                name="commonFilesFieldArray"
                component={this.renderFiles}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {(() => {
          if (this.props.showmessage) {
            return (
              <div className="ui positive message">
                <i
                  className="close icon"
                  onClick={this.props.clearMailResponseMessage}
                />
                <div className="header">Mail response:</div>
                <p>{this.props.responsemessage}</p>
              </div>
            );
          }
        })()}
        <br />
        <button
          className="ui button orange"
          id="previewButton"
          disabled={this.props.submitting}
        >
          Preview
        </button>
        <button className="ui button green" disabled={this.props.submitting}>
          Send
        </button>
        <button
          type="button"
          className="ui button"
          onClick={this.handleCancelClick}
        >
          Cancel
        </button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (formValues.email) {
    if (!isEmail(formValues.email)) {
      errors.email = "Not a valid email.";
    }
  } else {
    errors.email = "A valid email is required.";
  }

  if (!formValues.subject) {
    errors.subject = "You must provide a subject.";
  }

  if (!formValues.body) {
    errors.body = "You cannot send an empty body.";
  }

  return errors;
};

export default reduxForm({
  form: "mailForm",
  validate,
})(MailForm);
