import React, { useState } from "react";
import { useDispatch } from "react-redux";

import * as actions from "../../actions";
import StandardTableHeader from "../UI/StandardHeader";
import RoofSectionForm from "./RoofSectionForm";
import * as util from "../../shared/utility";

const RoofSectionCreateOnOverview = ({ projectID, articles }) => {
  const dispatch = useDispatch();
  const [newRoofSectionActive, setNewRoofSection] = useState(false);

  // const articles = useSelector((state) => Object.values(state.articles.items));

  const onSubmit = (fv) => {
    dispatch(actions.createRoofSectionForProject(fv, projectID));
    setNewRoofSection(false);
  };

  const handleCancelClick = () => {
    setNewRoofSection(false);
  };

  const MountingArticles = articles
    .filter((article) => {
      return article.categoryID === 3;
    })
    .sort((a, b) =>
      a.priority < b.priority
        ? 1
        : a.priority > b.priority
        ? -1
        : a.name.toLowerCase() < b.name.toLowerCase()
        ? -1
        : a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : 0
    );

  if (MountingArticles.length === 0) {
    return <div>Error: no articles in mounting category.</div>;
  }

  const initialVal = {
    mountingArticleID: MountingArticles[0].articleID,
    roofPitch: 0,
    roofType: util.roofType.PITCHED,
  };

  return newRoofSectionActive ? (
    <div className="column forcusAreaOnForms">
      <StandardTableHeader headerLabel="New Roof Sections" />
      <RoofSectionForm
        onSubmit={onSubmit}
        articles={articles}
        initialValues={initialVal}
        handleCancelClick={handleCancelClick}
        projectID={projectID}
      />
    </div>
  ) : (
    <div className="column">
      <div
        onClick={() => setNewRoofSection(true)}
        className="ui primary button"
      >
        New
      </div>
    </div>
  );
};

export default RoofSectionCreateOnOverview;
