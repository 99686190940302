import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import ReactDOM from "react-dom";

const StickyMessage = () => {
  const dispatch = useDispatch();
  const globalErrors = useSelector((state) => state.utility.globalErrors);

  return (
    globalErrors.length !== 0 &&
    ReactDOM.createPortal(
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          // background: "yellow",
          // opacity: "0.2",
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // onClick={() => dispatch(actions.setGlobalErrors([]))}
      >
        <div
          className="ui negative message"
          style={{
            width: "90vw",
            maxWidth: "800px",
          }}
          // onClick={(e) => e.stopPropagation()}
        >
          <i
            className="close icon"
            onClick={() => dispatch(actions.setGlobalErrors([]))}
          />
          <div className="header">
            {globalErrors.length === 1
              ? "An error occured:"
              : "Errors occured:"}
          </div>
          <ul className="list">
            {globalErrors.map((error, i) => (
              <li key={i}>{error}</li>
            ))}
          </ul>
        </div>
      </div>,
      document.querySelector("#modal")
    )
  );
};

export default StickyMessage;
