import React, { useState } from "react";
import { useDispatch } from "react-redux";

import * as actions from "../../actions";
import PurchaseOrderRowForm from "./PurchaseOrderRowForm";

const PurchaseOrderRowCreateOnOverview = ({ purchaseOrderID, articles }) => {
  const dispatch = useDispatch();
  const [newPurchaseOrderRowActive, setNewPurchaseOrderRow] = useState(false);

  const onSubmit = (fv) => {
    dispatch(
      actions.createPurchaseOrderRowForPurchaseOrder(fv, purchaseOrderID)
    );
    setNewPurchaseOrderRow(false);
  };

  const handleCancelClick = () => {
    setNewPurchaseOrderRow(false);
  };

  const initialVal = {
    // quantity: 1,
    btwID: 1,
    purchaseOrderID: purchaseOrderID,
  };

  return newPurchaseOrderRowActive ? (
    <div className="column forcusAreaOnForms">
      {/* <StandardTableHeader headerLabel="New Roof Sections" /> */}
      <PurchaseOrderRowForm
        onSubmit={onSubmit}
        articles={articles}
        initialValues={initialVal}
        handleCancelClick={handleCancelClick}
        purchaseOrderID={purchaseOrderID}
      />
    </div>
  ) : (
    <div className="column">
      <button
        type="button"
        className="ui primary button "
        onClick={() => setNewPurchaseOrderRow(true)}
        autoFocus
      >
        New
      </button>
    </div>
  );
};

export default PurchaseOrderRowCreateOnOverview;
