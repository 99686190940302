import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import moment from "moment";

import * as actions from "../../actions";
import OrderForm from "./OrderForm";
import ProjectBreadcrumb from "../breadcrumb/ProjectBreadcrumb";
import StandardTableHeader from "../UI/StandardHeader";

class OrderEdit extends React.Component {
  componentDidMount() {
    const { orderid } = this.props.match.params;
    this.props.fetchOrder(orderid);
  }

  onSubmit = (fv) => {
    const { orderid } = this.props.match.params;
    this.props.editOrder(orderid, fv, this.props.projectID);
  };

  componentWillUnmount() {
    this.props.unloadOrder();
  }

  generateInvoice() {
    const { orderid } = this.props.match.params;
    this.props.generateInvoice(orderid, this.props.projectID);
  }

  render() {
    if (!this.props.order || !this.props.projectID) {
      return <div>Loading...</div>;
    }

    const initialVal = {
      ...this.props.order,
      orderDate: moment(this.props.order.orderDate).format("YYYY-MM-DD"),
      plannedDeliverDate: this.props.order.plannedDeliverDate
        ? moment(this.props.order.plannedDeliverDate).format("YYYY-MM-DD")
        : null,
      deliverDate: this.props.order.deliverDate
        ? moment(this.props.order.deliverDate).format("YYYY-MM-DD")
        : null,
    };

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <StandardTableHeader headerLabel="Edit Order">
              <ProjectBreadcrumb
                projectID={this.props.projectID}
                currentPath="Order"
              />
            </StandardTableHeader>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <OrderForm
              initialValues={initialVal}
              onSubmit={this.onSubmit}
              projectID={this.props.projectID}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <button
              type="button"
              className="ui orange button "
              onClick={() => this.generateInvoice()}
            >
              Create Invoice
            </button>
          </Grid.Column>
          <Grid.Column width={8}>
            <Link
              to={"/sales/trellos/create/" + this.props.order.orderID}
              className="button"
            >
              <div className="ui right floated green button">
                Send to Trello
              </div>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    order: state.orders.item,
    projectID: state.orders.projectID,
    isMobile: state.utility.isMobile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrder: (id) => dispatch(actions.fetchOrder(id)),
    unloadOrder: () => dispatch(actions.unloadOrder()),
    editOrder: (id, formValues, projectID) =>
      dispatch(actions.editOrder(id, formValues, projectID)),
    generateInvoice: (orderID, projectID) =>
      dispatch(actions.generateInvoice(orderID, projectID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderEdit);
