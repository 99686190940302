import _ from "lodash";

import {
  FETCH_ORDER_FOR_PROJECT,
  FETCH_ORDER,
  UNLOAD_ORDER,
  FETCH_ORDER_PROJECTID,
  UNLOAD_ORDERS,
  GENERATE_PDF_ORDER,
  RESET_ORDERS_MESSAGE,
  FETCH_ORDERS_PAGED,
  SET_ORDERS_PAGE_FILTER,
  SET_ORDERS_PAGE_NUMBER,
  RESET_ORDERS_FILTER,
  TOGGLE_SHOW_INVOICED,
  SET_ORDERS_VISITOR_FILTER,
  TOGGLE_SHOW_PLANNED,
  FETCH_ORDERS_PAGED_PLANNING,
  SET_ORDERS_PAGE_FILTER_PLANNING,
  SET_ORDERS_PAGE_NUMBER_PLANNING,
  RESET_ORDERS_FILTER_PLANNING,
  TOGGLE_SHOW_INVOICED_PLANNING,
  SET_ORDERS_VISITOR_FILTER_PLANNING,
  TOGGLE_SHOW_PLANNED_PLANNING,
  UPDATE_ORDER_DELIVER_DATE,
} from "../actions/types";

var initialState = {
  responsemessage: "",
  showmessage: false,
  items: [],
  item: null,
  projectID: null,

  pageItems: {},
  pageMetadata: {},
  pageNumber: 1,
  pageSize: 10,
  pageFilter: "",
  showInvoiced: false,
  showPlanned: true,
  visitorFilter: "",

  pageItemsPlanning: {},
  pageMetadataPlanning: {},
  pageNumberPlanning: 1,
  pageSizePlanning: 10,
  pageFilterPlanning: "",
  showInvoicedPlanning: false,
  showPlannedPlanning: false,
  visitorFilterPlanning: "",

  lastOrderDeliverDateUpdate: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER:
      return {
        ...state,
        item: action.payload,
      };
    case UNLOAD_ORDER:
      return {
        ...state,
        item: null,
        projectID: null,
      };
    case UNLOAD_ORDERS:
      return {
        ...state,
        responsemessage: "",
        showmessage: false,
        items: [],
        item: null,
        projectID: null,
      };
    case FETCH_ORDER_FOR_PROJECT:
      return {
        ...state,
        items: action.payload,
      };
    case FETCH_ORDER_PROJECTID:
      return {
        ...state,
        projectID: action.payload,
      };
    case GENERATE_PDF_ORDER:
      return {
        ...state,
        responsemessage: action.payload,
        showmessage: true,
      };
    case RESET_ORDERS_MESSAGE:
      return {
        ...state,
        responsemessage: "",
        showmessage: false,
      };

    case FETCH_ORDERS_PAGED:
      return {
        ...state,
        pageItems: {
          ..._.mapKeys(action.payload.result, "orderID"),
        },
        pageMetadata: { ...action.payload.metadata },
      };
    case SET_ORDERS_PAGE_FILTER:
      return {
        ...state,
        pageFilter: action.payload,
        pageNumber: 1,
      };
    case SET_ORDERS_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case SET_ORDERS_VISITOR_FILTER:
      return {
        ...state,
        visitorFilter: action.payload,
        pageNumber: 1,
      };
    case RESET_ORDERS_FILTER:
      return {
        ...state,
        pageFilter: "",
        showInvoiced: initialState.showInvoiced,
        pageNumber: 1,
        visitorFilter: "",
        showPlanned: initialState.showPlanned,
      };
    case TOGGLE_SHOW_PLANNED:
      return {
        ...state,
        showPlanned: !state.showPlanned,
      };
    case TOGGLE_SHOW_INVOICED:
      return {
        ...state,
        showInvoiced: !state.showInvoiced,
        pageNumber: 1,
      };

    case FETCH_ORDERS_PAGED_PLANNING:
      return {
        ...state,
        pageItemsPlanning: {
          ..._.mapKeys(action.payload.result, "orderID"),
        },
        pageMetadataPlanning: { ...action.payload.metadata },
      };
    case SET_ORDERS_PAGE_FILTER_PLANNING:
      return {
        ...state,
        pageFilterPlanning: action.payload,
        pageNumberPlanning: 1,
      };
    case SET_ORDERS_PAGE_NUMBER_PLANNING:
      return {
        ...state,
        pageNumberPlanning: action.payload,
      };
    case SET_ORDERS_VISITOR_FILTER_PLANNING:
      return {
        ...state,
        visitorFilterPlanning: action.payload,
        pageNumberPlanning: 1,
      };
    case RESET_ORDERS_FILTER_PLANNING:
      return {
        ...state,
        pageFilterPlanning: "",
        showInvoicedPlanning: initialState.showInvoicedPlanning,
        pageNumberPlanning: 1,
        visitorFilterPlanning: "",
        showPlannedPlanning: initialState.showPlannedPlanning,
      };
    case TOGGLE_SHOW_PLANNED_PLANNING:
      return {
        ...state,
        showPlannedPlanning: !state.showPlannedPlanning,
      };
    case TOGGLE_SHOW_INVOICED_PLANNING:
      return {
        ...state,
        showInvoicedPlanning: !state.showInvoicedPlanning,
        pageNumber: 1,
      };
    case UPDATE_ORDER_DELIVER_DATE:
      return {
        ...state,
        lastOrderDeliverDateUpdate: action.payload.lastUpdatedDateTime,
      };
    default:
      return state;
  }
};
