import solartoolapi from "../apis/solartoolapi";
import history from "../history";
import {
  CREATE_ARTICLE,
  FETCH_ARTICLES,
  FETCH_ARTICLE,
  DELETE_ARTICLE,
  EDIT_ARTICLE,
  SET_ARTICLES_FILTER,
  SET_ARTICLES_OFFSET,
  FETCH_INVENTORY,
  SET_INVENTORY_FILTER,
  SET_INVENTORY_OFFSET,
  TOGGLE_SHOW_ONLY_ACTION_REQUIRED,
  SET_INVENTORY_LIST_ARTICLE_INVENTORY_CATEGORY_FILTER,
  RESET_INVENTORY_LIST_FILTER,
  FETCH_INVENTORY_ITEM,
  UNLOAD_INVENTORY_ITEM,
  SET_INVENTORY_LIST_ARTICLE_CATEGORY_FILTER,
  TOGGLE_INVENTORY_LIST_INVENTORY_ORDER_ECONOMIC,
} from "./types";
import { authHeader } from "../shared/utility";

export const createArticle = (formValues) => async (dispatch, getState) => {
  const response = await solartoolapi.post(
    "/articles",
    {
      ...formValues,
    },
    { headers: authHeader() }
  );

  dispatch({ type: CREATE_ARTICLE, payload: response.data.result });
  dispatch({ type: SET_ARTICLES_FILTER, payload: "" });
  history.push("/inventory/articles");
};

export const editArticle = (id, formValues) => async (dispatch) => {
  const response = await solartoolapi.put(`/articles/${id}`, formValues, {
    headers: authHeader(),
  });

  dispatch({ type: EDIT_ARTICLE, payload: response.data.result });
  history.goBack();
};

export const fetchInventoryItem = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/articles/inventoryitem/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_INVENTORY_ITEM, payload: response.data.result });
};

export const unloadInventoryItem = () => async (dispatch) => {
  dispatch({ type: UNLOAD_INVENTORY_ITEM });
};

export const deleteArticle = (id) => async (dispatch) => {
  await solartoolapi.delete(`/articles/${id}`, { headers: authHeader() });

  dispatch({ type: DELETE_ARTICLE, payload: id });
  history.push("/inventory/articles");
};

export const fetchArticle = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/articles/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_ARTICLE, payload: response.data.result });
};

export const fetchArticles = () => async (dispatch) => {
  const response = await solartoolapi.get("/articles", {
    headers: authHeader(),
  });
  dispatch({ type: FETCH_ARTICLES, payload: response.data.result });
};

export const fetchInventory = () => async (dispatch) => {
  const response = await solartoolapi.get("/articles/inventory", {
    headers: authHeader(),
  });
  dispatch({ type: FETCH_INVENTORY, payload: response.data.result });
};

export const setArticlesFilter = (searchText) => (dispatch) => {
  dispatch({ type: SET_ARTICLES_FILTER, payload: searchText });
};

export const setArticlesOffset = (offset) => (dispatch) => {
  dispatch({ type: SET_ARTICLES_OFFSET, payload: offset });
};

export const setInventoryFilter = (searchText) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_FILTER, payload: searchText });
};

export const setInventoryOffset = (offset) => (dispatch) => {
  dispatch({ type: SET_INVENTORY_OFFSET, payload: offset });
};

export const toggleShowOnlyActionRequired = () => (dispatch) => {
  dispatch({ type: TOGGLE_SHOW_ONLY_ACTION_REQUIRED });
};

export const setInventoryListArticleInventoryCategoryFilter =
  (v) => (dispatch) => {
    dispatch({
      type: SET_INVENTORY_LIST_ARTICLE_INVENTORY_CATEGORY_FILTER,
      payload: v,
    });
  };

export const setInventoryListArticleCategoryFilter = (v) => (dispatch) => {
  dispatch({
    type: SET_INVENTORY_LIST_ARTICLE_CATEGORY_FILTER,
    payload: v,
  });
};

export const resetInventoryListFilter = () => (dispatch) => {
  dispatch({ type: RESET_INVENTORY_LIST_FILTER });
};

export const toggleInventoryListInventoryOrderEconomic = () => (dispatch) => {
  dispatch({ type: TOGGLE_INVENTORY_LIST_INVENTORY_ORDER_ECONOMIC });
};
