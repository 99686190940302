import solartoolapi from "../apis/solartoolapi";
import history from "../history";
import {
  CREATE_MAILTEMPLATE,
  FETCH_MAILTEMPLATES,
  FETCH_MAILTEMPLATE,
  DELETE_MAILTEMPLATE,
  EDIT_MAILTEMPLATE,
  SET_MAILTEMPLATES_FILTER,
  SET_MAILTEMPLATES_OFFSET,
} from "./types";
import { authHeader } from "../shared/utility";

export const createMailTemplate =
  (formValues) => async (dispatch, getState) => {
    const response = await solartoolapi.post(
      "/mailtemplates",
      {
        ...formValues,
      },
      { headers: authHeader() }
    );

    dispatch({ type: CREATE_MAILTEMPLATE, payload: response.data.result });
    dispatch({ type: SET_MAILTEMPLATES_FILTER, payload: "" });
    history.push("/application/mailtemplates");
  };

export const editMailTemplate = (id, formValues) => async (dispatch) => {
  const response = await solartoolapi.put(`/mailtemplates/${id}`, formValues, {
    headers: authHeader(),
  });

  dispatch({ type: EDIT_MAILTEMPLATE, payload: response.data.result });
  history.goBack();
};

export const deleteMailTemplate = (id) => async (dispatch) => {
  await solartoolapi.delete(`/mailtemplates/${id}`, { headers: authHeader() });

  dispatch({ type: DELETE_MAILTEMPLATE, payload: id });
  history.push("/application/mailtemplates");
};

export const fetchMailTemplate = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/mailtemplates/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_MAILTEMPLATE, payload: response.data.result });
};

export const fetchMailTemplates = () => async (dispatch) => {
  const response = await solartoolapi.get("/mailtemplates", {
    headers: authHeader(),
  });
  dispatch({ type: FETCH_MAILTEMPLATES, payload: response.data.result });
};

export const setMailTemplatesFilter = (searchText) => (dispatch) => {
  dispatch({ type: SET_MAILTEMPLATES_FILTER, payload: searchText });
};

export const setMailTemplatesOffset = (offset) => (dispatch) => {
  dispatch({ type: SET_MAILTEMPLATES_OFFSET, payload: offset });
};
