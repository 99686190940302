import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Pagination, Popup } from "semantic-ui-react";

import * as actions from "../../actions";
import * as util from "../../shared/utility";
import StandardTableHeader from "../UI/StandardHeader";
import PurchaseOrderSection from "../purchaseOrders/PurchaseOrderSection";

const InventoryList = () => {
  const dispatch = useDispatch();

  const [checkedIds, setCheckedIds] = useState([]);
  const [purchaseOrderSectionActive, setPurchaseOrderSectionActive] =
    useState(false);

  const toggleCheckBox = (id) => {
    if (checkedIds.indexOf(id) === -1) {
      setCheckedIds((checkedIds) => [...checkedIds, id]);
    } else {
      setCheckedIds((checkedOrderIds) => [
        ...checkedIds.filter((item) => item !== id),
      ]);
    }
  };

  const inventory = useSelector((state) =>
    Object.values(state.articles.inventoryFilteredItems)
  );

  const categories = useSelector((state) => state.categories.items);

  const inventoryOrderEconomic = useSelector(
    (state) => state.articles.inventoryOrderEconomic
  );

  const inventoryFilter = useSelector(
    (state) => state.articles.inventoryFilter
  );

  const inventoryOffset = useSelector(
    (state) => state.articles.inventoryOffset
  );

  const limit = useSelector((state) => state.articles.articlesLimit);

  const showOnlyActionRequired = useSelector(
    (state) => state.articles.showOnlyActionRequired
  );

  const inventoryListArticleInventoryCategoryFilter = useSelector(
    (state) => state.articles.inventoryListArticleInventoryCategoryFilter
  );

  const inventoryListArticleCategoryFilter = useSelector(
    (state) => state.articles.inventoryListArticleCategoryFilter
  );

  const pages = Math.ceil(inventory.length / limit);
  const activePage = 1 + inventoryOffset / limit;

  useEffect(() => {
    dispatch(actions.fetchInventory());

    if (categories.length === 0) {
      dispatch(actions.fetchCategories());
    }
  }, []);

  const handlePageChange = (e) => {
    const newOffset = (e.activePage - 1) * limit;
    dispatch(actions.setInventoryOffset(newOffset));
  };

  const onSubmit = (fv) => {
    dispatch(
      actions.createPurchaseOrderFromIds(
        checkedIds,
        inventoryOrderEconomic ? "economic" : "technical",
        fv.orderDate,
        fv.plannedDeliverDate,
        fv.deliverDate,
        fv.relationID
      )
    );
  };

  const getABC = (param) => {
    switch (param) {
      case 0:
        return "-";
      case 1:
        return "A";
      case 2:
        return "B";
      case 3:
        return "C";
      default:
        return "";
    }
  };

  const purchaseOrderRowList = inventory
    .sort((a, b) => a.diff - b.diff)
    .slice(inventoryOffset, inventoryOffset + limit)
    .map((article) => {
      return (
        <React.Fragment key={article.articleID}>
          <tr>
            <td
              rowSpan="2"
              className="center aligned checkboxDiv"
              onClick={() => toggleCheckBox(article.articleID)}
            >
              <input
                type="checkbox"
                checked={checkedIds.includes(article.articleID)}
                onChange={() => {}}
              />
            </td>
            <td colSpan={10} className="noWrapCell">
              <Link
                className="item"
                to={`/inventory/inventoryitem/${article.articleID}`}
              >
                <div className="item">{article.name}</div>
              </Link>
            </td>
            <td
              rowSpan={2}
              className={`right aligned ${
                article.technicalStock >= article.reorderPoint &&
                article.economicStock < article.reorderPoint
                  ? "urgent"
                  : article.technicalStock < article.reorderPoint &&
                    article.economicStock < article.reorderPoint
                  ? "very-urgent"
                  : article.technicalStock < article.reorderPoint &&
                    article.economicStock >= article.reorderPoint
                  ? "not-urgent"
                  : null
              }`}
            >
              {article.diff}
            </td>
            <td rowSpan={2} className="center aligned">
              <Link
                className="item"
                to={`/inventory/articles/edit/${article.articleID}`}
                key={article.articleID}
              >
                <i className="edit icon" />
              </Link>
            </td>
          </tr>
          <tr>
            <td className="mobile hidden">{article.articleID}</td>
            <td className="mobile hidden center aligned">
              {article.remarks && (
                <Popup
                  wide="very"
                  position="bottom right"
                  trigger={
                    <i
                      className="comment alternate outline icon"
                      style={{
                        color: "var(--company-color-orange-75)",
                      }}
                    />
                  }
                >
                  <Popup.Content style={{ whiteSpace: "pre-wrap" }}>
                    {article.remarks}
                  </Popup.Content>
                </Popup>
              )}
            </td>
            <td className="mobile hidden right aligned">
              {getABC(article.articleInventoryCategory)}
            </td>
            <td className="mobile hidden right aligned">
              {util.formatDecimal(article.price)}
            </td>
            <td className="mobile hidden right aligned">
              {article.technicalStock}
            </td>
            <td className="mobile hidden right aligned">
              {article.purchasedNotDelivered}
            </td>
            <td className="mobile hidden right aligned">
              {article.soldNotDelivered}
            </td>
            <td className="mobile hidden right aligned">
              {article.economicStock}
            </td>
            <td className="mobile hidden right aligned">
              {article.orderQuantity}
            </td>
            <td className="mobile hidden right aligned">
              {article.reorderPoint}
            </td>
          </tr>
        </React.Fragment>
      );
    });

  const renderTable = (
    <>
      <table className="ui celled structured table unstackable">
        <thead>
          <tr>
            <th rowSpan="2" className="one wide"></th>
            <th colSpan={10} className="twelve wide ">
              Article
            </th>
            <th
              rowSpan="2"
              className="two wide right aligned table-header-action"
              title={`${
                inventoryOrderEconomic ? "Economic " : "Technical "
              }Stock minus Reorder Point`}
              onClick={() =>
                dispatch(actions.toggleInventoryListInventoryOrderEconomic())
              }
            >
              <div>
                {inventoryOrderEconomic ? "Economic " : "Technical "}
                - ROP
                <i className="caret down icon" />
              </div>
            </th>
            <th rowSpan="2" className="one wide center aligned">
              Action
            </th>
          </tr>
          <tr>
            <th className="one wide mobile hidden">ID</th>
            <th className="one wide mobile hidden ">Remarks</th>
            <th className="one wide mobile hidden right aligned">ABC</th>
            <th className="two wide mobile hidden right aligned">Price (€)</th>
            <th className="two wide mobile hidden right aligned">
              Technical Stock
            </th>
            <th className="one wide mobile hidden right aligned">Incomming</th>
            <th className="one wide mobile hidden right aligned">Outgoing</th>
            <th className="one wide mobile hidden right aligned">
              Economic Stock
            </th>
            <th
              className="one wide mobile hidden right aligned"
              title="Order Quantity"
            >
              OQ
            </th>
            <th
              className="one wide mobile hidden right aligned"
              title="Reorder Point"
            >
              ROP
            </th>
          </tr>
        </thead>
        <tbody>{purchaseOrderRowList}</tbody>
      </table>
      <div className="table-bottom-pagination-container">
        <Pagination
          activePage={activePage}
          totalPages={pages}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          onPageChange={(e, props, offset) => handlePageChange(props)}
        />
        <Popup
          wide="very"
          position="bottom right"
          trigger={
            <i
              className="info circle icon big"
              style={{
                color: "var(--company-color-orange-75)",
                margin: "6px",
              }}
            />
          }
        >
          <Popup.Content>
            <h3>Inventory:</h3>
            <p>
              You can switch between economic and technical by clicking the diff
              header. This will also change the way a purchase order is created:
              <br />
              The amount that will be ordered is x times the orderquantity (OQ)
              which will get either the technical stock or the economic stock
              above the reorderpoint (ROP) depending on the diff header.
            </p>
            <p>
              <b>Technical stock:</b> all items that are currently in inventory.
              <br />
              <b>Economic stock:</b> the difference between all items purchased
              and all items sold.
            </p>
            <p>
              The meaning of the colors: <br />- dark orange: very-urgent -
              {"> "}economic stock and technical stock below reorderpoint.{" "}
              <br />- orange: only economic stock below reorderpoint.
              <br />- yellow: only technical stock below reorderpoint.
              <br />- white: no action required -{"> "}technical stock and
              economic stock are above reorderpoint.
            </p>
            <p>
              If "show only action required" is selected the white rows will not
              be shown.
            </p>
          </Popup.Content>
        </Popup>
      </div>
    </>
  );

  const InventoryListFilter = () => {
    return (
      <div className="pageFilterRow">
        <select
          className="ui selection dropdown "
          name="articleCategory"
          label="Category:"
          onChange={(e) =>
            dispatch(
              actions.setInventoryListArticleCategoryFilter(e.target.value)
            )
          }
          value={inventoryListArticleCategoryFilter}
        >
          <option key={0} value={""}>
            Select category...
          </option>
          {categories.map((x) => (
            <option key={x.categoryID} value={x.categoryID}>
              {x.name}
            </option>
          ))}
        </select>
        <select
          className="ui selection dropdown"
          name="articleInventoryCategory"
          label="Category:"
          onChange={(e) =>
            dispatch(
              actions.setInventoryListArticleInventoryCategoryFilter(
                e.target.value
              )
            )
          }
          value={inventoryListArticleInventoryCategoryFilter}
        >
          <option key={0} value={""}>
            all inventory categories
          </option>
          {util.ARTICLE_INVENTORY_CATEGORY.map((x) => (
            <option key={x.Key} value={x.Key}>
              {x.Name}
            </option>
          ))}
        </select>
        <div
          className="ui toggle checkbox"
          onClick={() => dispatch(actions.toggleShowOnlyActionRequired())}
          style={{ cursor: "pointer" }}
        >
          <input
            type="checkbox"
            name="public"
            defaultChecked={showOnlyActionRequired}
          />
          <label>Show Only Action Required</label>
        </div>
        <div>
          <button
            id="pageFilterButton"
            className="ui button labeled icon "
            onClick={() => dispatch(actions.resetInventoryListFilter())}
            title="Reset filters."
            style={{
              backgroundColor: "var(--company-bg-color-50)",
              marginLeft: "10px",
            }}
          >
            <i className="undo icon" />
            Reset Filters
          </button>
        </div>
      </div>
    );
  };

  const ArticleSelection = () => {
    return (
      <div className="pageFilterRow">
        <div>Items selected: {checkedIds.length}</div>
        <button
          className="ui button labeled icon primary"
          onClick={() => setPurchaseOrderSectionActive(true)}
          disabled={checkedIds.length === 0}
          title="Create purchase order."
        >
          <i className="file shopping cart icon" />
          Create Purchase Order
        </button>
        <button
          className="ui button labeled icon"
          onClick={() => setCheckedIds([])}
          title="Reset selection."
          style={{
            backgroundColor: "var(--company-bg-color-50)",
            marginLeft: "10px",
          }}
        >
          <i className="undo icon" />
          Reset Selection
        </button>
      </div>
    );
  };

  const initialVal = {
    orderDate: new Date().toISOString().split("T")[0],
  };

  return (
    <div className="ui one column grid">
      <div className="column">
        <div className="row">
          <StandardTableHeader
            headerLabel="Inventory"
            recordCount={inventory.length}
            searchValue={inventoryFilter}
            searchFunction={(e) =>
              dispatch(actions.setInventoryFilter(e.target.value))
            }
          ></StandardTableHeader>
        </div>
        <div className="row">
          <InventoryListFilter />
        </div>
        <div className="row">
          <ArticleSelection />
        </div>
      </div>
      <div className="column">{renderTable}</div>
      {purchaseOrderSectionActive && (
        <PurchaseOrderSection
          setPurchaseOrderSectionActive={setPurchaseOrderSectionActive}
          purchaseOrderSectionActive={purchaseOrderSectionActive}
          checkedIds={checkedIds}
          inventoryOrderEconomic={inventoryOrderEconomic}
          onSubmit={onSubmit}
          initialValues={initialVal}
        />
      )}
    </div>
  );
};

export default InventoryList;
