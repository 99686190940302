import {
  FETCH_VISIT_START,
  FETCH_VISIT,
  FETCH_VISIT_END,
  CREATE_VISIT,
  DELETE_VISIT,
  UNLOAD_VISIT,
  FETCH_VISIT_MAP,
  SET_VISIT_CREATE_CALCULATION_ON_SAVE,
  SET_VISIT_NAVIGATE_TO_ROOF_SECTIONS_ON_SAVE,
} from "../actions/types";

var initialState = {
  item: null,
  base64map: null,
  createCalculationOnSave: false,
  navigateToRoofSectionsOnSave: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VISIT_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_VISIT:
      const visit = {
        ...action.payload,
        visitDateTime: action.payload.visitDateTime.split("T")[0],
      };

      return {
        ...state,
        item: visit,
      };
    case FETCH_VISIT_END:
      return {
        ...state,
        loading: false,
      };
    case CREATE_VISIT:
      return {
        ...state,
        item: action.payload,
      };
    case DELETE_VISIT:
      return {
        ...state,
        item: null,
      };
    case UNLOAD_VISIT:
      return {
        ...state,
        item: null,
        base64map: null,
        imageExtension: null,
      };
    case FETCH_VISIT_MAP:
      return {
        ...state,
        base64map: action.payload.image,
        imageExtension: action.payload.extension,
      };
    case SET_VISIT_CREATE_CALCULATION_ON_SAVE:
      return {
        ...state,
        createCalculationOnSave: action.payload,
        navigateToRoofSectionsOnSave: false,
      };
    case SET_VISIT_NAVIGATE_TO_ROOF_SECTIONS_ON_SAVE:
      return {
        ...state,
        createCalculationOnSave: false,
        navigateToRoofSectionsOnSave: action.payload,
      };
    default:
      return state;
  }
};
