import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Segment, Popup } from "semantic-ui-react";

import * as actions from "../../actions";
import * as util from "../../shared/utility";
import ProjectBreadcrumb from "../breadcrumb/ProjectBreadcrumb";
import StandardTableHeader from "../UI/StandardHeader";
class ProjectFileList extends React.Component {
  constructor() {
    super();
    this.state = {
      errorMessages: [],
    };
  }

  componentDidMount() {
    this.props.fetchProjectFiles(this.props.projectid);
  }

  fileChangedHandler = (event) => {
    let newErrorMessages = [];
    Array.from(event.target.files).forEach((file) => {
      if (file.name.includes("%")) {
        newErrorMessages.push(`Can't upload file with the name: ${file.name}`);
      } else {
        this.props.createProjectFile(this.props.projectid, file);
      }
    });

    if (newErrorMessages.length > 0) {
      this.setState({
        errorMessages: newErrorMessages,
      });
    }
  };

  renderList = () => {
    if (!this.props.projectFiles) {
      return <div>No projectFiles...</div>;
    }

    let entities = null;

    entities = this.props.projectFiles
      .sort((a, b) => b.lastAccessTime.localeCompare(a.lastAccessTime))
      .map((file) => {
        return (
          <tr key={file.name}>
            <td>{file.name}</td>
            <td className="center aligned mobile hidden">
              {util.formatBytes(file.length)}
            </td>
            <td className="center aligned mobile hidden">
              {util.formatStringToDateTime(file.lastAccessTime)}
            </td>
            <td className="center aligned">
              <i
                style={{ cursor: "pointer" }}
                className="download icon blue"
                onClick={() =>
                  this.props.downloadProjectFile(
                    this.props.projectid,
                    file.name
                  )
                }
              />
            </td>
            <td className="center aligned">
              <Link
                className="item"
                to={`/sales/files/project/${this.props.projectid}/edit/${file.name}`}
                key={file.name}
              >
                <i className="edit icon" />
              </Link>
            </td>
            <td className="center aligned">
              <Link
                className="item"
                to={`/sales/files/project/${this.props.projectid}/delete/${file.name}`}
                key={file.name}
              >
                <i className="trash icon" title="Delete record." />
              </Link>
            </td>
          </tr>
        );
      });

    return (
      <div>
        <table className="ui celled table unstackable">
          <thead>
            <tr>
              <th className="nine wide">Name</th>
              <th className="three wide center aligned mobile hidden">Size</th>
              <th className="three wide center aligned mobile hidden">
                Changed
              </th>
              <th className="one wide center aligned" colSpan="3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>{entities}</tbody>
        </table>
      </div>
    );
  };

  renderUpload = () => {
    return (
      <div>
        <input
          style={{ display: "none" }}
          type="file"
          onChange={this.fileChangedHandler}
          ref={(fileInput) => (this.fileInput = fileInput)}
          multiple
        />
        <Popup
          content="Use shift click to select multiple files."
          position="bottom left"
          trigger={
            <button
              className="ui button primary"
              onClick={() => this.fileInput.click()}
            >
              <i className="upload icon" />
              Upload ProjectFile
            </button>
          }
        />
      </div>
    );
  };

  errorMessage = () =>
    this.state.errorMessages.length > 0 && (
      <Grid.Row>
        <Grid.Column>
          <div className="ui error message">
            <i
              className="close icon"
              onClick={() =>
                this.setState({
                  errorMessages: [],
                })
              }
            />
            <div className="header">Error:</div>
            {this.state.errorMessages.map((x, i) => {
              return <p key={i}>{x}</p>;
            })}
          </div>
        </Grid.Column>
      </Grid.Row>
    );

  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <StandardTableHeader headerLabel="Project Files">
              <ProjectBreadcrumb
                projectID={this.props.projectid}
                currentPath="Files"
              />
            </StandardTableHeader>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>{this.renderUpload()}</Grid.Column>
          <Grid.Column width={12}>
            <Segment>
              Project file with the name "maps.jpg" or "maps.png" will be used
              on the visit.
              <br />
              Project Files with "woonhuis" in the name will be added
              automatically to the first card.
              <br />
              Project Files with "meterkast" in the name will be added
              automatically to a separated card.
              <br />
              Project file with the name "kabelrouting" in the name will be
              added automatically to a separated card.
              <br />
              Project file with the name "lay-out" in the name will be added
              automatically to a separated card.
              <br />
              Project file with the name "stringplan" in the name will be added
              automatically to a separated card.
            </Segment>
          </Grid.Column>
        </Grid.Row>
        {this.errorMessage()}
        <Grid.Row>
          <Grid.Column>{this.renderList()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    projectFiles: state.projectFiles.items,
    projectid: ownProps.match.params.projectid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProjectFiles: (projectid) =>
      dispatch(actions.fetchProjectFiles(projectid)),
    createProjectFile: (projectid, file) =>
      dispatch(actions.createProjectFile(projectid, file)),
    downloadProjectFile: (projectid, filename) =>
      dispatch(actions.downloadProjectFile(projectid, filename)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFileList);
