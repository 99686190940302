import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { Link } from "react-router-dom";
import * as util from "../../shared/utility";
import { Popup } from "semantic-ui-react";

import StandardTableHeader from "../UI/StandardHeader";

class FileList extends React.Component {
  constructor() {
    super();
    this.state = {
      searchValue: "",
      errorMessages: [],
    };
  }

  componentDidMount() {
    this.props.fetchFiles();
  }

  fileChangedHandler = (event) => {
    let newErrorMessages = [];
    Array.from(event.target.files).forEach((file) => {
      if (file.name.includes("%")) {
        newErrorMessages.push(`Can't upload file with the name: ${file.name}`);
      } else {
        this.props.createFile(file);
      }
    });

    if (newErrorMessages.length > 0) {
      this.setState({
        errorMessages: newErrorMessages,
      });
    }
  };

  updateSearchValue(event) {
    this.setState({
      searchValue: event.target.value,
    });
  }

  renderTable = () => {
    if (!this.props.files) {
      return <div>No files...</div>;
    }

    let entities = null;

    entities = this.props.files
      .filter((file) => {
        return file.name
          .toUpperCase()
          .includes(this.state.searchValue.toUpperCase());
      })
      // .sort((a, b) => b.lastAccessTime.localeCompare(a.lastAccessTime))
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((file) => {
        return (
          <tr key={file.name}>
            <td
              className="actionlink"
              onClick={() => this.props.downloadFile(file.name)}
            >
              {file.name}
            </td>
            <td className="center aligned mobile hidden">
              {util.formatBytes(file.length)}
            </td>
            <td className="center aligned mobile hidden">
              {util.formatStringToDateTime(file.lastAccessTime)}
            </td>
            <td className="center aligned">
              <Link
                className="item"
                to={`/application/files/edit/${file.name}`}
                key={file.name}
              >
                <i className="edit icon" />
              </Link>
            </td>
            <td className="center aligned">
              <Link
                className="item"
                to={`/application/files/delete/${file.name}`}
                key={file.name}
              >
                <i className="trash icon" title="Delete record." />
              </Link>
            </td>
          </tr>
        );
      });

    return (
      <div>
        <table className="ui celled table unstackable">
          <thead>
            <tr>
              <th className="nine wide">Name</th>
              <th className="three wide center aligned mobile hidden">Size</th>
              <th className="three wide center aligned mobile hidden">
                Changed
              </th>
              <th className="one wide center aligned" colSpan="2">
                Action
              </th>
            </tr>
          </thead>
          <tbody>{entities}</tbody>
        </table>
      </div>
    );
  };

  renderUpload = () => {
    return (
      <div>
        <input
          style={{ display: "none" }}
          type="file"
          onChange={this.fileChangedHandler}
          ref={(fileInput) => (this.fileInput = fileInput)}
          multiple
        />
        <Popup
          content="Use shift click to select multiple files."
          position="right center"
          trigger={
            <button
              className="ui button primary"
              onClick={() => this.fileInput.click()}
            >
              <i className="upload icon" />
              Upload File
            </button>
          }
        />
      </div>
    );
  };

  errorMessage = () =>
    this.state.errorMessages.length > 0 && (
      <div className="column">
        <div className="ui error message">
          <i
            className="close icon"
            onClick={() =>
              this.setState({
                errorMessages: [],
              })
            }
          />
          <div className="header">Error:</div>
          {this.state.errorMessages.map((x, i) => {
            return <p key={i}>{x}</p>;
          })}
        </div>
      </div>
    );

  render() {
    return (
      <div className="ui one column grid">
        <div className="column">
          <StandardTableHeader
            headerLabel="Files"
            recordCount={
              this.props.files.filter((file) => {
                return file.name
                  .toUpperCase()
                  .includes(this.state.searchValue.toUpperCase());
              }).length
            }
            searchValue={this.props.searchValue}
            searchFunction={(e) => this.updateSearchValue(e)}
          >
            {this.renderUpload()}
          </StandardTableHeader>
        </div>
        {this.errorMessage()}
        <div className="column">{this.renderTable()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    files: state.files.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFiles: () => dispatch(actions.fetchFiles()),
    createFile: (file) => dispatch(actions.createFile(file)),
    downloadFile: (filename) => dispatch(actions.downloadFile(filename)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileList);
