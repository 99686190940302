import React from "react";
import { Field, reduxForm } from "redux-form";
import history from "../../history";
import * as util from "../../shared/utility";

class CalculationForm extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  handleCancelClick = () => {
    history.goBack();
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className="ui form error"
      >
        <Field name="name" component={util.RenderInput} label="Name:" />
        <Field
          name="revenuePercentage"
          component={util.RenderInputNumber}
          label="Revenue Percentage:"
        />
        <button className="ui button primary">Save</button>

        <button
          type="button"
          className="ui button"
          onClick={this.handleCancelClick}
        >
          Cancel
        </button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.name) {
    errors.name = "You must enter a name.";
  }

  if (formValues.name && formValues.name.length > 200) {
    errors.name = "The name cannot exceed 200 characters.";
  }

  if (
    !formValues.revenuePercentage ||
    formValues.revenuePercentage < 0 ||
    formValues.revenuePercentage > 1000
  ) {
    errors.revenuePercentage = "You need to select a value between 0 and 1000.";
  }

  return errors;
};

export default reduxForm({
  form: "calculationForm",
  validate,
})(CalculationForm);
