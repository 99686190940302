import React from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../../actions";
import ProjectDetail from "./ProjectDetail";
import ProjectEdit from "./ProjectEdit";
import ProjectDelete from "./ProjectEdit";
import * as util from "../../shared/utility";

import { Segment } from "semantic-ui-react";

const DashboardProject = ({ project }) => {
  const formMode = useSelector((state) => state.projects.mode);
  const dispatch = useDispatch();

  if (!project) {
    return null;
  } else {
    if (project && formMode === util.formModes.reading) {
      return (
        <ProjectDetail
          project={project}
          onEdit={() =>
            dispatch(actions.setProjectMode(util.formModes.updating))
          }
          onDelete={() =>
            dispatch(actions.setProjectMode(util.formModes.deleting))
          }
        />
      );
    } else if (project && formMode === util.formModes.updating) {
      return (
        <ProjectEdit
          project={project}
          onCancel={() =>
            dispatch(actions.setProjectMode(util.formModes.reading))
          }
        />
      );
    } else if (project && formMode === util.formModes.deleting) {
      return (
        <ProjectDelete
          project={project}
          onCancel={() =>
            dispatch(actions.setProjectMode(util.formModes.reading))
          }
        />
      );
    } else {
      return <Segment loading />;
    }
  }
};

export default DashboardProject;
