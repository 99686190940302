import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import * as util from "../../../shared/utility";
import * as actions from "../../../actions";

const DashboardCalculations = ({ calculations, projectid }) => {
  if (!calculations || calculations.length === 0) {
    return null;
  }

  const dispatch = useDispatch();

  const fileList = calculations
    .sort((a, b) => b.lastUpdatedDateTime.localeCompare(a.lastUpdatedDateTime))
    .map((calculation) => {
      return (
        <tr key={calculation.calculationID}>
          <td>
            <Link
              className="item"
              to={`/sales/calculations/detail/${calculation.calculationID}`}
            >
              <div className="item">{calculation.name}</div>
            </Link>
          </td>
          <td className="center aligned mobile hidden">
            {util.utcStringToLocalDateTime(calculation.lastUpdatedDateTime)}
          </td>
          <td className="center aligned">
            <Link
              className="item"
              to={`/sales/calculations/edit/${calculation.calculationID}`}
              key={calculation.calculationID}
            >
              <i className="edit icon" title="Edit record." />
            </Link>
          </td>
          <td className="center aligned">
            <i
              style={{ cursor: "pointer" }}
              className="copy icon blue"
              onClick={() =>
                dispatch(actions.copyCalculation(calculation.calculationID))
              }
              title="Copy record."
            />
          </td>
          <td className="center aligned">
            <Link
              className="item"
              to={`/sales/calculations/delete/${calculation.calculationID}`}
              key={calculation.calculationID}
            >
              <i className="trash icon" title="Delete record." />
            </Link>
          </td>
        </tr>
      );
    });

  return (
    <div className="ui one column grid">
      <div className="row">
        <div className="column">
          <table className="ui celled very compact table unstackable">
            <thead>
              <tr>
                <th className="eleven wide">
                  <i className={`calculator icon large`} />
                  <h4 className="dashboardHeaderTexts">Calculations</h4>
                </th>
                <th className="three wide center aligned mobile hidden">
                  Changed
                </th>
                <th className="two wide center aligned" colSpan="3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{fileList}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DashboardCalculations;
