import React, { Component } from "react";
import { connect } from "react-redux";
import { setPassword, clearAuth } from "../../actions";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import * as util from "../../shared/utility";

class SetPassword extends Component {
  componentWillUnmount() {
    this.props.clearAuth();
  }

  onSubmit = (formValues) => {
    this.props.setPassword(
      this.props.match.params.resettoken,
      formValues.password
    );
  };

  render() {
    var divStyleDesktop = {
      background: "#ddd",
      marginTop: "60px",
    };

    let errorMessage = null;
    if (this.props.authError) {
      errorMessage = (
        <p className="ui error message">{this.props.authErrorMessage}</p>
      );
    }

    let successMessage = null;
    if (this.props.authSuccess) {
      successMessage = (
        <p className="ui message">{this.props.authSuccessMessage}</p>
      );
    }
    return (
      <div>
        <div className="ui grid stackable">
          <div className="three wide column computer tablet only" />
          <div className="ten wide column">
            <div
              className="ui middle aligned center aligned column segment"
              style={divStyleDesktop}
            >
              <form
                onSubmit={this.props.handleSubmit(this.onSubmit)}
                className="ui form error"
              >
                <Field
                  name="password"
                  component={util.RenderInputPassword}
                  label="Password"
                  type="password"
                />
                <Field
                  name="confirmPassword"
                  component={util.RenderInputPassword}
                  label="Repeat Password"
                  type="password"
                />

                <div className="ui three column grid">
                  <div className="column"></div>
                  <div className="column">
                    {!this.props.authSuccess && (
                      <button
                        disabled={this.props.authRequest}
                        className="ui button primary"
                      >
                        Set Password
                      </button>
                    )}
                  </div>
                  <div className="column bottom aligned ">
                    <Link className="ui right floated item" to={"/login"}>
                      Login
                    </Link>
                  </div>
                </div>
              </form>
              {errorMessage}
              {successMessage}
            </div>
          </div>
          <div className="three wide column computer tablet only" />
        </div>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.password) {
    errors.password = "You must enter a password.";
  }

  if (!formValues.confirmPassword) {
    errors.confirmPassword = "You must repeat the password.";
  }

  if (formValues.password && formValues.confirmPassword) {
    if (formValues.password !== formValues.confirmPassword) {
      errors.confirmPassword = "The passwords are not the same.";
    }
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    authRequest: state.auth.authRequest,
    authError: state.auth.authError,
    authErrorMessage: state.auth.authErrorMessage,
    authSuccess: state.auth.authSuccess,
    authSuccessMessage: state.auth.authSuccessMessage,
  };
};

const SetPasswordComponent = connect(mapStateToProps, {
  setPassword,
  clearAuth,
})(SetPassword);

export default reduxForm({
  form: "setPasswordForm",
  validate,
})(SetPasswordComponent);
