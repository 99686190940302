import solartoolapi from "../apis/solartoolapi";
import history from "../history";
import {
  CREATE_PROJECT,
  FETCH_PROJECTS,
  FETCH_PROJECT,
  DELETE_PROJECT,
  EDIT_PROJECT,
  SET_PROJECTS_FILTER,
  SET_PROJECTS_OFFSET,
  FETCH_PROJECTS_PAGED,
  SET_PROJECTS_PAGE_FILTER,
  SET_PROJECTS_PAGE_NUMBER,
  FETCH_PROJECT_DASHBOARD,
  UNLOAD_PROJECT_DASHBOARD,
  SET_PROJECT_MODE,
  SET_PROJECTS_VISITOR_FILTER,
  RESET_PROJECTS_FILTER,
} from "./types";
import { authHeader } from "../shared/utility";

import * as actions from "../actions";
import * as util from "../shared/utility";

export const createProject = (formValues) => async (dispatch, getState) => {
  const response = await solartoolapi.post(
    "/projects",
    {
      ...formValues,
    },
    { headers: authHeader() }
  );

  dispatch({ type: CREATE_PROJECT, payload: response.data.result });
  dispatch({ type: SET_PROJECTS_FILTER, payload: "" });
  history.push("/sales/projects");
};

export const editProject = (id, formValues) => async (dispatch) => {
  const response = await solartoolapi.put(`/projects/${id}`, formValues, {
    headers: authHeader(),
  });

  dispatch({ type: EDIT_PROJECT, payload: response.data.result });
};

export const editProjectAndUpdateProject =
  (projectID, formValues) => async (dispatch) => {
    await dispatch(editProject(projectID, formValues));
    await dispatch(actions.fetchProjectDashboard(projectID));
    await dispatch(setProjectMode(util.formModes.reading));
  };

export const deleteProject = (id) => async (dispatch) => {
  await solartoolapi.delete(`/projects/${id}`, { headers: authHeader() });

  dispatch({ type: DELETE_PROJECT, payload: id });
  history.push("/sales/projects");
};

export const fetchProject = (id) => async (dispatch) => {
  const response = await solartoolapi.get(`/projects/${id}`, {
    headers: authHeader(),
  });

  dispatch({ type: FETCH_PROJECT, payload: response.data.result });
};

export const fetchProjects = () => async (dispatch) => {
  const response = await solartoolapi.get("/projects", {
    headers: authHeader(),
  });
  dispatch({ type: FETCH_PROJECTS, payload: response.data.result });
};

export const setProjectsFilter = (searchText) => (dispatch) => {
  dispatch({ type: SET_PROJECTS_FILTER, payload: searchText });
};

export const setProjectsOffset = (offset) => (dispatch) => {
  dispatch({ type: SET_PROJECTS_OFFSET, payload: offset });
};

export const fetchProjectsPaged =
  (pageFilter, pageNumber, visitorFilter) => async (dispatch) => {
    const response = await solartoolapi.get(
      `/projects/paged?pageNumber=${pageNumber}&pageFilter=${pageFilter}&visitorFilter=${visitorFilter}`,
      {
        headers: authHeader(),
      }
    );
    dispatch({
      type: FETCH_PROJECTS_PAGED,
      payload: response.data,
    });
  };

export const setProjectsPageFilter = (pageFilter) => (dispatch) => {
  dispatch({ type: SET_PROJECTS_PAGE_FILTER, payload: pageFilter });
};

export const setProjectsVisitorFilter = (visitorFilter) => (dispatch) => {
  dispatch({ type: SET_PROJECTS_VISITOR_FILTER, payload: visitorFilter });
};

export const resetProjectsFilter = () => (dispatch) => {
  dispatch({ type: RESET_PROJECTS_FILTER });
};

export const setProjectsPageNumber = (pageNumber) => (dispatch) => {
  dispatch({ type: SET_PROJECTS_PAGE_NUMBER, payload: pageNumber });
};

export const fetchProjectDashboard = (projectid) => async (dispatch) => {
  const response = await solartoolapi.get(`/projects/dashboard/${projectid}`, {
    headers: authHeader(),
  });
  dispatch({
    type: FETCH_PROJECT_DASHBOARD,
    payload: response.data.result,
  });
};

export const unloadProjectDashboard = () => (dispatch) => {
  dispatch({ type: UNLOAD_PROJECT_DASHBOARD });
};

export const setProjectMode = (mode) => (dispatch) => {
  dispatch({ type: SET_PROJECT_MODE, payload: mode });
};
