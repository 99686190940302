import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import ProjectFileForm from "./ProjectFileForm";

import StandardTableHeader from "../UI/StandardHeader";

class ProjectFileEdit extends React.Component {
  onSubmit = (fv) => {
    const projectid = this.props.match.params.projectid;
    this.props.renameProjectFile(projectid, fv);
  };

  render() {
    const fileName = this.props.match.params.filename;
    if (fileName == null || fileName.lastIndexOf(".") === -1) {
      return <div>No valid filename found...</div>;
    }

    var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
    var name = fileName.substr(0, fileName.lastIndexOf("."));

    const initialVal = {
      oldName: fileName,
      newName: fileName,
      ext: ext,
      name: name,
    };

    return (
      <div className="ui one column grid">
        <div className="column">
          <StandardTableHeader headerLabel="Edit Project File"></StandardTableHeader>
        </div>
        <div className="column">
          <ProjectFileForm
            initialValues={initialVal}
            onSubmit={this.onSubmit}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    renameProjectFile: (projectid, fv) =>
      dispatch(actions.renameProjectFile(projectid, fv)),
  };
};

export default connect(null, mapDispatchToProps)(ProjectFileEdit);
