import React from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal';
import history from '../../history';
import * as actions from '../../actions';

class MailTemplateDelete extends React.Component {
  componentDidMount() {
    this.props.fetchMailTemplate(this.props.match.params.mailTemplateID);
  }

  goBack() {
    history.goBack();
  }

  renderActions() {
    const { mailTemplateID } = this.props.match.params;

    return (
      <React.Fragment>
        <button onClick={() => this.props.deleteMailTemplate(mailTemplateID)} className="ui button negative">
          Delete
        </button>
        <button className="ui button" onClick={this.goBack}>
          Cancel
        </button>
      </React.Fragment>
    );
  }

  renderContent() {
    if (!this.props.mailTemplate) {
      return 'Are you sure you want to delete this mail template?';
    }

    return `Are you sure you want to delete the mail template with name: ${this.props.mailTemplate.name}`;
  }

  render() {
    return (
      <Modal
        title="Delete MailTemplate"
        content={this.renderContent()}
        actions={this.renderActions()}
        onDismiss={this.goBack}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { mailTemplate: state.mailTemplates.items[ownProps.match.params.mailTemplateID] };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchMailTemplate: id => dispatch(actions.fetchMailTemplate(id)),
    deleteMailTemplate: id => dispatch(actions.deleteMailTemplate(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailTemplateDelete);
