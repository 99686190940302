import React from "react";
import { connect } from "react-redux";
import axios from "axios";

import { setUpdateAvailable } from "../../actions/utilityActions";

class Updater extends React.Component {
  componentDidMount = () => {
    setInterval(this.checkForUpdates, 60 * 1000);
  };

  checkForUpdates = async () => {
    const { setUpdateAvailable, updateAvailable } = this.props;

    if (!updateAvailable) {
      await axios
        .create(window.location.hostname)
        .head("/index.html", { headers: { "Cache-Control": "no-cache" } })
        .then((response) => {
          const storedLastModified = localStorage.getItem("last-modified");
          const lastModified = response.headers["last-modified"];
          if (lastModified) {
            if (storedLastModified == null) {
              localStorage.setItem("last-modified", lastModified);
            } else if (storedLastModified !== lastModified) {
              localStorage.setItem("last-modified", lastModified);
              console.log("update available");
              setUpdateAvailable(true);
            }
          }
        })
        .catch(() => {
          console.log("Error checking for update available.....");
        });
    }
  };

  render() {
    const { updateAvailable } = this.props;

    if (updateAvailable) {
      return (
        <div
          className="ui orange inverted segment center aligned padded"
          onClick={() => window.location.reload()}
          title={localStorage.getItem("last-modified")}
          style={{ cursor: "pointer" }}
        >
          A new version of the application is available, click here to update!
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = ({ utility }) => ({
  updateAvailable: utility.updateAvailable,
});

const mapDispatchToProps = (dispatch) => ({
  setUpdateAvailable: (updateAvailable) =>
    dispatch(setUpdateAvailable(updateAvailable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Updater);
