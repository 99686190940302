import React from "react";
import { Table, Grid } from "semantic-ui-react";
import * as util from "../../../shared/utility";

const PersonDetail = ({ person, onEdit, onDelete }) => {
  if (!person) {
    return null;
  }

  const {
    firstName,
    middleName,
    lastName,
    title,
    email,
    phoneNumber,
    companyName,
  } = person;

  return (
    <Table celled definition unstackable>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell colSpan="2">
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <h3>Contact</h3>
                </Grid.Column>
                <Grid.Column width={12} textAlign="right">
                  <button
                    style={{}}
                    type="button"
                    className="ui button primary"
                    onClick={onEdit}
                  >
                    Edit
                  </button>
                  <button
                    style={{}}
                    type="button"
                    className="ui button negative"
                    onClick={onDelete}
                  >
                    Delete
                  </button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Title:</Table.Cell>
          <Table.Cell>{title}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={5}>FirstName:</Table.Cell>
          <Table.Cell>{firstName}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Middlename:</Table.Cell>
          <Table.Cell>{middleName}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>LastName:</Table.Cell>
          <Table.Cell>{lastName}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Email:</Table.Cell>
          <Table.Cell>{util.EmailHelperLink(email, email)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Phone number:</Table.Cell>
          <Table.Cell>
            {util.PhoneNumberHelperLink(phoneNumber, phoneNumber)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Company name:</Table.Cell>
          <Table.Cell>{companyName}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default PersonDetail;
