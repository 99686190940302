import React, { useRef, useState } from "react";
import * as d3 from "d3";

import "./InventoryChart.css";
import { Legend } from "./Legend";
import { AxisBottom } from "./AxisBottom";
import { AxisLeft } from "./AxisLeft";

export const InventoryChart = ({
  data,
  reorderPoint,
  currentTechnicalStock,
  currentEconomicStock,
}) => {
  const chartDiv = useRef(null);

  const dimensions = {
    width: 1000,
    height: 500,
    margin: { top: 40, right: 20, bottom: 90, left: 60 },
  };

  const strokeWidth = 1.5;

  const { width, height, margin } = dimensions;
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const xValue = (d) => d.date;
  const yValue = (d) => d.value;

  const [period, setPeriod] = useState(6);

  const xAxisTickFormat = d3.timeFormat("%x");

  var periodStart = new Date();
  var periodEnd = new Date();

  if (period != null) {
    periodStart = new Date(
      periodStart.setMonth(periodStart.getMonth() - period / 2)
    );
    periodEnd = new Date(periodEnd.setMonth(periodEnd.getMonth() + period / 2));
  }
  var datasetTechnical = data
    .filter((x) => x.deliverDate != null)
    .filter(
      (x) =>
        (new Date(x.deliverDate) >= periodStart &&
          new Date(x.deliverDate) < periodEnd &&
          period != null) ||
        period === null
    )
    .sort((a, b) => a.deliverDate.localeCompare(b.deliverDate))
    .map((d) => {
      return {
        date: new Date(d.deliverDate),
        value: d.technicalStock,
      };
    });

  var datasetEconomic = data
    .filter(
      (x) =>
        (new Date(x.orderDate) >= periodStart &&
          new Date(x.orderDate) < periodEnd &&
          period != null) ||
        period === null
    )
    .sort((a, b) => a.orderDate.localeCompare(b.orderDate))
    .map((d) => {
      return {
        date: new Date(d.orderDate),
        value: d.economicStock,
      };
    });

  var xMin = d3.min([
    periodStart,
    d3.min(datasetTechnical, (d) => xValue(d)),
    d3.min(datasetEconomic, (d) => xValue(d)),
  ]);

  var xMax = d3.max([
    periodEnd,
    d3.max(datasetTechnical, (d) => xValue(d)),
    d3.max(datasetEconomic, (d) => xValue(d)),
  ]);

  var yMin = d3.min([
    0,
    d3.min(datasetTechnical, (d) => yValue(d)),
    d3.min(datasetEconomic, (d) => yValue(d)),
    currentTechnicalStock,
    currentEconomicStock,
  ]);

  var yMax = d3.max([
    0,
    reorderPoint,
    d3.max(datasetTechnical, (d) => yValue(d)),
    d3.max(datasetEconomic, (d) => yValue(d)),
    currentTechnicalStock,
    currentEconomicStock,
  ]);

  const xScale = d3.scaleTime().range([0, innerWidth]).domain([xMin, xMax]);
  const yScale = d3
    .scaleLinear()
    .range([innerHeight, 0])
    .domain([yMin, yMax])
    .nice();

  if (datasetTechnical.length > 0) {
    datasetTechnical = [
      {
        date: xMin,
        value: datasetTechnical[0].value,
      },
      ...datasetTechnical,
      {
        date: xMax,
        value: datasetTechnical[datasetTechnical.length - 1].value,
      },
    ];
  } else {
    datasetTechnical = [
      {
        date: xMin,
        value: currentTechnicalStock,
      },
      {
        date: xMax,
        value: currentTechnicalStock,
      },
    ];
  }

  if (datasetEconomic.length > 0) {
    datasetEconomic = [
      {
        date: xMin,
        value: datasetEconomic[0].value,
      },
      ...datasetEconomic,
      {
        date: xMax,
        value: datasetEconomic[datasetEconomic.length - 1].value,
      },
    ];
  } else {
    datasetEconomic = [
      {
        date: xMin,
        value: currentEconomicStock,
      },
      {
        date: xMax,
        value: currentEconomicStock,
      },
    ];
  }

  var today = new Date();
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  today.setHours(0);

  return (
    <div ref={chartDiv}>
      <svg viewBox="0 0 1000 500" className="inventorychart">
        <Legend
          margin={margin}
          strokeWidth={strokeWidth}
          setPeriod={setPeriod}
          period={period}
        />
        <g transform={`translate(${margin.left},${margin.top})`}>
          <AxisLeft yScale={yScale} innerWidth={innerWidth} />
          <AxisBottom
            xScale={xScale}
            innerHeight={innerHeight}
            tickFormat={xAxisTickFormat}
          />
          <line
            y1={0}
            x1={xScale(today)}
            y2={innerHeight}
            x2={xScale(today)}
            stroke="black"
            strokeWidth={strokeWidth}
            strokeDasharray={10}
          ></line>
          <line
            y1={yScale(reorderPoint)}
            x1={xScale(xMin)}
            y2={yScale(reorderPoint)}
            x2={xScale(xMax)}
            stroke="red"
            strokeWidth={strokeWidth}
            strokeDasharray={10}
          ></line>
          <path
            fill="none"
            stroke="orange"
            strokeWidth={strokeWidth}
            d={d3
              .line()
              .x((d) => xScale(xValue(d)))
              .y((d) => yScale(yValue(d)))
              .curve(d3.curveStepAfter)(datasetTechnical)}
          ></path>
          <path
            fill="none"
            stroke="steelblue"
            strokeWidth={strokeWidth}
            d={d3
              .line()
              .x((d) => xScale(xValue(d)))
              .y((d) => yScale(yValue(d)))
              .curve(d3.curveStepAfter)(datasetEconomic)}
          ></path>
        </g>
      </svg>
    </div>
  );
};
