import React from "react";
import { Table, Grid } from "semantic-ui-react";

import * as util from "../../../shared/utility";

const AddressDetail = ({ address, onEdit, onDelete }) => {
  if (!address) {
    return null;
  }

  const {
    street,
    number,
    numberAddition,
    city,
    zipCode,
    fullAddress,
    countryName,
  } = address;

  return (
    <Table celled definition unstackable>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell colSpan="2">
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column width={4}>
                  <h3>
                    {util.GoogleHelperLink(
                      "Address",
                      `${fullAddress},${zipCode},${city},${countryName}`
                    )}
                  </h3>
                </Grid.Column>
                <Grid.Column width={12} textAlign="right">
                  <button
                    style={{}}
                    type="button"
                    className="ui button primary"
                    onClick={onEdit}
                  >
                    Edit
                  </button>
                  <button
                    style={{}}
                    type="button"
                    className="ui button negative"
                    onClick={onDelete}
                  >
                    Delete
                  </button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell width={5}>Street:</Table.Cell>
          <Table.Cell>{street}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Number:</Table.Cell>
          <Table.Cell>{number}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Number addition:</Table.Cell>
          <Table.Cell>{numberAddition}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>City:</Table.Cell>
          <Table.Cell>{city}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Zipcode:</Table.Cell>
          <Table.Cell>{zipCode}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Country:</Table.Cell>
          <Table.Cell>{countryName}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default AddressDetail;
