import React from "react";
import { Link } from "react-router-dom";

const StandardHeader = ({
  headerLabel,
  linkForNewButton,
  recordCount,
  searchValue,
  searchFunction,
  searchTooltip,
  children,
}) => {
  const countDiv = recordCount && (
    <div
      style={{
        paddingRight: "20px",
      }}
    >{`count: ${recordCount}`}</div>
  );

  const searchDiv = searchFunction && (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <div className="ui icon input">
        <input
          type="text"
          placeholder="Search..."
          value={searchValue}
          onChange={searchFunction}
          title={searchTooltip}
        />
        <i className="search link icon" />
      </div>
    </div>
  );

  const newDiv = linkForNewButton && (
    <Link to={linkForNewButton} className="button">
      <div className="ui primary button">New</div>
    </Link>
  );

  const newAndSearchDiv = (children || linkForNewButton || searchFunction) && (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        whiteSpace: "nowrap",
        paddingTop: "10px",
        flexWrap: "wrap",
        gap: "10px",
      }}
    >
      <div>
        {newDiv}
        {children}
      </div>
      {searchDiv}
    </div>
  );

  const tableHeader = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <h3
            style={{
              textTransform: "uppercase",
              alignItems: "center",
              margin: "0px",
            }}
          >
            {headerLabel}
          </h3>
        </div>
        <div
          style={{
            position: "absolute",
            right: "0px",
            // display: "flex",
            // justifyContent: "flex-end",
            // alignItems: "center",
          }}
        >
          {countDiv}
        </div>
      </div>
      {newAndSearchDiv}
    </div>
  );

  return tableHeader;
};

export default StandardHeader;
