import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Segment } from 'semantic-ui-react';

class AddressDelete extends React.Component {
  onDelete = () => {
    this.props.deleteAddressAndUpdateProject(this.props.address.addressID, this.props.projectID);
  };

  render() {
    return (
      <Segment textAlign="center" style={{ background: '#BBB' }}>
        <h3>Are you sure you want to delete this address?</h3>
        <button type="button" className="ui button negative" onClick={this.onDelete}>
          Delete
        </button>
        <button type="button" className="ui button" onClick={this.props.onCancel}>
          Cancel
        </button>
      </Segment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    address: state.addresses.address
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteAddressAndUpdateProject: (addressID, projectID) =>
      dispatch(actions.deleteAddressAndUpdateProject(addressID, projectID))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressDelete);
