import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import CalculationRowForm from "./CalculationRowForm";
import { Grid } from "semantic-ui-react";
import StandardTableHeader from "../UI/StandardHeader";

const CalculationRowCreate = ({
  match: {
    params: { calculationid },
  },
}) => {
  const dispatch = useDispatch();

  const articles = useSelector((state) => Object.values(state.articles.items));
  const init = { quantity: 1, btwID: 1, articleReferenceID: 0 };

  useEffect(() => {
    dispatch(actions.fetchArticles());

    return () => {
      dispatch(actions.unloadCalculationRow());
    };
  }, []);

  const onSubmit = (fv) => {
    dispatch(actions.createCalculationRow(calculationid, fv));
  };

  if (articles.length === 0) {
    return <div>Loading articles...</div>;
  }

  if (!calculationid) {
    return <div>No valid CalculationID...</div>;
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <StandardTableHeader headerLabel="Create CalculationRow"></StandardTableHeader>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <CalculationRowForm
            initialValues={init}
            onSubmit={onSubmit}
            articles={articles}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CalculationRowCreate;
