import solartoolapi from '../apis/solartoolapi';
import { FETCH_COUNTRIES } from './types';
import { authHeader } from '../shared/utility';

export const fetchCountries = () => async dispatch => {
  const response = await solartoolapi.get('/countries', {
    headers: authHeader()
  });

  dispatch({ type: FETCH_COUNTRIES, payload: response.data.result });
};
