import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Icon, Popup, Button, Modal } from "semantic-ui-react";

import * as actions from "../../actions";
import history from "../../history";
import * as util from "../../shared/utility";
import ProjectBreadcrumb from "../breadcrumb/ProjectBreadcrumb";
import StandardTableHeader from "../UI/StandardHeader";

const CalculationDetail = ({
  match: {
    params: { calculationid },
  },
}) => {
  const dispatch = useDispatch();

  const calculation = useSelector((state) => state.calculations.item);
  const projectID = useSelector((state) => state.calculations.projectID);

  useEffect(() => {
    dispatch(actions.fetchCalculation(calculationid));

    return () => {
      dispatch(actions.unloadCalculationRow());
    };
  }, []);

  const generateOffer = () => {
    dispatch(actions.generateOffer(calculationid, projectID));
  };

  const rowMarge = (calculationRow, revenuePercentage) => {
    let result = null;

    if (
      calculationRow.quantity &&
      calculationRow.quantity > 0 &&
      calculationRow.price != null
    ) {
      const rowPercentageMultiplier = calculationRow.name.match(/korting/i)
        ? 1
        : (calculationRow.marge ? revenuePercentage : 0) / 100;

      result = (
        calculationRow.quantity *
        calculationRow.price *
        rowPercentageMultiplier
      ).toFixed(2);
    }

    return result;
  };

  const rowTotalCost = (calculationRow) => {
    let result = 0;

    if (
      calculationRow.quantity &&
      calculationRow.quantity > 0 &&
      calculationRow.price != null &&
      !calculationRow.name.match(/korting/i)
    ) {
      result = (calculationRow.quantity * calculationRow.price).toFixed(2);
    }

    return result;
  };

  const generatetFooter = () => {
    let totalCost = 0;
    let totalMarge = 0;
    const revenuePercentage = calculation.revenuePercentage;

    calculation.calculationRows.forEach((calculationRow) => {
      totalCost += parseFloat(rowTotalCost(calculationRow));
      totalMarge += parseFloat(rowMarge(calculationRow, revenuePercentage));
    });

    return (
      <tr>
        <th colSpan={4}>Total:</th>
        <th className="right aligned">{util.formatDecimal(totalCost)}</th>
        <th className="right aligned textSolarOrange">
          {util.formatDecimal(totalMarge)}
        </th>
        <th className="right aligned">
          {util.formatDecimal(totalCost + totalMarge)}
        </th>
        <th colSpan={4} />
      </tr>
    );
  };

  const [deleteCalculationRowActive, setDeleteCalculationRowActive] =
    useState(null);

  const handleDeleteCancelClick = () => {
    setDeleteCalculationRowActive(null);
  };

  const handleDeleteSubmitClick = () => {
    dispatch(
      actions.deleteCalculationRow(calculationid, deleteCalculationRowActive)
    );
    setDeleteCalculationRowActive(null);
  };

  const DeleteCalculationRow = () => {
    if (!deleteCalculationRowActive) {
      return null;
    }

    const item = calculation.calculationRows.find(
      (e) => e.calculationRowID === deleteCalculationRowActive
    );

    return (
      <Modal
        onClose={handleDeleteCancelClick}
        open={!!deleteCalculationRowActive}
      >
        <Modal.Header>{`Delete Calculation Row: ${item.calculationRowID}`}</Modal.Header>
        <Modal.Content>
          <p>
            {`Are you sure you want to delete the calculation row with ${item.quantity} items:`}
          </p>
          <p>{`${item.name}`}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Delete"
            labelPosition="right"
            icon="trash"
            onClick={handleDeleteSubmitClick}
            negative
          ></Button>
          <Button onClick={handleDeleteCancelClick}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  };

  const renderBody = () => {
    const { calculationID, name, revenuePercentage } = calculation;

    let calculationRowsList = null;

    calculationRowsList = calculation.calculationRows
      .sort((a, b) => a.rowNumber - b.rowNumber)
      .map((calculationRow, index) => {
        return (
          <tr key={calculationRow.calculationRowID}>
            <td title={calculationRow.descriptionCustomer}>
              <Link
                className="item"
                to={`/sales/calculationrows/edit/${calculationRow.calculationRowID}`}
                key={calculationRow.calculationRowID}
              >
                {calculationRow.name}
              </Link>
            </td>
            <td className="right aligned">{calculationRow.quantity}</td>
            <td className="right aligned">
              {calculationRow.isVisibleOnOffer === true ? (
                <Icon className="green check" />
              ) : (
                <Icon className="x red" />
              )}
            </td>
            <td className="right aligned">
              {util.formatDecimal(calculationRow.price)}
            </td>
            <td className="right aligned">
              {util.formatDecimal(rowTotalCost(calculationRow))}
            </td>
            <td className="right aligned">
              {util.formatDecimal(rowMarge(calculationRow, revenuePercentage))}
            </td>
            <td className="right aligned">
              {util.formatDecimal(
                parseFloat(rowTotalCost(calculationRow)) +
                  parseFloat(rowMarge(calculationRow, revenuePercentage))
              )}
            </td>
            <td>
              {(() => {
                if (index !== 0) {
                  return (
                    <i
                      style={{ cursor: "pointer" }}
                      className="arrow up icon blue"
                      onClick={() =>
                        dispatch(
                          actions.upCalculationRow(
                            calculationID,
                            calculationRow.calculationRowID
                          )
                        )
                      }
                    />
                  );
                }
              })()}
            </td>
            <td>
              {(() => {
                if (index !== calculation.calculationRows.length - 1) {
                  return (
                    <i
                      style={{ cursor: "pointer" }}
                      className="arrow down icon blue"
                      onClick={() =>
                        dispatch(
                          actions.downCalculationRow(
                            calculationID,
                            calculationRow.calculationRowID
                          )
                        )
                      }
                    />
                  );
                }
              })()}
            </td>
            <td>
              <i
                style={{ cursor: "pointer" }}
                className="trash icon blue"
                title="Delete record."
                onClick={() =>
                  setDeleteCalculationRowActive(calculationRow.calculationRowID)
                }
              />
            </td>
          </tr>
        );
      });

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <table className="ui definition table unstackable">
              <tbody>
                <tr>
                  <td className="four wide column">CalculationID:</td>
                  <td>{calculationID}</td>
                </tr>
                <tr>
                  <td className="four wide column">Name:</td>
                  <td>{name}</td>
                </tr>
                <tr>
                  <td>Revenue Percentage:</td>
                  <td>{revenuePercentage}</td>
                </tr>
              </tbody>
            </table>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <table className="ui celled table unstackable">
              <thead>
                <tr>
                  <th className="three wide">Name</th>
                  <th className="one wide right aligned">Quantity</th>
                  <th className="one wide right aligned">Visible</th>
                  <th className="two wide right aligned">Price (€)</th>
                  <th className="two wide right aligned">Cost (€)</th>
                  <th className="two wide right aligned">Marge (€)</th>
                  <th className="two wide right aligned">Project (€)</th>
                  <th className="one wide center aligned" colSpan="3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>{calculationRowsList}</tbody>
              <tfoot>{generatetFooter()}</tfoot>
            </table>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <button
              type="button"
              className="ui orange button "
              onClick={() => generateOffer()}
            >
              Create Offer
            </button>

            <Popup
              wide
              position="bottom right"
              trigger={
                <i
                  className="info circle icon big"
                  style={{
                    color: "var(--company-bg-color-100)",
                    margin: "6px",
                  }}
                />
              }
            >
              <Popup.Content>
                <h3>Marge:</h3>
                <p>t/m 8 panelen {">"} €. 900,-</p>
                <p>t/m 10 panelen {">"} €. 1.000,-</p>
                <p>t/m 12 panelen {">"} €. 1.200,-</p>
                <p>t/m 15 panelen {">"} €. 1.400,-</p>
                <p>t/m 18 panelen {">"} €. 1.500,-</p>
                <p>vanaf 19 panelen €. 1.600,-</p>
                <p>klus met 3 man {">"} €. 2.000,-</p>
              </Popup.Content>
            </Popup>
          </Grid.Column>
          <Grid.Column width={8}>
            <Link
              to={"/sales/calculationrows/create/" + calculation.calculationID}
              className="button"
            >
              <div className="ui right floated primary button">Add Row</div>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  if (!calculation || !projectID) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <StandardTableHeader headerLabel="Calculation">
              <ProjectBreadcrumb
                projectID={projectID}
                currentPath="Calculation"
              />
            </StandardTableHeader>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Link
              to={"/sales/calculations/edit/" + calculation.calculationID}
              className="button"
            >
              <div className="ui left floated primary button">Edit</div>
            </Link>
            <Link
              to={"/sales/calculations/delete/" + calculation.calculationID}
              className="button"
            >
              <div className="ui left floated negative button">Delete</div>
            </Link>
            <button
              type="button"
              className="ui button"
              onClick={history.goBack}
            >
              Back
            </button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {renderBody()}
      <DeleteCalculationRow />
    </div>
  );
};

export default CalculationDetail;
