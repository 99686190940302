import _ from "lodash";

import {
  FETCH_RELATION,
  FETCH_SUPPLIERS,
  FETCH_CUSTOMERS,
  UNLOAD_RELATION,
  FETCH_RELATIONS_PAGED,
  SET_RELATIONS_PAGE_FILTER,
  SET_RELATIONS_RELATION_TYPE_FILTER,
  RESET_RELATIONS_PAGE_FILTER,
  SET_RELATIONS_PAGE_NUMBER,
} from "../actions/types";

var initialState = {
  item: null,
  pageItems: {},
  pageMetadata: {},
  pageNumber: 1,
  pageFilter: "",
  suppliers: {},
  customers: {},
  relationTypeFilter: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RELATION:
      return {
        ...state,
        item: action.payload,
      };
    case UNLOAD_RELATION:
      return {
        ...state,
        item: null,
      };
    case FETCH_SUPPLIERS:
      return {
        ...state,
        suppliers: {
          ..._.mapKeys(action.payload.result, "relationID"),
        },
      };
    case FETCH_CUSTOMERS:
      return {
        ...state,
        customers: {
          ..._.mapKeys(action.payload.result, "relationID"),
        },
      };
    case FETCH_RELATIONS_PAGED:
      return {
        ...state,
        pageItems: {
          ..._.mapKeys(action.payload.result, "relationID"),
        },
        pageMetadata: { ...action.payload.metadata },
      };
    case SET_RELATIONS_PAGE_FILTER:
      return {
        ...state,
        pageFilter: action.payload,
        pageNumber: 1,
      };
    case SET_RELATIONS_RELATION_TYPE_FILTER:
      return {
        ...state,
        relationTypeFilter: action.payload,
        pageNumber: 1,
      };
    case RESET_RELATIONS_PAGE_FILTER:
      return {
        ...state,
        relationTypeFilter: action.payload,
        pageFilter: "",
        pageNumber: 1,
      };
    case SET_RELATIONS_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    default:
      return state;
  }
};
