import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from "semantic-ui-react";
import { Link } from "react-router-dom";

import * as util from "../../shared/utility";
import * as actions from "../../actions";
import StandardTableHeader from "../UI/StandardHeader";

const RelationList = ({ relationType, newItemUrl, headerText }) => {
  const dispatch = useDispatch();

  const pageMetadata = useSelector((state) => state.relations.pageMetadata);
  const pageItems = useSelector((state) => state.relations.pageItems);
  const relationsPageFilter = useSelector(
    (state) => state.relations.pageFilter
  );
  const relationTypeFilter = useSelector(
    (state) => state.relations.relationTypeFilter
  );
  const pageNumber = useSelector((state) => state.relations.pageNumber);

  const [searchValue, setSearchValue] = useState(relationsPageFilter);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      dispatch(actions.setRelationsRelationTypeFilter(relationType));
      dispatch(
        actions.fetchRelationsPaged(
          relationsPageFilter,
          pageNumber,
          relationType
        )
      );
    } else {
      dispatch(
        actions.fetchRelationsPaged(
          relationsPageFilter,
          pageNumber,
          relationTypeFilter
        )
      );
    }

    // On a reset, make sure the searchvalue is also resetted.
    if (searchValue !== relationsPageFilter) {
      setSearchValue(relationsPageFilter);
    }
  }, [relationsPageFilter, pageNumber, relationTypeFilter]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchValue !== relationsPageFilter) {
        dispatch(actions.setRelationsPageFilter(searchValue));
      }
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchValue]);

  const relationlist = Object.values(pageItems)
    .sort((a, b) => b.lastUpdatedDateTime.localeCompare(a.lastUpdatedDateTime))
    .map((relation) => {
      return (
        <tr key={relation.relationID}>
          <td className="mobile hidden">{relation.relationID}</td>
          <td>
            <Link
              className="item"
              to={`/purchasing/suppliers/edit/${relation.relationID}`}
            >
              <div className="item" title={relation.description}>
                {relation.name}
              </div>
            </Link>
          </td>
          <td className="mobile hidden">
            {Object.keys(util.RelationType)[relation.relationType]}
          </td>
          <td className="mobile hidden">
            {util.utcStringToLocalDateTime(relation.lastUpdatedDateTime)}
          </td>
          <td>
            <Link
              className="item"
              to={`/purchasing/suppliers/edit/${relation.relationID}`}
              key={relation.relationID}
            >
              <i className="edit icon" />
            </Link>
          </td>
          <td>
            <Link
              className="item"
              to={`/purchasing/suppliers/delete/${relation.relationID}`}
              key={relation.relationID}
            >
              <i className="trash icon" title="Delete record." />
            </Link>
          </td>
        </tr>
      );
    });

  const renderTable = (
    <>
      <table className="ui celled table unstackable">
        <thead>
          <tr>
            <th className="one wide mobile hidden">ID</th>
            <th className="nine wide">Name</th>
            <th className="three wide mobile hidden">Type</th>
            <th className="three wide mobile hidden">Changed</th>
            <th className="one wide center aligned " colSpan="2">
              Action
            </th>
          </tr>
        </thead>
        <tbody>{pageMetadata.totalCount > 0 && relationlist}</tbody>
      </table>
      {pageMetadata.totalCount > 0 && (
        <Pagination
          activePage={pageMetadata.currentPage}
          totalPages={pageMetadata.totalPages}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          onPageChange={(e, props) =>
            dispatch(actions.setRelationsPageNumber(props.activePage))
          }
        />
      )}
    </>
  );

  const RelationFilter = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          whiteSpace: "nowrap",
          paddingTop: "10px",
        }}
      >
        <select
          className="ui dropdown"
          name="relation type"
          label="RelationType:"
          onChange={(e) =>
            dispatch(actions.setRelationsRelationTypeFilter(e.target.value))
          }
          value={relationTypeFilter}
        >
          <option key={0} value={""}>
            Show all relations
          </option>
          <option key={1} value={"0"}>
            Customers
          </option>
          <option key={2} value={"1"}>
            Suppliers
          </option>
        </select>
        <button
          className="ui button labeled icon"
          onClick={() => {
            dispatch(actions.resetRelationsFilter(relationType));
            setSearchValue("");
          }}
          title="Reset filters."
          style={{
            backgroundColor: "var(--company-bg-color-50)",
            marginLeft: "10px",
          }}
        >
          <i className="undo icon" />
          Reset Filters
        </button>
      </div>
    );
  };

  return (
    <div className="ui one column grid">
      <div className="column">
        <div className="row">
          <StandardTableHeader
            headerLabel={headerText}
            linkForNewButton={newItemUrl}
            recordCount={pageMetadata.totalCount}
            searchValue={searchValue}
            searchTooltip={`You can search on the name.`}
            searchFunction={(e) => setSearchValue(e.target.value)}
          ></StandardTableHeader>
        </div>
        <div className="row">
          <RelationFilter />
        </div>
      </div>
      <div className="column">{renderTable}</div>
    </div>
  );
};

export default RelationList;
