import React from "react";
import { connect } from "react-redux";
import { createProject, fetchCountries } from "../../actions";
import ProjectForm from "./ProjectForm";
import { Grid } from "semantic-ui-react";
import history from "../../history";

class ProjectCreate extends React.Component {
  componentDidMount() {
    if (this.props.countries.length === 0) {
      this.props.fetchCountries();
    }
  }

  onSubmit = (formValues) => {
    this.props.createProject(formValues);
  };

  handleCancelClick = () => {
    history.goBack();
  };

  render() {
    const initialVal = {
      projectAddress_countryID: this.props.defaultCountryID,
    };

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <h3>Create a Project</h3>
            <ProjectForm
              initialValues={initialVal}
              countries={this.props.countries}
              onSubmit={this.onSubmit}
              onCancel={this.handleCancelClick}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    countries: state.countries.items,
    defaultCountryID: state.countries.defaultID,
  };
};

export default connect(mapStateToProps, { createProject, fetchCountries })(
  ProjectCreate
);
