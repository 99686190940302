import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field, reduxForm, change, submit } from "redux-form";

import { Grid, Modal, Button } from "semantic-ui-react";

import * as actions from "../../actions";
import * as util from "../../shared/utility";

let PurchaseOrderSection = ({
  purchaseOrderSectionActive,
  setPurchaseOrderSectionActive,
  checkedIds,
  onSubmit,
  handleSubmit,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchSuppliers());
  }, []);

  const suppliers = useSelector((state) => state.relations.suppliers);

  if (!purchaseOrderSectionActive) {
    return null;
  }

  const onSelectWithSearchChange = (e, data) => {
    dispatch(change("PurchaseOrderSectionForm", data.name, data.value));
  };

  return (
    <Modal
      onClose={() => setPurchaseOrderSectionActive(false)}
      open={!!purchaseOrderSectionActive}
    >
      <Modal.Header>{`Create Purchase Order`}</Modal.Header>
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)} className="ui form error">
          <Grid stackable>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Field
                  name="relationID"
                  label="Supplier:"
                  component={util.RenderSelectWithSearch}
                  handleChange={onSelectWithSearchChange}
                >
                  {[
                    ...[{ key: 0, value: 0, text: "Select a supplier..." }],
                    ...Object.values(suppliers)
                      .sort((a, b) =>
                        a.name.toLowerCase() < b.name.toLowerCase()
                          ? -1
                          : a.name.toLowerCase() > b.name.toLowerCase()
                          ? 1
                          : 0
                      )
                      .map((x) => {
                        return {
                          key: x.relationID,
                          value: x.relationID,
                          text: x.name,
                        };
                      }),
                  ]}
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field
                  name="orderDate"
                  component={util.RenderInputDate}
                  label="Order date:"
                ></Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field
                  name="plannedDeliverDate"
                  component={util.RenderInputDate}
                  label="Planned Deliver date:"
                ></Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field
                  name="deliverDate"
                  component={util.RenderInputDate}
                  label="Deliver date:"
                ></Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </form>
      </Modal.Content>
      <Modal.Actions>
        <button
          className="ui button labeled icon primary"
          onClick={() => dispatch(submit("PurchaseOrderSectionForm"))}
          disabled={checkedIds.length === 0}
          title="Create purchase order."
        >
          <i className="file shopping cart icon" />
          Create Purchase Order
        </button>
        <Button onClick={() => setPurchaseOrderSectionActive(false)}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const validate = (formValues) => {
  const errors = {};

  if (!formValues.relationID || formValues.relationID === "0") {
    errors.relationID = "You need to select a supplier.";
  }

  if (!formValues.orderDate) {
    errors.orderDate = "The order date is mandatory.";
  }

  if (
    !formValues.plannedDeliverDate ||
    formValues.plannedDeliverDate < formValues.orderDate
  ) {
    errors.plannedDeliverDate =
      "The planned deliver date is mandatory and needs to be later then the order date.";
  }

  if (
    formValues.orderDate &&
    formValues.deliverDate &&
    formValues.deliverDate < formValues.orderDate
  ) {
    errors.deliverDate =
      "The deliver date needs to be later then the order date.";
  }

  return errors;
};

export default reduxForm({
  form: "PurchaseOrderSectionForm",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(PurchaseOrderSection);
