import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import * as util from "../../../shared/utility";
import * as actions from "../../../actions";

const DashboardOffers = ({ orders, projectid }) => {
  if (!orders || orders.length === 0) {
    return null;
  }

  const creating = useSelector((state) => state.trellos.creating);
  const responsemessage = useSelector((state) => state.trellos.responsemessage);
  const showmessage = useSelector((state) => state.trellos.showmessage);

  const dispatch = useDispatch();

  const orderList = orders
    .sort((a, b) => b.lastUpdatedDateTime.localeCompare(a.lastUpdatedDateTime))
    .map((order) => {
      return (
        <tr key={order.orderID}>
          <td>
            <Link className="item" to={`/sales/orders/edit/${order.orderID}`}>
              <div className="item">{order.name}</div>
            </Link>
          </td>
          <td className="center aligned">
            <button
              className={`ui button small green ${creating ? "loading" : ""}`}
              disabled={creating}
              onClick={() =>
                dispatch(actions.createTrelloDirect(order.orderID))
              }
              title="Send to trello."
            >
              <i className="trello icon" />

              <div className="mobile hidden" style={{ display: "inline" }}>
                Trello
              </div>
            </button>
          </td>
          <td className="center aligned mobile hidden">
            {util.utcStringToLocalDate(order.deliverDate)}
          </td>
          <td className="center aligned mobile hidden">
            {util.utcStringToLocalDateTime(order.lastUpdatedDateTime)}
          </td>
          <td className="center aligned">
            <i
              style={{ cursor: "pointer" }}
              className="file pdf icon orange"
              title="Download file preview."
              onClick={() => dispatch(actions.previewOrderPdf(order.orderID))}
            />
          </td>
          <td className="center aligned">
            <i
              style={{ cursor: "pointer" }}
              className="file save icon blue"
              title="Create file in project folder."
              onClick={() =>
                dispatch(actions.generateOrderPdf(order.orderID, projectid))
              }
            />
          </td>
          <td className="center aligned">
            <Link
              className="item"
              to={`/sales/orders/delete/${order.orderID}`}
              key={order.orderID}
            >
              <i className="trash icon" title="Delete record." />
            </Link>
          </td>
        </tr>
      );
    });

  return (
    <div className="ui one column grid">
      <div className="row">
        <div className="column">
          <table className="ui celled very compact table unstackable">
            <thead>
              <tr>
                <th className="seven wide">
                  <i className="handshake icon large" />
                  <h4 className="dashboardHeaderTexts">Orders</h4>
                </th>
                <th className="two wide center aligned">Trello</th>
                <th className="two wide center aligned mobile hidden">
                  Deliver
                </th>
                <th className="three wide center aligned mobile hidden">
                  Changed
                </th>
                <th className="two wide center aligned" colSpan="3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{orderList}</tbody>
          </table>
          {(() => {
            if (showmessage) {
              return (
                <div className="ui positive message">
                  <i
                    className="close icon"
                    onClick={() => dispatch(actions.resetTrelloMessage())}
                  />
                  <div className="header">Trello response:</div>
                  <p>{responsemessage}</p>
                </div>
              );
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default DashboardOffers;
