import {
  SEND_MAIL_RESET,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_FAIL,
  SET_MAILTEMPLATE,
  SEND_MAIL_START,
  SET_PROJECT_MAIL_DTO,
  CLEAR_MAIL_RESPONSE_MESSAGE,
} from "../actions/types";

var initialState = {
  responsemessage: "",
  showmessage: false,
  mailTemplate: null,
  projectMailDto: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_MAIL_RESET:
      return {
        ...state,
        responsemessage: "",
        showmessage: false,
        mailTemplate: null,
        projectMailDto: null,
      };
    case SEND_MAIL_START:
      return {
        ...state,
        showmessage: false,
      };
    case SEND_MAIL_SUCCESS:
      return {
        ...state,
        responsemessage: action.payload,
        showmessage: true,
      };
    case SEND_MAIL_FAIL:
      return {
        ...state,
        responsemessage: action.payload,
        showmessage: true,
      };
    case SET_MAILTEMPLATE:
      return {
        ...state,
        mailTemplate: action.payload,
      };
    case SET_PROJECT_MAIL_DTO:
      return {
        ...state,
        projectMailDto: action.payload,
      };
    case CLEAR_MAIL_RESPONSE_MESSAGE:
      return {
        ...state,
        responsemessage: "",
        showmessage: false,
      };
    default:
      return state;
  }
};
